import React, { useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Collapse, ListGroupItem } from 'reactstrap';

const ThemeSelection  = ({children,title, required}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
      
      <ListGroupItem className='accordion'>
        <Card style={{overflow:'visible'}}  >
          <CardHeader  onClick={toggle} className={isOpen? 'accordion-title active':'accordion-title'}>
            
            {(required === true || required === false) && <Badge color={required?'soft-gray':'soft-green'} className='mr-2'>{required?'Required':'Complete'}</Badge>}
            {title}
            </CardHeader>
       
          <Collapse isOpen={isOpen}>
            <CardBody  >{children}</CardBody>
          </Collapse>
        </Card>
      </ListGroupItem>
  );
};

export default ThemeSelection;
