import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from '../../common/Section';
import bagel from '../../../assets/images/illustration/pushka/bagelpushka.png'
const HowItWorks = ({theme}) => {
  return (
<Section bg='white' className=''>
  <Row className="flex-center bg-white px-md-4 px-2 text-md-left text-center" >
    <Col md lg={6} xl={5} className={classNames('pl-lg-6', { 'order-md-2': true })}>
      <h3 className='fs-1 text-primary'>A Modern Approach to Mitzvot</h3>
      <h2 className='fs-3 mb-4 '>Transform Every Purchase into Purpose</h2>
      <p>With your linked Credit Card, Pushka rounds up your purchases
        to the next dollar and sets the change aside to be donated.
        </p>
        <ul className='text-left mt-3'>
          <li>You purchase a bagel for $2.65</li>
          <li>Pushka rounds that bagel purcahse up to $3.00</li>
          <li>The $0.35 in change gets set aside to be donated</li>
        </ul>
        <p>At the end of each week, your donations are grouped together and donated to {theme?.charityName?theme?.charityName: 'your causes'}. </p>
           <Link className={'round  py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/get-started">Get Started</Link>
    </Col>
    <Col md lg={4} xl={5} className="mt-4 mt-md-0 p-0">
      <div>

        <img src={bagel} alt="Logo"className="img-fluid  p-4"/>
      </div>
    </Col>
  </Row>
</Section>
  );
};



export default HowItWorks;
