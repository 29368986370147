  
import React from "react";
import {useDispatch} from 'react-redux'
import {
  Navbar,
  Button,
  Collapse,
  Nav,
  NavItem,
  Container,
} from "reactstrap";
import Logo from "../Logo";

const Topbar = ({  }) => {
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type:"LOG_OUT",
      payload:{
      }
    })
  }

  return (
    <Navbar
      color="light"
      light
      className="navbar p-3 dashboard-topbar"
      expand="md"
    >
      <Logo at='navbar-top'/>

      <Container>
        
        <Collapse isOpen={true} navbar className="scrollbar">
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Button color='link' onClick={logout}>
              Logout
            </Button>
          </NavItem>
        </Nav>
        </Collapse>
      </Container>
     
    </Navbar>
  );
};

export default Topbar;