import React, { Fragment, useEffect } from 'react';
import AlertButton from '../../common/AlertButton';
import { gql, useMutation } from '@apollo/client';
import {useDispatch} from 'react-redux'

const Activate  = () => {

  
const ACTIVATE = gql`
mutation SetUserProfileActive{
  setUserProfileActive{
    firstName
    lastName
    email 
    active
    inactiveReason
    requirements
    setupStep
    dailyMax
    dailyMaxAmount
    transferDay
    wholeDollarRoundup
  }
}
`;
const [mutate,{data, loading, error}] = useMutation(ACTIVATE)
const dispatch = useDispatch()

useEffect(() => {
  if(data){
    dispatch({
      type:"UPDATE_USER",
      payload: {
        ...data.setUserProfileActive,
      }
    })
  }
}, [data])
  return (
<Fragment>
  
  <AlertButton 
    color='red'
    buttonTitle='Activate'
    onClick={mutate}
    loading={loading}
    error={error}
  >Your account isnt active. Activate your account</AlertButton>
</Fragment>
  );
};

export default Activate;
