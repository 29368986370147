import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Card, Col, Row, Spinner } from 'reactstrap';
import Flex from './Flex';

const ComingSoon = ({loading}) => (    
  <Card className='py-5'>

<Flex column align='center' justify="center">
  <h5 className='text-muted'>Coming soon...</h5>
    </Flex>
  </Card>
);


ComingSoon.propTypes = { ...Spinner.propTypes };

ComingSoon.defaultProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary'
};

export default ComingSoon;
