import React, { Fragment, useEffect } from 'react';
import AuthBasicLayout from '../../layouts/AuthBasicLayout';
import NonprofitDetailsForm from './forms/NonprofitDetailsForm';
import {useSelector} from 'react-redux'

import { useHistory } from "react-router-dom";


const NonprofitDetails = () => {
  let history = useHistory();

  const {charity} = useSelector((state) => state.charityUser)
  useEffect(() => {
    if(charity){
      history.push('/onboarding');
    }
  }, [charity])
  return (
    <AuthBasicLayout>
        <NonprofitDetailsForm/>
    </AuthBasicLayout>
  );
};


export default NonprofitDetails;
