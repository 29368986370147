import React, { Fragment,  useState } from 'react';
import { Button } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage'
import Flex from '../../common/Flex'


const UpdateCharityUserIdentities  = () => {
    const UPDATE_DONORS = gql`
    mutation updateCharityUserIdentities{
      updateCharityUserIdentities
    }
    `;
    const [update, {data, error,loading}] = useMutation(UPDATE_DONORS)

  return (
      <Flex align='center'>
        <Button onClick={update}>Update</Button>
        {loading && <p className='mb-0 ml-2'>loading...</p>}
        {error && <ErrorMessage error={error}/>}
      </Flex>
      
  );
};

export default UpdateCharityUserIdentities;
