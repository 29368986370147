import React, { useState, useEffect, Fragment } from 'react';
import {  Card, Spinner, Table, Button} from 'reactstrap';
import { gql, useQuery,  } from '@apollo/client';
import classNames from 'classnames'
import { usePagination, useTable } from 'react-table';
import moment from 'moment'
import Flex from '../common/Flex';
import BadgePending from './cell-renderings/BadgePending';
import BadgeSucceeded from './cell-renderings/BadgeSucceeded';
import Source from './cell-renderings/Source';
import TableLoader from '../common/TableLoader';
import TableNoData from '../common/TableNoData';
const AdminNonprofitTable  = ({className,approved,handleRowClick}) => {


  
  const NONPROFITS = gql`
  query nonprofits($approved: Boolean,){
    nonprofits(approved:$approved){
      _id
      EIN
      taxStatus
      name
      url
      approved
      subCharity
      created
      tenant{
        subdomain
      }
    }
  }
  `;
  const {data,error,loading,refetch } = useQuery (NONPROFITS,{variables:{approved:approved}, fetchPolicy: "no-cache"}) 
  const columns = React.useMemo(
    () => [
      {
        Header: '_id',
        id: '_id',
        accessor: d => d._id,
        Cell: props => props.value
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
        Cell: props => props.value
      },
      {
        Header: 'EIN',
        id: 'EIN',
        accessor: d => d.EIN,
        Cell: props => props.value
      },
      {
        Header: 'Tax',
        id: 'taxStatus',
        accessor: d => d.taxStatus,
        Cell: props => props.value
      },
      {
        Header: 'Website',
        id: 'url',
        accessor: d => d.url,
        Cell: props => props.value
      },
      {
        Header: 'Approved',
        id: 'approved',
        accessor: d => d.approved,
        Cell: props => props.value===true?"Yes":"No"
      },
      {
        Header: 'Sub?',
        id: 'subCharity',
        accessor: d => d.subCharity,
        Cell: props => props.value===true?"Yes":"No"
      },
      {
        Header: 'Tenant',
        id: 'tenant',
        accessor: d => d.tenant?.subdomain,
        Cell: props => props.value
      },
      {
        id:'created',    
        Header: 'Created',
        accessor: d => d.created,
        Cell: props => <div>{ moment(props.value).local().format("MM/D/YY")}</div>
      },
      // {
      //   Header: 'EIN',
      //   id: 'EIN',
      //   accessor: d => d.EIN,
      //   Cell: props => <div>{"$"+ Number(props.value).toFixed(2)}</div>
      // },
      // {
      //   Header: 'Account',
      //   id: 'account',
      //   accessor: d => d.payoutAccount.last4,
      //   Cell: props => <Source last4={props.value} />
      // },
      // {
      //   id:'status',    
      //   Header: 'Status',
      //   //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
      //   accessor: d => d.status,
      //   Cell: props => <Fragment>
      //     {props.value==='pending' &&<BadgePending/> }
      //     {props.value==='succeeded' &&<BadgeSucceeded/> }
      //     </Fragment>
      // },

      // {
      //   id:'link',    
      //   Header: '',
      //   //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
      //   accessor: d =>     
      //   <NavItem  className='list-unstyled'>
      //   <NavLink className='p-0' tag={Link} to={'?_id='+d._id}>
      //       <FontAwesomeIcon icon={faExternalLinkAlt} className="" size="md"/>
      //   </NavLink>
      // </NavItem>
      // },

    ],
    []
  )

  function TablePaginaton({ columns, data,initialPageSize, }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      pageCount,
      nextPage,
      previousPage,
      state: { pageIndex },
      
    } = useTable(
      {
        columns,
        data,
        initialState: { 
          pageIndex: 0, 
          pageSize: 20,
          hiddenColumns: ['_id']
        },
      },
      usePagination
    )
  
    // Render the UI for your table
    return (
      <Fragment>
      {/* <Button onCllick={() => refetch()} disabled={loading}>refresh</Button> */}
        <div className={`bg-white ${className}`}>
        <Table responsive hover size="sm" className='fs--1 table-bordered' {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} onClick={()=>handleRowClick(row?.values)}>
                      {row.cells.map(cell => {
                        return <td className='' {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
              {loading && <TableLoader height={130}/>}
              {!loading && !data[0] &&  <TableNoData message={"No Results"} height={250}/>}
                       <Flex wrap align='center' className='pl-1'>
              <p className='m-0 fs--1 text-muted'>{"results: " + data.length}</p>
              
            <Flex justify='end' align='center' className='ml-auto' >
              <p className='m-0 mr-2 fs--1'>{'page ' }{ pageIndex + 1} {' of ' + pageCount}</p>
              <ul className='pagination mb-0'>
                <li  className='page-item'>
                  <Button disabled={pageIndex===0} color='falcon-primary' size='sm' onClick={() => previousPage()}>Previous</Button>
                </li>
                <li  className='page-item'>
                  <Button disabled={pageIndex===pageCount-1}  color='falcon-primary' size='sm' onClick={() => nextPage()}>Next</Button>
                </li>
                <li  className='page-item'></li>
              </ul>
            </Flex>
            </Flex>
            
          </div>
      </Fragment>
      
    )
  }
 
  return (
    <div>
      <TablePaginaton
       // tooltip={tooltip}
        //title={title}
        columns={columns}
        data={data?data.nonprofits:[]}
        initialPageSize={3}
        loading={loading}
        //renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
};

export default AdminNonprofitTable;
