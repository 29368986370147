import React from 'react';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout'
import CharityForgetPasswordForm from '../forms/CharityForgetPasswordForm';
// import ReactGA from 'react-ga';

const CharityForgetPassword = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  return (
    <AuthBasicLayout>
    <div className="text-center">
      <h5 className=""> Forgot your password?</h5>
      <p>Enter your email and we'll send you a One Time Passcode (OTP)</p>
      <CharityForgetPasswordForm />
    </div>
    </AuthBasicLayout>
  );
};

export default CharityForgetPassword;
