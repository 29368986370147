import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Logo from '../components/navbar/Logo';
import Section from '../components/common/Section';
import {useSelector} from 'react-redux'
import PushkaLogo from '../components/navbar/PushkaLogo';

const AuthBasicLayout = (props) => {
  const {pushka} = useSelector((state) => state.config)
  return(
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                    {pushka 
                      ? <PushkaLogo at='auth' />
                      :<Logo at='auth' />
                      }
        <Card>
          <CardBody className="fs--1 font-weight-normal p-sm-4 p-2 py-sm-5 py-4 ">
            {props.children}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Section>
)};

export default AuthBasicLayout;
