import React, { useState, useEffect,Fragment } from 'react';
import { Card, Badge, Button,ButtonGroup ,ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle, Spinner } from 'reactstrap';
import Flex from '../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { gql, useMutation, } from '@apollo/client';
import AlertModal from '../../common/AlertModal';
import {useSelector,useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import {toast} from 'react-toastify'

const ACTIVATE_CREDIT_ACCOUNT = gql`
mutation ActivatePlaidCreditAccount($accountId: String!){
  activatePlaidCreditAccount(accountId:$accountId)
}
`;

const CardAccountOptions = ({account,verifyAccount, setAsDefault, refetch, loading}) => {
  
  const {item_id,autoActivate} = useSelector((state) => state.plaid)
  const [dropdown, setDropdown] = useState(false)
  const {appName} = useSelector((state) => state.theme)
  const dispatch = useDispatch()
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const toggle =() => {
    setDropdown(!dropdown)
  }
  const [activateCreditAccount, activeCreditAccountResponses] = useMutation(ACTIVATE_CREDIT_ACCOUNT, {
    variables:{accountId:account._id}
  })

  const activate = async ( ) => {
    await activateCreditAccount()
    .then(res => {
      if(res?.data?.activatePlaidCreditAccount === false){
        toast.info('Card removed')
      }else if(res?.data?.activatePlaidCreditAccount === true){
        toast.success('Card added')
      }
    })
    .catch(e => {
      toast.error(e.message)
    })

    await refetch()
    
    //alert('click')
  }

  const plaidReset = () => {
    dispatch({
      type:"PLAID_RESET_STATE",
    })
  }

  useEffect(() => {
    if(account.item_id === item_id && autoActivate && !account.active){
      activate()
      plaidReset()
    }
    if(account.item_id === item_id && autoActivate && account.active){
      plaidReset()
    }
  }, [account,item_id])
  const loginModal = () => {
    setModal(true)
  }
  const goToReauth = () => {
    // dispatch({
    //   type:"UPDATE_PLAID_STATE",
    //   payload: {
    //     redirectURL:window.location.href,
    //     _id:account.plaidItem._id
    //   }
    // })
    history.push('/cards/restore');
  }
  

  return (
    <Fragment>
      <AlertModal modal={modal} setModal={setModal} title={"Please Reauthenticate for Round-Ups"} buttonText='Login to fix' onClick={goToReauth}>
        <p>For your security, {account.plaidItem.name} ask you to reauthenticate Round-Ups.</p>
            <p>Please login allow Round-Ups for your {account.name} card.</p>
        </AlertModal>
      <Flex align='center'>
      {(activeCreditAccountResponses.loading || loading) && 
        <Spinner size="sm" color="secondary" className='mr-2' />
      }
      { !activeCreditAccountResponses.loading && !loading && !account.active &&
      
        <Button className='border btn-white px-2 py1 shadow-sm mr-2 fs--1' onClick={activate}>
          activate
        </Button>
      
      }
        <ButtonGroup>
            {(account.plaidItem.loginRequired && account.active ) &&
              <Button className='border btn-white px-2 py1 shadow-sm' onClick={loginModal} >
                  <FontAwesomeIcon icon={faExclamationTriangle} color='black' size='1x'/>
              </Button>
            }
            <ButtonDropdown direction="left"  isOpen={dropdown} toggle={toggle}>
                <DropdownToggle className='rounded-right border btn-white px-2 py1 shadow-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} color='gray' size='1x'/>
                </DropdownToggle>
                <DropdownMenu>
                  
  <h6 class="dropdown-header">ACTIONS</h6>
                    {account.active && <DropdownItem  onClick={activate} >Deactivate</DropdownItem>}
                    {!account.active && <DropdownItem  onClick={activate} >Activate</DropdownItem>}

                    {/* {account.status!=='verified' && <DropdownItem  >Verify Account</DropdownItem>}
                    {/* {account.status!=='verified' && <DropdownItem  >Verify Account</DropdownItem>}
                    <DropdownItem disabled={account.default} >Set as default</DropdownItem> */}
                    <DropdownItem disabled >Remove</DropdownItem>

                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>
    </Flex>

    </Fragment>
    
  )
}



export default CardAccountOptions