import React, { useEffect,useState } from 'react';
import { Card,} from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames'
const INVITE_LINK = gql`
query myInviteLink{
  myInviteLink
}
`;


const Invite  = ({className}) => {  
  const {data} = useQuery(INVITE_LINK, {})
const [link,setLink] = useState([]);

useEffect(() => {
  if(data){
    setLink(data.myInviteLink)
  }
}, [data])
  return (
      
    <div  className={classNames(' ',className )}>
    <Card className='p-md-4 p-2 shadow-none'>
          <h5 className=''>Invite Link</h5>
          <p>Your custom invite page is located here. Send your donors here to sign up and support your campaign.</p>
      <a href={link}target="_blank">{link}
      </a>
            
    </Card></div>
  );
};

export default Invite;
