import React, { Fragment, useState, useEffect } from 'react';
import { ListGroup, Spinner } from 'reactstrap';
import ThemeSelection from '../themeDashboard/ThemeSection';
import General from '../themeDashboard/General';
import ThemeColors from '../themeDashboard/ThemeColors';
import { gql,  useQuery  } from '@apollo/client';
import Logos from '../themeDashboard/Logos';
import AboutSection from '../themeDashboard/AboutSection';
import ResetTheme from '../themeDashboard/ResetTheme';
import HeroSection from '../themeDashboard/HeroSecion';
import AboutText from '../themeDashboard/AboutText';
import HeroImage from '../themeDashboard/HeroImage';
import Footer from '../themeDashboard/Footer';
import PublishTheme from '../themeDashboard/PublishTheme';
import DashboardLayout from '../../layouts/DashboardLayout';
// import ReactGA from 'react-ga';

const Customization  = ({ match: { url } }) => {
  // ReactGA.pageview(url);

  const [theme, setTheme] = useState({})
  const [config, setConfig] = useState({})
  const THEME = gql`
  query{
    myTheme{
      primary
      secondary
      iconLogo
      heroImage
      appName
      charityName
      aboutImage1
      aboutImage2
      aboutImage3
      bioImage1
      about
      quote
      bioTitle
      heroTitle
      heroSubtitle
      heroText
      mission
      url
      urlMask
      facebook
      instagram
      published
      brandIdentityRequired
      themeColorsRequired
      mainImageRequired
      aboutTextRequired
      aboutImageRequired
      footerRequired
    }
  }
  `
  const CONFIG = gql`
  query{
    myConfig{
      appName
    }
  }
  `
  const { data, loading, refetch  }  = useQuery(THEME
    )
  const myConfig  = useQuery(CONFIG)


  useEffect(() => {
    if(data)
    setTheme(data.myTheme)
  }, [data])

  useEffect(() => {
    if(myConfig.data)
    setConfig(myConfig.data.myConfig)
  }, [myConfig.data])

  return (
    <DashboardLayout>   
         {loading && <Spinner/>}
      <h4>Customize your campaign</h4>
      <p>You can customize your campaign website to tell your non-profit’s story. Click a tab below to start customizing.</p>

      {data && 
      <Fragment>
        <ListGroup>
          {/* <PublishTheme key='publish' theme={theme}  refetch={refetch}/> */}
          <ThemeSelection key="brand" title="Brand identity" required={theme?.brandIdentityRequired} ><General theme={data?.myTheme} config={config} refetch={refetch} refetchConfig={myConfig.refetch}/></ThemeSelection>
          <ThemeSelection key="colors" title="Theme colors"  required={theme?.themeColorsRequired}><ThemeColors theme={data?.myTheme} refetch={refetch}/></ThemeSelection>
          {/* <ThemeSelection key="logos" title="Logos"><Logos theme={theme} refetch={refetch}/></ThemeSelection> */}
          {/* <ThemeSelection key="bannerText" title="Banner text"><HeroSection theme={theme} refetch={refetch}/></ThemeSelection> */}
          <ThemeSelection key="bannerImage" title="Main image"  required={theme?.mainImageRequired}><HeroImage theme={data?.myTheme} refetch={refetch}/></ThemeSelection>
          <ThemeSelection key="aboutText" title="About text"  required={theme?.aboutTextRequired}><AboutText theme={data?.myTheme} refetch={refetch}/></ThemeSelection>
          <ThemeSelection key="aboutImage" title="About image"  required={theme?.aboutImageRequired}><AboutSection theme={data?.myTheme} refetch={refetch}/></ThemeSelection>
          <ThemeSelection key="footer" title="Footer"  required={theme?.footerRequired}><Footer theme={data?.myTheme} refetch={refetch}/></ThemeSelection>
          {/* <ThemeSelection key="reset" title="Reset Theme" ></ThemeSelection> */}
        </ListGroup> 
        
        <ResetTheme  key="reset"  refetch={refetch}/>
      </Fragment>}
      
  </DashboardLayout>
  );
};

export default Customization;
