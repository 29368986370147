//import './App.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/scss/theme.scss'
//import './assets/scss/theme/theme.scss'
import React from 'react';
import {  ApolloProvider } from '@apollo/client';
// import Tenant from './multiTenancy/Tenant';
// import Layout from './layouts/Layouts';
import { Provider } from 'react-redux'
import store from './redux/store'
import client from './apollo/client'
import Initialize from './Initialize';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LayoutAlt from './layouts/LayoutsAlt';
import ReactGA from 'react-ga';
import usePageViews from './hooks/usePageViews'
import { Switch } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { CloseButton, Fade } from '../src/components/common/Toast';


// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
// ReactGA.pageview(window.location.pathname + window.location.search);
Sentry.init({
  dsn:process.env.REACT_APP_SENTRY_DSN_FRONTEND,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


function App() {
    return (

      <Switch>
        <Sentry.ErrorBoundary>
              <Provider store={store}>
                <ApolloProvider client={client} >
                  <Initialize>    
                    {/* <IntercomProvider> */}
                      <LayoutAlt/> 
                    {/* </IntercomProvider>  */}
                  </Initialize>
                  {/* <Tenant>      
                      <Layout/>  
                  </Tenant> */}
                <ToastContainer theme="light" position={toast.POSITION.BOTTOM_CENTER} />
                </ApolloProvider>
              </Provider>
            </Sentry.ErrorBoundary>
      </Switch>
  );
}

export default App;
