import React from 'react';
import {  Card, Badge } from 'reactstrap';
import Flex from '../../common/Flex';
import BadgePending from '../../tables/cell-renderings/BadgePending';
import BadgeSucceeded from '../../tables/cell-renderings/BadgeSucceeded';
import moment from 'moment'
import Source from '../../tables/cell-renderings/Source';

const Summary  = ({transfer}) => {
  return (

      <Card className='shadow-none p-2 mb-3'>
        <Flex align='center'>
            <h3 className='mb-0 mr-2'>${transfer.amount?Number(transfer.amount).toFixed(2):""}</h3>
          <Badge className='mr-2 p-2' color='yellow'>USD</Badge>
          {transfer.status==='pending'
          ?<BadgePending/>
          :<BadgeSucceeded/>}
        </Flex>
        <hr/>
        <Flex wrap>
          <div className='border-right pr-3 mr-3'>
            <p className='mb-0'>Date</p>
            <p className='text-muted'>{ moment(transfer.created).local().format("MMM D, YYYY")}</p>
          </div>
          <div>
           <p className='mb-0'>Source</p>
           {transfer.source && <Source last4={transfer.source.last4}/>}

          </div>
        </Flex>
      </Card>
  );
};

export default Summary;
