import React, { Fragment } from 'react';
import {  CardBody, CardText, CardSubtitle, CardTitle } from 'reactstrap';

const Step4 = () => {
  return (
    <Fragment>

<CardBody className='theme-wizard-header rounded  px-md-8  px-sm-6 px-2  py-sm-7 py-5'>
          <CardTitle>Set donation preferences</CardTitle>
          <CardSubtitle>You're in control! Set your donation preferences below and click "Finish".</CardSubtitle>
          <CardText>
          </CardText>
        </CardBody>
        </Fragment>
  );
};

export default Step4;
