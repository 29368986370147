const initialState = {
    step: 0,
    mfa: false,
    setupPassword:null,
    getStartedLoaded:false
};

const getStartedReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "LOG_OUT":
            return {
                ...initialState
            }
        case "UPDATE_GET_STARTED":
            return {
                ...state,
                ...payload,
                getStartedLoaded:true
                // step:payload.step?payload.step:state.step,
                // mfa:payload.mfa?payload.mfa:state.mfa,
            }
        // case "STEP_3_VIEW":
        //     return {
        //         ...state,
        //         step3view:payload.step3view
        //     }
        default:
            return state
    }
}

export default getStartedReducer