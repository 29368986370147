import React from 'react';
import classNames from 'classnames';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from '../../common/Section';
import Flex from '../../common/Flex';
import { Image } from 'cloudinary-react';
import Flame from '../../../assets/images/svgr/Flame';
import AboutImageGrid from '../../landing/About/AboutImageGrid';
const AboutCharity = ({theme}) => {
  const title = (theme.aboutTitle)
  const subTitle = (theme.aboutSubtitle)
  const text = (theme.aboutText)
  const quote = (theme.quote)
  const bioTitle = (theme.bioTitle)
  const bioImage = (theme.bioImage1)
  return (
    <div className='bg-black position-relative overflow-hidden' >
    <Flame className='flame-background'/>
  <Section className=' text-white '>
    <Row className="flex-center py-lg-8 p-md-6 p-4" >
  <Col lg={8} xl={8} className={classNames('pr-md-7 p-2', { 'order-lg-2': false })}>
        <h2 className='mb-4 text-white'>About {theme.charityName}</h2>
          {subTitle && <p className='font-weight-semi-bold'>{subTitle}</p>}
        <p className=''>{text}</p>
          <hr className=' border-muted py-3'/>
          <Flex align='center' className='flex-wrap-reverse flex-lg-nowrap'>
            <div></div>
        <Image  className={'about-image-quote mr-3 '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={bioImage}/>
           
            <div className=''>
              <p className=''>{quote}</p>
                  <p className=''>{bioTitle}</p>
            </div>
          </Flex>
          <Link className={'round  px-md-5 px-4 mt-5 btn btn-secondary btn-lg'} to="/get-started">Join Our Cause</Link>
  
      </Col>
     
    <AboutImageGrid iconLogo={theme.iconLogo} aboutImage1={theme.aboutImage1} aboutImage2={theme.aboutImage2} aboutImage3={theme.aboutImage3} />
   </Row>
  </Section>
    </div>
    



  );
};



export default AboutCharity;
