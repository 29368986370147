import {combineReducers} from 'redux'
import themeReducer from './themeReducer'
import configReducer from './configReducer'
import getStartedReducer from './getStartedReducer'
import authReducer from './authReducer'
import userReducer from './userReducer'
import charityUserReducer from './charityUserReducer'
import inviteReducer from './inviteReducer'
import plaidReducer from './plaidReducer'
import processReducer from './processReducer'
import intercomReducer from './intercomReducer'

const reducer = combineReducers({
    theme: themeReducer,
    config:configReducer,
    getStarted:getStartedReducer,
    auth:authReducer,
    user:userReducer,
    charityUser:charityUserReducer,
    inviteReducer:inviteReducer,
    plaid:plaidReducer,
    process:processReducer,
    intercom:intercomReducer
})

export default reducer