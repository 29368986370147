import React, { Fragment,useCallback, useState, useEffect,useImperativeHandle ,forwardRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import ButtonIcon from '../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import {useSelector} from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client';
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import Loader from '../common/Loader'
const CREATE_LINK_TOKEN = gql`
query CreateLinkToken($products: [String!]){
  createLinkToken(products:$products)
  }
`;

const OpenPlaidLink = ({ token,onSuccess,onExit }) => {

  const config = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    token,
    onSuccess,
    onExit,
    //receivedRedirectUri: document.location.href, // required for OAuth
    // onExit
    // onEvent
  };

  const { open, ready, error } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    // alert(ready)
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  // don't render anything, just open Link
  return null;
};

export default OpenPlaidLink;
