import React from 'react';
import {  Card, } from 'reactstrap';
import Flex from '../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  moment  from 'moment';

const TimelineElement = ({title,date,shortDate}) => {
  return(

    <Flex className='border-bottom py-2' align='center'>
    <FontAwesomeIcon color='green' icon={"check"} className='mr-2'/>
    <div>
      <p className='mb-0'>{title}</p>
      <p className='mb-0 text-muted'>{ moment(date).local().format(shortDate?"MMM D, YYYY":"MMM D, YYYY, h:mm a")}</p>
    </div>
  </Flex>
  )
}

const Timeline  = ({donation}) => {
  return (
      
      <Card className='shadow-none p-2 mb-3'>
        <h5>Timeline</h5>
        <hr className='mb-0'/>
          <TimelineElement title={"Transaction Created"} date={donation.date} shortDate/>
        {donation.pendingTransaction &&
          <TimelineElement title={"Pending Transaction Recorded"} date={donation.pendingTransaction.created}/>
        }
        {donation.pendingTransaction &&
          <TimelineElement title={"Pending Roundup Created"} date={donation.created}/>
        }
        {donation.transaction &&
          <TimelineElement title={"Transaction Posting Recorded"} date={donation.transaction.created}/>
        }
        {donation.transaction &&
          <TimelineElement title={"Roundup Created"} date={donation.created}/>
        }
        {donation.processedAt &&
          <TimelineElement title={"Donation Processed"} date={donation.processedAt}/>
        }
      </Card>
  );
};

export default Timeline;
