import React, { Fragment,useCallback, useState, useEffect,useImperativeHandle ,forwardRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import ButtonIcon from '../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import {useSelector} from 'react-redux'
import { gql, useMutation } from '@apollo/client';
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import Loader from '../common/Loader'
import OpenPlaidLink from './OpenPlaidLink';
import PlaidLoader from '../common/PlaidLoader';
import PlaidErrorModal from './PlaidErrorModal';
// import ReactGA from 'react-ga';
import OpenPlaidOAuthLink from './OpenPlaidOAuthLink';
import { toast } from 'react-toastify';

const CREATE_PLAID_ITEM = gql`
mutation CreatePlaidItem($publicToken: String!){
  createPlaidItem(publicToken:$publicToken){
    item_id
    name
    color
    logo
  }
}
`;
const RELOGIN_SUCCESS = gql`
mutation plaidItemReloginSucccess($_id: String!){
  plaidItemReloginSucccess(_id:$_id)
}
`;
const OAuthPage = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {redirectURL,product, initialized,autoActivate,linkToken,updateLogin,_id} = useSelector((state) => state.plaid)
  // const [linkToken, setLinkToken] = useState('')
  const dispatch = useDispatch()
  const [products, setProducts] = useState(null)

  const [modal, setModal] = useState(false);
  const [publicToken, setPublicToken] = useState(null);
  const [error, setError] = useState(null);
  let history = useHistory();

  const [plaidItemReloginSucccess, plaidItemReloginSucccessResponse] = useMutation(RELOGIN_SUCCESS, {
    variables:{_id:_id}
  })

  //cases:
  // 1) after store is updated it will set local storage and get the link token
  // 2) because it will always be a redirect, this componet should look at the local storage, set the plaid redux state, then it will update redux and go to case 1. 
  //   - this will be if redirectUrl is null


  useEffect(() => {
    //case 1
    if(initialized && redirectURL && product){
     // alert('case 1')
      localStorage.setItem("plaidRedirect",redirectURL)
      localStorage.setItem("plaidProducts",JSON.stringify(product))
      localStorage.setItem("plaidAutoActivate",autoActivate)
      localStorage.setItem("plaidLinkToken",linkToken)
      localStorage.setItem("plaidUpdateItemId",_id)
      localStorage.setItem("plaidUpdateLogin",updateLogin)
    }
    //case 2
    else if(!redirectURL){
      //alert('case 2')
      dispatch({
        type:"UPDATE_PLAID_STATE",
        payload: {
          redirectURL:localStorage.getItem("plaidRedirect"),
          product:JSON.parse(localStorage.getItem("plaidProducts")),
          autoActivate:localStorage.getItem("plaidAutoActivate"),
          linkToken:localStorage.getItem("plaidLinkToken"),
          _id:localStorage.getItem("plaidUpdateItemId"),
          updateLogin:localStorage.getItem("plaidUpdateLogin"),
        }
      })
    }
 }, [redirectURL,product,initialized,autoActivate,linkToken,updateLogin,_id])

  const [createPlaidItem, plaidItemResponse] = useMutation(CREATE_PLAID_ITEM, {
    variables:{publicToken}
  })
  const redirect = () => { 
    const url = new URL(localStorage.getItem("plaidRedirect"))
    console.log(url)
    const redirectPath = url.pathname + url.search
    localStorage.removeItem('plaidRedirect')
    localStorage.removeItem('plaidLinkToken')
    localStorage.removeItem('plaidProducts')
    history.push(redirectPath)
  }

  const onSuccess = useCallback(async (token, metadata) => {
    setPublicToken(token)
    if(localStorage.getItem("plaidUpdateLogin")){
      await plaidItemReloginSucccess()
      .then( (res) => {
        toast.success('Round-Ups reauthorized and updated!')
        redirect()
      }).catch(error => {
        toast.info('Authorized! Please update Round-Ups')
        redirect()
      })
    }else{
      await createPlaidItem()
      .then( async (res) => { 
        //set the item id, so if auto activate is true, then the component on the redirect will activate based on this item_id
        await dispatch({
          type:"UPDATE_PLAID_STATE",
          payload: {
            item_id:res.data.createPlaidItem.item_id,
          }
        })
        redirect()
      }).catch(error => {
        setError(error.message)
        setModal(true)
      })
    }

  }, []);
  const onExit = useCallback(async (error, metadata) => {
    if(error?.error_code==="item-no-error" && localStorage.getItem("plaidUpdateLogin")){
      await plaidItemReloginSucccess()
      .then( (res) => {
        toast.success('Round-Ups reauthorized and updated!')
        redirect()
      }).catch(error => {
        toast.info('Authorized! Please update Round-Ups')
        redirect()
      })
      }
    if(metadata.status!=='connected')
      redirect()
  }, []);





  return (
    <Fragment>
      <PlaidErrorModal error={error}  modal={modal} setModal={setModal}/>
      {!plaidItemResponse.loading && !modal &&  linkToken && <OpenPlaidOAuthLink token={linkToken} onExit={onExit} onSuccess={onSuccess}/>}
      {plaidItemResponse.loading && !linkToken && <PlaidLoader loading={true}/>}
    </Fragment>

  );
};


export default OAuthPage;
