import React from 'react';
import classNames from 'classnames';
import { Row, Col, Card, CardBody,  CardHeader } from 'reactstrap';
import Flex from '../common/Flex'
import { Link } from 'react-router-dom';
import Coin from '../../assets/images/illustration/coin.png'
import Section from '../common/Section';
import Verified from '../../assets/images/svgr/Verified';
import Settings from '../../assets/images/svgr/Settings';
import Diagram from '../../assets/images/svgr/Diagram';
const Features = () => {
  return (
  <Section bg='gray' className=' pl-lg-4 px-md-0  '>
  <Row className="flex-center pl-lg-5 p-md-0 " >
    <Col md md={6} lg={5} xl={5} className=" p-4">
      <Flex justify='center'>
<div>

<h2 className='text-md-left text-center' style={{ maxWidth: 300, }}>Giving back has a
        transformational
        impact on people,
        even if it’s <span className='text-primary'>one
        penny a day.</span></h2>
        <div className='mt-5'>

        <Link className={'round px-5 py-2   btn btn-secondary btn-md d-md-inline d-none '} to="/get-started">Get Started</Link>
        </div>
</div></Flex>
    </Col>
    <Col md xxl={5} xl={6} lg={7} className={classNames('px-lg-6  p-3', { 'order-md-0': true })}>
    <Card className='feature-table  rounded-0 shadow-none'>  
    <img src={Coin} alt="" style={{
      transform: 'rotate(-15deg)', 
      width:'60px',
      position:'absolute',
      right:'-30px',
      bottom:'-30px'}}/>      
    <CardHeader className='rounded-0'></CardHeader>

        <CardBody className='p-4'>            
          <Flex align='start' className='my-2'>
                <Verified className=''/>
                <div className='ml-3'>
                  <h5>Bank-Level Security</h5>
                  <p>Fully encrypted, PCI compliant payment
                      processing means nobody can touch your
                      sensitive information—not even us!</p>
                </div>

              </Flex>
          <Flex align='start' className='my-2'>
                <Settings />
                <div className='ml-3'>
                  <h5>In Your Control</h5>
                  <p>Set a daily max or pause your transactions
                    at any time. You’re always in control!</p>
                </div>

              </Flex>
          <Flex align='start' className='my-2'>
                <Diagram />
                <div className='ml-3'>
                  <h5>Measure Your Impact</h5>
                  <p>Get a daily summary of your impact on
                    Bartram’s Garden delivered straight to
                    your inbox.</p>
                </div>

              </Flex>

        </CardBody>
      </Card>
    </Col>
    
    <Link className={'round  px-5 py-2 mt-4  btn btn-primary btn-md  d-md-none d-block '} to="/get-started">Get Started</Link>
    
  </Row>
</Section>
  );
};



export default Features;
