import React from 'react';
import Flex from '../common/Flex';
import SpareChange from '../../assets/images/svgr/Sparechange'

const Powered  = ({}) => {
  return (
    <Flex wrap align='center' className='pt-4'>
      <p className='text-muted mb-0'>Powered by</p>
      <a href="https://www.sparechange.fund/" target="_blank">
          <SpareChange height='23' className='mx-2' fill='#ADB5BD'/>
      </a>
      <p  className='text-muted mb-0 ml-0 ml-md-auto'><small>
        Copyright {new Date().getFullYear()} &copy;  Spare Change, LLC. All Rights Reserved.
      </small>

      </p>

    </Flex>
  );
};

export default Powered;
