import React, { Fragment, useEffect, useState } from 'react';
import { gql, useMutation,useQuery } from '@apollo/client';
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import _ from 'lodash'
import Flex from '../../common/Flex';
import { Button, Card, ListGroup, ListGroupItem, Badge, Alert } from 'reactstrap';
import moment from 'moment';
import {toast} from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const RESTORED_DONATIONS = gql`
mutation processRestoredDonations($timestamp:DateTime!,$plaidAccount:String!,$ignore:Boolean){
  processRestoredDonations(timestamp:$timestamp,plaidAccount:$plaidAccount,ignore:$ignore)
}
`;

const RestoreDonationCard   = ({restore}) => {
  let history = useHistory();
  const dispatch = useDispatch()
  const [response, setResponse] = useState(null);


  
  const [submit,{data,loading,refetch}] = useMutation(RESTORED_DONATIONS)
    

  const process = (e) => {
    e.preventDefault()
    submit({variables:{timestamp:restore.timestamp,plaidAccount:restore.plaidAccount._id}})
  }  
  const ignore = (e) => {
    e.preventDefault()
    submit({variables:{timestamp:restore.timestamp,plaidAccount:restore.plaidAccount._id,ignore:true}})
  }
  useEffect(() => {
    if(data?.processRestoredDonations === 'PROCESSED' ){
      toast.success('The Round-Ups were processed and will be included in next weeks donation!')
    }else if(data?.processRestoredDonations === 'IGNORED' ){
      toast.info('The Round-Ups were ignored')
    }
  }, [data])




  return (
<Card key={restore.timestamp} className='p-2 mb-2 border shadow-none'>
              <Flex align='center' className={'mb-1'}>
                <h5 className='mr-2'>{restore.plaidAccount.plaidItem.name}</h5>
                <Badge className='ml-auto'color='soft-blue'>{moment(restore.timestamp).fromNow()}</Badge>
              </Flex>
              {!data?.processRestoredDonations && 
                <Fragment>
                  <ListGroup flush className='mb-2'>
                    <ListGroupItem className='fs--1 py-1'>{restore.plaidAccount.official_name} - {restore.plaidAccount.mask}</ListGroupItem>
                    <ListGroupItem className='fs--1 py-1'>Transactions: {restore.count}</ListGroupItem>
                    <ListGroupItem className='fs--1 py-1'>Donations: ${restore.sum.toFixed(2)}</ListGroupItem>
                  </ListGroup>
                  <Flex>
                    <div className='w-50 mr-1'><Button disabled={loading} onClick={process} color='primary'className='w-100'>Process</Button></div>
                    <div className='w-50 '><Button disabled={loading}  onClick={ignore} color='muted' className='w-100'>Ignore</Button></div>
                  </Flex>
                </Fragment>
              }
              {/* {response === 'PROCESSED' &&
                <Alert color='green'>
                  <Flex align={'center'}>
                    <FontAwesomeIcon className='mr-2'icon='check'/>
                    <p className='mb-0 font-weight-bold'>Processed</p>
                  </Flex>
                  <p className='mb-0'>The {restore.count} Round-Up{restore.count===1?"":'s'} of ${restore.sum.toFixed(2)} {restore.count===1?"was":'were'} processed and will be included in next weeks donation.</p>
                </Alert>  
              }
              {response === 'IGNORED' &&
                <Alert color='gray'>
                  <Flex align={'center'}>
                    <FontAwesomeIcon className='mr-2'icon='check'/>
                    <p className='mb-0 font-weight-bold'>Ignored</p>
                  </Flex>
                  <p className='mb-0'>The {restore.count} Round-Up{restore.count===1?"":'s'} of ${restore.sum.toFixed(2)} {restore.count===1?"was":'were'} ignored.</p>
                </Alert>  
              } */}
            </Card>
    
  // 
      
  );
};

export default RestoreDonationCard;
