import React, { useState, useEffect,Fragment } from 'react';
import { Card, Badge, Button,ButtonGroup ,ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';
import Flex from '../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';


const BankAccountOptions = ({account,verifyAccount, setAsDefault}) => {
  const [dropdown, setDropdown] = useState(false)
  const toggle =() => {
    setDropdown(!dropdown)
  }
  return (
    <Flex>
        <ButtonGroup>
            {account.status!=='verified' && 
              <Button className='border btn-white px-2 py1 shadow-sm' onClick={verifyAccount}>
                  <FontAwesomeIcon icon={faExclamationTriangle} color='#FCAB0F' size='1x'/>
              </Button>
            }
            <ButtonDropdown   isOpen={dropdown} toggle={toggle}>
                <DropdownToggle className='rounded-right border btn-white px-2 py1 shadow-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} color='gray' size='1x'/>
                </DropdownToggle>
                <DropdownMenu>
                    <p className='mb-0 pl-2 border-bottom pb-1 font-weight-semi-bold'>ACTIONS</p>
                    {account.status!=='verified' && <DropdownItem onClick={verifyAccount} >Verify Account</DropdownItem>}
                    <DropdownItem disabled={account.default} onClick={setAsDefault}>Set as default</DropdownItem>
                    <DropdownItem disabled >Remove</DropdownItem>

                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>
    </Flex>
  )
}



export default BankAccountOptions