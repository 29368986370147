import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout';
import CharitySignupForm from '../forms/CharitySignupForm';
// import ReactGA from 'react-ga';
import {useSelector} from 'react-redux'

const CharitySignup = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {appName} = useSelector((state) => state.theme)

  return (
    <AuthBasicLayout
      // leftSideContent={
      //   <Fragment>
      //     <p>
      //       Already have an account?
      //       <br />
      //       <Link className="text-white text-underline" to="/authentication/nonprofit/login">
      //         Login
      //       </Link>
      //     </p>
      //     <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
      //       Read our{' '}
      //       <Link className="text-underline text-300" to="/terms-and-conditions">
      //         terms
      //       </Link>{' '}
      //       and{' '}
      //       <Link className="text-underline text-300" to="/privacy-policy">
      //         privacy policy{' '}
      //       </Link>
      //     </p>
      //   </Fragment>
      // }
    >
      <h4>Create a {appName} account</h4>
      <p>Enroll your non-profit in {appName} in less than two minutes. Just provide some basic information.</p>
      {/* <p className='mb-4'>Your are moments away from increasing your donor engagement with a {appName} campaign</p> */}
      <CharitySignupForm layout="card" hasLabel />
    </AuthBasicLayout>
  );
};

export default CharitySignup;
