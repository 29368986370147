import React, { useEffect } from 'react';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout'
// import ReactGA from 'react-ga';
import {useSelector} from 'react-redux'
import Verified from '../../../assets/images/illustration/verified.png'
import { Link } from 'react-router-dom';
import withRedirect from '../../../hoc/withRedirect';
import CharityOTPForm from '../forms/CharityOTPForm';
import { Button } from 'reactstrap';
const CharityVerifyEmail = ({ match: { url },setRedirect,setRedirectUrl }) => {
  // ReactGA.pageview(url);
  const {isVerified} = useSelector((state) => state.charityUser)
  useEffect(() => {
    if(isVerified){
      setRedirectUrl({
        pathname: `/nonprofit/dashboard`
      })
      setRedirect(true)
    }
  }, [isVerified])
  return (
    <AuthBasicLayout>
      {/* {isVerified && 
        <div className=' text-center'>
          
          <img className='dashboard-img p-2 mb-2'src={Verified} alt=""/>
          <p>Your email is verified</p>           
          <Link className={'round  py-2 px-md-5 px-4 btn btn-secondary btn-md'} to="/onboarding">Continue</Link>

        </div>
      } */}
      {isVerified === false && <CharityOTPForm/>}
      </AuthBasicLayout>
  );
};

export default withRedirect(CharityVerifyEmail);
