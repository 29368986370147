import React from 'react';
import { Card, ListGroup, ListGroupItem, } from 'reactstrap';
import classNames from 'classnames'
import DailyMax from './DailyMax';
import Multiplier from './Multiplier';
import WholeDollarRoundups from './WholeDollarRoundups';

const DonationSettings  = ({className}) => {
  return (
      
    <div className={classNames(className )}>
    <Card className='profile p-4 shadow-none'>
          <h5 className='mb-0'>Donation Settings</h5>
        <ListGroup flush >
            <ListGroupItem  className='px-0'>                    
            <Multiplier/>

            </ListGroupItem>
            <ListGroupItem  className='px-0'>
                    <DailyMax/>
            </ListGroupItem>
            <ListGroupItem  className='px-0'>
                    <WholeDollarRoundups/>
            </ListGroupItem>
        </ListGroup>
    </Card>
    </div>
  )
};

export default DonationSettings;
