import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, } from 'reactstrap';
import { gql, useQuery,   } from '@apollo/client';
import Flex from '../../common/Flex';
import BankAccountList from '../../stripe/BankAccountList';
import InstantVerificationCard from '../../bankAccount/InstantVerificationCard';
import ManualVerificationCard from '../../bankAccount/ManualVerificationCard';
import ManualVerification from '../../bankAccount/ManualVerification';
import { useLocation, useHistory   } from 'react-router-dom';
import InstantVerification from '../../bankAccount/InstantVerification';
import PlaidAccountVerifyList from '../../bankAccount/PlaidAccountVerifyList';
import InstantVerificationAlt from '../../bankAccount/InstantVerificationAlt';
const ChangeTransferAccountModal = ({accounts,plaidAccounts, modal, view, setModal, refetch,removeSearch, redirectURL}) => {

  
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  // const view = params.get('view'); 

  //const [accounts,setAccounts] = useState([]);
 // const [view, setView] = useState("OPTIONS")
  const toggle = () => {
    setView('OPTIONS')
    setModal(false)
    removeSearch()
  };
  const setView = (view) => {
    history.push(`?change_bank=${view}`)
    // history.replace({
    //   search: 'change_bank='+view,
    // })
  }

  // const STRIPE_ACCOUNTS = gql`
  // query MyStripeBankAccounts{
  //   myStripeBankAccounts{
  //     _id
  //     account_holder_name
  //     bank_name
  //     last4
  //     routing_number
  //     currency
  //     created
  //     default
  //     status
  //   }
  // }
  // `;
  // const stripeAccounts = useQuery(STRIPE_ACCOUNTS)
  // useEffect(() => {
  //   if (stripeAccounts.data){
  //     setAccounts(stripeAccounts.data.myStripeBankAccounts)
  //   }
  // }, [stripeAccounts.data])
 
  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
    
    
    modalClassName="overflow-hidden modal-fixed-right modal-theme"
    className="modal-dialog-vertical"
    contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>
          <h4>Default Bank Account</h4>
          {view==='OPTIONS' &&
          <p className='fs--1 mb-0'>Choose a default Bank Account, or <span><a style={{textDecoration: "underline"}} href="#"  onClick={() => setView("ADD")}>connect another bank</a></span>.</p>
          
          }
        
        </ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
        <Fragment>
          { view === "OPTIONS" && <Fragment>
            

            
            <ListGroup flush>
                <BankAccountList  accounts={accounts} plaidAccounts={plaidAccounts} refetch={refetch}onVerificationSuccess={refetch}/>
                <PlaidAccountVerifyList accounts={plaidAccounts} refetch={refetch}/>    
              </ListGroup>
              {/* <Flex justify='end'><Button color='link' onClick={() => setView("ADD")} >Add another bank account</Button></Flex> */}
          </Fragment> }
              {view === 'ADD' &&
                <Fragment>
                  <InstantVerificationCard setView={setView}/>
                  <ManualVerificationCard setView={setView}/>
                </Fragment>
              }
              
        {view === "MANUAL" && 
            <ManualVerification refetch={refetch} closeModal={toggle} onBack={() => setView("OPTIONS")}/>
        }
        {view === "INSTANT" && 
            <InstantVerificationAlt onBack={() => setView("OPTIONS")} refetch={refetch} closeModal={toggle}  redirectURL={redirectURL}  />
        }

        </Fragment>
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
        <Button color="secondary" onClick={() => {
          toggle()}}>Cancel</Button>        
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default ChangeTransferAccountModal;