import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames'
import React from 'react';
import Flex from '../common/Flex';
import {useSelector} from 'react-redux'


  const Social  = ({dark}) => {
  const {url, urlMask,facebook,instagram,secondary} = useSelector((state) => state.theme)
  return (
    <Flex className='footer-social' align='center' className='pt-2'>
      {url && urlMask && <a href={url} className={classNames('mr-3 text-black', { 'text-white': dark })}target="_blank"><strong>{urlMask}</strong></a>}
      {facebook && <a href={facebook} target="_blank">
        <FontAwesomeIcon   color={secondary} size='2x' className='mx-1' icon={['fab', 'facebook']} ></FontAwesomeIcon>
      </a>}
      {instagram && <a href={instagram} target="_blank">
        <FontAwesomeIcon color={secondary} size='2x' className='mx-1'  icon={['fab', 'instagram']} ></FontAwesomeIcon>
      </a>}
    </Flex>
  );
};

export default Social;
