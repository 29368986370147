import React, { Fragment } from 'react';
import { Row, Col, Badge, Card, Button } from 'reactstrap';
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Section from '../../common/Section';
import Photograph from '../../../assets/images/svgr/HeroImagePushkaInvite';
import DonorQuickSignupForm from '../../auth/forms/DonorQuickSignupForm';
import { logout } from '../../../functions/auth';
const Hero = ({theme,donorGroup}) => {
  const text = theme.heroText
  const heroTitle = theme.heroTitle
  const heroSubtitle = theme.heroSubtitle
  const publicId = theme.heroImage
  const iconLogo = theme.iconLogo
  const charityName = theme.charityName
  const {loggedIn,role} = useSelector((state) => state.auth)
  const {email} = useSelector((state) => state.user)

  return (
    <Fragment>      <div   className=' pt-xl-4  pt-5 bg-gradient'>
        <Section  bg='' className='mb-3 pb-3  text-white '>
          <Row className="flex-center text-md-left text-center" style={{ marginBottom: '-50px' }}>
            <Col  className='overlapped-hero-invite-text mt-md-5 mt-0' md lg={5}>
            <h1 className='fs-4  mb-3 text-white'>Round-Up and donate to <span className='text-yellow'>{charityName}</span></h1>
              {/* {heroSubtitle && !donorGroup?.name && <p className=' fs-1 font-weight-medium'>{heroSubtitle}</p>} */}
              {donorGroup?.name && <h4 className='mt-2 mb-4'><Badge color="white" className='p-3 text-black rounded-0'>{donorGroup.name}</Badge></h4>}
              <p className='fs-0'>Round-Up your purchases to the next dollar by linking your credit/debit card.</p>
              {role !== 'DONOR' && 
          <Card className='p-3  border bg-light text-black'>
                <p className='text-gray'>Enter your information:</p>
                    <DonorQuickSignupForm/>
                    <p className='mb-0 mt-2 fs--1'>Already have an account? <span><Link  to="/authentication/login">Login</Link></span></p>
              </Card>  
      }
      {loggedIn && role === 'DONOR' &&
          <Card className='p-3  border bg-light text-black'>
            <p>You're logged in as {email}</p>
            <Link className={'round py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/dashboard">Go To Dashboard</Link>
            {/* <Button onClick={goToDashboard}>Go to Dashboard</Button> */}
            <Button color='link' size='sm' onClick={logout}>Logout</Button>
            </Card>
      }
           {/* <Link className={'round  py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/get-started">Get Started</Link> */}
            </Col>
            <Col md lg={7}>
              
            <div className='overlapped-hero-invite-img px-3 py-md-0'>
            <Photograph className='img-fluid' publicid={publicId} iconLogo={iconLogo} />
            </div>
            </Col>
          </Row >


</Section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width='100%' height='200px' style={{marginTop:'-100px'}} preserveAspectRatio="none">
    <polygon fill="white"stroke="white"  points="0,100 100,0 100,100"/>
  </svg>
</div></Fragment>


  );
}; 

Hero.propTypes = {
};


export default Hero;
