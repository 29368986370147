import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import Flex from '../../common/Flex';
import Summary from './Summary';
import Timeline from './Timeline';
const TransferDetailsModal = ({_id,modal, setModal,removeSearch}) => {

  const toggle = () => {
    removeSearch()
    setModal(false)
  };

  const TRANSFER_DETAILS = gql`
  query achDetails($_id:String!){
    achDetails(_id:$_id){
      amount
      created
      status
      succeededAt
      source{
        bank_name
        last4
      }
    }
  }
  `;
  const {data} = useQuery(TRANSFER_DETAILS,{variables:{_id:_id}})
  const [transfer, setTransfer] = useState({})
  useEffect(() => {
    if(data){
      setTransfer(data.achDetails)
    }
  }, [data])
  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
    
    
    modalClassName="overflow-hidden modal-fixed-right modal-theme"
    className="modal-dialog-vertical"
    contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>Transfer Details</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
        <Fragment>
          <Summary transfer={transfer}/>
          <Timeline transfer={transfer}/>
        </Fragment>
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
        <Button color="secondary" onClick={() => {
          toggle()}}>Cancel</Button>        
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default TransferDetailsModal;