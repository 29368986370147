import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 21.6"  width='200px' height='100px' {...props}>
      <linearGradient
        id="cloud4"
        gradientUnits="userSpaceOnUse"
        x1={18.266}
        y1={20}
        x2={18.266}
        y2={2.04}
      >
      <stop offset={0} stopColor="white" stopOpacity={0} />
      <stop offset={1} stopColor="white" stopOpacity={.6} />
      </linearGradient>
      <path
        d="M32.29 12.16a2.616 2.616 0 00-2.22-4.01c-.68 0-1.3.26-1.77.68a3.713 3.713 0 00-3.16-1.77c-.74 0-1.44.22-2.02.6-.35-3.16-3.02-5.62-6.27-5.62-2.27 0-4.25 1.2-5.37 2.99a3.709 3.709 0 00-4.93 5.05c-.14-.01-.28-.02-.43-.02a4.97 4.97 0 100 9.94h25.3c2.2 0 3.97-1.78 3.97-3.97a3.987 3.987 0 00-3.1-3.87z"
        opacity={0.58}
        fill="url(#cloud4)"
      />
    </svg>
  )
}

export default SvgComponent
