import React from 'react';
import { Badge, Card, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames'
import {useSelector} from 'react-redux'
import Flex from '../../common/Flex';
const Settings  = ({className}) => {
  
  const {multiplier, dailyMaxAmount, dailyMax, active, wholeDollarRoundup} = useSelector((state) => state.user)
  return (
    <div className={classNames(className )}>
      <Card className={' p-md-4 p-3 shadow-none '}>

        <Flex align='center' className='mb-3'>
          <h5 className='m-0'>Account Settings</h5>
          <Link className="font-weight-semi-bold ml-auto  fs--1 text-nowrap" to={'/settings'}>
            Edit
            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
          </Link>
        </Flex>
        <Flex align='center mb-2'>
          <p className='m-0 fs--1 font-weight-bold'>Your Account is:</p> {active
            ?<Badge className='px-2 ml-1' color='soft-green'>Active</Badge>
            :<Badge className='px-2 ml-1' color='soft-red'>Inactive</Badge>}    
        </Flex>

        <p className='mb-2 fs--1 font-weight-bold'>Daily Max: 
        {dailyMax
            ?<span className='text-primary'> ${dailyMaxAmount}</span>
            :<span className='text-muted'> None</span>}
        </p>
        <p className='mb-2 fs--1 font-weight-bold'>Multiplier: <span className='text-primary'> {multiplier}x</span></p>
        <p className='mb-2 fs--1 font-weight-bold'>Whole Dollar Roundup: <span className={wholeDollarRoundup?'text-primary':'text-muted'}> {wholeDollarRoundup?'ON':'OFF'}</span></p>
    </Card>
      </div>
  );
};

export default Settings;
