import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import AuthCardLayout from '../../../layouts/AuthCardLayout';
import CharityLoginForm from '../forms/CharityLoginForm';
// import ReactGA from 'react-ga';
import {useSelector} from 'react-redux'

const CharityLogin = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {demo,oneToMany} = useSelector((state) => state.config)

  return (
    <AuthCardLayout
      leftSideContent={
        <Fragment>
          <p>
            Don't have a non-profit account?
          </p>
            
            <Link className="btn btn-outline-white" to="/authentication/nonprofit/signup">
              Create a campaign
            </Link>
          
          {oneToMany && 
            <Fragment>
            <p className='my-1'>or</p>
            <Link className={'btn btn-white'} to="/authentication/login">Log in as donor</Link>
              </Fragment>

            }
          {/* <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
            Read our{' '}
            <Link className="text-underline text-300" to="/terms-and-conditions">
              terms
            </Link>{' '}
            and{' '}
            <Link className="text-underline text-300" to="/privacy-policy">
              privacy policy{' '}
            </Link>
          </p> */}
        </Fragment>
      }
    >
      <h3>Non-Profit Login</h3>
      <CharityLoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default CharityLogin;
