import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import {useSelector} from 'react-redux'
import classNames from 'classnames';

const LandingRightSideNavItem = ({transparent, light}) => {
  const {role, loggedIn} = useSelector((state) => state.auth)
  const {management} = useSelector((state) => state.config)
  return (
    <Fragment>
      {!loggedIn &&
        <Fragment>
        <div>
          { !management && <NavLink   className={light?'text-black':''} tag={Link} to="/authentication/login">Login</NavLink>}
          { management && <NavLink tag={Link} to="/authentication/charity/login">Login</NavLink>}
          </div>
          <div>
            { !management &&<Link className={classNames('btn btn-navbar',transparent && !light?"btn-white ":"btn-primary ")} to="/get-started">Get Started</Link>}
            { management &&<Link className={classNames('btn btn-navbar',transparent && !light?"btn-white ":"btn-primary ")} to="/authentication/nonprofit/signup">Get Started</Link>}
          </div>

        </Fragment>
      }
      {loggedIn &&
          <div>
            { role === 'DONOR' && <Link className={classNames('btn btn-navbar',transparent && !light?"btn-white ":"btn-primary ")} to="/dashboard">Dashboard</Link>}
            { role === 'CHARITY' && <Link className={classNames('btn btn-navbar',transparent && !light?"btn-white ":"btn-primary ")}  to="/nonprofit/dashboard">Charity Dashboard</Link>}
            { role === 'ADMIN' && <Link className={classNames('btn btn-navbar',transparent && !light?"btn-white ":"btn-primary ")}  to="/admin/dashboard">Admin Dashboard</Link>}
            </div>

      }
      </Fragment>

  );
};

export default LandingRightSideNavItem;
