import React from 'react';
import { Row, Col } from 'reactstrap';
import Section from '../common/Section';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Banner = ({title, subtitle, bg,textColor, cta, ctaText,ctaLink,className}) => (
  <Section overlay bg={bg} position="center top" className={className}>
    <Row className="justify-content-center text-center">
      <Col lg={8}>
        <h1 className={classNames({ [`text-${textColor}`]: textColor }, "")} >
          <strong>{title}</strong>
        </h1>
        <h5 className={classNames({ [`text-${textColor}`]: textColor }, "")} >
          {subtitle}
        </h5>
        {cta && 
        <Link className="btn btn-outline-light border-2x rounded-pill btn-lg mt-4 fs-0 py-2" to={ctaLink}>
          {ctaText}
        </Link>
        }
      </Col>
    </Row>
  </Section>
);

export default Banner;
