import React, { Fragment, useState, useEffect } from 'react';
import DashboardSummmary from './Dashboard/BalanceSummary';
import PayoutSummary from './Dashboard/PayoutSummary';
import { gql,  useQuery  } from '@apollo/client';
import {useSelector} from 'react-redux'
import { Row, Col, Button, Card } from 'reactstrap';
import RequiredAction from './RequiredAction/RequiredActions';
import DashboardLayout from '../../layouts/DashboardLayout';
// import ReactGA from 'react-ga';
import Flex from '../common/Flex';
import InviteCard from './misc/InviteCard';

const Dashboard   = ({ match: { url } }) => {
  // ReactGA.pageview(url);

  // const {charity,} = useSelector((state) => state.charityUser)
  // const {past_due} = useSelector((state) => state.charityUser.charity.stripeRequirements)
  // const {publishedTheme} = useSelector((state) => state.charityUser.charity)

  return (
    <DashboardLayout>
      {/* <h4>Dashboard</h4> */}
      <Row>
        <Col className='px-1 mb-2'>
      <InviteCard/>
        </Col>
        </Row>
    <Row>
      {/* {(past_due[0] || !publishedTheme) && 
        <Col lg="5"  className='px-1 order-lg-1 mb-3'>   
          <RequiredAction/>
        </Col>
        } */}
        {/* <Col lg={(past_due[0] || !publishedTheme)?"7":'12'}  className={' px-1'}> */}
        <Col lg={'12'}  className={' px-1'}>
          <DashboardSummmary/>
          <PayoutSummary/>
        </Col>
      </Row>
  </DashboardLayout>
  );
};

export default Dashboard;
