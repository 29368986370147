import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Flex from '../../common/Flex';
import CharitySearch from './CharitySearch';
const CharitySearchModal = ({insertNewCharity,modal, setModal, tenantId}) => {
  const toggle = () => {
    
    setSelectedCharity(null)
    setActiveTab('1')
    setModal(!modal)};
  const [activeTab, setActiveTab] = useState('1');
  const [selectedCharity, setSelectedCharity] = useState(null);

  useEffect(() => {
    if(activeTab === '1'){
      setSelectedCharity(null)
    }
  }, [activeTab])
  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
    
    
    modalClassName="overflow-hidden modal-fixed-right modal-theme"
    className="modal-dialog-vertical"
    contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>New Nonprofit</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
        
              <CharitySearch tenantId={tenantId} onSelect={(x) => {
                insertNewCharity(x)
                toggle()
                }}/>
     
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
        <Button color="secondary" onClick={() => {
          toggle()}}>Cancel</Button>
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default CharitySearchModal;