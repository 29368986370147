import React, { useState, useEffect , Fragment} from 'react';
import PropTypes from 'prop-types';
import {  Form, Row, Col, FormGroup,  CardFooter, CardBody, Spinner } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { useForm } from "react-hook-form";
import WizardInput from '../../common/WizardInput';
import { gql, useMutation } from '@apollo/client';
import {useSelector} from 'react-redux'
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import GetBankAccountToken from '../../stripe/GetBankAccountToken';
import Flex from '../../common/Flex';
const CREATE_STRIPE_BANK_ACCOUNT = gql`
mutation CreateStripeBankAccount(  $stripeToken: String!,$validationType: String!){
  createStripeBankAccount(stripeToken:$stripeToken,validationType:$validationType){ 
    _id
    account_holder_name
    bank_name
    last4
    routing_number
    currency
    created
    default
    }
  }
`;


const ManualVerificationForm = ({  refetch, onBack,hasLabel,closeModal }) => {
  const hidden = false
  const { firstName, lastName,} = useSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm();
  const [submit,{data,loading,error}] = useMutation(CREATE_STRIPE_BANK_ACCOUNT)
  // State
  const [email, setEmail] = useState('');
  const [stripeError, setStripeError] = useState(null);
  const [stripeToken, setStripeToken] = useState(null);
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const onSubmit = async (data) => {
    setIsLoading(true)
    await GetBankAccountToken({
      ...data,
      currency:"usd",
      country:"US",
      account_holder_type:'individual',
      account_holder_name:firstName + ' ' + lastName
    }).then(res => {
      setIsLoading(false)
      setStripeToken(res.token.id)
    }).catch(e => {
      setStripeError(e)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);
  useEffect(() => {
    if(stripeToken){
      submit({variables:{stripeToken,validationType:'MANUAL'}}).catch(e => {console.log(e)})
    }
  }, [stripeToken]);
  
  useEffect(() => {
    if(data){
      onBack()
      closeModal()
      refetch()
    }
  }, [data]);

  return (

    <Fragment>
      {hidden && <p>This feature is currently unavailable. Sorry for the inconvience.</p>}
    {!hidden && <Form onSubmit={handleSubmit(onSubmit)}>
    <CardBody className="p-0">
      {/* <Row form>
          <Col md={6}>
              <FormGroup>
              <WizardInput
            label="Account Holder Name*"
            defaultValue={firstName + " " + lastName}
            id="account_holder_name"
            name="account_holder_name"
            innerRef={register({
              required: 'Account Holder Name is required',
              minLength: {
                value: 2,
                message: 'Min length 2'
              }
            })}
            errors={errors}
          />
              </FormGroup>
          </Col>
          <Col md={6}>
          <FormGroup>
            <WizardInput
              label="Account Holder Type*"
              type="select"
              defaultValue="individual"
              id="account_holder_type"
              name="account_holder_type"
              placeholder="Select:"
              options={[
                          'individual',
                          'company',
                        ]}

              innerRef={register({
                required: 'Account Type is requred'
              })}
              errors={errors}
            />
            </FormGroup>
        </Col>
        </Row> */}
        <WizardInput
          type="text"
          label={hasLabel?'Routing Number*':null}
          placeholder='Routing Number'
          name="routing_number"
          id="routing_number"
          name="routing_number"
          innerRef={register({
            required: 'Routing Number is required',
            pattern: {
              value: /^[-+]?[0-9]+$/,
              message: 'Routing Number must be valid'
            },
            minLength: {
              value: 9,
              message: 'Routing Number must be 9 digits'
            },
            maxLength: {
              value: 9,
              message: 'Routing Number must be 9 digits'
            }
          })}
          errors={errors}
        />            <WizardInput
        type="text"
        label={hasLabel?'Account Number*':null}
        placeholder="Account Number"
        id="account_number"
        name="account_number"
        innerRef={register({
          required: 'Account Number is required',
          minLength: {
            value: /^[-+]?[0-9]+$/,
            message: 'Only numbers'
          }
        })}
        errors={errors}
      />
       <WizardInput
        type="text"
        label={hasLabel?'Confirm Account Number*':null}
        placeholder="Confirm Account Number"
        id="confirm_account_number"
        name="confirm_account_number"
        innerRef={register({
          validate: value => value === watch('account_number')|| `The account number does not match`
        })}
        errors={errors}
      />
  </CardBody>
  <CardFooter className={'  p-0'}>
            <ButtonIcon
                  disabled={loading || isLoading}
                  color="primary"
                  className="btn-block mb-2"
                  type="submit"
                  hideIcon={loading || isLoading}
                >
                  {(!loading && !isLoading ) && "Save and continue"}
                  {(loading || isLoading) && <Spinner  size={"sm"}/>}
                </ButtonIcon>

  
  {error &&<ErrorMessage error={error}/>}
  {stripeError &&<ErrorMessage error={stripeError}/>}
          </CardFooter>
    </Form>}
    </Fragment>
    // <Form onSubmit={handleSubmit}>
    //   <Row form>
    //   <Col md={6}>
    //       <FormGroup>
    //           {hasLabel && <Label>Account Holder Name</Label>}
    //           <Input
    //           placeholder={!hasLabel ? 'Account Holder Name' : ''}
    //           value={name}
    //           onChange={({ target }) => setName(target.value)}
    //           type="text"
    //           />
    //       </FormGroup>
    //   </Col>
    //   <Col md={6}>
    //       <FormGroup>
    //           {hasLabel && <Label>Account Type</Label>}
    //           <Input onChange={({ target }) => setAccountType(target.value)} type="select" name="select" id="exampleSelect">
    //             <option>Individual</option>
    //             <option>Company</option>
    //           </Input>
    //           {/* <Input
    //           placeholder={!hasLabel ? 'Account Type' : ''}
    //           value={name}
    //           onChange={({ target }) => setName(target.value)}
    //           type="text"
    //           /> */}
    //       </FormGroup>
    //   </Col>
    // </Row>
    //   <FormGroup>
    //     {hasLabel && <Label>Routing Number</Label>}
    //     <Input
    //       placeholder={!hasLabel ? 'Routing Number' : ''}
    //       value={email}
    //       onChange={({ target }) => setEmail(target.value)}
    //       type="text"
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     {hasLabel && <Label>Account Number</Label>}
    //     <Input
    //       placeholder={!hasLabel ? 'Account Number' : ''}
    //       value={email}
    //       onChange={({ target }) => setEmail(target.value)}
    //       type="text"
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     {hasLabel && <Label>Confirm Account Number</Label>}
    //     <Input
    //       placeholder={!hasLabel ? 'Confirm Account Number' : ''}
    //       value={email}
    //       onChange={({ target }) => setEmail(target.value)}
    //       type="text"
    //     />
    //   </FormGroup>
      
    //   <FormGroup>
    //     <Button color="primary" block className="mt-3" disabled={isDisabled}>
    //       Save and Continue
    //     </Button>
    //   </FormGroup>
    // </Form>
  );
};

ManualVerificationForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ManualVerificationForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(ManualVerificationForm);
