import React from 'react';
import {Image} from 'cloudinary-react'
import classNames from 'classnames';
import { Row, Col, } from 'reactstrap';
import Section from '../common/Section';
import {useSelector} from 'react-redux'
import Flex from '../common/Flex';
import AboutImageGrid from './About/AboutImageGrid';
import Logo from '../common/Logo';
const About = () => {
  const {charityName, aboutImage1,aboutImage2,aboutImage3, bioImage1, about, quote, bioTitle, iconLogo} = useSelector((state) => state.theme)
  return (
<Section bg='gray' className=''>
  <Row className="flex-center bg-white py-lg-8 p-md-6 p-4" >
    <Col lg lg={8} xl={7} className={classNames('pl-lg-6', { 'order-lg-2': true })}>
      <Flex  className='mb-4' align='center'>
    <Logo publicId={iconLogo} />
      <h2 className='mb-0'>About <span>{charityName}</span></h2>
      </Flex>
      <p className='border-bottom pb-4'>{about}</p>
        <Flex align='center' className='flex-wrap-reverse flex-lg-nowrap pt-4'>
      <Image  className={'about-image-quote mr-3 '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={bioImage1}/>
         
          <div className=''>
            <p>{quote}</p>
                <p>{bioTitle}</p>
          </div>
        </Flex>
    </Col>
    <AboutImageGrid coin iconLogo={iconLogo} aboutImage1={aboutImage1} aboutImage2={aboutImage2} aboutImage3={aboutImage3} />


  </Row>
</Section>
  );
};



export default About;
