import React, { useState,  Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {  Form,  Col, FormGroup,  CardFooter, CardBody, Spinner,  Alert, CardText } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { useForm } from "react-hook-form";
import WizardInput from '../../common/WizardInput';
import { gql, useMutation } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import moment from 'moment'
import ButtonIcon from '../../common/ButtonIcon';
import Flex from '../../common/Flex';
const VERIFY_STRIPE_ACCOUNT = gql`
mutation VerifyStripeBankAccount(  $accountId: String!,$amount1: Int!,$amount2: Int!){
  verifyStripeBankAccount(accountId:$accountId,amount1:$amount1,amount2:$amount2){ 
    _id
    account_holder_name
    bank_name
    last4
    routing_number
    currency
    created
    default
    status
    }
  }
`;


const StripeVerificationForm = ({ accountId, created,onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm();
  const [verify,{data,loading,error}] = useMutation(VERIFY_STRIPE_ACCOUNT)
  // State
  const [stripeError, setStripeError] = useState(null);
  const onSubmit = async (data) => {
    verify({variables:{amount1:Number(data.amount1),amount2:Number(data.amount2),accountId}}).catch(e => {console.log(e)})
  }
  useEffect(() => {
    if(data && data.verifyStripeBankAccount.status === 'verified'){
      setSuccess(true)
      onSuccess()
    }
  }, [data])
 

  return (
    <Fragment>
      {success && 
        <CardBody>
          <Alert className='alert-green'>Success! Your account is verified</Alert>
        </CardBody>
      }
      {!success &&
      <Form onSubmit={handleSubmit(onSubmit)}>
      {/* <CardBody className=""> */}
        <p>To verify your bank account, you’ll see two deposits on your bank statement, each $1 or less.</p>
        <p className='mb-0'>These deposits should be between: </p>
          <p className='text-primary font-weight-semi-bold ml-3'>{" "+ moment(created).add(3,'d').format("MMMM Do") + " and " + moment(created).add(5,'d').format("MMMM Do, YYYY")}
        </p>
        <p>Please enter the deposit amounts below.</p>
        <Col className='p-0' md={6} sm={10}>
            <FormGroup>
                <WizardInput
                type='number'
              label="Deposit Amount 1*"
              id="amount1"
              name="amount1"
              innerRef={register({
                required: 'Deposit amount 1 is required'
              })}
              errors={errors}
            />
              </FormGroup>
          </Col>
          
      <Col className='p-0'  md={6} sm={10}>
            <FormGroup>
                <WizardInput
                type='number'
              label="Deposit Amount 2*"
              id="amount2"
              name="amount2"
              innerRef={register({
                required: 'Deposit amount 2 is required'
              })}
              errors={errors}
            />
              </FormGroup>
          </Col>
        <p className='text-muted'>Note: $0.45 should be entered as "45"</p>
        
         
    {/* </CardBody>  */}
  
  <ButtonIcon
                  disabled={loading || isLoading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  hideIcon={loading || isLoading}
                >
                  {(!loading && !isLoading ) && "Verify account"}
                  {(loading || isLoading) && <Spinner  size={"sm"}/>}
                </ButtonIcon>
  
    
  {error &&<ErrorMessage error={error}/>}
  {stripeError &&<ErrorMessage error={stripeError}/>}

      </Form>
      }
    </Fragment>
  );
};

StripeVerificationForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

StripeVerificationForm.defaultProps = {
  layout: 'basic',
  hasLabel: true
};

export default withRedirect(StripeVerificationForm);
