import React, { useState, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import Flex from '../common/Flex';
import MediaLibrary from './MediaLibrary';
import UploadFile from './UploadFile';
const CloudinaryModal = ({insertSelectedId,modal, setModal, insertPublicId}) => {
  const [selectedId, setSelectedId] = useState('')
  const toggle = () => setModal(!modal);
  const [activeTab, setActiveTab] = useState('2');
  const mediaRef = useRef()
  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const switchAndRefresh = () => {
    setActiveTab('2')
    mediaRef.current.refresh()
  }
  const insert = () => {
    insertSelectedId(selectedId)
 //   setModal(false)
  }

  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
      modalClassName="overflow-hidden modal-fixed-right theme-modal"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>Insert Media</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
      <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '1' })}
            onClick={() => { toggleTab('1'); }}
          >
            Upload File
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '2' })}
            onClick={() => { toggleTab('2'); }}
          >
            Media library
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent  className='overflow-hidden'activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <UploadFile onUploaded={switchAndRefresh} insert={insertPublicId}/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
         
        <Row>
            <Col sm="12">
              <MediaLibrary ref={mediaRef}  insert={insertPublicId} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
        <Button color="secondary"  onClick={toggle}>Cancel</Button>
        {/* {activeTab === '2' && <Button disabled={!selectedId} color="primary" onClick={insert}>Insert Media</Button>} */}
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default CloudinaryModal;