import React, { Fragment } from 'react';
import { Spinner  } from 'reactstrap';
import { gql, useMutation  } from '@apollo/client';
import PropTypes from 'prop-types';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';

const DummyData  = () => {
  const DUMMY_DATA = gql`
  mutation generateDummyData{
    generateDummyData
  }
  `;  
  const [update, {loading,error}] = useMutation(DUMMY_DATA)

  const handleSubmit = e => {
    e.preventDefault();    
    update().then( () => {
    }).catch(e => {})

  };
  return (
    <Fragment>       
      
      <ButtonIcon
              disabled={ loading}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
              onClick={handleSubmit}
            >
              {(!loading ) && "Transactions"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}</Fragment>
 
  );
};
DummyData.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

DummyData.defaultProps = {
  layout: 'basic',
  hasLabel: true
};

export default DummyData;
