import React, {  useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {  Form, Row, Col, FormGroup,  CustomInput,  CardBody, CardFooter, Spinner, ListGroup, ListGroupItem, Button } from 'reactstrap';
import withRedirect from '../../../../hoc/withRedirect';
import ButtonIcon from '../../../common/ButtonIcon';
import { useForm } from "react-hook-form";
import WizardInput from '../../../common/WizardInput'
import {useSelector} from 'react-redux'
import { gql, useMutation,useLazyQuery } from '@apollo/client';
import ErrorMessage from '../../../common/ErrorMessage';
import {useDispatch} from 'react-redux'
import { loginDonor, logout, updateDonor } from '../../../../functions/auth';
import Flex from '../../../common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const SET_PASSWORD = gql`
mutation setPassword($password: String!){
  setPassword(password:$password)
}
`;

const SetupPassword = ({  hasLabel,  setEmail }) => {
  const {invited, EIN, donorGroup, approved,_id} = useSelector((state) => state.inviteReducer)
  const {firstName, lastName, email} = useSelector((state) => state.user)
  const { register, handleSubmit, errors, watch } = useForm();
  const [submit,{data,loading,error}] = useMutation(SET_PASSWORD)
  // const [getProfile, profile] = useLazyQuery (GET_PROFILE)
  // const dispatch = useDispatch()
  const onSubmit = (data) => {
    // setEmail(data.email)
  submit({variables:{...data}})
    .then((res)=>{
      console.log(res)
      updateDonor()
      .then()
      .catch(e=>{})
    })
    .catch(e => {})
  }

 

  return (
    <Fragment>
    <Form onSubmit={handleSubmit(onSubmit)}>
    <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
      <p className='mb-0'>You're logged in as {email}.</p>
      <p>Not {email}?  <span>
      <Button color='link' size='sm' className='p-0'onClick={logout}>Logout</Button>

        </span></p>
      {/* <h6>Account Information:</h6>
    <ListGroup className='mb-3'>
        <ListGroupItem><Flex align='center'>
          <FontAwesomeIcon className='mr-2' color='primary' icon={'user'} />
          <p className='mb-0 text-primary'>{firstName + " " + lastName}</p>
          </Flex></ListGroupItem>
        <ListGroupItem><Flex align='center'>
          <FontAwesomeIcon className='mr-2' color='primary' icon={'envelope'} />
          <p className='mb-0 text-primary'>{email}</p>
          </Flex></ListGroupItem>
      </ListGroup>       */}
      
      <h6>{firstName}, please enter a password:</h6>

      
                  <WizardInput
            type="password"
            // label="Password"
            placeholder="Password"
            id="password"
            name="password"
            innerRef={register({
              required: 'You must specify a password',
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })}
            errors={errors}
          />

      {/* <p>By clicking Next, you accept the <a href="/terms-and-conditions"> terms</a> and <a href="/privacy-policy"> privacy policy</a></p>
      <p>Already have an account?<a href="/authentication/login"> Sign in</a> </p>
     */}
  </CardBody>
  <CardFooter className={' bg-light d-flex'}>
    
  {error &&<ErrorMessage error={error}/>}
            <ButtonIcon
                  disabled={loading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  icon={false ? 'chevron-left' : 'chevron-right'}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  hideIcon={loading}
                >
                  {(!loading ) && "Next"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
          </CardFooter>
    </Form>
    </Fragment>
  
  );
};

SetupPassword.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

SetupPassword.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(SetupPassword);
