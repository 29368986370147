import React, { Fragment } from 'react';
 import PropTypes from 'prop-types';
// import createMarkup from '../../helpers/createMarkup';
import Section from '../common/Section';
//import IconGroup from '../common/icon/IconGroup';
import { Link } from 'react-router-dom';
import { Row, Col, } from 'reactstrap';
import Flex from '../common/Flex';
import {useSelector} from 'react-redux'
import Logo from '../navbar/PushkaLogo';
import Social from './Social';
import Powered from './Powered';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { blogPostList, iconList, menuList1, menuList2 } from '../../data/footer';
//import { animateScroll } from 'react-scroll';
//import { version } from '../../config';

const FooterTitle = ({ children }) => <h5 className="text-uppercase mb-3">{children}</h5>;

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-0" key={index}>
        <Link className="text-nowrap text-white fs--1" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const PushkaFooterLanding = () => {
  
const {mission} = useSelector((state) => state.theme)
    return (
      <div className='bg-gradient'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width='100%' height='200px' style={{marginTop:'-1px'}}  preserveAspectRatio="none">
    <polygon fill="#222539"stroke="#222539"  points="0 100,0 0,100 0"/>
  </svg>
        <Section className='text-white' >
          <Row>
            <Col lg lg={6} className='mb-3'>
            <Logo at='footer' className='mb-3' white={true}></Logo>
            <p className="">{mission}
                <Social/>
              </p>
            </Col>
            <Col md lg={2} md={4} className='mt-lg-5 mt-0 mb-3'>
              <h5 className='text-white text-uppercase mb-3 ' >Support</h5>
              <FooterList list={[
                    { title: 'Contact', to: '/support' },
                    { title: 'Terms', to: '/terms-and-conditions' },
                    { title: 'Privacy', to: '/privacy-policy' },
                    // { title: 'FAQ', to: '/FAQ' },
                  ]}   />
            </Col>
            <Col md lg={2} md={4} className='mt-lg-5 mt-0 mb-3'>
            <h5 className='text-white text-uppercase mb-3' >Donors</h5>
                          <FooterList list={[
                    { title: 'Get Started', to: '/get-started' },
                    { title: 'Login', to: '/authentication/login' },
                    { title: 'Forgot Password', to: '/authentication/forgot-password' },
                  ]}   />
            </Col>
            <Col md lg={2} md={4} className='mt-lg-5 mt-0 mb-3'>
            <h5 className='text-white text-uppercase mb-3' >Non Profits</h5>
                          <FooterList list={[
                    { title: 'Create Account', to: '/authentication/nonprofit/signup' },
                    { title: 'Login', to: '/authentication/nonprofit/login' },
                    { title: 'Forgot Password', to: '/authentication/nonprofit/forgot-password' },
                  ]}   />
            </Col>
          </Row>

        {/* <Flex wrap className='flex-md-nowrap'  >
          <div className='pr-md-8 pr-0 mb-md-0 mb-4'>
          <Flex column  align='start'>

<Logo at='footer' className='mb-3' white={true}></Logo>
<p className="">{mission}
    <Social/>
   </p>
</Flex>
          </div>
          <Flex justify='around' className=' flex-fill'>
            <div className='mx-md-5 mx-0'>
               <h5 className='text-white text-uppercase mb-3' >Support</h5>
              <FooterList list={[
                    { title: 'Contact', to: '/support' },
                    { title: 'Terms', to: '/terms-and-conditions' },
                    { title: 'Privacy', to: '/privacy-policy' },
                    { title: 'FAQ', to: '/FAQ' },
                  ]}   />
            </div>
            <div className='mx-md-5 mx-0'>
            <h5 className='text-white text-uppercase mb-3' >Donors</h5>
                          <FooterList list={[
                    { title: 'Get Started', to: '/get-started' },
                    { title: 'Login', to: '/authentication/login' },
                    { title: 'Forgot Password', to: '/authentication/forgot-password' },
                  ]}   />
            </div>
            <div className='mx-md-5 mx-0'>
            <h5 className='text-white text-uppercase mb-3' >Non Profits</h5>
                          <FooterList list={[
                    { title: 'Create Account', to: '/authentication/nonprofit/signup' },
                    { title: 'Login', to: '/authentication/nonprofit/login' },
                    { title: 'Forgot Password', to: '/authentication/nonprofit/forgot-password' },
                  ]}   />
            </div>
          </Flex>

        </Flex> */}
        <Powered/>
        </Section>
        {/* <Section className='text-white' >

<Flex wrap className='flex-md-nowrap'  >
  <div className='pr-md-8 pr-0 mb-md-0 mb-4'>
  <Flex column  align='start'>

<Logo at='footer' className='mb-3' white={true}></Logo>
<p className="">{mission}
<Social/>
</p>
</Flex>
  </div>
  <Flex justify='around' className=' flex-fill'>
    <div className='mx-md-5 mx-0'>
       <h5 className='text-white text-uppercase mb-3' >Support</h5>
      <FooterList list={[
            { title: 'Contact', to: '/support' },
            { title: 'Terms', to: '/terms-and-conditions' },
            { title: 'Privacy', to: '/privacy-policy' },
            { title: 'FAQ', to: '/FAQ' },
          ]}   />
    </div>
    <div className='mx-md-5 mx-0'>
    <h5 className='text-white text-uppercase mb-3' >Donors</h5>
                  <FooterList list={[
            { title: 'Get Started', to: '/get-started' },
            { title: 'Login', to: '/authentication/login' },
            { title: 'Forgot Password', to: '/authentication/forgot-password' },
          ]}   />
    </div>
    <div className='mx-md-5 mx-0'>
    <h5 className='text-white text-uppercase mb-3' >Non Profits</h5>
                  <FooterList list={[
            { title: 'Create Account', to: '/authentication/nonprofit/signup' },
            { title: 'Login', to: '/authentication/nonprofit/login' },
            { title: 'Forgot Password', to: '/authentication/nonprofit/forgot-password' },
          ]}   />
    </div>
  </Flex>

</Flex>
<Powered/>
</Section> */}
      </div>
  
    );
  };
  
  export default PushkaFooterLanding;