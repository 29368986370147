import React, {  useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {  Form, Row, Col, FormGroup,  CustomInput,  CardBody, CardFooter, Spinner } from 'reactstrap';
import withRedirect from '../../../../hoc/withRedirect';
import ButtonIcon from '../../../common/ButtonIcon';
import { useForm } from "react-hook-form";
import WizardInput from '../../../common/WizardInput'
import {useSelector} from 'react-redux'
import { gql, useMutation,useLazyQuery } from '@apollo/client';
import ErrorMessage from '../../../common/ErrorMessage';
import {useDispatch} from 'react-redux'
import { loginDonor } from '../../../../functions/auth';

const WIZARD_STEP_1 = gql`
mutation WizardStep1($email:String!,$password: String!,$fullName: String!, $donorGroup:String,$_id:String){
  wizardStep1(email:$email,password:$password,fullName:$fullName,donorGroup:$donorGroup,_id:$_id){
    token
    userRole
    exp
  }
}
`;
// const GET_PROFILE = gql`
// query{ 
//   me{ 
//     firstName
//     lastName
//     email 
//     active
//     inactiveReason
//     isVerified
//     requirements
//     setupStep 
//     dailyMax
//     dailyMaxAmount
//     transferDay
//     wholeDollarRoundup
//   }
// }
// `;

const WizardStep1 = ({  hasLabel,  setEmail }) => {
  const {invited, EIN, donorGroup, approved,_id} = useSelector((state) => state.inviteReducer)
  const { register, handleSubmit, errors, watch } = useForm();
  const [submit,{data,loading,error}] = useMutation(WIZARD_STEP_1)
  // const [getProfile, profile] = useLazyQuery (GET_PROFILE)
  const dispatch = useDispatch()
  const onSubmit = (data) => {
    setEmail(data.email)
  submit({variables:{...data,_id:(invited && approved)?_id:null,donorGroup:donorGroup?donorGroup:null}})
    .then((res)=>{
      loginDonor(res.data.wizardStep1)
      .then()
      .catch(e=>{})
    })
    .catch(e => {})
  }

  // useEffect(() => {
  //   if(data){
  //     localStorage.setItem("token",data.wizardStep1.token)  
  //     dispatch({
  //       type:"LOG_IN",
  //       payload:{
  //         loggedIn:true,
  //         token:data.wizardStep1.token,
  //         role:data.wizardStep1.userRole,
  //         expiry:data.wizardStep1.exp,
  //       }
  //     })
  //     getProfile()
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if(profile.data){
  //     dispatch({
  //       type:"UPDATE_USER",
  //       payload:{
  //         ...profile.data.me
  //       }
  //     })
  //     dispatch({
  //       type:"UPDATE_GET_STARTED",
  //       payload:{
  //         step:profile.data.me.isVerified?1:0,
  //         mfa:profile.data.me.isVerified?false:true,
  //       }
  //     })
  //     console.log('here')
  //   }
    
  // }, [profile.data])

  return (
    <Fragment>
    <Form onSubmit={handleSubmit(onSubmit)}>
    <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
      {/* <Row form>
          <Col md={6}>
              <FormGroup>
                  <WizardInput
                    label={hasLabel?"First Name*":""}
                    placeholder="First Name"
                    id="firstName"
                    name="firstName"
                    innerRef={register({
                      required: 'First Name is required',
                      minLength: {
                        value: 2,
                        message: 'Min length 2'
                      }
                    })}
                    errors={errors}
                  />
              </FormGroup>
          </Col>
          <Col md={6}>
          <FormGroup>
            <WizardInput
              label={hasLabel?"Last Name*":""}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              innerRef={register({
                required: 'Last Name is required',
                minLength: {
                  value: 2,
                  message: 'Min length 2'
                }
              })}
              errors={errors}
          />
            </FormGroup>
        </Col>
        </Row> */}
            <WizardInput
              label={hasLabel?"Name On Credit/Debit Card":""}
              placeholder="Name On Credit/Debit Card"
              id="fullName"
              name="fullName"
              innerRef={register({
                required: 'Name is required',
                minLength: {
                  value: 2,
                  message: 'Min length 2'
                }
              })}
              errors={errors}
          />
        <WizardInput
          label={hasLabel?"Email":""}
          placeholder="Email"
          id="email"
          name="email"
          innerRef={register({
            required: 'Email is required',
            pattern: {
              value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          })}
          errors={errors}
        /> 
                  <WizardInput
            type="password"
            label="Password"
            placeholder="Password"
            id="password"
            name="password"
            innerRef={register({
              required: 'You must specify a password',
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })}
            errors={errors}
          />
      {/* <Row form>
        <Col>
          <WizardInput
            type="password"
            label="Password*"
            placeholder="Password"
            id="password"
            name="password"
            innerRef={register({
              required: 'You must specify a password',
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })}
            errors={errors}
          />
        </Col>
        <Col>
          <WizardInput
            type="password"
            label="Confirm Password*"
            placeholder="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            innerRef={register({
              validate: value => value === watch('password')|| `The password do not match`
            })}
            errors={errors}
          />
        </Col>
      </Row> */}
      <p>By clicking Next, you accept the <a href="/terms-and-conditions"> terms</a> and <a href="/privacy-policy"> privacy policy</a></p>
      <p>Already have an account?<a href="/authentication/login"> Sign in</a> </p>
      {/* <WizardInput
          type="checkbox"
          id="agreeToTerms"
          tag={CustomInput}
          label={
            <Fragment>
              I accept the <a href="/terms"> terms</a> and <a href="/privacy"> privacy policy</a>
            </Fragment>
          }
          name="agreeToTerms"
          innerRef={register({
            required: 'You have to agree with us'
          })}
          errors={errors}
        /> */}
  </CardBody>
  <CardFooter className={' bg-light d-flex'}>
    
  {error &&<ErrorMessage error={error}/>}
            <ButtonIcon
                  disabled={loading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  icon={false ? 'chevron-left' : 'chevron-right'}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  hideIcon={loading}
                >
                  {(!loading ) && "Next"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
          </CardFooter>
    </Form>
    </Fragment>
  
  );
};

WizardStep1.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

WizardStep1.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(WizardStep1);
