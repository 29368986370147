import React, { Fragment, useEffect, useState } from 'react';
import { gql, useMutation,useQuery } from '@apollo/client';
import {  Row, Col,Card, Button, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Flex from '../common/Flex';
import {useSelector} from 'react-redux'
import Section from '../common/Section';
import NewPlaidItem from './linked-cards/NewPlaidItem';
import CloudScene from '../scene/CloudScene';
import ActiveCards from '../bankAccount/ActiveCards';
import {useDispatch} from 'react-redux'
import InactiveCards from '../bankAccount/InactiveCards';
import CardIllustration from '../../assets/images/svgr/CardIllustration';
import ActivateCreditCards from '../getStarted/ActivateCreditCards';
import ManageCards from './linked-cards/ManageCards';
import { Link, useHistory } from "react-router-dom";
import {toast} from 'react-toastify'

// import ReactGA from 'react-ga';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';
import _ from 'lodash'
import Loader from '../common/Loader';

const AVAILABLE_ACCOUNTS = gql`
query AvailableAccounts{
  availableAccounts(SCF_type:"credit",loginRequired:true,active:true){
    _id
    mask
    name
    official_name
    SCF_type
    active
    subtype
    item_id
    plaidItem{
      _id
      name
      color
      logo
      loginRequired
    }
  }
  cardsRestoreOnNextSync{
    _id
    mask
    name
    official_name
    SCF_type
    active
    subtype
    item_id
    plaidItem{
      _id
      name
      color
      logo
      loginRequired
      restoreOnNextSync
    }
  }
}
`;



const RestoreCards   = ({ match: { url } }) => {
  let history = useHistory();
  const dispatch = useDispatch()
  const [bankGroups, setBankGroups] = useState([]);
  const [nextSyncbankGroups, setNextSyncBankGroups] = useState([]);

  const {data,loading,refetch} = useQuery(AVAILABLE_ACCOUNTS, {
    fetchPolicy: "no-cache"
  })
    
  useEffect(() => {
    if(data){
      console.log(data.cardsRestoreOnNextSync)
      const group = _.chain(data.availableAccounts)
      .groupBy('plaidItem._id')
      .map((value,key) => ({_id:key,name:value[0].plaidItem.name,value:value}))
      .value()
      setBankGroups(group)
      const nextSyncGroup = _.chain(data.cardsRestoreOnNextSync)
      .groupBy('plaidItem._id')
      .map((value,key) => ({_id:key,name:value[0].plaidItem.name,value:value}))
      .value()
      setNextSyncBankGroups(nextSyncGroup)
    }
  }, [data])

  const plaidUpdate = ({_id}) => {
    dispatch({
      type:"UPDATE_PLAID_STATE",
      payload: {
        redirectURL:window.location.href,
        _id:_id,
        updateLogin:true
      }
    })
    history.push('/plaid-update');
  }

  



  return (
    <DonorDashboardLayout>
      <Section className={'mt-3 mb-5'}>
      <Row noGutters className='justify-content-center'> 
     <Col  lg='8' md='10' sm='12'>
       
     <h2>Reauthenticate Round-Ups</h2>
     <p>For your security, your banks may ask you to reauthenticate Round-Ups. This may happen periodically depending on your bank.</p>
     {loading && <Loader/>}

        {bankGroups[0] && 
        <Fragment>
          <h4>Please login to reauthenticate roundups for the following accounts.</h4>
        <Flex column align={'center'}>
          <div style={{maxWidth:500}}>
            {bankGroups.map((bank) => 
            <Card key={bank._id} className='p-2 mb-2 border shadow-none'>
              <Flex className='mb-2' align='center'>
                <h5 className='mb-0'>{bank.name}</h5>
                <Badge color='soft-red' className='ml-auto'>Auth Required</Badge>
              </Flex>
              <ListGroup flush className='mb-2'>
              {bank.value.map((account) => 
                <ListGroupItem>{account.official_name} - {account.mask}</ListGroupItem>
              )}
              </ListGroup>
              <Flex>
                <Button className='ml-auto' onClick={() => plaidUpdate({_id:bank._id})}>Login to Reauthenticate</Button>
              </Flex>
 
            </Card>
            )}
          </div></Flex>
        </Fragment>

        }
        
        {nextSyncbankGroups[0] && 
        <Fragment>
          
        <h4>Update your Round-Ups?</h4>
        <p>We are waiting for your bank to send us your updated round-ups. Or, you can try to click Update and we will try to retireive them if they are available.</p>
        <p>Don't worry, you will be able to choose to donate them or ignore them later.</p>        
        <Flex column align={'center'}>
          <div style={{maxWidth:500}}>
            {nextSyncbankGroups.map((bank) => 
              <UpdateRoundups bank={bank}/>
            )}
          </div></Flex>
        </Fragment>
        }
        {!nextSyncbankGroups[0] && !bankGroups[0] && !loading &&
        <Flex align='center' column>
          <h5>Your cards are up to date!</h5>
          <p>Nothing to do here :)</p>
          <Link to='/donations'>Go To Donations</Link>

        </Flex>
        }
     </Col>
     </Row>
      </Section>

    </DonorDashboardLayout>
    
  // 
      
  );
};

export default RestoreCards;


const UpdateRoundups = ({bank}) => {

  const MANUAL_RESTORE = gql`
  mutation manualSyncRestore($_id: String!){
    manualSyncRestore(_id:$_id)
  }
  `;
  
  const [manualSyncRestore, manualSyncRestoreResponse] = useMutation(MANUAL_RESTORE)
  const syncRestore = ({_id}) => {
    manualSyncRestore({variables:{_id:_id}})
    .then(res => {
      toast.info('Round-Ups Updated!')
    })
    .catch(e => {
      toast.error('Update not available yet, try again in 1-2 minutes')
    })
  }
  
  return (
    <Fragment>
      { !manualSyncRestoreResponse.data && 
          <Card key={bank._id} className='p-2 mb-2 border shadow-none'>
          <Flex className='mb-2' align='center'>
            <h5 className='mb-0'>{bank.name}</h5>
            <Badge color='soft-green' className='ml-auto'>Auth Success</Badge>
          </Flex>
            <ListGroup flush className='mb-2'>
            {bank.value.map((account) => 
              <ListGroupItem>{account.official_name} - {account.mask}</ListGroupItem>
            )}
            </ListGroup>
            <Flex>
              <Button disabled={manualSyncRestoreResponse.loading} className='ml-auto' onClick={() => syncRestore({_id:bank._id})}>{manualSyncRestoreResponse.loading?'loading...':'Update Round-Ups'}</Button>
            </Flex>

          </Card>
      }
    </Fragment>

  )

}