import React, { Fragment } from 'react';
import { Col, Nav, Row } from 'reactstrap';
//import UserForm from './UserForm';
import Section from '../components/common/Section';
import Logo from '../components/navbar/Logo';
//import AuthWizardProvider from './AuthWizardProvider';

import {useSelector} from 'react-redux'
import PushkaLogo from '../components/navbar/PushkaLogo';
const WizardLayout = (props) => {
  const {pushka} = useSelector((state) => state.config)

  return (
    <Fragment>
    <Nav className="navbar navbar-light bg-light justify-content-center "> 
    {pushka 
    ? <PushkaLogo at='navbar-top'/>
    :<Logo at='navbar-top'/>
    }
      </Nav>
    <Section className="py-0 mb-5">
      <Row className="flex-center mt-md-5 mt-2 ">
        <Col sm={10} lg={7} className='px-2'>
            {props.children}
        </Col>
      </Row>
    </Section>
    </Fragment>
  );
};

export default WizardLayout;
