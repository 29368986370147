import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Spinner, Container } from 'reactstrap';
import Flex from './Flex';


const LoadingOverlay = ({loading, message}) => (    
  // <div className='loading-overlay'>
  <Container>

<Row className="flex-center loading-overlay p-4">
    <Flex column align='center'>

    <ClipLoader className=''
 color={'white'} loading={loading} size={40} />

 <p className='text-white mt-3' style={{zIndex:'2001'}}>{message}</p>
    </Flex>
    </Row>
  </Container>

  // </div>
);


LoadingOverlay.propTypes = { ...Spinner.propTypes };

LoadingOverlay.defaultProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary'
};

export default LoadingOverlay;
