import React, { useState, useEffect } from 'react';
import {  Card, } from 'reactstrap';
import { gql, useQuery,   } from '@apollo/client';
import classNames from 'classnames'
import  moment  from 'moment';
import TooltipIcon from '../../common/TooltipIcon';
import Table from '../../tables/PaymentsTable';
import Loader from '../../common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import Flex from '../../common/Flex';
const TransfersTable  = ({className,handleRowClick}) => {
  
  const PAYMENTS = gql`
  query achHistory{
    achHistory{
      _id
      amount
      created
      status
      source{
        bank_name
        last4
      }
    }
    myNextAch
  }
  `;

  
  const {data, loading} = useQuery (PAYMENTS)  
  const [tableData, setTableData] = useState([])
  const [date, setDate] = useState("")

  useEffect(() => {
    if(data){
      setTableData(data.achHistory)
      setDate(data.myNextAch)
    }
  }, [data])
  return (
    <div className={classNames(' ',className )}>
      <Card className={' p-md-4 p-2  shadow-none '}>
      <div className='' style={{minHeight:'200px'}}>
      <h5 className='mb-2'>Donation History</h5>
      <p>Your roundups are grouped together into a weekly donation. </p>
      <Flex align='center'>
        <FontAwesomeIcon className='mr-2' icon={faCalendarPlus} />
      <p className='fs--1 mb-0'>Your next donation will be on {moment(date).local().format("MMMM Do")}.</p>

      </Flex>
      {loading && <div className='mt-6'>
        <Loader/>
        </div>}
        {data && 
        <Table data={tableData} handleRowClick={handleRowClick}    />
        
        }
      </div>
    </Card>
      </div>
  );
};

export default TransfersTable;
