import * as React from "react"

function Diagram(props) {
  return (
    <svg viewBox="0 0 512.004 512.004"  {...props}>
      <path d="M497 512.004H15c-8.284 0-15-6.716-15-15s6.716-15 15-15h482c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
      <path d="M106 512.004H46c-8.284 0-15-6.716-15-15v-110c0-13.785 11.215-25 25-25h40c13.785 0 25 11.215 25 25v110c0 8.284-6.716 15-15 15zm-45-30h30v-90H61zm35-90h.01zM226 512.004h-60c-8.284 0-15-6.716-15-15v-146c0-13.785 11.215-25 25-25h40c13.785 0 25 11.215 25 25v146c0 8.284-6.716 15-15 15zm-45-30h30v-126h-30zm35-126h.01zM346 512.004h-60c-8.284 0-15-6.716-15-15v-201c0-13.785 11.215-25 25-25h40c13.785 0 25 11.215 25 25v201c0 8.284-6.716 15-15 15zm-45-30h30v-181h-30zm35-181h.01zM466 512.004h-60c-8.284 0-15-6.716-15-15v-301c0-13.785 11.215-25 25-25h40c13.785 0 25 11.215 25 25v301c0 8.284-6.716 15-15 15zm-45-30h30v-281h-30zm35-281h.01zM15 322.308c-8.284 0-15-6.716-15-15s6.716-15 15-15c88.206 0 184.244-32.12 270.421-90.443C364.702 148.21 427.876 77.87 458.744 8.881c3.384-7.562 12.257-10.95 19.818-7.565 7.562 3.383 10.949 12.256 7.565 19.818-33.086 73.947-100.112 148.877-183.891 205.576C211.147 288.357 109.137 322.308 15 322.308z" />
      <path d="M496.991 121.678c-6.624 0-12.684-4.42-14.479-11.122l-20.681-77.181-77.181 20.681c-8.005 2.146-16.227-2.604-18.371-10.606s2.604-16.227 10.606-18.371L468.555.516c8.005-2.145 16.227 2.605 18.371 10.606l24.563 91.67c2.144 8.002-2.604 16.227-10.606 18.371a15.045 15.045 0 01-3.892.515z" />
    </svg>
  )
}

export default Diagram
