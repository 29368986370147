const initialState = {
    redirectURL: null,
  //  launchNumber:1,
    product:null,
    autoOpen:true,
    _id:null,
    item_id:null,
    autoActivate:null,
    initialized:false,
    linkToken:null,
    initialized:false,
    updateLogin:null,
    // invited: false,
    // EIN: null,
    // charityName:null,
};

const plaidReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "ADD_NEW_CARD":
            return {
                ...state,
                ...payload,
                autoActivate:true,
                initialized:true
            }
        case "UPDATE_PLAID_STATE": 
            return {
                ...state,
                ...payload,
                initialized:true,
                updateLogin:payload.updateLogin?true:null

            }
        case "PLAID_RESET_STATE": 
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default plaidReducer