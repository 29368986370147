import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Flex from '../components/common/Flex';
// import SideBar from '../components/navbar/dashboard/SideBar';
import CharitySidebar from '../components/navbar/charityDashboard/SideBar';
import AdminSideBar from '../components/navbar/adminDashboard/SideBar';
import classNames from "classnames";
import {useSelector} from 'react-redux'
import Topbar from '../components/navbar/dashboard/TopBar';
import {  isMobile} from "react-device-detect";
import { Container } from 'reactstrap';

const DashboardLayout = ({  children }) => {
  const userRole = useSelector((state) => state.auth.role)
  const [collapsed, setCollapsed] = useState(isMobile);
  const toggleNavbar = () => {   
    setCollapsed(!collapsed)
  };
  return (
    <Fragment>  
      <div className='dashboard '>
          
        <Topbar  className={classNames({ "mobile": isMobile },)}/>
        <Flex>
        {/* {userRole==='DONOR' && <SideBar toggleNavbar={toggleNavbar} collapsed={collapsed} mobile={isMobile}/>} */}
        {userRole==='CHARITY' && <CharitySidebar toggleNavbar={toggleNavbar} collapsed={collapsed} mobile={isMobile}/>}
        {userRole==='ADMIN' && <AdminSideBar toggleNavbar={toggleNavbar} collapsed={collapsed} mobile={isMobile}/>}
        <div className={classNames("dashboard-content", { "collapsed": collapsed }, { "mobile": isMobile },)}>
          <Container className='m-0' >

          {children} 
          </Container   >
        </div>
        </Flex>
        
      </div>
    </Fragment>


  );
};
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardLayout;
