import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterStandard from '../footer/FooterStandard'
import Banner from '../common/Banner';
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';
import TermsConentent from '../Terms/TermsContent';
import Section from '../common/Section';
import {useSelector} from 'react-redux'
// import ReactGA from 'react-ga';
import PageLoader from '../common/PageLoader';


const HomeRedirect = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {homeRedirect} = useSelector((state) => state.config)


  useEffect(() => {
    window.location.href = homeRedirect;
  }, []);
  return (
    <Fragment>
      <PageLoader loading={true}/>

    </Fragment>
  );
};

// Terms.propTypes = { location: PropTypes.object.isRequired };

export default HomeRedirect;
