import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck,
  faMoneyCheck,
  faBars,
  faBookmark
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Flex from "../../common/Flex";

const SideBar = ({ collapsed, toggleNavbar }) => {

  const routes = [
    {title:"Approve Nonprofit",to:"/admin/approve-nonprofit",icon:faUserCheck},
    {title:"Approve (Pushka)",to:"/admin/approve-nonprofit-pushka",icon:faUserCheck},
    {title:"Payout Nonprofit",to:"/admin/payout-nonprofit",icon:faMoneyCheck},
    {title:"Misc.",to:"/admin/misc",icon:faBookmark},
  ]



 
  return(
    <div className={classNames("sidebar", { "collapsed": collapsed })}>
    <div className="sidebar-header">
    </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          {routes.map((route,i) => {
            return(
              <NavItem key={i}>
              <NavLink tag={Link} to={route.to}>
                <Flex align='center'>
                  <FontAwesomeIcon icon={route.icon} className="" size="lg"/>
                  <div className='ml-2 sidebar-text'>{route.title}</div>
                </Flex>
              </NavLink>
            </NavItem>
            )
          })}
        </Nav>
      </div>
      
      <div className="sidebar-footer">
            <div  onClick={toggleNavbar}>
              <FontAwesomeIcon icon={faBars} className="mr-2" />
              Collapse Menu
            </div>
      </div>
    </div>
  )
}


export default SideBar;
