import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { gql, useQuery, } from '@apollo/client';
import Flex from '../../common/Flex';
import Summary from '../../donation/Summary';
import Details from '../../donation/Details';
import Timeline from '../../donation/Timeline';
const DonationDetailsModal = ({_id,modal, setModal,removeSearch}) => {


  const toggle = () => {
    removeSearch()
    setModal(false)
  };

  const DONATION_DETAILS = gql`
  query DonationDetails($_id:String!){
    donationDetails(_id:$_id){
      _id
      pending
      transactionAmount
      roundupAmount
      roundupReason
      multiplier
      roundupMultiplied
      adjustmentAmount
      adjustmentReason
      adjustmentRemainder
      amount
      processed
      dailyMax
      processedAt
      created
      date
      transaction{
        amount
        created
        amount
        merchant_name
        name
      }
      pendingTransaction{
        amount
        created
        amount
        merchant_name
        name
      }
      plaidAccount{
        _id
        mask
        name
        official_name
        SCF_type
        active
        subtype
        plaidItem{
          name
          color
          logo
        }
      }
    }
  }
  `;
  const {data,} = useQuery(DONATION_DETAILS,{variables:{_id:_id}})
  const [donation, setDonation] = useState({})
  useEffect(() => {
    if(data){
      setDonation(data.donationDetails)
    }
  }, [data])
  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
    
    
    modalClassName="overflow-hidden modal-fixed-right modal-theme"
    className="modal-dialog-vertical"
    contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>Donation Details</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
        <Fragment>
          <Summary donation={donation}/>
          <Details donation={donation}/>
          <Timeline donation={donation}/>
        </Fragment>
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
       <Button color="secondary" onClick={() => {
          toggle()}}>Cancel</Button>        
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default DonationDetailsModal;