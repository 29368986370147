
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useHistory   } from 'react-router-dom';
import classNames from 'classnames'
import {  Row, Col, Card, } from 'reactstrap';
import {useSelector} from 'react-redux'
import Flex from '../common/Flex';
import Section from '../common/Section';
import ManagePayoutAccount from './Payouts/ManagePayoutAccount'
import PayoutURL from './Payouts/PayoutURL';
import PayoutDetailsModal from './Payouts/PayoutDetailsModal';
import ComingSoon from '../common/ComingSoon';
import PayoutsTable from '../tables/PayoutsTable';
import DashboardLayout from '../../layouts/DashboardLayout';
// import ReactGA from 'react-ga';

const CharityPayouts = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {charity} = useSelector((state) => state.charityUser)
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  const [modal, setModal] = useState(false);
  const _id = params.get('_id'); // bar
  const removeSearch = () => {

    history.replace({
      search: '',
    })
  }
  useEffect(() => {
    if(_id){
      setModal(true)
    }
  }, [_id])
  return (
<DashboardLayout>
      {/* {_id && <PayoutDetailsModal  _id={_id} modal={modal} setModal={setModal} removeSearch={removeSearch}/> } */}



        
    <Row >
    <Col lg="7"  className='p-1'>
      <Card className='p-4 shadow-none'>
          <h5 className='mb-2'>Payout History</h5>
        <PayoutsTable/>
      </Card>
    </Col>
    <Col lg="5"  className={classNames('p-1 order-lg-1 ', )}>
      <Flex column className='h-100'>
        <ManagePayoutAccount/>

      </Flex>
    </Col>
  </Row></DashboardLayout>
  );
};

export default CharityPayouts;
