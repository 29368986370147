
import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row, Spinner } from 'reactstrap';
import ComingSoon from '../common/ComingSoon';
import Section from '../common/Section';

import { gql, useQuery } from '@apollo/client';
import Flex from '../common/Flex';
import MyDonorsTable from '../tables/MyDonorsTable';
// import ReactGA from 'react-ga';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useLocation, useHistory   } from 'react-router-dom';
import CreateDonorGroup from './DonorGroup/CreateDonorGroup';
import MyDonorGroupsTable from '../tables/MyDonorGroupsTable';
import DonorGroupDetails from './DonorGroup/DonorGroupDetails';
import AddDonorGroupModal from './DonorGroup/AddDonorGroupModal';
const CharityDonorGroups  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  const _id = params.get('_id'); 
  const removeSearch = () => {
    history.replace({
      search: '',
    })
  }
  const addSearch = (_id) => {
    history.replace({
      search: '_id='+_id,
    })
  }
  const [modal, setModal] = useState(false)
  const add = () => {
    setModal(true)
  }
  //test
  // const {data,loading,error, refetch} = useQuery(MY_DONORS)
  return (
    <DashboardLayout>
      <AddDonorGroupModal modal={modal} setModal={setModal}/>
    <Row>
      <Col className='p-1'>
      {/* <Card className='p-md-4 p-1 shadow-none mb-4'>
          <h5 className='mb-2'>Create a Donor Group</h5>
          <p>Donors can be assigned to a Donor Group based on their invite link. Donor Groups are useful if your non profit has multiple regions / chapters. Create a Donor Group with a name and a description.</p>
          <CreateDonorGroup/>
      </Card> */}

      <Card className='p-md-4 p-1 shadow-none mb-4'>
          {_id && <Fragment>
            
            <div className='mb-2'>
              
            <Button color='link' className='p-0' onClick={removeSearch}>Show all Donor Groups</Button>
              </div>
            <h5 className='mb-2'>Donor Group Details</h5>
              <DonorGroupDetails _id={_id}/>
            </Fragment>}
          {!_id && <Fragment>
            <Flex className='mb-2' align='center'>
              <h5 className='mb-0'>Donor Group</h5>
              <Button onClick={add} className='ml-2' size='sm'>+</Button>
            </Flex>
          <p>Donors can be assigned to a Donor Group based on their invite link. Donor Groups are useful if your non profit has multiple regions / chapters. Create a Donor Group with a name and a description.</p>

            <MyDonorGroupsTable selectCampaign={addSearch}/>
          </Fragment>}
      </Card>
      </Col>
    </Row>

    </DashboardLayout>
  );
};

export default CharityDonorGroups;
