
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import { Card, Alert } from 'reactstrap';
import Flex from '../../common/Flex';
import {useSelector} from 'react-redux'
import PayoutAccount from '../Payouts/PayoutAccount';
import { gql, useQuery,  } from '@apollo/client';
import { Link } from 'react-router-dom';

const PayoutSummary  = ({}) => {
  const [pendingAmount, setPendingAmount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [days, setDays] = useState(0);
  const [lastPayout, setLastPayout] = useState(null);
  const now = new Date()

const PAYOUT_SUMMARY = gql`
query {
  currentPayoutSummary{
    pendingAmount
    pendingCount
    lastPayout{
      amount
      created
      status
      succeededAt
    }
  }
}
`;
  const {data,loading,error} = useQuery(PAYOUT_SUMMARY)
  const {primary} = useSelector((state) => state.theme.colors)
  useEffect(() => {
    if(data){
        setPendingAmount(data.currentPayoutSummary.pendingAmount)
        setPendingCount(data.currentPayoutSummary.pendingCount)
        setLastPayout(data.currentPayoutSummary.lastPayout)
        if(data.currentPayoutSummary.lastPayout){
          setDays(Math.round((now.getTime() - new Date(data.currentPayoutSummary.lastPayout.succeededAt).getTime())/ (1000 * 3600 * 24)))
        }
    }
  }, [data])


  return (
    <Card className='shadow-none p-4 mb-3 bg-white'>
      
      <Flex align='center' className='mb-3 flex-wrap'>
          <h5 className='mb-0'>Payout Summary </h5>
          <Link className="font-weight-semi-bold ml-auto  fs--1 " to={'/nonprofit/payouts'}>
            <p className='text-wrap m-0'>Go to Payouts         
            <FontAwesomeIcon icon="angle-right" transform="" className="ml-1" />
            </p>
          </Link>
        </Flex>
    <Flex align='center' wrap >
      <p className='mb-0 '> 
      <FontAwesomeIcon icon='exchange-alt' color={primary} className='mr-2'/>
      {pendingCount !== 0 && <span className=''>You have {pendingCount} pending payout{pendingCount===1?"":"s"} for ${pendingAmount.toFixed(2)}. </span>}
      {(pendingCount === 0 && lastPayout===null) && <span className=''>Your account has not been paid out yet. </span>}
      {(pendingCount === 0 && lastPayout!==null) && <span className=''>A payout for ${Number(lastPayout.amount).toFixed(2)} was completed {days === 0 ? "today": days + " days ago"}. </span>}
      
      </p>
    </Flex>

    {/* <h5>Your Payout Account:</h5>
    <PayoutAccount/> */}
  </Card>
  );
};

export default PayoutSummary;
