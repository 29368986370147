import React, { Fragment } from 'react';
 import PropTypes from 'prop-types';
import Section from '../common/Section';
import { Link } from 'react-router-dom';
import Flex from '../common/Flex';
import Logo from '../navbar/Logo';
import { Row, Col, } from 'reactstrap';
import Social from './Social';
import {useSelector} from 'react-redux'
import Powered from './Powered';

const FooterTitle = ({ children }) => <h5 className="text-uppercase mb-3">{children}</h5>;

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-0" key={index}>
        <Link className="text-nowrap  fs--1" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };
  const FooterStandard = () => {
    
  const {pushka, management} = useSelector((state) => state.config)
  const {mission} = useSelector((state) => state.theme)
    return (
      <Fragment>
        <Section>
        <Row>
            <Col lg lg={(pushka) ? 6 : 8} className='mb-3'>
    <Logo at='footer' className='mb-3'></Logo>
            <p className="">{mission}
                <Social/>
              </p>
            </Col>
            <Col md lg={2} md={4} className='mt-lg-5 mt-0 mb-3'>
              <h5 className=' text-uppercase mb-3 ' >Support</h5>
              <FooterList list={[
                    { title: 'Contact', to: '/support' },
                    { title: 'Terms', to: '/terms-and-conditions' },
                    { title: 'Privacy', to: '/privacy-policy' },
                    // { title: 'FAQ', to: '/FAQ' },
                  ]}   />
            </Col>
            {!management && <Col md lg={2} md={4} className='mt-lg-5 mt-0 mb-3'>
            <h5 className=' text-uppercase mb-3' >Donors</h5>
                          <FooterList list={[
                    { title: 'Get Started', to: '/get-started' },
                    { title: 'Login', to: '/authentication/login' },
                    { title: 'Forgot Password', to: '/authentication/forgot-password' },
                  ]}   />
            </Col> }
           { (pushka || management)  && <Col md lg={2} md={4} className='mt-lg-5 mt-0 mb-3'>
            <h5 className='  text-uppercase mb-3' >Non Profits</h5>
                          <FooterList list={[
                    { title: 'Create Account', to: '/authentication/nonprofit/signup' },
                    { title: 'Login', to: '/authentication/nonprofit/login' },
                    { title: 'Forgot Password', to: '/authentication/nonprofit/forgot-password' },
                  ]}   />
            </Col>}
          </Row>
        
        <Powered/>
        </Section>
        {/* <Section>

<Flex wrap className='flex-md-nowrap'  >
  <div className='pr-md-8 pr-0 mb-md-0 mb-4'>
    <Flex column  align='start'>

    <Logo at='footer' className='mb-3'></Logo>
    <p className="">{mission}
        <Social/>
       </p>
    </Flex>
  </div>
  <Flex  justify='around' className=' flex-fill'>
    <div className='mx-md-5 mx-0'>
       <FooterTitle>Support</FooterTitle>
      <FooterList list={[
            { title: 'Contact', to: '/support' },
            { title: 'Terms', to: '/terms-and-conditions' },
            { title: 'Privacy', to: '/privacy-policy' },
            { title: 'FAQ', to: '/FAQ' },
          ]}   />
    </div>
    {!management && <div className='mx-md-5 mx-0'>
       <FooterTitle>Account</FooterTitle>  
                  <FooterList list={[
            { title: 'Get Started', to: '/get-started' },
            { title: 'Login', to: '/authentication/login' },
            { title: 'Forgot Password', to: '/authentication/forgot-password' },
          ]}   />
    </div>}
    
    {(pushka || management) && <div className='mx-md-5 mx-0'>
    <h5 className=' text-uppercase mb-3' >Non Profits</h5>
                  <FooterList list={[
            { title: 'Create Account', to: '/authentication/nonprofit/signup' },
            { title: 'Login', to: '/authentication/nonprofit/login' },
            { title: 'Forgot Password', to: '/authentication/nonprofit/forgot-password' },
          ]}   />
    </div>}
  </Flex>

</Flex>
<Powered/>
</Section> */}
      </Fragment>
    );
  };
  
  export default FooterStandard;