import React, {  useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import {gql,useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'


const getAcronym = (str) => {
  return str.match(/\b(\w)/g).join('')
}

const colors = [
  "#892B64","#723C70","#5C4D7D","#455E89","#2E6F95"
]


const CharityMixTable  = ({className, viewOnly}) => {
  const GET_PROFILE = gql`
  query{ 
    me{ 
      charities{
        charity{
          name
          EIN
        }
        percent
      }
    }
  }
  `;
    const {data} = useQuery (GET_PROFILE)
  const [charities, setCharities] = useState([])


    useEffect(() => {
      if(data){
        const formatted = data.me.charities.map((each,i) => {
          return{
            name:each.charity.name,
            text: getAcronym(each.charity.name),
            color:colors[i],
            EIN: each.charity.EIN,
            percentage: each.percent * 100
          }
        })
        setCharities(formatted)
      }
    }, [data])

    const handleRemove = (charity) => {
      if(Object.keys(charities).length === 1){
        alert("You must have atleast 1 charity. Add a new charity before removing.")
      } 
      else{

        const filter = _.filter(charities, x => {
          return x != charity
        })
        let remaining = 100
        const newCharities = filter.map((each,i) => {
          if(i + 1 === Object.keys(filter).length){
            return {
              ...each,
              percentage:remaining,
              color:colors[i],
            }
          }else{
            remaining -= (100 / Object.keys(filter).length).toFixed(0)
            return {
              ...each,
              percentage:100 / Object.keys(filter).length,
              color:colors[i],
            }
          }
        })
         setCharities(newCharities)
      }
    }
  return (
      
    <div className={classNames(className )}>

    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Charity</th>
          <th>% Mix</th>
          {!viewOnly && <th></th>}
        </tr>
      </thead>
      <tbody>
        {charities.map((charity,i) => {
          return(
            <tr key={i}>
            <td>{
              <Flex align='center'>
                {!viewOnly && <div className="px-3 py-1 mr-3 rounded" style={{ backgroundColor: charity.color }}><p className='mb-0 r-2 text-white font-weight-semi-bold'>{charity.text}</p></div>}
                <p className='m-0'>{charity.name}</p>
              </Flex>
              }</td>
            <td>{charity.percentage.toFixed(0) + "%"}</td>
           {!viewOnly && <td><Button color='muted'size='sm' className='py-0 px-2' onClick={()=>{handleRemove(charity)}}><FontAwesomeIcon color='white' icon='trash' size='md'/></Button></td>} 
          </tr>
          )
      })}
      </tbody>
    </Table>
    </div>
  )
};

export default CharityMixTable;
