import React, { useState, useEffect,Fragment } from 'react';
import { Card, Badge, Button,ButtonGroup ,Dropdown ,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';
import Flex from '../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEllipsisH, faCog } from '@fortawesome/free-solid-svg-icons';
// import SetCampaignModel from './campaign/SetCampaignModel';


const CharityOptions = ({charity,setCharities, setAsDefault}) => {
  const [modal, setModal] = useState(false);
  const [dropdown, setDropdown] = useState(false)
  const toggle =() => {
    setDropdown(!dropdown)
  }
  return (
    <Fragment>
      {/* <SetCampaignModel  modal={modal} setModal={setModal} charity={charity}/> */}
    <Flex>
        <ButtonGroup className='ml-auto'>
        {/* {charity.donorGroupsEnabled && 
              <Button className='border btn-white px-2 py1 shadow-sm' onClick={()=>{setModal(true)}}>
                  <FontAwesomeIcon icon={faCog} color='gray' size='1x'/>
              </Button>
            } */}
            <Dropdown    isOpen={dropdown} toggle={toggle} direction="left">
                <DropdownToggle className='rounded-right border btn-white px-2 py1 shadow-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} color='gray' size='1x'/>
                </DropdownToggle>
                <DropdownMenu  >
                    <p className='mb-0 pl-2 border-bottom pb-1 font-weight-semi-bold'>ACTIONS</p>
                    {/* {account.status!=='verified' && <DropdownItem onClick={verifyAccount} >Verify Account</DropdownItem>}
                    <DropdownItem disabled={account.default} onClick={setAsDefault}>Set as default</DropdownItem> */}
                    <DropdownItem disabled >Remove</DropdownItem>

                </DropdownMenu>
            </Dropdown >
            </ButtonGroup>
            </Flex>

    </Fragment>

  )
}



export default CharityOptions