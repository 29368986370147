
import React, {useEffect, Fragment , useState} from 'react';
import Config from './multiTenancy/Config'
import Theme from './multiTenancy/Theme';
import {useSelector} from 'react-redux'
import {Helmet} from 'react-helmet'
import chroma from "chroma-js";
import ApplyBranding from './multiTenancy/ApplyBranding'
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import {useDispatch} from 'react-redux'
import useWindowDimensions from './hooks/useWindowDimensions';
import pushkaColors from './multiTenancy/pushkaColors'
import { BrowserRouter as Router, Redirect, Route, Switch,  } from 'react-router-dom';
import PageLoader from './components/common/PageLoader';
import { meCharityUser } from '../src/queries/queries/meCharityUser';

// import ReactGA from 'react-ga';
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  shutdown as shutdownIntercom
} from "./components/intercom/intercom"
import { loginAdmin, loginCharity, loginDonor } from './functions/auth';
const Initialize = (props) => {
  const {iconLogo} = useSelector((state) => state.theme)
  const {tenant} = useSelector((state) => state.config)
  const [colors, setColors] = useState({})
  const { height } = useWindowDimensions();
//   const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: red;
// `;

  const INIT = gql`
  query Initialize{
    getConfig{
      appName
      oneToMany
      demo
      management
      pushka
      homeRedirect
      tenant{
        subdomain
        approved
        _id
      }
    }
    getTheme{
      primary
      secondary
      iconLogo
      appName
      charityName
      aboutImage1
      aboutImage2
      aboutImage3
      bioImage1
      about
      quote
      bioTitle
      heroTitle
      heroImage
      heroSubtitle
      heroText
      mission
      url
      urlMask
      facebook
      instagram
    }
    verifyToken{ 
      token
      userRole
      exp
    }
  }
  `
  
// const GET_PROFILE = gql`
// query{ 
//   me{ 
//     _id
//     firstName
//     lastName
//     email 
//     active
//     inactiveReason
//     requirements
//     isVerified
//     setupStep
//     dailyMax
//     dailyMaxAmount
//     multiplier
//     transferDay
//     wholeDollarRoundup
//   }
// }
// `; 


  const { data, loading, error  }  = useQuery(INIT)
  // const [getProfile, profile] = useLazyQuery (GET_PROFILE)
  // const [getCharityUserProfile, charityUserProfile] = useLazyQuery (meCharityUser)

  
  const dispatch = useDispatch()
  // const getUserData = (userRole) => {
  //   switch(userRole){
  //     case 'DONOR':
  //       getProfile()
  //       break;
  //     case 'CHARITY':
  //         getCharityUserProfile()
  //       break;
  //      default:
  //   }
  // }
  const addConfig = (data) => {
    dispatch({
      type:"STORE_CONFIG",
      payload: {
        ...data
      }
    })
  }
  const login = async (data) => {
    console.log(data)
    if(data?.userRole === "DONOR"){
      await loginDonor(data)
      // dispatch({
      //   type:"LOG_IN",
      //   payload: {
      //     loggedIn:true,
      //     token:localStorage.getItem("token"),
      //     role:data.userRole,
      //     expiry:data.exp
      //   }
      // })
    }else if(data?.userRole === "CHARITY"){
      await loginCharity(data)
    }else if(data?.userRole === "ADMIN"){
      await loginAdmin(data)
    }
    else{
      localStorage.clear()
      dispatch({
        type:"AUTH_AVAIL",
      })
    }
  }
  const addTheme = (data) => {
    dispatch({
      type:"STORE_THEME",
      payload: {
        ...data,
        colors:{
          primary:data.primary,
          darker1:scale(data.primary)[7],
          darker2:scale(data.primary)[8],
          lighter1:scale(data.primary)[4],
          lighter2:scale(data.primary)[2],

        }
      }
    })
  }
  const scale  = (color)=> {
    return chroma
    .scale(['white', color, '#212529'])
    .mode("lrgb")
    .colors(11);
  } 
  useEffect(() => {
    if(data){
      addConfig(data.getConfig)
      //ReactGA.set({ tenant: data?.getConfig?.tenant?.subdomain });
      addTheme(data.getTheme)
      login(data.verifyToken)
      //getUserData(data.verifyToken?data.verifyToken.userRole:null)
      if(data.getConfig.pushka){
        setColors(pushkaColors)
      }else{
        setColors({
          primary: data.getTheme.primary,
          secondary: data.getTheme.secondary,      
          primary_gradient:  scale(data.getTheme.primary)[7],
          primary_light: scale(data.getTheme.primary)[4],
        })
      }
      loadIntercom()
      // if(data?.verifyToken?.userRole){
      //   loginDonor({token:localStorage.getItem("token"),...data.verifyToken})
      // }else{
        if(!data?.verifyToken?.userRole){
        localStorage.clear()
        dispatch({
          type:"AUTH_AVAIL",
        })
        shutdownIntercom()
        bootIntercom({action_color:data.getTheme.secondary,background_color:data.getTheme.primary})
      }
      // login(data.verifyToken)
      // getUserData(data.verifyToken?data.verifyToken.userRole:null)
    }
  }, [data])

  useEffect(() => {
    if(error && error.message === 'jwt expired'){  
      dispatch({type:"JWT_EXPIRE"})
    }
  }, [error])
  
    // useEffect(() => {
    //   if(profile.data){
    //     shutdownIntercom()
    //     bootIntercom({
    //       user_id: profile.data.me._id,
    //       role:'DONOR',
    //       action_color:data.getTheme.secondary,
    //       background_color:data.getTheme.primary})
    //     updateIntercom({
    //       email: profile.data.me.email,
    //       name: profile.data.me.firstName + " " + profile.data.me.lastName,
    //     })
    //     dispatch({
    //       type:"UPDATE_USER",
    //       payload:{
    //         ...profile.data.me
    //       }
    //     })
    //     dispatch({
    //       type:"UPDATE_GET_STARTED",
    //       payload:{
    //         step:!profile.data.me.isVerified?0:profile.data.me.setupStep ,
    //         mfa:profile.data.me.isVerified?false:true
    //       }
    //     })
    //     }
    // }, [profile.data])



  // useEffect(() => {
  //   if(charityUserProfile.data){
  //     shutdownIntercom()
  //     bootIntercom({
  //       // email: charityUserProfile.data.meCharityUser.email,
  //       user_id: charityUserProfile.data.meCharityUser._id,
  //       role:'CHARITY',
  //       action_color:data.getTheme.secondary,
  //       background_color:data.getTheme.primary})
  //     updateIntercom({
  //       email: charityUserProfile.data.meCharityUser.email,
  //       name: charityUserProfile.data.meCharityUser.firstName + " " + charityUserProfile.data.meCharityUser.lastName,
  //     })
  //     dispatch({
  //       type:"UPDATE_CHARITY_USER",
  //       payload:{
  //         ...charityUserProfile.data.meCharityUser,
  //       }
  //     })
  //   }
  // }, [charityUserProfile.data])


  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height])

    return (
      <Fragment>
        {(loading) && <PageLoader loading={true}/>}
        {data && 
        <Fragment>          
          <ApplyBranding colors={colors}/>
          {tenant && 
          
          props.children
          }
        </Fragment>
        }
        {/* {error && error.message === 'jwt expired' && <Router><Redirect to="/errors/401" /></Router>} */}
      </Fragment>

      // <Config>
      //   <Theme>
      //   <Helmet>
      //     <link rel="icon" href={iconLogo}/>
      //   </Helmet>  
      //   {props.children}
      //   </Theme>
      // </Config>
    //   <Fragment>
        
    //  <ApplyBranding colors={colors} ></ApplyBranding>
    //  {error && <p>Error: this domain is not a valid tenant</p>}
    //  {loading && <p>loading...</p>}

    //  {!error && !loading &&
    //      <Fragment>
    //        {props.children}
    //      </Fragment>
    //  }
    //   </Fragment>
      
    );
  };
  
  export default Initialize;




