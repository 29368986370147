import React, {  useEffect, useState } from 'react';
import {  Form, FormGroup, Input,  Label,  Spinner  } from 'reactstrap';
import { gql, useMutation,   } from '@apollo/client';
import PropTypes from 'prop-types';
import ErrorMessage from '../common/ErrorMessage';
import ButtonIcon from '../common/ButtonIcon';


const EditAndApproveCharityForm  = ({charityUser, hasLabel, sub }) => {


  
    const [charityName, setCharityName] = useState(charityUser.charityName)
    const [email, setEmail] = useState(charityUser.email)
    const [EIN, setEIN] = useState(charityUser.EIN)
    const [tenantId, setTenantId] = useState('')
    const [isDisabled, setIsDisabled] = useState(true);
    



    const EDIT_AND_APPROVE_CHARITY_USER = gql`
    mutation EditAndApproveCharityUser($email:String!,$EIN:String!,$charityName:String!,$tenantId:String!){
      editAndApproveCharityUser(email:$email,EIN:$EIN,charityName:$charityName,tenantId:$tenantId){ 
        firstName
        lastName
        email 
        EIN
        isVerified
        approved
        created
      }
    }
    `;    
    const EDIT_AND_APPROVE_SUB_CHARITY_USER = gql`
    mutation editAndApproveSubCharityUser($email:String!,$EIN:String!,$charityName:String!,$tenantId:String!){
      editAndApproveSubCharityUser(email:$email,EIN:$EIN,charityName:$charityName,tenantId:$tenantId){ 
        firstName
        lastName
        email 
        EIN
        isVerified
        approved
        created
      }
    }
    `;

  useEffect(() => {
    setIsDisabled(!tenantId);
  }, [tenantId]);
  
  const [editAndApprove, {data,loading,error}] = useMutation(sub?EDIT_AND_APPROVE_SUB_CHARITY_USER:EDIT_AND_APPROVE_CHARITY_USER,{
    variables:{email:email,EIN:EIN,charityName:charityName,tenantId:tenantId}
  })


  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    editAndApprove().then( () => {
    }).catch(e => {})

  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Charity Name</Label>}
        <Input
          placeholder={!hasLabel ? 'Charity Name' : ''}
          value={charityName}
          onChange={({ target }) => setCharityName(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>EIN</Label>}
        <Input
          placeholder={!hasLabel ? 'EIN' : ''}
          value={EIN}
          onChange={({ target }) => setEIN(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Tenant subdomain</Label>}
        <Input
          placeholder={!hasLabel ? 'tenantId' : ''}
          value={tenantId}
          onChange={({ target }) => setTenantId(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={isDisabled || loading}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Approve"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
    </Form>
  );
};
EditAndApproveCharityForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

EditAndApproveCharityForm.defaultProps = {
  layout: 'basic',
  hasLabel: true
};

export default EditAndApproveCharityForm;
