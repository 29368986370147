import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CharityReports from '../CharityReports';
import DonorGroupsReport from './DonorGroupsReport';

const CharityReportsRoutes = ({ match: { url } }) => {


return(

  <Fragment>
      <Switch>
        <Route exact path={`${url}`} component={CharityReports}/>
        {/* <Route path={`${url}/donor-groups`} component={DonorGroupsReport}/> */}
        <Route path={`${url}`} ><Redirect to="/errors/404" /></Route>
      </Switch>
  </Fragment>
);
}
export default CharityReportsRoutes;
