import React, { useState, useEffect,Fragment } from 'react';
import { Card, Badge, Button,ButtonGroup ,ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';
import Flex from '../common/Flex';
import BankImage from './BankImageIcon'
import Spinner from 'reactstrap/lib/Spinner';
import { gql, useMutation, } from '@apollo/client';
import ButtonIcon from '../common/ButtonIcon';
import ErrorMessage from '../common/ErrorMessage';
import {useDispatch} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useHistory   } from 'react-router-dom';
import { faExclamationTriangle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import AlertModal from '../common/AlertModal';
import StripeNotAvialableButtons from './options/StripeNotAvailable';
import InstantVerificationBankAccountOptions from './options/InstantVerificationBankAccountOptions';




const INSTANT_VERIFICATION = gql`
mutation InstantVerification($accountId: String!){
  instantVerification(accountId:$accountId){
    _id
    last4
    routing_number
    default
    created
    currency
  }
}
`;

const PlaidAccountVerify=({account, refetch,setIsVerifying,isVerifying, })=> {
  
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const [active, setActive] = useState(account.active);

  const dispatch = useDispatch()
  const [modal, setModal] = useState(false);
 
  const [instantVerification,{data,loading,error}] = useMutation(INSTANT_VERIFICATION, {
    variables:{accountId:account._id},
  })

  const verify = () => {
    dispatch({
      type:"UPDATE_PROCESS",
      payload:{
        loading:true,
        message:'We are verifying your account. Please do not refresh this page. This process can take up to 2 minutes.',
      }
    })
    setIsVerifying(!isVerifying)
    instantVerification().catch(e=>{
      dispatch({
        type:"UPDATE_PROCESS",
        payload:{
          loading:false,
          message:null,
        }
      })
    })
  }
  useEffect(() => {
    if(data && data.instantVerification){
      refetch()
      setIsVerifying(false)
      dispatch({
        type:"UPDATE_PROCESS",
        payload:{
          loading:false,
          message:null,
        }
      })
    }
      else if(error){
        setModal(true)
      refetch()

      setIsVerifying(false)
      dispatch({
        type:"UPDATE_PROCESS",
        payload:{
          loading:false,
          message:null,
        }
      })
      }
  }, [data,error])


  const stripeAuthSupportedClick = () => {
    setModal(true)
  }
  const onClick= () => {
    setModal(false)
    history.push(`?change_bank=MANUAL`)
  }
      return (
        <Fragment>
          <AlertModal modal={modal} setModal={setModal} title={"Instant verification not supported"} buttonText='Manual Verification' onClick={onClick}>
            <p>Unfortunitly, {account.plaidItem.name} does not support instant authentication.</p>
            <p>If you want to use this  {account.plaidItem.name} account, please manually verify it by clicking the button below.</p>
            </AlertModal>
  <Card        className={`px-1 py-2 my-0 shadow-none ${!account.stripeAuthSupported?'border-red':''}`}   >
            <Flex align="center"> 
            <div className='d-sm-block d-none'> 
            <BankImage className='' base64={account.plaidItem.logo}></BankImage>

            </div>
            <div className='ml-1' >
                <Flex align="center">
                    <strong style={{ color: account.plaidItem.color }}>{account.plaidItem.name}</strong>
                    <Badge className="ml-2" color={'soft-gray'}pill>{account.subtype}</Badge>
                </Flex> 
                <p className="m-0 fs--1">{account.official_name}</p>
                <Flex align="center">
                    <p className="m-0 fs--1">•••• {account.mask}</p>
                </Flex>
            </div>
            <div className='ml-auto'>
              <InstantVerificationBankAccountOptions account={account} verify={verify}  stripeAuthSupportedClick={stripeAuthSupportedClick} verifyManually={onClick}/>

              
  {/* {error &&<ErrorMessage error={error}/>} */}
              {/* {account.stripeAuthSupported && 
                  <ButtonIcon
                    disabled={loading || isVerifying}
                    color="primary"
                    className={loading?"ml-auto px-5" :"ml-auto"}
                    size="sm"
                    type="submit"
                    icon={false ? 'chevron-left' : 'chevron-right'}
                    iconAlign="right"
                    transform="down-1 shrink-4"
                    onClick={verify}
                    hideIcon={true}
                  >
                    {(!loading ) && "Select"}
                    {loading && <Spinner  size={"sm"}/>}
                  </ButtonIcon>} */}
{/*                 
                {!account.plaidItem.stripeAuthSupported && <StripeNotAvialableButtons stripeAuthSupportedClick={stripeAuthSupportedClick} account={account} verifyManually={onClick}/>
                } */}
              </div>
            </Flex>
        </Card>

        </Fragment>

      
  );
    }


export default PlaidAccountVerify