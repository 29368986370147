import React from 'react';
import {  Card,  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import DefaultBankAccount from '../transfers/DefaultBankAccount';
import CurrentAchSummay from '../transfers/CurrentAchSummay';
const TransferAccount  = ({className}) => {
  
  return (
    <div className={classNames(className )}>
      <Card className={' p-md-4 p-3  shadow-none '}>

        <Flex align='center' className='mb-3'>
          <h5 className='m-0'>Bank Account</h5>
          <Link className="font-weight-semi-bold ml-auto  fs--1 text-nowrap" to={'/settings'}>
            Edit
            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
          </Link>
        </Flex>
        
        <p className=''>Your donations are grouped together and withdrawn from your bank account, automatically.</p>
      <DefaultBankAccount/>
      <CurrentAchSummay className='mt-3'/>
 </Card>
      </div>
  );
};

export default TransferAccount;
