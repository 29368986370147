import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from '../../common/Flex';
import { Badge } from 'reactstrap';

const BadgePending = () => {
  return (
      <Badge className='px-2' color='soft-yellow'>
        <Flex>
          <p className='m-0'>pending</p>
          <FontAwesomeIcon className='ml-1' icon={'clock'}></FontAwesomeIcon>
        </Flex>
      </Badge>
  );
};

export default BadgePending;
