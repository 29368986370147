import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {  Form, FormGroup, Input,  Label,  Spinner  } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { gql, useMutation,   } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import {useDispatch} from 'react-redux'
import { loginAdmin } from '../../../functions/auth';

const AdminLoginForm = ({ setRedirect,setRedirectUrl, hasLabel,  }) => {
  // State
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const dispatch = useDispatch()
  const LOGIN = gql`
  mutation Login($userName: String!,$password: String!){
    superAdminLogin(userName:$userName,password:$password){ 
        token
        userRole
        exp
    }
  }
  `;
  const [login, auth] = useMutation(LOGIN,{variables:{userName:userName,password:password}})

  // useEffect(() => {
  //   if(auth.data){
  //     localStorage.setItem("token",auth.data.superAdminLogin.token,)  
  //     dispatch({
  //       type:"LOG_IN",
  //       payload:{
  //         loggedIn:true,
  //         token:auth.data.superAdminLogin.token,
  //         role:auth.data.superAdminLogin.userRole,
  //         expiry:auth.data.superAdminLogin.exp,
  //       }
  //     })
  //     setRedirectUrl({
  //       pathname: `/admin/dashboard`
  //     })
  //     setRedirect(true)
  //   }
  // }, [auth.data])


  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true)
    login().then( res => {
      loginAdmin(res.data.superAdminLogin)
      .then(
        setRedirectUrl({pathname: `/admin/dashboard`}),
        setRedirect(true)
      )
    }).catch(e => {})

  };

  useEffect(() => {
    setIsDisabled(!userName || !password);
  }, [userName, password]);
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>User Name</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={userName}
          onChange={({ target }) => setUserName(target.value)}
          type="userName"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={isDisabled}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={auth.loading}
            >
              {(!auth.loading ) && "Log in"}
              {auth.loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {auth.error &&<ErrorMessage error={auth.error}/>}
      </FormGroup>
    </Form>
  );
};

AdminLoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

AdminLoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(AdminLoginForm);
