import React, { Fragment, useState,useEffect } from 'react';
import {  CardTitle, CardBody,   Collapse,  Card, CardHeader } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import Loader from '../../common/Loader'
import Flex from '../../common/Flex';
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import ActivateCreditCards from './ActivateCreditCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Step2 from './Step2';
import Step3 from './Step3';

// const AVAILABLE_ACCOUNTS = gql`
// query AvailableAccounts($SCF_type: String!){
//   availableAccounts(SCF_type:$SCF_type){
//     _id
//     mask
//     name
//     official_name
//     SCF_type
//     active
//     subtype
//     plaidItem{
//       name
//       color
//       logo
//       loginRequired
//     }
//   }
// }
// `;


const Step2and3 = ({step}) => {
  // const cards = [1,2]
  const {appName} = useSelector((state) => state.theme)
  // const SCF_type = 'credit'
  // const [accounts,setAccounts] = useState([]);
  // const [isRefetching,setIsRefetching] = useState(false);
  // let history = useHistory();
  const dispatch = useDispatch()
  // const {appName} = useSelector((state) => state.theme)
  // const {data,loading,refetch} = useQuery(AVAILABLE_ACCOUNTS, {
  //   variables:{SCF_type},
  //   fetchPolicy: "no-cache"
  // })


  // useEffect(() => {

  //   if (data){
  //     setAccounts(data.availableAccounts)
  //     setIsRefetching(false)
  //   }
  // }, [data])

  // const openPlaid = () => {
  //    dispatch({
  //     type:"ADD_NEW_CARD",
  //     payload: {
  //       redirectURL:window.location.href,
  //       product:["transactions"]
  //     }
  //   })

  //   history.push('/plaid');
  // }

  const open2again = () => {
    if(step === 2){
      dispatch({
        type:"UPDATE_GET_STARTED",
        payload: {
          step:1,
        }
      })
    }
  }

  return (
    <Fragment>
      <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3 px-2">

      <CardTitle>Connect a card then a bank account</CardTitle>
      {/* <p>By connecting a debit or credit card, {appName} will round up your purchases to the next dollar. Once a week {appName} will group and trasnfer your donations from your bank account.</p> */}


      <Card className='shadow-none border  mb-3' key={1}>
              <CardHeader onClick={open2again} data-event={1} className={`border-bottom ${step === 1?'bg-black':''}`}>
                <Flex align='center'>

                  <div className={`number-circle ${step === 1?'active':''}`}><p className='fs-1 font-weight-semi-bold'>1</p></div>
                  <p className={`mb-0 fs-1 font-weight-semi-bold ${step === 1?'text-white':''}`}>Connect a Card</p>
                  </Flex>
              </CardHeader>
              <Collapse isOpen={step === 1}>
                <Step2/>
              </Collapse>
            </Card>

            <Card className='shadow-none border mb-3' key={2}>
              <CardHeader  data-event={2} className={`border-bottom ${step === 2?'bg-black' :''}`}>
                <Flex align='center'>
                  <div className={`number-circle ${step === 2?'active' :'muted'}`}><p className='fs-1 font-weight-semi-bold'>2</p></div>
                  <p className={`mb-0 fs-1 font-weight-semi-bold ${step === 2?'text-white':'text-muted'}`}>Connect a Bank Account</p>
                  </Flex>
              </CardHeader>
              <Collapse isOpen={step === 2}>
                {step === 2 &&
                  <Step3/>
                }
              </Collapse>
            </Card>
      </CardBody>

        {/* {cards.map(index => {
          return (
            <Card style={{ marginBottom: '1rem' }} key={index}>
              <CardHeader onClick={toggle} data-event={index}>Header</CardHeader>
              <Collapse isOpen={collapse === index}>
              <CardBody>Example</CardBody>
              </Collapse>
            </Card>
          )
        })}      */}


        </Fragment>
  );
};

export default Step2and3;
