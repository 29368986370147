import React, { Fragment } from 'react';
import { Alert, Card, Spinner  } from 'reactstrap';
import WizardLayout from '../../../layouts/WizardLayout';
import Header from './header/Header';
import Step1 from './Step1';
import Step2 from './Step2';
import Step2and3 from './Step2and3';
import Step3 from './Step3';
import {useSelector} from 'react-redux'
import HeaderNav from './HeaderNav';
import Step4 from './Step4';
// import ReactGA from 'react-ga';
import {  isMobile} from "react-device-detect";
import WhatToExpect from './WhatToExpect';
import NotApproved from './NotApproved';
import Flex from '../../common/Flex';

const GetStarted = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {tenant} = useSelector((state) => state.config)
  const {step, mfa,getStartedLoaded, setupPassword} = useSelector((state) => state.getStarted)
  const {invited, EIN, charityName,approved} = useSelector((state) => state.inviteReducer)
  const handleBackStep = () => {}

  return (
    <WizardLayout>
        {tenant?.approved===false && 
         <Card  className="theme-wizard  mb-4 mt-2">
           <NotApproved/>
         </Card>
        }
      
      {tenant?.approved!==false &&  
      <Fragment>
      <Card className="theme-wizard mb-md-4 ">
        <Header step={step} setupPassword={setupPassword} />
      </Card>
      
      <Card  className="theme-wizard  mb-4 mt-2">
        {/* {!getStartedLoaded && <Flex className='m-5 p-4' justify={'center'}><Spinner color='gray'/></Flex>} */}
        {true && 
        <Fragment>

          {step !== 4 && <HeaderNav step={step} handleBackStep={handleBackStep}/>        }
          {step === 0 && <Step1/>}
          {(step === 1 || step === 2) && <Step2and3 step={step}/>}  
          {/* {step === 2  && <Step3/>} */}
          {step === 3 && <Step4/>}
          {step === 4 && <WhatToExpect/>}
        </Fragment>
        }
      </Card>
      {invited && step===0 && !mfa && approved && <Alert className='mb-4'color='green'>You were invited by {charityName} [{EIN}]</Alert>}

      </Fragment>
      
      }
    </WizardLayout>
  );
};

export default GetStarted;
