import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 21.6"  width='200px' height='100px'{...props}>
      <linearGradient
        id="cloud2"
        gradientUnits="userSpaceOnUse"
        x1={18}
        y1={20.437}
        x2={18}
        y2={1.302}
      >
      <stop offset={0} stopColor="white" stopOpacity={0} />
      <stop offset={1} stopColor="white" stopOpacity={.6} />
      </linearGradient>
      <path
        d="M31.19 12.82c-.1 0-.2.01-.3.01v-.09c0-2.98-2.21-5.44-5.09-5.82v-.05c0-.67-.55-1.22-1.22-1.22-.17 0-.33.03-.47.1a8.165 8.165 0 00-7.27-4.44c-3.75 0-6.9 2.52-7.87 5.96a3.171 3.171 0 00-4.11 3.03c0 .53.13 1.04.37 1.48C2.89 11.82 1 13.74 1 16.1c0 2.4 1.94 4.34 4.34 4.34H31.2c2.1 0 3.81-1.7 3.81-3.81a3.824 3.824 0 00-3.82-3.81z"
        opacity={0.58}
        fill="url(#cloud2)"
      />
    </svg>
  )
}

export default SvgComponent
