import React, { Fragment } from 'react';
import {  Col, Row } from 'reactstrap';
import Section from '../common/Section';
import {useSelector} from 'react-redux'
import CloudScene from '../scene/CloudScene';
import CharitySettings from './charities/CharitySettings';
import MoneyTree from '../../assets/images/svgr/MoneyTree';
import Flex from '../common/Flex';
// import ReactGA from 'react-ga';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';

const Charities   = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {primary} = useSelector((state) => state.theme)
  return (
    <DonorDashboardLayout>
    <CloudScene>
      <Section className=' h-100 pb-0'>
          <Flex wrap align='center' justify='around'className='h-100 '>

          <div className='mt-6' style={{maxWidth:'500px'}}>

          <h1 className='text-black mb-4'>{"Support the Cause You Care About" }</h1>
          <h5 className='text-primary mb-4'>{'You decide how much of your donations go to which non-profits'}</h5>      
            </div>
          <MoneyTree  primary={primary} width='100%' className='scene-foreground  mt-5 px-4 '/>
          </Flex>  

      </Section>
    </CloudScene>
    

    <Section className=' '>

     <Row noGutters className='justify-content-center'>
            <Col md={8} >
 
         <CharitySettings className='py-2'/>
       </Col></Row>
       </Section>
    </DonorDashboardLayout>
  );
};

export default Charities;
