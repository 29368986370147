import React, { useState, useEffect } from 'react';
import AuthBasicLayout from '../../layouts/AuthBasicLayout';
import NonprofitDetailsForm from './forms/NonprofitDetailsForm';
import {useSelector} from 'react-redux'
import { gql, useQuery, useMutation } from '@apollo/client';

import { useHistory } from "react-router-dom";
import Flex from '../common/Flex';
import withRedirect from '../../hoc/withRedirect';
import { Alert, Button, FormGroup, Input, Spinner } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import ErrorMessage from '../common/ErrorMessage';
import { updateCharity } from '../../functions/auth';

const INVITE_LINK = gql`
query myInviteLink{
  myInviteLink
}
`;

const CUSTOMIZE_SUBDOMAIN = gql`
mutation customizeSubdomain($subdomain:String!){
  customizeSubdomain(subdomain:$subdomain)
}
`;

const NonprofitCustomization = ({ setRedirect,setRedirectUrl,}) => { 
  let history = useHistory();

  const {onboardingComplete} = useSelector((state) => state.charityUser)
  useEffect(() => {
    if(onboardingComplete === true){
      history.push('/nonprofit/dashboard');
    }
  }, [onboardingComplete])
  
  const {data,loading} = useQuery(INVITE_LINK, {
    fetchPolicy: "no-cache"})
  const [subdomain, setSubdomain] = useState('')
  
  const [submit,customizeSubdomainResponse] = useMutation(CUSTOMIZE_SUBDOMAIN,{variables:{subdomain}})

  useEffect(() => {
    if(data?.myInviteLink){

      const link = new URL(data.myInviteLink)
    
      setSubdomain(link.hostname.split('.')[0])
    }
  }, [data])

  const onSubmit = () => {
    submit().then(async res => {
      await updateCharity().then(
      ).catch(e=>{})
    }).catch(e => {})
  }
  return (
    <AuthBasicLayout>
        {/* <NonprofitDetailsForm/> */}
        {/* <Alert color='green'>You application is under review</Alert> */}
        <h5>Customize your subdomain</h5>
        <p>Your sparechange campaign will be hosted as a subdomain of sparechange.fund. Please customize your subdomain.</p>
        {/* <Flex align='center'>
        {loading && <p className='mb-0'>loading...</p>}  
        {data && <a className='mb-0'>{data?.myInviteLink}</a>}
        <Button className='ml-auto'>View</Button>
        </Flex> */}
        
        <Flex className='mb-3' align='center'>
          <p className='mb-0'>https://</p>
          
          <Input type="email" name="email" id="exampleEmail" placeholder={subdomain} onChange={ e => {setSubdomain(e.target.value)}} />
          <p className='mb-0'>.sparechange.fund</p>
        </Flex>
        
  {customizeSubdomainResponse.error &&<ErrorMessage error={customizeSubdomainResponse.error}/>}
        <Alert color='gray'>Note: Once you click update, you will not be able to modify your subdomain again.</Alert>
       
        <Flex>
          <ButtonIcon
                  disabled={loading || customizeSubdomainResponse.loading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  onClick={onSubmit}
                  hideIcon={customizeSubdomainResponse.loading}
                >
                  {(!customizeSubdomainResponse.loading ) && "Update"}
                  {customizeSubdomainResponse.loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
        {/* This button should call CustomizeSubdomain... it may error if the subdomain is not available */}

          </Flex>


    </AuthBasicLayout>
  );
};


export default withRedirect(NonprofitCustomization);
