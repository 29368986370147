import React, { useState,  useEffect } from 'react';
import { gql,useQuery } from '@apollo/client';
import { Form, Input, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TenantSelect from './TenantSelect';
//import CharitySelect from './CharitySelect'


const TENANT_SEARCH_QUERY = gql`
  query tenantSearch($filter: String!) {
    tenantSearch(filter: $filter) {
      id
      count
      tenants{
        _id
        subdomain
        customDomains
        contactName
        contactEmail
        contactPhone
        charityName
        charityEIN
      }
    }
  }
`;
const TenantSearch = ({onSelect,EINSelect, EINCompare}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const { data, loading,  } = useQuery(TENANT_SEARCH_QUERY, {
    variables:{filter:searchFilter},
    context: { headers: { test: `Bearer test` } }
  })

  useEffect(() => {
    if(loading ){
      setIsLoading(false)
    }
    if (data && data.tenantSearch){
      setIsLoading(false)
      setTenants(data.tenantSearch.tenants)
    }
  }, [loading, data])
  return(
    <div className='mt-3'>
      <Form inline className="search-box">
        <Input onChange={(e) => setSearchFilter(e.target.value)} type="search" placeholder="Search..." aria-label="Search" className="rounded-pill search-input" />
        <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
      </Form>
      {isLoading && <Spinner/>}
      {tenants.map((tenant,i) => {
        <p key={i}>{"tenant._id"}</p>
      })}
      {tenants && 
      <TenantSelect tenants={tenants} onSelect={onSelect} EINSelect={EINSelect} EINCompare={EINCompare}/>
      }
    </div>
  );
};

export default TenantSearch