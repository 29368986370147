import React, { useState, useEffect,Fragment } from 'react';
import { Card, Badge, Button,ButtonGroup ,ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';
import Flex from '../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';


const StripeNotAvialableButtons = ({account, stripeAuthSupportedClick,verifyManually, verify}) => {
  const [dropdown, setDropdown] = useState(false)
  const toggle =() => {
    setDropdown(!dropdown)
  }
  return (
    <Flex>
        <ButtonGroup>
          
        {account.plaidItem.stripeAuthSupported && 
          <Button className='border btn-white px-2 py1 shadow-sm mr-2 fs--1' onClick={verify}>
            verify
          </Button>
}
          {!account.plaidItem.stripeAuthSupported && 
            <Button className='border btn-white px-2 py1 shadow-sm' onClick={stripeAuthSupportedClick}>
                <FontAwesomeIcon icon={faExclamationTriangle} color='red' size='1x'/>
            </Button>
          }
            <ButtonDropdown   isOpen={dropdown} toggle={toggle}>
                <DropdownToggle className='rounded-right border btn-white px-2 py1 shadow-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} color='gray' size='1x'/>
                </DropdownToggle>
                <DropdownMenu>
                    <p className='mb-0 pl-2 border-bottom pb-1 font-weight-semi-bold'>ACTIONS</p>
                    <DropdownItem onClick={verifyManually}>Verify Manually</DropdownItem>
                    <DropdownItem disabled>Remove</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>
    </Flex>
  )
}



export default StripeNotAvialableButtons