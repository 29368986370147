import React  from 'react';
import PropTypes from 'prop-types';
import Flex from '../components/common/Flex';
import Section from '../components/common/Section';
import { Card, CardBody, Col, Row } from 'reactstrap';

import {useSelector} from 'react-redux'
import Logo from '../components/navbar/Logo';
import PushkaLogo from '../components/navbar/PushkaLogo';

const AuthCardLayout = ({ leftSideContent, children }) => {  
  const {pushka} = useSelector((state) => state.config)
  return (
    <Section fluid className="py-0">
      <Row noGutters className="min-vh-100 flex-center">
        <Col lg={8} className="col-xxl-5 py-3">
          <Card className="overflow-hidden z-index-1">
            <CardBody className="p-0">
              <Row noGutters className="h-100">
                <Col md={5} className="text-white text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7 ">
                    <div className="z-index-1 position-relative">
                    {pushka 
                      ? <PushkaLogo at='auth' white/>
                      :<Logo at='auth' dark/>
                      }
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5">{leftSideContent}</div>
                </Col>
                <Col md={7} tag={Flex} align="center" justify="center">
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default AuthCardLayout;
