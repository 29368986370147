import React, { useState, useEffect, Fragment } from 'react';
import { Button,  Row, Col, Spinner, Card, CardBody,CardTitle, Label  } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import CloudinaryModal from '../cloudinary/CloudinaryModal';
import ButtonIcon from '../common/ButtonIcon';
import AboutImageGrid from '../landing/About/AboutImageGrid';
import ErrorMessage from '../common/ErrorMessage';
import {Image} from 'cloudinary-react'
import Flex from '../common/Flex';

const AboutSection = ({  theme, refetch }) => {
  const [iconLogo, setIconLogo] = useState('')
  const [aboutImage1, setAboutImage1] = useState('')
  const [aboutImage2, setAboutImage2] = useState('')
  const [aboutImage3, setAboutImage3] = useState('')
  const [selectedCursor, setSelectedCursor] = useState(0)
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setIconLogo(theme.iconLogo)
    setAboutImage1(theme.aboutImage1)
    setAboutImage2(theme.aboutImage2)
    setAboutImage3 (theme.aboutImage3)
  }, [theme])

  const insertMedia = (publicId) => {
    switch(selectedCursor) {
      case 1:
        setAboutImage1(publicId)
        break;
      case 2:
        setAboutImage2(publicId)
        break;
      case 3:
        setAboutImage3(publicId)
        break;
      default:
      // code block
    }
    
  setModal(false)
  }
  const UPDATE = gql`
  mutation UpdateTheme($input:ThemeInput!){
    updateTheme(input:$input)
  }
`;
  const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{aboutImage1:aboutImage1,aboutImage2:aboutImage2,aboutImage3:aboutImage3}}})

  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (  <Fragment>  
    <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertMedia}/>
    <p>The images in the about section further customize your campaign. Select meaningful photos that will resonate with your donors and compelled them to donate to your cause.</p>
    <Row>
      <Col lg={6}>
        <div className='mb-4'>
        <Label>Top image</Label>
        <Flex align='center'>
          <Image width='150' className='img-thumbnail mr-3 ' publicId={aboutImage1} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/>

          <Button color='primary' onClick={
              ()=>{
                setSelectedCursor(1);
                setModal(true)
              }
            }>Change image</Button>
        </Flex>
        </div>
        <div className='mb-4'>
        <Label>Bottom left image</Label>
        <Flex align='center'>
          <Image width='150' className='img-thumbnail mr-3 ' publicId={aboutImage2} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/>

          <Button color='primary' onClick={
              ()=>{
                setSelectedCursor(2);
                setModal(true)
              }
            }>Change image</Button>
        </Flex>
        </div>
        <div className='mb-4'>
        <Label>Bottom right image</Label>
        <Flex align='center'>
          <Image width='150' className='img-thumbnail mr-3 ' publicId={aboutImage3} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/>

          <Button color='primary' onClick={
              ()=>{
                setSelectedCursor(3);
                setModal(true)
              }
            }>Change image</Button>
        </Flex>
        </div>
       
        <div className='mb-4'>
        <ButtonIcon
              disabled={loading}
              className="ml-auto"
              onClick={handleSubmit}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Save changes"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
        </div>

      </Col>
        <Col lg={6}>
          <Card className='p-2 border border-primary'>
            <CardTitle>Preview:</CardTitle>
            <CardBody>
            < AboutImageGrid coin iconLogo={iconLogo} aboutImage1={aboutImage1} aboutImage2={aboutImage2} aboutImage3={aboutImage3} />

            </CardBody>
          </Card>
        </Col>
    </Row>


    
    
    
    </Fragment>




  );
};


export default AboutSection;
