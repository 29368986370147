import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input,  Spinner  } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { gql, useMutation, useLazyQuery  } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import { useDispatch} from 'react-redux'
import { useForm } from "react-hook-form";
import Flex from '../../common/Flex';

const OTP_CHECK = gql`
mutation charityVerifyOTP($OTP:Int!){
  charityVerifyOTP(OTP:$OTP)
}
`;
const RESEND_OTP = gql`
mutation charitySendOTP{
  charitySendOTP
}
`;

const GET_PROFILE = gql`
query{ 
  meCharityUser{ 
    firstName
    lastName
    email 
    EIN
    isVerified
    approved
    created
    charity{
      name
      EIN
      publishedTheme
      donorGroupsEnabled
      stripeRequirements{
        past_due
      }
    }
  }
}
`;
const CharityOTPForm = ({ setRedirect,setRedirectUrl, }) => {
  // State
  
  const {  handleSubmit,   } = useForm();
  const [code, setCode] = useState('');
  const [resent, setResent] = useState(false);
  const [sendOTP, sendOTPResponse] = useMutation(RESEND_OTP)
  const [getProfile, {data,loading,error}] = useLazyQuery (GET_PROFILE,{
     fetchPolicy: "no-cache"
  })
  const [isNum, setIsNum] = useState(true);
  const [checkOTP, checkOTPResponse] = useMutation(OTP_CHECK, {
    variables:{OTP:Number(code)}
  })
  // const onSubmit = () => {
  //   checkOTP()
  //   .then( () => {
  //     getProfile().catch(e => {})
  //   })
  //   .catch(e => {})
  // }

  const onSubmit = () => {
    checkOTP().catch(e => {})
  }
  useEffect(() => {
    if(checkOTPResponse.data){
      getProfile()
    }
  }, [checkOTPResponse.data])
  useEffect(() => {
    if(sendOTPResponse.data){
      setResent(true)
    }
  }, [sendOTPResponse.data])
  
  useEffect(() => {
    if(data){
      dispatch({
        type:"UPDATE_CHARITY_USER",
        payload:{
          ...data.meCharityUser
        }
      })
      setRedirectUrl({
        pathname: `/nonprofit/dashboard`
      })
      setRedirect(true)
    }
  }, [data])
  
  const dispatch = useDispatch()

  return (
      
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h5>Verify your email address</h5>
      <p>You should have recieved an email with a One Time Passcode (OTP). Enter the 6-digit code below:</p>
      {/* <ReactCodeInput type='text' fields={6} margin="10x" onChange={(x) => setCode(x)}/> */}
    <FormGroup>
      <Input
        placeholder={'code'}
        value={code}
        onChange={({ target }) => setCode(target.value)}
        type="number"
      />
    </FormGroup>
      <p>This code will expire in 30 minutes.</p>
      <Flex align='center'>

      {!resent && <Button onClick={sendOTP} disabled={sendOTPResponse.loading} >Resend Code</Button>}
      {resent && <p className='mb-0 text-muted'>Code has been resent</p>}
          <ButtonIcon
                disabled={loading || !isNum}
                color="primary"
                className="ml-auto px-5"
                icon={false ? 'chevron-left' : 'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
                hideIcon={loading || checkOTPResponse.loading}
              >
                {(!loading && !checkOTPResponse.loading ) && "Next"}
                {loading || checkOTPResponse.loading && <Spinner  size={"sm"}/>}
              </ButtonIcon>
      
      </Flex>
      {checkOTPResponse.error &&<ErrorMessage error={checkOTPResponse.error}/>}
      {!isNum && <p className="error-message">You must enter a number</p>}
        </Form>
  );
};

CharityOTPForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

CharityOTPForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(CharityOTPForm);
