import React, { useCallback, useState, useEffect,useImperativeHandle ,forwardRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import ButtonIcon from '../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import { gql, useQuery } from '@apollo/client';

const CREATE_LINK_TOKEN = gql`
query CreateLinkToken($products: [String!]){
  createLinkToken(products:$products)
  }
`;
const Link = ({onLinkSuccess, product, icon, iconAlign, iconClassName,hideIcon, transform, children, disabled,className,size, ...rest },ref) => {
 
  const [openOnReady, setOpenOnReady] = useState(false)
  useImperativeHandle(ref, () => ({
    openPlaid() {
      setOpenOnReady(true)
      // alert("getAlert from Child")
    }
  }), [])
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [linkToken, setLinkToken] = useState('');
  const createLinkToken = useQuery(CREATE_LINK_TOKEN, {
    variables:{products:product}
  })
  const onSuccess = useCallback((token, metadata) => {
    onLinkSuccess({token, metadata})
  }, []);

  useEffect(() => {
    if(createLinkToken.laoding){
      setIsLoading(true)
    }
    if(createLinkToken.data){
      setLinkToken(createLinkToken.data.createLinkToken)
      setIsLoading(false)
    }
    if(createLinkToken.error){
      setIsLoading(false)
      setIsError(true)
    }
 }, [createLinkToken])

  const config = {
    token: linkToken,
    onSuccess,
    // ...
  };
   const { open, ready  } = usePlaidLink(config);

   useEffect(() => {
     if(ready && openOnReady){
       open()
     }
  }, [ready,openOnReady])
  return (

    
    <ButtonIcon
      size={size}
      onClick={() => open()}
      color={isError?"warning":"primary"}
      className={className}
      icon={false ? 'chevron-left' : 'chevron-right'}
      iconAlign="right"
      transform="down-1 shrink-4"
      hideIcon={isLoading || hideIcon}
      disabled={ isError || isLoading || !ready || disabled}
      >
      {(!isLoading && !isError) && children}
      {isError && "Error"}
      {(isLoading ) && <Spinner  size={"sm"}/>}
    </ButtonIcon>

  );
};
export default forwardRef(Link);

