import client from '../apollo/client';
import {me} from '../queries/queries/me'
import store from '../redux/store';
import {
    load as loadIntercom,
    boot as bootIntercom,
    update as updateIntercom,
    shutdown as shutdownIntercom
  } from "../components/intercom/intercom"
import { meCharityUser } from '../queries/queries/meCharityUser';

export const loginDonor = async ({token,userRole,exp}) => {
    try{

        await store.dispatch({
            type:"LOG_IN",
            payload:{
              loggedIn:true,
              token:token,
              role:userRole,
              expiry:exp
            }
          })
        const profile = await client.query({
            query:me,
            fetchPolicy: "no-cache"
        })
        await store.dispatch({
            type:"UPDATE_USER",
            payload:{
                ...profile.data.me
            }
        })
        await store.dispatch({
            type:"UPDATE_GET_STARTED",
            payload:{
                step:profile.data.me.setupPassword?profile.data.me.setupStep:0,
                // mfa:profile.data.me.isVerified?false:true,
                setupPassword:profile.data.me.setupPassword,
               // step:profile.data.me.setupStep
            }
        })
        const {theme} = store.getState();
        window.analytics.identify(profile.data.me._id)
        // window.analytics.identify(profile.data.me._id, {
        //         name: profile.data.me.firstName + profile.data.me.lastName ,
        //         email: profile.data.me.email,
        //         userRole:userRole
        //       });
        await shutdownIntercom()
        await bootIntercom({
            user_id: profile.data.me._id,
            // role:'DONOR',
            action_color:theme.secondary,
            background_color:theme.primary})
        // await updateIntercom({
        //     email: profile.data.me.email,
        //     name: profile.data.me.firstName + " " + profile.data.me.lastName,
        // })
        return true
    }catch(error){
        throw error
    }
}


export const updateDonor = async () => {
  try{
      const profile = await client.query({
          query:me,
          fetchPolicy: "no-cache"
      })
      await store.dispatch({
          type:"UPDATE_USER",
          payload:{
              ...profile.data.me
          }
      })
      await store.dispatch({
          type:"UPDATE_GET_STARTED",
          payload:{
              step:profile.data.me.setupPassword?profile.data.me.setupStep:0,
              // mfa:profile.data.me.isVerified?false:true,
              setupPassword:profile.data.me.setupPassword,
             // step:profile.data.me.setupStep
          }
      })
      return true
  }catch(error){
      throw error
  }
}

export const logout = async () => {
        
    const {theme} = store.getState();
    await shutdownIntercom()
    await bootIntercom({
      action_color:theme.secondary,
      background_color:theme.primary})
    // updateIntercom({
    //   email: profile.data.me.email,
    //   name: profile.data.me.firstName + " " + profile.data.me.lastName,
    // })
    await store.dispatch({
      type:"LOG_OUT",
      payload:{
      }
    })
}

export const loginCharity = async ({token,userRole,exp}) => {
    try{
        await store.dispatch({
            type:"LOG_IN",
            payload:{
              loggedIn:true,
              token:token,
              role:userRole,
              expiry:exp
            }
          })
        const profile = await client.query({
            query:meCharityUser,
            fetchPolicy: "no-cache"
        })
        await store.dispatch({
            type:"UPDATE_CHARITY_USER",
            payload:{
              ...profile.data.meCharityUser
            }
          })
        const {theme} = store.getState();
        window.analytics.identify(profile.data.meCharityUser._id)
        await shutdownIntercom()
        bootIntercom({
            user_id: profile.data.meCharityUser._id,
            // role:'CHARITY',
            action_color:theme.secondary,
            background_color:theme.primary})
        // updateIntercom({
        //     email: profile.data.meCharityUser.email,
        //     name: profile.data.meCharityUser.firstName + " " + profile.data.meCharityUser.lastName,
        // })
        return true
    }catch(error){
        throw error
    }
}
export const updateCharity = async () => {
    try{
        const profile = await client.query({
            query:meCharityUser,
            fetchPolicy: "no-cache"
        })
        await store.dispatch({
            type:"UPDATE_CHARITY_USER",
            payload:{
              ...profile.data.meCharityUser
            }
          })
        return true
    }catch(error){
        throw error
    }
}

export const loginAdmin = async ({token,userRole,exp}) => {
    try{
        await store.dispatch({
            type:"LOG_IN",
            payload:{
              loggedIn:true,
              token:token,
              role:userRole,
              expiry:exp
            }
          })
        // const profile = await client.query({
        //     query:meCharityUser,
        //     fetchPolicy: "no-cache"
        // })
        // await store.dispatch({
        //     type:"UPDATE_CHARITY_USER",
        //     payload:{
        //       ...profile.data.meCharityUser
        //     }
        //   })
        // const {theme} = store.getState();
        window.analytics.identify(null)
        await shutdownIntercom()
        // bootIntercom({
        //     user_id: profile.data.meCharityUser._id,
        //     // role:'CHARITY',
        //     action_color:theme.secondary,
        //     background_color:theme.primary})
        // // updateIntercom({
        // //     email: profile.data.meCharityUser.email,
        // //     name: profile.data.meCharityUser.firstName + " " + profile.data.meCharityUser.lastName,
        // // })
        return true
    }catch(error){
        throw error
    }
}
  