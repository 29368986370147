import React, { Fragment,useState,useEffect,  } from 'react';
import ButtonIcon from '../common/ButtonIcon';
import Flex from '../common/Flex';
import { gql, useQuery } from '@apollo/client';
import { CardBody, CardText, Button } from 'reactstrap';
import Loader from '../common/Loader';
import AccountVerifyList from './AccountVerifyList';
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";

const AVAILABLE_ACCOUNTS = gql`
query AvailableAccounts($SCF_type: String!){
  availableAccounts(SCF_type:$SCF_type){
    _id
    mask
    name
    official_name
    SCF_type
    active
    subtype
    stripeAuthSupported
    plaidItem{
      name
      color
      logo
      loginRequired
    }
  }
}
`;
const InstantVerification = ({onBack,closeModal,refetch}) => {
  const dispatch = useDispatch()
  let history = useHistory();
  const {autoOpen} = useSelector((state) => state.plaid)
    const [SCF_type, setSCF_type] = useState('checking');
    const [accounts,setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState('');
    const availableAccountsData = useQuery(AVAILABLE_ACCOUNTS, {
      variables:{SCF_type},
      fetchPolicy: "no-cache"
    })
    useEffect(() => {
      if(availableAccountsData.loading ){
        setLoading(true)
      }
      if (availableAccountsData.data){
        setAccounts(availableAccountsData.data.availableAccounts)
        setLoading(false)
      }
    }, [availableAccountsData])

    useEffect(() => {
      if(!availableAccountsData.loading && accounts.length===0 && autoOpen){
        openPlaid()
      }
    }, [loading, accounts])

    const openPlaid = () => {
      dispatch({
       type:"UPDATE_PLAID_STATE",
       payload: {
         redirectURL:window.location.href,
         product:["auth"],
         autoOpen:false
       }
     })
     
     history.push('/plaid');
   }


   const refetchList = () => {
    //  alert('here')
     availableAccountsData.refetch()
   }

  
  return (
    <Fragment>
      <Flex align='center'className="mb-4">
          <ButtonIcon
            color="link"
            icon={'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className='px-0 font-weight-semi-bold flex-shrink-r'
            onClick={() => {
              onBack()
            }}
            >
            Back
          </ButtonIcon>
          <h5 className="flex-grow-1 text-center m-0" >Instant Verification</h5>
          <div style={{ width: 55 }} ></div>
        </Flex>
      {!loading && accounts[0] && 
      <Fragment>
        <CardText>Select a transfer account or connect another bank account below.</CardText>
        <AccountVerifyList 
          refetchList={refetchList}
          refetch={refetch}
          closeModal={closeModal}
          accounts={accounts} 
          SCF_type={SCF_type}
          setAccountId={setAccountId}
          accountId={accountId}
          setIsLoading={()=>{}}
          />
      </Fragment>
      }
      
      {!loading && !accounts[0] && 
      <Fragment>
        <CardText>Instantly verify your bank account with your online banking login. This process uses <strong>Plaid</strong>, which is safe and secure. Read more about <strong>Plaid</strong> <a href="https://plaid.com/how-it-works-for-consumers/" target="_blank">here</a>.</CardText>
        
      </Fragment>
      }
      {!availableAccountsData.loading &&
       <Flex justify='end' className='my-3'>
          <Button className="ml-auto" color="link" onClick={openPlaid}>
          Connect a bank account
          </Button>       
       </Flex>

      }      
        {loading && 
          <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
            <Loader/>
          </CardBody>} 
    </Fragment>
  );
};

export default InstantVerification;
