
import { useLocation   } from 'react-router-dom';
import { useEffect } from 'react';
import {useSelector} from 'react-redux'

export default function usePageViews() {
    
  const {tenant} = useSelector((state) => state.config)
    const location = useLocation()
    useEffect(() => {
        window.analytics.page(location.pathname,{subdomain:tenant?.subdomain,tenant:tenant?._id})
    }, [location])
}