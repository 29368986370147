  
import React from "react";
import {  Button, Navbar,} from "reactstrap";
import Logo from "../Logo";
import {useSelector,useDispatch} from 'react-redux'
import ProfileDropdown from "../ProfileDropdown";
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  shutdown as shutdownIntercom
} from "../../intercom/intercom"

const Topbar = ({ className }) => {
  const {secondary,primary} = useSelector((state) => state.theme)
  const dispatch = useDispatch()
  
  const logout = () => {
    
    shutdownIntercom()
    bootIntercom({
      action_color:secondary,
      background_color:primary})
    // updateIntercom({
    //   email: profile.data.me.email,
    //   name: profile.data.me.firstName + " " + profile.data.me.lastName,
    // })
    dispatch({
      type:"LOG_OUT",
      payload:{
      }
    })
  }


  return (
    <Navbar
      color="light"
      light
      className={"navbar dashboard-topbar " + className}
      expand="md"
    >
      <Logo at='navbar-top'/>
        <div className='ml-auto mr-2'>
          <Button  onClick={logout} color='primary' className="btn-navbar ml-3" >Logout</Button>
        </div>
     
    </Navbar>
  );
};

export default Topbar;