import React from 'react';
import {Image} from 'cloudinary-react'
import { Row, Col } from 'reactstrap';
import Coin from '../../../assets/images/illustration/coin.png'
import Logo from '../../common/Logo';

const AboutImageGrid = ({coin, aboutImage1,aboutImage2,aboutImage3,iconLogo}) => {
  return (
    <Col lg lg={4} xl={4} className="mt-4 mt-md-0 about-image-grid ">
        
    <Row className=" pt-4 pb-3 " >
      
    <div className='charity-icon'>
      {coin &&     <img src={Coin} alt="" style={{
      transform: 'rotate(15deg)', 
      width:'60px',
      position:'absolute',
      right:'-10px',
      top:'0px'}}/>     }
    {!coin && <Logo icon publicId={iconLogo} width={80}></Logo>}
   </div>
       <Image  className={'img-about-top '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={aboutImage1}/>
     </Row>
     <Row className="" >
       <Col md xs={6} className=' p-0 pr-2'>
       <Image  className={'img-about-bottom '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={aboutImage2}/>
       </Col>    
       <Col md xs={6} className=' p-0  pl-2'>
       <Image  className={'img-about-bottom '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={aboutImage3}/>
       </Col>
   </Row>
  
  
      </Col>
  );
};



export default AboutImageGrid;
