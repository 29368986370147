import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {  Form, Row, Col, FormGroup, Input, CustomInput, Label,  Spinner  } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { gql, useMutation, useLazyQuery  } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { loginDonor } from '../../../functions/auth';

const DonorQuickSignupForm = ({ setRedirect,setRedirectUrl, hasLabel }) => {
  // State
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const {invited, EIN, donorGroup, approved,_id} = useSelector((state) => state.inviteReducer)
  let history = useHistory();



  const SIGNUP = gql`
  mutation quickDonorSignup($email:String!,$fullName: String!, $donorGroup:String,$_id:String){
    quickDonorSignup(email:$email,fullName:$fullName,donorGroup:$donorGroup,_id:$_id){
      token
      userRole
      exp
    }
  }
  `;

  const [signup, auth] = useMutation(SIGNUP,{variables:{email:email,fullName:fullName,_id:(invited && approved)?_id:null,donorGroup:donorGroup?donorGroup:null}})

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    signup()
    .then((res) => {
      console.log(res)
      loginDonor(res.data.quickDonorSignup)
      .then(        
        history.push('/get-started')
      ).catch(e => {})
    }).catch(e => {})

  };

  useEffect(() => {
    setIsDisabled(!email || !fullName);
  }, [email, fullName]);
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Name On Credit/Debit Card</Label>}
        <Input
          placeholder={!hasLabel ? 'Name On Credit/Debit Card' : ''}
          value={fullName}
          onChange={({ target }) => setFullName(target.value)}
          type="text"
        />
      </FormGroup>
      
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
        <ButtonIcon
              size={'lg'}
              disabled={isDisabled}
              className="ml-auto px-5 mt-2 w-100"
            >
              {(!auth.loading ) && "Round-Up and Donate!"}
              {auth.loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
              {auth.error &&<ErrorMessage error={auth.error}/>}
    </Form>
  );
};

DonorQuickSignupForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

DonorQuickSignupForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(DonorQuickSignupForm);
