import React, { useState,  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Badge,  } from 'reactstrap';
import Flex from '../common/Flex';
import BankImage from './BankImageIcon'
import {useSelector} from 'react-redux'
import { gql, useMutation, } from '@apollo/client';
import ToggleSwitch from '../common/ToggleSwitch';
import CardAccountOptions from './options/CardAccountOptions';

const ACTIVATE_CREDIT_ACCOUNT = gql`
mutation ActivatePlaidCreditAccount($accountId: String!){
  activatePlaidCreditAccount(accountId:$accountId)
}
`;

const INSTANT_VERIFICATION = gql`
mutation InstantVerification($accountId: String!){
  instantVerification(accountId:$accountId){
    _id
    last4
    routing_number
    default
    created
    currency
  }
}
`;

const AccountSelect=({account, refetch,  accountUpdating,SCF_type,   readOnly, loading})=> {
  //const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(account.active);
  const {primary} = useSelector((state) => state.theme.colors)

  // const [activateCreditAccount] = useMutation(ACTIVATE_CREDIT_ACCOUNT, {
  //   variables:{accountId:account._id}
  // })
  // const [instantVerification] = useMutation(INSTANT_VERIFICATION, {
  //   variables:{accountId:account._id}
  // })

  // const toggleAccount = (_id) =>{
  //   if(SCF_type==='credit'){
  //     setLoading(true)
  //     accountUpdating( + 1)
  //     activateCreditAccount().then(() => {
  //       accountUpdating( - 1)
  //       refetch()
  //       setActive(active => !active)
  //       setLoading(false)
  //     }).catch(e => {
  //     //  alert(e)
  //       setLoading(false)
  //       accountUpdating( - 1)
  //     })
  //   }else if(SCF_type==='checking'){
  //     setLoading(true)
  //     accountUpdating( + 1)
  //     instantVerification().then(()=>{
  //       accountUpdating( - 1)
  //       setActive(active => !active)
  //       setLoading(false)
  //     }).catch(e => {
  //       setLoading(false)
  //       accountUpdating( - 1)
  //     })
  //   }
  // }
  
      return (

        <Card  className="p-0 my-2 shadow-none" >
          <Flex align='center'>
            <div className='d-sm-block d-none'> 
              {account.plaidItem.logo ? <BankImage className='' base64={account.plaidItem.logo}/>:<FontAwesomeIcon icon={account.subtype==='checking'?"university":"credit-card"} className='mr-3' color={primary} size='2x'/> }
            </div>
            <div className='mb-2' >
                 <Flex align="center">
                     <p className={"m-0 fs--1 font-weight-semi-bold text-primary"}>{account.plaidItem.name}</p>
                     {(account.active  && !account.plaidItem.loginRequired) && 
                         <Badge className="ml-2 fs--2" color={'soft-green'}pill>
                             Active
                         </Badge>
                     }
                     {(account.active && account.plaidItem.loginRequired) && 
                         <Badge className="ml-2 fs--2" color={'soft-red'}pill>
                            Please Reauthenticate
                         </Badge>
                     }
                 </Flex> 
                 <p className="fs--2 m-0">{account.official_name}</p>
                 <Flex align="center">
                     <p className="fs--2 m-0">•••• {account.mask}</p>
                   <Badge className="ml-2 fs--2" size='xs' color={'soft-yellow'}pill>{account.subtype}</Badge>
                 </Flex>
             </div>             
             <div className='ml-auto'>
                <CardAccountOptions account={account} refetch={refetch} loading={loading}/>
              </div>

          </Flex>
       
        </Card>

        // <Card  className="p-0 my-2 shadow-none" >
        //     <Flex align="center mr-1" wrap> 
        //     <Flex align='center '>
        //       <div className='d-sm-block d-none'> 
        //       {account.plaidItem.logo ? <BankImage className='' base64={account.plaidItem.logo}/>: 
        //         <FontAwesomeIcon icon={account.subtype==='checking'?"university":"credit-card"} className='mr-3' color={primary} size='2x'/> }

        //       </div>
           
        //     <div className='mb-2' >
        //         <Flex align="center">
        //             <p className={"m-0 fs--1 font-weight-semi-bold text-primary"}>{account.plaidItem.name}</p>
        //             {account.active && 
        //                 <Badge className="ml-2 fs--2" color={'soft-green'}pill>
        //                     Active
        //                 </Badge>
        //             }
        //             {account.plaidItem.loginRequired && 
        //                 <Badge className="ml-2 fs--2" color={'soft-red'}pill>
        //                     Login Required
        //                 </Badge>
        //             }
        //         </Flex> 
        //         <p className="fs--2 m-0">{account.official_name}</p>
        //         <Flex align="center">
        //             <p className="fs--2 m-0">•••• {account.mask}</p>

        //             <Badge className="ml-2 fs--2" size='xs' color={'soft-yellow'}pill>{account.subtype}</Badge>
        //         </Flex>
        //     </div>
        //     </Flex>
            
        //       <div className='ml-auto'>
        //         <CardAccountOptions account={account} refetch={refetch} loading={loading}/>
        //       </div>
        //     </Flex>
        // </Card>

  );
    }


export default AccountSelect