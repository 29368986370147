import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Spinner } from 'reactstrap';
import Flex from './Flex';

const TableLoader = ({loading, height}) => (    
<Flex column align='center' justify="center" style={{height:`${height}px`}}>
      <ClipLoader 
 color={'#BBBBBB'} loading={loading} size={40} />
    </Flex>
);


TableLoader.propTypes = { ...Spinner.propTypes };

TableLoader.defaultProps = {
  height: 100,
  loading: true,
  type: 'grow',
  size: 'lg',
  color: 'primary'
};

export default TableLoader;
