import React, { useState, useEffect, Fragment } from 'react';

import { gql, useMutation,  } from '@apollo/client';
import Flex from '../../common/Flex';
import { Button, Form, FormGroup, Input,  Label,  Spinner , Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import { Image } from 'cloudinary-react';
import Section from '../../common/Section';
import Logo from '../../common/Logo'
import CloudinaryModal from '../../cloudinary/CloudinaryModal';

const AboutText = ({  theme,refetch }) => {
  const [aboutText, setAboutText] = useState('')
  const [aboutSubtitle, setAboutSubtitle] = useState('')
  const [aboutTitle, setAboutTitle] = useState('')
  const [bioImage1, setBioImage1] = useState('')
  const [quote, setQuote] = useState('')
  const [bioTitle, setBioTitle] = useState('')
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const [iconLogo, setIconLogo] = useState('')
  const [charityName, setCharityName] = useState('')

  useEffect(() => {
    setAboutText(theme.aboutText)
    setAboutSubtitle(theme.aboutSubtitle)
    setAboutTitle(theme.aboutTitle)
    setBioImage1(theme.bioImage1)
    setQuote(theme.quote)
    setBioTitle(theme.bioTitle)
    setIconLogo(theme.iconLogo)
    setCharityName(theme.charityName)
  }, [theme])

  const insertPublicId = (publicId) => {
    setSelectedId(publicId)
    setModal(false)
  }

  const UPDATE = gql`
  mutation upadatePushkaCharityTheme($input:pushkaThemeInput!){
    upadatePushkaCharityTheme(input:$input)
  }
`;
  const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{aboutTitle:aboutTitle,aboutSubtitle:aboutSubtitle,aboutText:aboutText,bioImage1:selectedId?selectedId:bioImage1,quote:quote,bioTitle:bioTitle}}})

  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (
    <Fragment>  
    <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertPublicId}/>
    <p>The about section of your campaign is an opportunity to tell your story. Personal stories resonate the most with donors so include a quote from a team member or from someone active in your donor community. </p>
  <Flex wrap align='center' className='p-3'>
    <Form onSubmit={handleSubmit} className='w-100'>
      <Row>
        <Col lg={6}>
        {/* <FormGroup>
        {true && <Label>About Title</Label>}
        <Input
          placeholder={!true ? 'About Title' : ''}
          value={aboutTitle}
          onChange={({ target }) => setAboutTitle(target.value)}
          type="textarea"
          rows="1"
        />
      </FormGroup> */}
        {/* <FormGroup>
        {true && <Label>About Subtitle</Label>}
        <Input
          placeholder={!true ? 'About Subtitle' : ''}
          value={aboutSubtitle}
          onChange={({ target }) => setAboutSubtitle(target.value)}
          type="textarea"
          rows="2"
        />
      </FormGroup> */}
        <FormGroup>
        {true && <Label>About Text</Label>}
        <Input
          placeholder={!true ? 'About Text' : ''}
          value={aboutText}
          onChange={({ target }) => setAboutText(target.value)}
          type="textarea"
          rows="4"
        />
      </FormGroup>
      <FormGroup>
        {true && <Label>Quote</Label>}
        <Input
          placeholder={!true ? 'Quote' : ''}
          value={quote}
          onChange={({ target }) => setQuote(target.value)}
          type="textarea"
          rows="3"
        />
      </FormGroup>
      <FormGroup>
        {true && <Label>Title</Label>}
        <Input
          placeholder={!true ? 'Title' : ''}
          value={bioTitle}
          onChange={({ target }) => setBioTitle(target.value)}
          type="textarea"
          rows="1"
        />
      </FormGroup>
      <FormGroup>

      {true && <Label>Headshot</Label>}
      
      <Flex align='center'>

      <Image  className={'about-image-quote mr-3 '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={selectedId?selectedId:bioImage1}/>
      <div>
       <Button className='mr-2'color='primary' onClick={() => {setModal(true)}}>Change photo</Button>
      </div>
      </Flex>
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={loading}
              hideIcon={loading}
            >
              {(!loading ) && "Save changes"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
        </Col>
        <Col lg={6}>
          
        <Card className=' border border-primary'>
            <CardTitle className='p-2'>Preview:</CardTitle>
            <div  className='bg-black position-relative overflow-hidden'>

<Section className=' text-white '>
<h2 className='mb-4 text-white'>About {theme.charityName}</h2>
    {aboutSubtitle && <p className='font-weight-semi-bold'>{aboutSubtitle}</p>}
<p className=''>{aboutText}</p>
<hr className=' border-muted py-3'/>
<Flex align='center' className='flex-wrap-reverse flex-lg-nowrap'>
  <div></div>
<Image  className={'about-image-quote mr-3 '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={selectedId?selectedId:bioImage1}/>
 
  <div className=''>
    <p className=''>{quote}</p>
        <p className=''>{bioTitle}</p>
  </div>
</Flex>
</Section>

</div>
          </Card>
        </Col>
        </Row>
    </Form>
    </Flex>
        {error &&<ErrorMessage error={error}/>}

    
    
    
    </Fragment>


  );
};


export default AboutText;
