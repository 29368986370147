import * as React from "react"

function Cloud1(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 21.6" width='200px' height='100px' {...props}
    
    
    
    
    
    >
      <linearGradient
        id="cloud1"
        gradientUnits="userSpaceOnUse"
        x1={18.486}
        y1={21}
        x2={18.486}
        y2={0.178}
      >
      <stop offset={0} stopColor="white" stopOpacity={0} />
      <stop offset={1} stopColor="white" stopOpacity={.6} />
      </linearGradient>
      <path
        d="M32.4 15.03c.23-.56.35-1.18.35-1.82 0-2.71-2.19-4.9-4.9-4.9-.42 0-.83.05-1.22.15a6.432 6.432 0 00-6.15-8.3 6.41 6.41 0 00-6.11 4.44c-.75-.78-1.8-1.26-2.96-1.26A4.12 4.12 0 007.4 8.41a6.336 6.336 0 00-5.14 6.23c0 3.5 2.84 6.34 6.34 6.34h23.11c1.67 0 3.02-1.35 3.02-3.02a3.05 3.05 0 00-2.33-2.93z"
        opacity={0.58}
        fill="url(#cloud1)"
      />
    </svg>
  )
}

export default Cloud1
