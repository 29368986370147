import React, { Fragment, useEffect, useState } from 'react';
import { gql, useMutation,useQuery } from '@apollo/client';
import {  Row, Col,Card, Button, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Section from '../common/Section';
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
// import ReactGA from 'react-ga';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';
import _ from 'lodash'
import moment from 'moment';
import Flex from '../common/Flex';
import RestoreDonationCard from './retoreDonations/RestoreDonationCard';


const RESTORED_DONATIONS = gql`
query {
  restoredDonations{
    timestamp
    count
    sum
    plaidAccount{
      _id
      name
      official_name
      mask
      plaidItem{
        name
      }
    }
  }
}
`;

const RestoreDonations   = ({ match: { url } }) => {
  let history = useHistory();
  const dispatch = useDispatch()
  const [bankGroups, setBankGroups] = useState([]);


  
  const {data,loading,refetch} = useQuery(RESTORED_DONATIONS, {
    fetchPolicy: "no-cache"
  })
    



  return (
    <DonorDashboardLayout>
      <Section className={'mt-3'}>
      <Row noGutters className='justify-content-center'> 
     <Col  lg='8' md='10' sm='12'>
       
     <h1>We found unprocessed round-ups when you reauthenticated your cards</h1>
        <p>Do you want to process these round-ups and add them to your next week's donations?</p>
        {data && 
        <Flex column align='center'>
          
        <div className='w-100' style={{maxWidth:500}}>
          {data.restoredDonations.map(restore => 
          <RestoreDonationCard restore={restore}/>
            // <Card key={restore.timestamp} className='p-2 mb-2 border shadow-none'>
            //   <Flex align='center' className={'mb-1'}>
            //     <h5 className='mr-2'>{restore.plaidAccount.plaidItem.name}</h5>
            //     <Badge className='ml-auto'color='soft-blue'>{moment(restore.timestamp).fromNow()}</Badge>
            //   </Flex>
            //   <ListGroup flush className='mb-2'>
            //     <ListGroupItem className='fs--1 py-1'>{restore.plaidAccount.official_name} - {restore.plaidAccount.mask}</ListGroupItem>
            //     <ListGroupItem className='fs--1 py-1'>Transactions: {restore.count}</ListGroupItem>
            //     <ListGroupItem className='fs--1 py-1'>Donations: ${restore.sum.toFixed(2)}</ListGroupItem>
            //   </ListGroup>
            //   {/* <h6>Process these donations?</h6>
            //   <Button>Process</Button>
            //   <Button color='link'>Ignore</Button> */}
            //   <Flex>
            //     <div className='w-50 mr-1'><Button color='primary'className='w-100'>Process</Button></div>
            //     <div className='w-50 '><Button color='muted' className='w-100'>Ignore</Button></div>
            //   </Flex>
            // </Card>
            )}
       </div>
        </Flex>}

     </Col>
     </Row>
      </Section>

    </DonorDashboardLayout>
    
  // 
      
  );
};

export default RestoreDonations;
