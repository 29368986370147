import React, { Fragment,useState } from 'react';
import WizardStep1 from './forms/WizardStep1';
import {useSelector} from 'react-redux'
import VerifyEmail from './VerifyEmail';
import SetupPassword from './forms/SetupPassword';

const Step1 = () => {
  const {mfa,setupPassword} = useSelector((state) => state.getStarted)
  const [email, setEmail] = useState("")
  return (
    <Fragment>
        {setupPassword !== false && <WizardStep1 layout="card" hasLabel setEmail={setEmail} email={email}/>}
        {/* {mfa && <VerifyEmail email={email}/>} */}
        {setupPassword === false && <SetupPassword/>}
        </Fragment>
  );
};

export default Step1;
