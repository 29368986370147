import React, { useState, useEffect } from 'react';
import { gql, useQuery,  } from '@apollo/client';
import {useSelector} from 'react-redux'
import classNames from 'classnames'
import Flex from '../../common/Flex';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash'

const CurrentAchSummay  = ({className}) => {
  const [pendingAmount, setPendingAmount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [days, setDays] = useState(0);
  const [lastAch, setLastAch] = useState(null);
  const [nextAch, setNextAch] = useState(null);
  const now = new Date()
  const {primary, } = useSelector((state) => state.theme.colors)
  const ACH_SUMMARAY = gql`
  query {
    currentAchSummay{
      pendingAmount
      pendingCount
      lastAch{
        amount
        created
        status
        succeededAt
      }
    }
    myNextAch
  }
  `;
  const {data,} = useQuery(ACH_SUMMARAY)
  
  useEffect(() => {
    if(data){
        setPendingAmount(data.currentAchSummay.pendingAmount)
        setPendingCount(data.currentAchSummay.pendingCount)
        setLastAch(data.currentAchSummay.lastAch)
        setNextAch(data.myNextAch)
    }
  }, [data])

  useEffect(() => {
    if(lastAch){
      setDays(Math.round((now.getTime() - new Date(lastAch.succeededAt).getTime())/ (1000 * 3600 * 24)))
    }
  }, [lastAch])
  return (
    <div className={classNames(' ',className )}>
      
    { (pendingCount === 0 && lastAch === null ) &&
    <Flex align='center' wrap >
      <p className='mb-0 '> 
      <FontAwesomeIcon icon='exchange-alt' color={primary} className='mr-2'/>
      <span className=''>Your first donation is scheduled for {moment(nextAch).format('MMMM DD')}</span>
      </p>
    </Flex>
    }
    { (pendingCount !== 0  ) &&
    <Flex align='center' wrap >
      <p className='mb-0 '> 
      <FontAwesomeIcon icon='exchange-alt' color={primary} className='mr-2'/>
      <span className=''>You have {pendingCount} pending donations for ${pendingAmount.toFixed(2)}. </span>
      <Link className="font-weight-semi-bold " to={'/donations'}>
          See Donation History
        </Link>
      </p>
    </Flex>
}
      { (pendingCount === 0 && lastAch !== null ) &&
      <Flex align='center' wrap >
        <p className='mb-0 '> 
        <FontAwesomeIcon icon='exchange-alt' color={primary} className='mr-2'/>
        <span className=''>A donation for ${Number(lastAch.amount).toFixed(2)} was completed {days === 0 ? "today": days + " days ago"}. </span>
        <Link className="font-weight-semi-bold " to={'/donations'}>
            See Donation History
          </Link>
        </p>
      </Flex>
      }
      </div>
  );
};

export default CurrentAchSummay;
