import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Row, Col, Button, Alert } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';

import Flex from '../../common/Flex';


const INVITE_LINK = gql`
query myInviteLink{
  myInviteLink
}
`;

const InviteCard   = () => {
  const {data,loading} = useQuery(INVITE_LINK, {})

  return (
      <Alert color='green'className='shadow-none p-3'>
          <h5>Invite Link:</h5>
        <Flex align='center'>
          <div  className='mr-2'>
          {loading && <p className='mb-0'>loading invite link...</p>}
          {data && <a  className='mb-0'>{data?.myInviteLink}</a>}
          </div>
            {/* <Button  className='mr-2' >Copy</Button>
            <Button>View</Button> */}
        </Flex>
        </Alert>
  );
};

export default InviteCard;
