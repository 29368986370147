import React, {  useEffect, useState } from 'react';
import {Image, Placeholder} from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ImageSelector = ({publicId,selectedId,setSelectedId,insert}) => {
    const [selected, setSelected] = useState(true)
  useEffect(() => {
    if(selectedId === publicId){
      setSelected(true)
    }else{
      setSelected(false)
    }
  }, [selectedId])

  return (
    <div onClick={() =>insert(publicId)}className='m-2' style={{position:'relative', boxShadow: selected?"  0px 0px 5px 3px #2271b1":""}}>
    <Image 
    className='img-fluid img-preview img-cloudinary-modal border'
      publicId={publicId} 
      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
      width='300'
      crop='scale'>
        
      <Placeholder type="predominant" />
    
    </Image>
   {selected &&
   <div style={{position:'absolute',
  top: 0,
  right: 0,
  width:'30px',
  height:'30px',
  backgroundColor:'#2271b1'}}>

    <FontAwesomeIcon className='m-1'color='white'style={{position:'absolute',
  top: 0,
  right: 0,}}icon='check' size='lg'/>
   
   </div>

   } 
    </div>)
}
export default ImageSelector;