import React from 'react';
import {  Card, Table  } from 'reactstrap';

const Details  = ({donation}) => {
  return (
      
      <Card className='shadow-none p-2 mb-3'>
        <h5>Donation Details</h5>
        <div>

        <Table className=' table-sm table-borderless w-auto '>
          <tbody>
            <tr>
              <td className='pr-4'>Transaction Amount:</td>
              <td >${donation.transactionAmount}</td>
            </tr>
            <tr>
              <td className='pr-4'>Roundup Amount:  </td>
              <td >${donation.roundupAmount}</td>
            </tr>
            <tr>
              <td className='pr-4'>Multiplier:  </td>
              <td >{donation.multiplier}x</td>
            </tr>
            <tr>
              <td className='pr-4'>Daily Max Adjusted?  </td>
              <td >{donation.adjustmentReason?"Yes":"No"}</td>
            </tr>
            <tr>
              <td className='pr-4'>Donation Amount  </td>
              <td >${donation.amount}</td>
            </tr>
          </tbody>
        </Table>
        </div>
      </Card>
  );
};

export default Details;
