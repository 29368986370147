import React, { Fragment, useState } from 'react';
import {  CardBody, CardText, CardSubtitle, CardTitle,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useSelector} from 'react-redux'
import Flex from '../../common/Flex';

const NotApprovedModal = ({modal, setModal, theme}) => {
  const {appName} = useSelector((state) => state.theme)
  
  const toggle = () => setModal(!modal);
  return (
      <Modal isOpen={modal} toggle={toggle} className={''}>
        <ModalHeader toggle={toggle}>The {theme?.charityName} campaign is not active</ModalHeader>
        <ModalBody>
          <p>{theme?.charityName} has recently signed up with Pushka and their application is being reviewed.</p>
          <p>If you decide to create a donor account through this link, you roundups will donate to Pushka Fund. Once {theme?.charityName} is approved, you can add them as a supported cause in your dashboard</p>
           {/* <p>At the end of each week, {appName} transfers your weekly donations from your bank account as an ACH (Automated Clearing House) transfer.</p>
          <p>{appName} uses ACH transfers so your donations are not wasted on high credit card fees. </p> */}
        <Flex>
        <Button color="primary" className='ml-auto' onClick={toggle}>OK</Button>
          </Flex>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>OK</Button>
        </ModalFooter> */}
      </Modal>
    
  );
};

export default NotApprovedModal;
