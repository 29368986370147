import React, { Fragment, useState, useEffect } from 'react';
import { Button, CardBody, CardFooter } from 'reactstrap';
import ButtonIcon from '../../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import AccountSelectList from '../../bankAccount/AccountSelectList';
import Flex from '../../common/Flex';
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import AlertModal from '../../common/AlertModal';
const WIZARD_STEP_2 = gql`
mutation WizardStep2{
  wizardStep2
}
`;
const ActivateCreditCards = ({SCF_type,accounts,refetch}) => {
  const {appName} = useSelector((state) => state.theme)
  let history = useHistory();
  const [modal, setModal] = useState(false);

  const [mutate,{data, loading, error}] = useMutation(WIZARD_STEP_2)
  const [creditCards, setCreditCards] = useState([]);
  const [debitCards, setDebitCards] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isAccountUpdating, setIsAccountUpdating] = useState(false);
  
  const dispatch = useDispatch()
  const onClick = (e) => {
    e.preventDefault()
    //setIsSubmitLoading(true)
    mutate().catch(e => {})
  };


  useEffect(() => {
    if(data){
      setIsSubmitLoading(false)
      dispatch({
        type:"UPDATE_GET_STARTED",
        payload: {
          step: 2,
        }
      })
    }
    if(error){
      setModal(true)
    }
  }, [data, error])
      
  const openPlaid = () => {
    dispatch({
     type:"ADD_NEW_CARD",
     payload: {
       redirectURL:window.location.href,
       product:["transactions"]
     }
   })
   
   history.push('/plaid');
 }


  useEffect(() => {
    if(accounts){
      const creditAccounts = _.filter(accounts,{SCF_type:'credit'})
      const debitAccounts = _.filter(accounts,{subtype:'checking'})
      setCreditCards(creditAccounts)
      setDebitCards(debitAccounts)
    }
  }, [accounts])

  return (
<Fragment>
  <AlertModal modal={modal} setModal={setModal} title={error?error.message:''} buttonText='Ok' onClick={() => {setModal(false)}}>
  </AlertModal>
            {/* {error && <ErrorMessage error={error}/>} */}
            <p className='font-weight-semi-bold'>Click next, or <span><a style={{textDecoration: "underline"}} href="#"  onClick={openPlaid}>connect another card</a></span>.</p>
          
          {/* <h5>Select the cards you want use for {appName} round-up donations</h5>
              <p>Use the toggle buttons below to select which credit cards to use. </p> */}
            {creditCards[0] && 
            <div className=''>
              <p className='mb-0'>Credit Cards ({creditCards.length}):</p>
            <AccountSelectList
            readOnly={false}
            refetch={refetch}
            SCF_type={SCF_type}
            setIsLoading={setIsAccountUpdating}
            accounts={creditCards}
        ></AccountSelectList>

            </div>
            }
            {debitCards[0] && 
            <div className=''>
              <p className='mb-0'>Debit Cards ({debitCards.length}):</p>
            <AccountSelectList
            readOnly={false}
            refetch={refetch}
            SCF_type={SCF_type}
            setIsLoading={setIsAccountUpdating}
            accounts={debitCards}
        ></AccountSelectList>
        </div>
            }
            <Flex>
              <Button className='ml-auto px-5' color='primary' onClick={onClick} disabled={loading}>
                {!loading?"Next":<Spinner  size={"sm"}/>}
              </Button>
            </Flex>

            {/* <Flex align="end">
              <Button className="ml-auto" color='link' onClick={openPlaid}
              >
              Register another credit card
              </Button>
              </Flex> */}
            {/* <CardFooter className={' bg-light d-flex'}>
            {error && <ErrorMessage error={error}/>}
            <ButtonIcon
                  onClick={onClick}
                  disabled={isSubmitLoading || isAccountUpdating}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  hideIcon={isSubmitLoading}
                >
                  {(!isSubmitLoading ) && "Confirm"}
                  {isSubmitLoading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
          </CardFooter> */}
          </Fragment>
  );
};

export default ActivateCreditCards;
