import {useSelector } from 'react-redux'

const Base64ToImage = ({ base64 }) => <img alt='logo' src={`${base64}`}  width={50}/>

const IconLogo = () => {
    const iconLogo = useSelector((state) => state.theme.iconLogo)
    return(
        <Base64ToImage base64 = {iconLogo}/>
    )

}

export default IconLogo


