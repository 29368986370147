const initialState = {
    branded: false,
    unread:null
};

const configReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "INTERCOM_SHUTDOWN":
            return {
                ...initialState
            }
        case "INTERCOM_UPDATE":
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default configReducer