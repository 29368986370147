import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from '../../common/Flex';
import { Badge } from 'reactstrap';

const BadgeSucceeded = () => {
  return (
      <Badge className='px-2' color='soft-green'>
        <Flex>
          <p className='m-0'>succeeded</p>
          <FontAwesomeIcon className='ml-1' icon={'check'}></FontAwesomeIcon>
        </Flex>
      </Badge>
  );
};

export default BadgeSucceeded;
