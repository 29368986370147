import React, { Fragment } from 'react';
import { Card, CardBody, CardText,  CardHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCalendarCheck,} from "@fortawesome/free-solid-svg-icons";
import Flex from '../common/Flex';

const ManualVerificationCard = ({setView}) => {
  return (
    <Fragment>
      <Card onClick={()=>setView("MANUAL")} className='card-select mb-3 shadow-none border '>
        <CardBody>
            <div className='p-0 pb-2'>
                <h5>
                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" />
                    Manual Verification
                </h5>
            </div>
            <CardText>Verify your bank account with your routing and account number. Verification can take up to 3 business days.</CardText>
            {/* <Flex justify="end">
              <Button color="primary" onClick={()=>setView("MANUAL")}>Select</Button>
            </Flex> */}
        </CardBody>
        </Card>
        </Fragment>
  );
};

export default ManualVerificationCard;
