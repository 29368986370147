import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";

const Loader = props => (
  <Row className="flex-center">

<PulseLoader
 color={'black'} loading={true} size={15} />
  </Row>
);

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary'
};

export default Loader;
