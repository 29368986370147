import React, { useState, useEffect } from 'react';
import AlertButton from '../../common/AlertButton';
import { gql, useMutation } from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux'
import DonorVerifyEmailForm from '../../auth/forms/DonorVerifyEmailForm';
import { Badge, Card, CardBody } from 'reactstrap';
import Flex from '../../common/Flex';
import { Link } from 'react-router-dom';

const ActivateCreditCard  = ({className, handleSuccess}) => {

  const {email} = useSelector((state) => state.user)
  

  return (
  <Card className={'shadow-none border border-red ' + className}>
      <CardBody className="fs--1 font-weight-normal ">
      <Flex className='mb-3' align='center'>

<h5 className='mb-0 mr-2'>Link a credit or debit card</h5>
<Badge color='soft-red'>Required</Badge>
</Flex>
<p>Please link a credit card or debit card.</p>
<Flex>

<Link to='/cards' className='btn btn-primary ml-auto'>Link a card</Link>
</Flex>
      </CardBody>
  </Card>

  );
};

export default ActivateCreditCard;
