
import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row, Spinner } from 'reactstrap';
import ComingSoon from '../common/ComingSoon';
import Section from '../common/Section';

import { gql, useQuery } from '@apollo/client';
import Flex from '../common/Flex';
import MyDonorsTable from '../tables/MyDonorsTable';
// import ReactGA from 'react-ga';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useHistory } from "react-router-dom";
const CharityDonors  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  let history = useHistory();
  const MY_DONORS = gql`
  query myDonors{
    myDonors(active:false){
      donor{
        firstName
        lastName
        email
        active
      }
      recentWeeklyDonations
      avgWeeklyDonations
    }
  }
  `;
  const goToDonorGroups = () => {
    history.push('/nonprofit/donors/groups');

  }
  
  // const {data,loading,error, refetch} = useQuery(MY_DONORS)
  return (
    <DashboardLayout>
    <Row>
      <Col className='p-1'>

      <Card className='p-md-4 p-1 shadow-none mb-4'>
          <h5 className='mb-2'>Active Donors</h5>
        <MyDonorsTable className='' active={true} />
      </Card>
      <Card className='p-md-4 p-1 shadow-none mb-4'>
          <h5 className='mb-2'>Inactive Donors</h5>
        <MyDonorsTable className='' active={false}/>
      </Card>
      </Col>
    </Row>

    <Button onClick={goToDonorGroups}>Edit Charity Donor Groups</Button>
    </DashboardLayout>
  );
};

export default CharityDonors;
