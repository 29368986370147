import React, { Fragment, useEffect } from 'react';
import { CardTitle, Card, CardBody, Row, Col, Spinner, CardText, CardSubtitle, CardHeader, Badge } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classNames from 'classnames'
import Flex from '../../common/Flex';
const TransferStatusBadge  = ({status, className}) => {

  const showText = false
  return (
    <Fragment>
    {status === 'pending' && 
    
    <Badge className={'p-1 ' +className } color='soft-blue'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'exchange-alt'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {status === 'succeeded' && 
    
    <Badge className={'p-1 ' +className } color='soft-green'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'check'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {status === 'failed' && 
    
    <Badge className={'p-1' +className } color='soft-red'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'times'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
{/*     
    {(!pending && !transferring && !processed )&& 
    
    <Badge className={'px-2 ' +className } color='soft-yellow'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'clock'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {transferring && 
    <Badge className={'px-2 ' +className } color='soft-blue'>
      <Flex>
        {showText && <p className='m-0'>transfer</p>}
          <FontAwesomeIcon className='' icon={'exchange-alt'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {processed && 
    <Badge className={'px-2 ' +className } color='soft-green'>
      <Flex>
        {showText && <p className='m-0'>donated</p>}
          <FontAwesomeIcon className='' icon={'check'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    } */}

    </Fragment>
  );
};

export default TransferStatusBadge;
