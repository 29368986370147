import React, { Fragment, useState, useEffect } from 'react';
import {  ListGroup,Row,Col, Spinner } from 'reactstrap';
import ThemeSelection from '../themeDashboard/ThemeSection';
import Logos from '../themeDashboard/pushka/Logos';
import General from '../themeDashboard/pushka/General';
import ResetTheme from '../themeDashboard/ResetTheme';
import HeroSection from '../themeDashboard/pushka/HeroSecion';
import { gql,  useQuery  } from '@apollo/client';
import AboutText from '../themeDashboard/pushka/AboutText';
import HeroImage from '../themeDashboard/pushka/HeroImage';
import AboutImage from '../themeDashboard/pushka/AboutImage';
import PublishTheme from '../themeDashboard/PublishTheme';
import Section from '../common/Section';
import DashboardLayout from '../../layouts/DashboardLayout';
// import ReactGA from 'react-ga';

const PushkaCustomization = ({ match: { url } }) => {
  // ReactGA.pageview(url);
const [theme, setTheme] = useState({})
  
  const MY_THEME = gql`
  query myPushkaCharityTheme{
    myPushkaCharityTheme{
      heroSubtitle
      heroText
      heroTitle
      aboutTitle
      aboutSubtitle
      aboutText
      quote
      bioTitle
      bioImage1
      iconLogo
      charityName
      heroImage
      aboutImage1
      aboutImage2
      aboutImage3
      published
      brandIdentityRequired
      mainImageRequired
      aboutTextRequired
      aboutImageRequired
    }
  }
  `;
  const { data,loading, refetch } = useQuery(MY_THEME)
  useEffect(() => {
    if(data){
      setTheme(data.myPushkaCharityTheme)
    }
  }, [data])
  return (
    <DashboardLayout>
      
      {loading && <Spinner/>}
      
    <Row>
      <Col className='p-1'>
    <h4>Customize your Pushka campaign</h4>
    <p>You can customize your campaign website to tell your non-profit’s story. Click a tab below to start customizing.</p>
      <ListGroup>  
          {/* <PublishTheme key='publish' theme={theme}  refetch={refetch}/>   */}
        <ThemeSelection key="general" title="Brand identity"  required={theme?.brandIdentityRequired}><General  theme={theme} refetch={() => refetch()} /></ThemeSelection>
        {/* <ThemeSelection key="logos" title="Logos"><Logos  theme={theme} refetch={() => refetch()} /></ThemeSelection> */}
        {/* <ThemeSelection  key="inviteHero"title="Banner text"><HeroSection  theme={theme} refetch={refetch}/></ThemeSelection> */}
        <ThemeSelection key="inviteImage" title="Main image"  required={theme?.mainImageRequired}><HeroImage  theme={theme} refetch={refetch}/></ThemeSelection>
        <ThemeSelection key="inviteAbout" title="About text"  required={theme?.aboutTextRequired}><AboutText theme={theme} refetch={refetch}/></ThemeSelection>
        <ThemeSelection key="landingAbout" title="About Image"  required={theme?.aboutImageRequired}><AboutImage  theme={theme} refetch={refetch}/></ThemeSelection>
      </ListGroup>
      
      <ResetTheme  key="reset"  refetch={refetch}/>
      </Col>
      </Row>
  </DashboardLayout>
  );
};

export default PushkaCustomization;
