import React from 'react';
import { Card, CardTitle,CardBody } from 'reactstrap';
import EditAndApproveCharityForm from './EditAndApproveCharityForm';

const EditAndApproveCharity  = ({charityUser, sub}) => {


  return (
          <Card className='shadow-none '>
            <CardTitle>Edit and Approve {sub?"Sub Nonprofit":"Nonprofit"}</CardTitle>
            <CardBody>
                <p><strong>Email: </strong> {charityUser.email}</p>
                <p><strong>Name: </strong> {charityUser.firstName + " " + charityUser.lastName}</p>
                <EditAndApproveCharityForm sub={sub} charityUser={charityUser}/>
            </CardBody>
          </Card>
  );
};

export default EditAndApproveCharity;
