import React, {Fragment, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import {useSelector} from 'react-redux'
import chroma from "chroma-js";
import {useDispatch} from 'react-redux'

import ApplyBranding from './ApplyBranding'
import TenantLoader from '../components/common/TenantLoader';

const THEME = gql`
query{
  getTheme{
    primary
    secondary
    iconLogo
    appName
    charityName
    aboutImage1
    aboutImage2
    aboutImage3
    bioImage1
    about
    quote
    bioTitle
    heroTitle
    heroImage
    heroSubtitle
    heroText
    mission
    url
    urlMask
    facebook
    instagram
  }
}
`

const Theme = (props) => {
  const {pushka} = useSelector((state) => state.config)
  const { data, loading, error  }  = useQuery(THEME)
  const [colors, setcolors] = useState({})
  const pushkaColors = {
    primary: "#0091AD",
    secondary: "#B7094C",
    primary_gradient: "#455E89",
    primary_light: "#72adc0",
    white: '#FFFFFF',
    gray_100:"#E6E8F0",
    gray_200:"#C0C2D8",
    gray_300:"#8C92BA",
    gray_400:"#8C92BA",
    gray_500:"#8C92BA",
    gray_600:"#545D8C",
    gray_700:"#545D8C",
    gray_800:"#545D8C",
    gray_900:"#3E4365",
    gray_1000:"#3E4365",
    gray_1100:"#2E324D",
    black:"#222539",
  }
  const scale  = (color)=> {
    return chroma
    .scale(['white', color, '#212529'])
    .mode("lrgb")
    .colors(11);
  } 

  const dispatch = useDispatch()
  const addTheme = (data) => {
    console.log(data)
    dispatch({
      type:"STORE_THEME",
      payload: {
        ...data,
        colors:{
          primary:data.primary,
          darker1:scale(data.primary)[7],
          darker2:scale(data.primary)[8],
          lighter1:scale(data.primary)[4],
          lighter2:scale(data.primary)[2],

        }
      }
    })
  }

  useEffect(() => {
    if (data){
      // console.log(data)
      // console.log(scale(data.getTheme.primary))
      addTheme(data.getTheme)
      setcolors({
        primary: data.getTheme.primary,
        secondary: data.getTheme.secondary?data.getTheme.secondary:data.getTheme.primary ,
        primary_gradient:  scale(data.getTheme.primary)[7],
        primary_light: scale(data.getTheme.primary)[4],
        primary_darker2: scale(data.getTheme.primary)[8],
        // //black: data.getTheme.colors.primary,
      })
      if(pushka){
        setcolors({
        ...pushkaColors
      })
    }
      //setcolors({...pushkaColors})
    }
    if (error){
      console.log(error)
    }
    }, [loading, data, error]
  )

    return (
      <Fragment>
        {error && <p>Error: this domain is not a valid tenant</p>}        
        {loading && <TenantLoader loadingMessage='loading...'/>}
        {data &&
        <Fragment>
          <ApplyBranding colors={colors}/>
            {props.children}
        </Fragment>
        }
    </Fragment>
      
    );
  };
  
  export default Theme;




