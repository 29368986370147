import React, { Fragment, useEffect, useState } from 'react';
import { Card,CardTitle,  Button, Table, Spinner } from 'reactstrap';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import CharityMixSlider from './CharityMixSlider';
import {gql,useQuery, useMutation } from '@apollo/client';
import CharitySearchModalAlt from './CharitySearchModalAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'
import ButtonIcon from '../../common/ButtonIcon';
import ErrorMessage from '../../common/ErrorMessage';
import CharityOptions from './CharityOptions';
import { formatCharities,getAcronym, colors } from './functions/formatCharities';

// const getAcronym = (str) => {
//   return str.match(/\b(\w)/g).join('')
// }

// const colors = [
//   "#892B64","#723C70","#5C4D7D","#455E89","#2E6F95"
// ]

const UPDATE = gql`
mutation updateMyCharities($charityMixArray:[CharityMixPercentInput!]!){ 
  updateMyCharities(charityMixArray:$charityMixArray){ 
    email
  }
}
`;

const CharitySettings  = ({className}) => {
  const GET_PROFILE = gql`
  query{ 
    me{ 
      charities{
        charity{
          _id
          name
          EIN
          donorGroupsEnabled
        }
        percent
        donorGroup{
          _id
          name
        }
      }
    }
  }
  `;
    const {data,error} = useQuery (GET_PROFILE)
  const [modal, setModal] = useState(false);
  const [removeError, setRemoveError] = useState({});
  const [charities, setCharities] = useState([])
  const [charityMixArray, setCharityMixArray] = useState([])
  const [update, updateResponse] = useMutation(UPDATE,  {
    variables:{
      charityMixArray:charityMixArray
    }})

  const insertNewCharity = (charity) => {
    setRemoveError({})
    if(Object.keys(charities).length === 5){
      alert("You can only add 5 nonprofits")
    } else{
      const newCharity = {
          name:charity.name,
          text: getAcronym(charity.name),
          color:colors[Object.keys(charities).length],
          EIN: charity.EIN,
          percentage: 0
        }
      charities.push(newCharity)
    }
  }

    useEffect(() => {
      if(data){
        const formatted = data.me.charities.map((each,i) => {
          return{
            ...each.charity,
            name:each.charity.name,
            text: getAcronym(each.charity.name),
            color:colors[i],
            EIN: each.charity.EIN,
            percentage: each.percent * 100,
            donorGroup:each.donorGroup?._id
          }
        })
        setCharities(formatted)
      }
    }, [data])

    // const handleRemove = (charity) => {
    //   if(Object.keys(charities).length === 1){
    //     setRemoveError({message:"You must have atleast 1 nonprofit. Add a new charity before removing."})
    //   } 
    //   else{

    //     const filter = _.filter(charities, x => {
    //       return x != charity
    //     })
    //     let remaining = 100
    //     const newCharities = filter.map((each,i) => {
    //       if(i + 1 === Object.keys(filter).length){
    //         return {
    //           ...each,
    //           percentage:remaining,
    //           color:colors[i],
    //         }
    //       }else{
    //         remaining -= (100 / Object.keys(filter).length).toFixed(0)
    //         return {
    //           ...each,
    //           percentage:100 / Object.keys(filter).length,
    //           color:colors[i],
    //         }
    //       }
    //     })
    //      setCharities(newCharities)
    //   }
    // }
    useEffect(() => {
      if(charities){
        const array = charities.map(charity => {
          return {
            EIN:charity.EIN,
            percent:Number((charity.percentage*0.01).toFixed(2)),
            donorGroup:charity.donorGroup,
          }
        })
        setCharityMixArray(array)
      }
    }, [charities])

  return (
      
    <div className={classNames(className )}>
      
      <CharitySearchModalAlt modal={modal} setModal={setModal} insertNewCharity={insertNewCharity}/>
    <Card className='profile p-md-4 p-2 shadow-none'>
        <CardTitle>Your Causes</CardTitle>
        <p>Split your tzedakah between the causes you care about. You decide how much of your donations go to each cause.</p>
        
    <Table  size="sm">
      <thead>
        <tr>
          <th>Cause</th>
          <th>% Mix</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {charities.map((charity,i) => {
          return(
            <tr key={i}>
            <td>{
              <Flex align='center'>
                <div className="px-3 py-1 mr-3 rounded" style={{ backgroundColor: charity.color }}><p className='mb-0 r-2 text-white font-weight-semi-bold'>{charity.text}</p></div>
                <p className='m-0'>{charity.name}</p>
              </Flex>
              }</td>
            <td>{charity?.percentage?.toFixed(0) + "%"}</td>
            <td ><CharityOptions charity={charity} setCharities={setCharities}/></td>
            {/* <td><Button color='muted'size='sm' className='py-0 px-2' onClick={()=>{handleRemove(charity)}}><FontAwesomeIcon color='white' icon='trash' size='md'/></Button></td> */}
          </tr>
          )
      })}
      </tbody>
    </Table>
    {charities[1] && 
    <Fragment>
      <p className='mb-2 mt-2'>Move the sliders to adjust the % contribution</p>
    <CharityMixSlider divisions={charities} setDivisions={setCharities} />

    </Fragment>}
    <Flex className='mt-3'>

    <Button className='px-5 ml-auto'onClick={()=>setModal(true)}>Add Another Cause</Button>
    </Flex>


    <Flex className='mt-3'>
      <ButtonIcon
              onClick={() => {update().catch( e => {})}}
              disabled={updateResponse.loading}
              className=" px-5 ml-auto "
             
            >
              {(!updateResponse.loading ) && "Save Changes"}
              {updateResponse.loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
    </Flex>
          {error &&<ErrorMessage error={error}/>}
          {updateResponse.error &&<ErrorMessage error={updateResponse.error}/>}
          {removeError &&<ErrorMessage error={removeError}/>}
    </Card>
    </div>
  )
};

export default CharitySettings;
