import React, { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import ErrorMessage from '../common/ErrorMessage';
import ButtonIcon from '../common/ButtonIcon';
import {useSelector} from 'react-redux'
import {  Spinner ,Button } from 'reactstrap';
import Flex from '../common/Flex';


const ResetTheme = ({  refetch }) => {
  const {pushka} = useSelector((state) => state.config)
  const [confirm, setConfirm] = useState(false)
  
  const RESET = gql`
    mutation resetTheme{
      resetTheme
    }
`;
const RESET_PUSHKA = gql`
  mutation resetPushkaCharityTheme{
    resetPushkaCharityTheme
  }
`;
  const [reset, {error,loading}] = useMutation(pushka?RESET_PUSHKA:RESET)
  const handleSubmit = e => {
    e.preventDefault();
    reset().then( () => {
      refetch()
      setConfirm(false)
    }).catch(e => {})

  };
  return (
    <div className='p-3'>  
        <h5>Reset theme</h5>       
        <p>Click the button below to reset your theme to it's default information</p> 
        {!confirm && <Button color='primary' onClick={() => {setConfirm(true)}}>Reset</Button>}
        {confirm && <Flex wrap align='center'>
          <ButtonIcon
              disabled={loading}
              color='red'
              className="mr-3"
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
              onClick={handleSubmit}
            >
              {(!loading ) && "Confirm Reset"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
            <p className='mb-0 text-red font-weight-semi-bold'>Cuation: this cannot be undone</p>
          </Flex>}
          {error &&<ErrorMessage error={error}/>}

    </div>


  );
};


export default ResetTheme;
