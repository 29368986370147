import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image, Transformation } from 'cloudinary-react';
import Flex from './Flex';

const Logo = ({ publicId, white, text, width, icon, className, }) => {
  return (
        <Fragment>
        {icon
        ? 
        <Image
          publicId={publicId}
          cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
         >
          <Transformation width={width} height={width} background="auto" crop="pad" />
          <Transformation radius="8"  />
         </Image>
        :

        <Flex align='center' className={className}>
        <div className={'rounded'} >
        <Image
          publicId={publicId + '.png'}
          cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
          style={{width:'50px',height:'50px',marginRight:'10px'}}
         >
          <Transformation width="400" height="400" background="auto" crop="pad" />
          <Transformation radius="72"  />
         </Image>
        </div>
        <div  className={classNames('logo-text', white?"text-white":"text-black")}>{text}</div>
    </Flex>
        }</Fragment>
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.bool
};

Logo.defaultProps = {  width: 50 };

export default Logo;
