
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const AlertModal = ({modal, setModal,title,children, buttonText,onClick}) => {
//   const {
//     buttonLabel,
//     className
//   } = props;

//   const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} toggle={toggle} className='modal-dialog modal-dialog-centered ' >
        <ModalHeader toggle={toggle}><h4 className='text-black'><FontAwesomeIcon icon={faExclamationTriangle} size={'1x'} color='black'className='mr-2'/>{title}</h4></ModalHeader>
        <ModalBody className=''>
            {children}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onClick}>{buttonText}</Button>{' '}
          {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AlertModal;