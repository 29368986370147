import React, { useState, useEffect, } from 'react';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames'
import _ from 'lodash'
import BankAccount from '../../stripe/BankAccount';
const DefaultBankAccount  = ({className}) => {
  const DEFUALT_ACCOUNT = gql`
  query MyDefaultStripeBank{
    myDefaultStripeBank{
        _id
        account_holder_name
        bank_name
        last4
        routing_number
        currency
        created
        default
        status
    }
  }
  `;
  const [account, setAccount] = useState([]);
  const {data, refetch} = useQuery(DEFUALT_ACCOUNT)
  
  useEffect(() => {
    if(data){
        setAccount(data.myDefaultStripeBank)
    }
  }, [data])
  return (
    <div className={classNames(' ',className )}>
        <BankAccount account={account} isDefault={true} onVerificationSuccess={refetch}/>

      </div>
  );
};

export default DefaultBankAccount;
