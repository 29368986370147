import React, { Fragment, useState } from 'react';
import {  CardBody, CardText, CardSubtitle, CardTitle,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useSelector} from 'react-redux'
import Flex from '../../../common/Flex'
import BankExplainModal from '../BankExplainModal';

const Step3 = () => {
  const [modal, setModal] = useState(false);
  
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
    <BankExplainModal modal={modal} setModal={setModal}/>

        <CardBody className='theme-wizard-header rounded  px-md-8  px-sm-6 px-2  py-sm-7 py-5'>
          <CardTitle>Connect your bank account</CardTitle>
          {/* <CardSubtitle>At the end of each week, your donations are grouped together and transferred from your bank account to {appName}.</CardSubtitle> */}
          <CardText className='mt-4'>Why do I need to register both my credit card and bank account? 
          </CardText>
          <Flex justify='center'>

          <Button color="white" onClick={toggle} outline size='sm'>Learn More</Button>
          </Flex>
        </CardBody>
        </Fragment>
  );
};

export default Step3;
