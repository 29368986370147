import React, { Fragment} from 'react';
import {Table  } from 'reactstrap';
import Flex from '../../common/Flex';


const CharitySelect = ({ charities, onSelect, EINCompare}) => {
  return (
    <Fragment>

<Table className='table-hover'>
      <thead>
        <tr>
          <th>Charity</th>
          <th>EIN</th>
        </tr>
      </thead>
      <tbody>
        {charities.map(charity => {
          return(
            <tr onClick={()=>{onSelect(charity)}}key={charity.EIN}>
            <td>{
              <Flex align='center'>
                <p className='m-0'>{charity.name}</p>
              </Flex>
              }</td>
            <td>{charity.EIN}</td>
          </tr>
          )
      })}
      </tbody>
    </Table>
    </Fragment>
  );
};

export default CharitySelect;
