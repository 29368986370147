import React, { useState,  useEffect } from 'react';
import { gql,useQuery } from '@apollo/client';
import { Form, Input, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CharitySelect from './CharitySelect'


const CHARITY_SEARCH_QUERY = gql`
  query CharitySearchQuery($filter: String!) {
    charity(filter: $filter) {
      id
      count
      charities{
        _id
        name
        EIN
        approved
        created
        payouts_enabled
      }
    }
  }
`;
const CharitySearch = ({onSelect,EINSelect, EINCompare,tenantId}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [charities, setCharities] = useState([]);
  const { data, loading, error,refetch  } = useQuery(CHARITY_SEARCH_QUERY, {
    variables:{filter:searchFilter},
    context: { headers: { tenantId } }
  })

  useEffect(() => {
    if(loading ){
      setIsLoading(false)
    }
    if (data && data.charity){
      setIsLoading(false)
      setCharities(data.charity.charities)
    }
  }, [loading, data])

  useEffect(() => {
    refetch()
  }, [tenantId])
  return(
    <div className='mt-3'>
      <Form inline className="search-box">
        <Input onChange={(e) => setSearchFilter(e.target.value)} type="search" placeholder="Search..." aria-label="Search" className="rounded-pill search-input" />
        <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
      </Form>
      {isLoading && <Spinner/>}
      {charities && 
      <CharitySelect charities={charities} onSelect={onSelect} EINSelect={EINSelect} EINCompare={EINCompare}/>
      }
    </div>

  );
};

export default CharitySearch