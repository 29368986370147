import React, { Fragment,useCallback, useState, useEffect,useImperativeHandle ,forwardRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import ButtonIcon from '../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import {useSelector} from 'react-redux'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import Loader from '../common/Loader'
import OpenPlaidLink from './OpenPlaidLink';
import PlaidLoader from '../common/PlaidLoader';
import {useDispatch} from 'react-redux'
// import ReactGA from 'react-ga';
import PlaidErrorModal from './PlaidErrorModal';
import PlaidPageLoader from './PlaidPageLoader';
import { toast } from 'react-toastify';
const CREATE_LINK_TOKEN = gql`
query createUpdateLinkToken($_id: String!){
  createUpdateLinkToken(_id:$_id)
  }
`;

const RELOGIN_SUCCESS = gql`
mutation plaidItemReloginSucccess($_id: String!){
  plaidItemReloginSucccess(_id:$_id)
}
`;

const PlaidUpdatePage  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const dispatch = useDispatch()
  const {redirectURL,_id, initialized,updateLogin} = useSelector((state) => state.plaid)
  const [linkToken, setLinkToken] = useState('')
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [publicToken, setPublicToken] = useState(null);
  let history = useHistory();

  const [getLinkToken,createLinkToken] = useLazyQuery(CREATE_LINK_TOKEN, {
    variables:{_id:_id}
  })

  const [plaidItemReloginSucccess, plaidItemReloginSucccessResponse] = useMutation(RELOGIN_SUCCESS, {
    variables:{_id:_id}
  })


 
  //cases:
  // 1) after store is updated it will set local storage and get the link token
  // 2) because it will always be a redirect, this componet should look at the local storage, set the plaid redux state, then it will update redux and go to case 1. 
  //   - this will be if redirectUrl is null


  useEffect(() => {
    //case 1
    if(initialized && redirectURL && _id){
      localStorage.setItem("plaidRedirect",redirectURL)
      localStorage.setItem("plaidUpdateItemId",_id)
      localStorage.setItem("plaidUpdateLogin",updateLogin)
      getLinkToken()
    }
    //case 2
    else if(!redirectURL){
      dispatch({
        type:"UPDATE_PLAID_STATE",
        payload: {
          redirectURL:localStorage.getItem("plaidRedirect"),
          _id:localStorage.getItem("plaidUpdateItemId"),
          updateLogin:localStorage.getItem("plaidUpdateLogin"),
        }
      })
    }
 }, [redirectURL,_id,initialized,updateLogin])





  const redirect = () => { 
    const url = new URL(redirectURL)
    const redirectPath = url.pathname + url.search
    localStorage.removeItem('plaidRedirect')
    localStorage.removeItem('plaidUpdateItemId')
    history.push(redirectPath)
  }

  const onSuccess = useCallback(async (token, metadata) => {
    setPublicToken(token)
    // instead of createing plaid item... we should make a resolver to repair the plaidItem
    await plaidItemReloginSucccess()
    .then( (res) => {
      toast.success('Round-Ups reauthorized and updated!')
      redirect()
    }).catch(error => {
      toast.info('Authorized! Please update Round-Ups')
      redirect()
    })
  }, []);
  const onExit = useCallback(async (error, metadata) => {
    if(error?.error_code==="item-no-error"){
      await plaidItemReloginSucccess()
      .then( (res) => {
        toast.success('Round-Ups reauthorized and updated!')
        redirect()
      }).catch(error => {
        toast.info('Authorized! Please update Round-Ups')
        redirect()
      })
      }
    if(metadata.status!=='connected'){

      redirect()
    }
  }, []);



  useEffect(() => {
    if(createLinkToken.data){
      setLinkToken(createLinkToken.data.createUpdateLinkToken)
      localStorage.setItem("plaidLinkToken",createLinkToken.data.createUpdateLinkToken)
    }
 }, [createLinkToken.data])





  return (
    <Fragment>
    <PlaidPageLoader message={`Loading ${linkToken?"":"(50%)"}...`} spinner={plaidItemReloginSucccessResponse.loading ||  !linkToken }/>
    <PlaidErrorModal error={error}  modal={modal} setModal={setModal}/>
      {!plaidItemReloginSucccessResponse.loading && !modal && linkToken && <OpenPlaidLink token={linkToken} onExit={onExit} onSuccess={onSuccess}/>}
      {/* {plaidItemReloginSucccessResponse.loading ||  !linkToken && <PlaidLoader loading={true}/>} */}
    </Fragment>

  );
};


export default PlaidUpdatePage;
