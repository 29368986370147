import React, { Fragment, useEffect, useState } from 'react';
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';
import PushkaFooterLanding from '../footer/PushkaFooterLanding'
import DemoMessage from '../demo/DemoMessage';
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import { Redirect, useLocation, Switch } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Hero from '../pushka/invite/Hero';
import HowItWorks from '../pushka/pushka-landing/HowItWorks';
import Features from '../pushka/pushka-landing/Features';
import CharityCTA from '../pushka/pushka-landing/CharityCTA';
import AboutCharity from '../pushka/pushka-landing/AboutCharity';
import NotApprovedModal from '../pushka/pushka-landing/NotApprovedModal';
import { gql, useQuery} from '@apollo/client';
import PageLoader from '../common/PageLoader';
import { useHistory } from "react-router-dom";
// import ReactGA from 'react-ga';
import FAQ from '../pushka/pushka-landing/FAQ';

const PushkaInvite = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const [modal, setModal] = useState(false)
  const history = useHistory();
  const {pushka, demo} = useSelector((state) => state.config)
  const {approved} = useSelector((state) => state.inviteReducer)
  const {charityName,iconLogo} = useSelector((state) => state.theme)
  const {loggedIn} = useSelector((state) => state.auth)
  const location = useLocation()
  const search = location.search; 
  const params = new URLSearchParams(search);
  const dispatch = useDispatch()
  const EIN = params.get('EIN'); // bar
  const _id = params.get('_id'); // bar
  const donorGroupId = params.get('donorGroup'); // bar
  const [theme, setTheme] = useState({})
  const [donorGroup, setDonorGroup] = useState({})
  const removeDonorGroup = () => {
    if(_id){
      history.replace({
        search: '?_id=' + _id,
      })
    }else if(EIN){
      history.replace({
        search: '?EIN=' + EIN,
      })
    }
  }
  useEffect(() => {
   if(approved===false){
     setModal(true)
   }
  }, [approved])


  const THEME = gql`
  query getPushkaCharityDetails($EIN:String,$_id:String){
    getPushkaCharityDetails(EIN:$EIN,_id:$_id){
      theme{
        iconLogo
        heroText
        heroImage
        heroTitle
        heroSubtitle
        aboutTitle
        aboutText
        aboutSubtitle
        aboutImage1
        aboutImage2
        aboutImage3
        bioImage1
        quote
        bioTitle
        charityName
      }
      charity{
        _id
        EIN
        name
        approved
      }
    }
  }
  `;
  const THEME_AND_CAMPAIGN = gql`
  query getPushkaCharityDetails($EIN:String,$donorGroup:String,$_id:String){
    getPushkaCharityDetails(EIN:$EIN, _id:$_id){
      theme{
        iconLogo
        heroText
        heroImage
        heroTitle
        heroSubtitle
        aboutTitle
        aboutText
        aboutSubtitle
        aboutImage1
        aboutImage2
        aboutImage3
        bioImage1
        quote
        bioTitle
        charityName
      }
      charity{
        _id
        EIN
        name
        approved
      }
    }
    donorGroupDetails(_id:$donorGroup){
      _id
      name
    }
  }
  `;

  const {data,loading,error} = useQuery(donorGroupId?THEME_AND_CAMPAIGN:THEME,{variables:{_id:_id,EIN:_id?null:EIN,donorGroup:donorGroupId?donorGroupId:null}})
  useEffect(() => {
    if(data){
      setTheme(data.getPushkaCharityDetails.theme)
      setDonorGroup(data.donorGroupDetails)
      dispatch({
        type:"UPDATE_INVITE_DATA",
        payload:{
          invited:true,
          EIN:data.getPushkaCharityDetails.charity.EIN,
          charityName:data.getPushkaCharityDetails.charity.name,
          donorGroup:data?.donorGroupDetails?._id,
          donorGroupName:data?.donorGroupDetails?.name,
          approved:data?.getPushkaCharityDetails?.charity?.approved,
          _id:data?.getPushkaCharityDetails?.charity?._id,

        }
      })
    }
    if(error){
      if(error.message === 'invalid donor group'){
        removeDonorGroup()
      }
      //history.push("/errors/404");
    }
  }, [data,error])

  useEffect(() => {
    if(!EIN && !_id){
      history.push("/errors/404");
    }
  }, [EIN, _id])

  return (
    <Fragment>
      <NotApprovedModal modal={modal} setModal={setModal} theme={theme}/>

      
      <Fragment>    
        
        <Helmet>
          <title>{charityName + " | Roundup donations"}</title>
          <link rel="icon" href={iconLogo}/>
        </Helmet>  
        {loading && <PageLoader/>}
        {!loading && theme && 
        <Fragment>

<TransparencyLandingNav  />
        <Hero theme={theme} donorGroup={donorGroup}/>
        <HowItWorks/>
        <AboutCharity theme={theme}/>
        <Features/>
        <FAQ/>
        <CharityCTA/>
        <PushkaFooterLanding/>

        </Fragment>
        }
      </Fragment>
      
      {demo && <DemoMessage message={"This is a demo"}/>}
      {approved===false && <DemoMessage message={"This campaign is not active"}/>}
    </Fragment>
  );
};
  

export default PushkaInvite;
