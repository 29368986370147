
import { gql } from '@apollo/client';
export const me = gql`
    query Me{ 
        me{ 
            _id
            firstName
            lastName
            email 
            active
            inactiveReason
            requirements
            setupStep
            dailyMax
            dailyMaxAmount
            multiplier
            transferDay
            wholeDollarRoundup
            isVerified
            setupPassword
        }
    }
`;