import React, { Fragment,useCallback, useState, useEffect,useImperativeHandle ,forwardRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import ButtonIcon from '../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import {useSelector} from 'react-redux'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import Loader from '../common/Loader'
import OpenPlaidLink from './OpenPlaidLink';
import PlaidLoader from '../common/PlaidLoader';
// import ReactGA from 'react-ga';
import PlaidErrorModal from './PlaidErrorModal';
import PlaidPageLoader from './PlaidPageLoader';
const CREATE_LINK_TOKEN = gql`
query CreateLinkToken($products: [String!]){
  createLinkToken(products:$products)
  }
`;

const CREATE_PLAID_ITEM = gql`
mutation CreatePlaidItem($publicToken: String!){
  createPlaidItem(publicToken:$publicToken){
    item_id
    name
    color
    logo
  }
}
`;

const PlaidPage  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  //on pageload, initilized is false. When a dispatch updates the state, then it is true
  const {redirectURL,product, initialized,autoActivate} = useSelector((state) => state.plaid)
  const [linkToken, setLinkToken] = useState('')
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [publicToken, setPublicToken] = useState(null);
  let history = useHistory();
  const dispatch = useDispatch()
  const [getLinkToken,createLinkToken] = useLazyQuery(CREATE_LINK_TOKEN, {    variables:{products:product}  })
  const [createPlaidItem, plaidItemResponse] = useMutation(CREATE_PLAID_ITEM, {    variables:{publicToken}  })

  //cases:
  // 1) user clicks a button, which dispateches the plaid state and refirects to this page and set local storage
  // 2) user refreshed the plaid page and this componet should look at the local storage, set the plaid redux state, then it will update redux and go to case 1. 
  //   - this will be if redirectUrl is null

  useEffect(() => {
    //case 1
    if(initialized && redirectURL && product){
     // alert('case 1')
      localStorage.setItem("plaidRedirect",redirectURL)
      localStorage.setItem("plaidProducts",JSON.stringify(product))
      localStorage.setItem("plaidAutoActivate",autoActivate)
      getLinkToken()
    }
    //case 2
    else if(!redirectURL){
      //alert('case 2')
      dispatch({
        type:"UPDATE_PLAID_STATE",
        payload: {
          redirectURL:localStorage.getItem("plaidRedirect"),
          product:JSON.parse(localStorage.getItem("plaidProducts")),
          autoActivate:localStorage.getItem("plaidAutoActivate"),
        }
      })
    }
 }, [redirectURL,product,initialized,autoActivate])


  const redirect = () => { 
    const url = new URL(redirectURL)
    const redirectPath = url.pathname + url.search
    localStorage.removeItem('plaidRedirect')
    localStorage.removeItem('plaidLinkToken')
    localStorage.removeItem('plaidProducts')
    localStorage.removeItem('plaidAutoActivate')
    history.push(redirectPath)
  }

  const onSuccess = useCallback(async (token, metadata) => {
    setPublicToken(token)
    await createPlaidItem()
    .then( async (res) => {
      //set the item id, so if auto activate is true, then the component on the redirect will activate based on this item_id
      await dispatch({
        type:"UPDATE_PLAID_STATE",
        payload: {
          item_id:res.data.createPlaidItem.item_id,
        }
      })
      redirect()
    }).catch(error => {
      setError(error.message)
      setModal(true)
    })
  }, []);

  const onExit = useCallback(async (error, metadata) => {
    if(metadata.status!=='connected'){
      redirect()
    }
  }, []);


  useEffect(() => {
    if(createLinkToken.data){
      setLinkToken(createLinkToken.data.createLinkToken)
      localStorage.setItem("plaidLinkToken",createLinkToken.data.createLinkToken)
    }
 }, [createLinkToken.data])



  return (
    <Fragment>
    {/* {!linkToken &&  <PlaidLoader loading={true}/>} */}
      <PlaidPageLoader message={`Loading ${linkToken?"":"(50%)"}...`} spinner={!linkToken}/>
    <PlaidErrorModal error={error}  modal={modal} setModal={setModal}/>
      {!modal && linkToken && <OpenPlaidLink token={linkToken} onExit={onExit} onSuccess={onSuccess}/>}
    </Fragment>

  );
};


export default PlaidPage;
