import React, { useState, useEffect, Fragment } from 'react';
import ChoosePrimaryColor from '../color-picker/ChoosePrimaryColor';
import GetColorSwatches from '../color-picker/GetColorSwatches';
import Flex from '../common/Flex'

import { gql, useMutation  } from '@apollo/client';
import ErrorMessage from '../common/ErrorMessage';
import ButtonIcon from '../common/ButtonIcon';
import {Spinner  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowRight,} from "@fortawesome/free-solid-svg-icons";
const ThemeColors = ({  theme, refetch }) => {
  const [primary, setPrimary] = useState('#6C757D')
  const [secondary, setSecondary] = useState('#6C757D')
  useEffect(() => {
    if(theme?.primary && theme?.secondary){
      setPrimary(theme?.primary)
      setSecondary(theme?.secondary)
    }
  }, [theme])
  const UPDATE = gql`
  mutation UpdateTheme($input:ThemeInput!){
    updateTheme(input:$input)
  }
`;
const handleClick = async () => {

  await update().then(async res => {

    await refetch()
  }) 
}
const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{primary:primary,secondary:secondary}}})

  return (
      
    <Fragment>
      <p>Your campaign will be branded with your non-profit’s primary and secondary colors. Click on the color boxes below to input your colors.</p>  
    <Flex wrap align='center' className='mb-2'>
      
      <h5 className='text-primary mr-2'>Primary color:</h5>
      <ChoosePrimaryColor className='p-2' color={primary} setColor={setPrimary}/>

    </Flex>
    <Flex wrap align='center' className='mb-2'>
      
    <h5 className='text-primary mr-2'>Secondary color:</h5>
      <ChoosePrimaryColor className='p-2' color={secondary} setColor={setSecondary}/>
      {/* <FontAwesomeIcon className='p-1' icon={faArrowRight} size="lg" color="red"/> */}

    </Flex>
    <ButtonIcon
            disabled={loading}
            className="px-3 mt-0"
            iconAlign="right"
            transform="down-1 shrink-4"
            hideIcon={loading}
            onClick={handleClick}
            size={"sm"}
          >
            {(!loading ) && "Update"}
            {loading && <Spinner  size={"sm"}/>}
          </ButtonIcon>
        {error &&<ErrorMessage error={error}/>}
</Fragment>


  );
};


export default ThemeColors;
