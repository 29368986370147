import React, { useState, useEffect, Fragment } from 'react';
import { Badge, Button,  CardBody, CardFooter,  Form, FormGroup, Input,  } from 'reactstrap';
import ButtonIcon from '../../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import { gql, useMutation } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import { useForm } from "react-hook-form";
import {useDispatch} from 'react-redux'
import Flex from '../../common/Flex';
const OTP_CHECK = gql`
mutation verifyOTP($OTP:Int!){
  verifyOTP(OTP:$OTP)
}
`;
const RESEND_OTP = gql`
mutation sendOTP{
  sendOTP
}
`;
const DonorVerifyEmailForm = ({email, handleSuccess}) => {
  const {  handleSubmit,  } = useForm();
  
  const [code, setCode] = useState('');
  const [sendCount, setSendCount] = useState(0);
  const [isNum, setIsNum] = useState(true);
  const dispatch = useDispatch()
  const [otpCheck,{data,loading,error}] = useMutation(OTP_CHECK, {
    variables:{OTP:Number(code)}
  })
  const [resendMfa,resendMfaResponse] = useMutation(RESEND_OTP)
  const submit = () => {
    otpCheck().catch(e => {})
  }
  useEffect(() => {
    if(data){
      handleSuccess()
    }
  }, [data])
  
  const sendOtp = () => {
    resendMfa()
    setSendCount(sendCount+1)
  }
  useEffect(() => {
    if(sendCount === 0){
      sendOtp()
    }
  }, [])


  useEffect(() => {
    isNaN(Number(code))?setIsNum(false):setIsNum(true)
    if(code.length===6 && isNum){
      submit()
    }
  }, [code])

  return (
    <Fragment>
    <Form onSubmit={handleSubmit(submit)}>
      <CardBody className="fs--1 font-weight-normal ">
        <Flex className='mb-3' align='center'>

        <h5 className='mb-0 mr-2'>Verify your email address</h5>
        <Badge color='soft-red'>Required</Badge>
        </Flex>
        <p>We've send a 6-digit code to {email}. Please enter the code below.</p>
        <p>This code will expire in 30 minutes.
</p>
      <FormGroup>
        <Input
          placeholder={'code'}
          value={code}
          onChange={({ target }) => setCode(target.value)}
          type="number"
        />
      </FormGroup>
        <ButtonIcon
                  disabled={loading || !isNum}
                  color="primary"
                  className=" "
                  hideIcon={loading}
                >
                  {(!loading ) && "Verify email"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
                <Button onClick={sendOtp} disabled={resendMfaResponse.loading || sendCount > 2 } color='link fs--1 '>Resend code</Button>
                
  {error &&<ErrorMessage error={error}/>}
  {!isNum && <p className="error-message">You must enter a number</p>}
      </CardBody>
          </Form>
      </Fragment>
  );
};

export default DonorVerifyEmailForm;
