
import React, {Fragment, useState} from 'react'
import reactCSS from 'reactcss'
import { ChromePicker   } from 'react-color'
//import { classNames } from '@ungap/global-this';
import Flex from '../common/Flex';

// class ChoosePrimaryColor extends React.Component {
//   state = {
//     displayColorPicker: false,
//     color: {
//       r: '241',
//       g: '112',
//       b: '19',
//       a: '1',
//     },
    
//   colors: ['#FF6900', '#FF6900', '#FF6900', '#FF6900', '#FF6900', '#FF6900', '#FF6900', '#FF6900', '#FF6900']
//   };



//   handleChange = (color) => {
//     this.setState({ color: color.rgb })
//   };





const ChoosePrimaryColor = ({  color, setColor, className}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const colors =  ['#FF6900', '#F44336', '#9C27B0', '#2196F3', '#00BCD4', '#4CAF50', '#CDDC39', '#FF9800', '#FF5722']

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };
  const handleClose = () => {
    setDisplayColorPicker(false)
    };

  const handleChange = (newColor) => {
    console.log(newColor)
    setColor(newColor.hex)
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: color,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });


return (
<Flex >
  <div className={className} style={ styles.swatch } onClick={handleClick }>
    <div style={ styles.color } />
  </div>
  { displayColorPicker ? <div style={ styles.popover }>
    <div style={ styles.cover } onClick={handleClose}/>
    <ChromePicker   color={ color } colors={colors} onChange={ handleChange} />
  </div> : null }
  </Flex>
);
};


export default ChoosePrimaryColor;
