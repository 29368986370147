import React, { Fragment,  useState, useEffect } from 'react';
import { ListGroupItem } from 'reactstrap';
import PayoutAccount from './PayoutAccount';
import PlaidAccountVerify from './PlaidAccountVerify';


const PayoutAccountList=({accounts,refetch})=> {

  
  const [isVerifying, setIsVerifying] = useState(false)




  // useEffect(() => {
  //   if(accountsLoading === 0 ){ 
  //     setIsLoading(false)
  //   }else{
  //     setIsLoading(true)
  //   }
  // }, [accountsLoading]);


  
    return (
    <Fragment>
      <Fragment>
        {accounts.map((account)=>
        
      <ListGroupItem className='p-1'  key={account._id} >
          <PayoutAccount
          // closeModal={closeModal}
          // refetchList={refetchList}
          // SCF_type={SCF_type}
          refetch={refetch}
          //isVerifying={isVerifying}
          //setIsVerifying={setIsVerifying}
          account={account}
          /></ListGroupItem>
        )}
      </Fragment>
    </Fragment>

  );
    }


export default PayoutAccountList