import React, { useState } from 'react';
import Flex from '../../common/Flex';
import { Button, Card } from 'reactstrap';
import classNames from 'classnames'
import TenantSelectorModal from './TenantSelectorModal';

  const TenantSelector  = ({select, className}) => {
    
  const [modal, setModal] = useState(false);
  const [tenant, setTenant] = useState(null);

  const onInsert = (tenant) => {
    setTenant(tenant)
    select(tenant)
  }


  return (    <Card  className={classNames('p-4 shadow-none',className )}>

      <TenantSelectorModal insert={onInsert} modal={modal} setModal={setModal}/>
      <Flex wrap align='center' >
        <h5 className='font-weight-semi-bold mr-2 mb-0'>Tenant</h5>
        <Button  onClick={() => {setModal(true)}} color='primary' size='sm'>Select Tenant</Button>
      
      </Flex>
      <h5 className=' mb-0 mr-2'>{tenant? tenant.subdomain: 'select a tenant'}</h5>

    </Card>
  );
};

export default TenantSelector;
