import React from 'react';
import AuthBasicLayout from '../../layouts/AuthBasicLayout'
import ResetPasswordForm from './forms/ResetPasswordForm';
// import ReactGA from 'react-ga';

const ResetPassword = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  return (
    <AuthBasicLayout>
    <div className="text-center">
      <h5 className="mb-3"> Reset your password</h5>
      <ResetPasswordForm />
    </div>
    </AuthBasicLayout>
  );
};

export default ResetPassword;
