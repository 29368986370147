
import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import DashboardLayout from '../../layouts/DashboardLayout';
import ComingSoon from '../common/ComingSoon';
// import ReactGA from 'react-ga';
import Section from '../common/Section';

const CharityReports  = ({ match: { url } }) => {
  // ReactGA.pageview(url);

  return (
    <DashboardLayout>
    <Section>
      <ComingSoon/>
    </Section>
    </DashboardLayout>
  );
};

export default CharityReports;
