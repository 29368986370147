import React from 'react';
import ForgetPasswordForm from './forms/ForgetPasswordForm';
import AuthBasicLayout from '../../layouts/AuthBasicLayout'
// import ReactGA from 'react-ga';

const ForgetPassword = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  return (
    <AuthBasicLayout>
    <div className="text-center">
      <h5 className=""> Forgot your password?</h5>
      <p>Enter your email and we'll send you a One Time Passcode (OTP)</p>
      <ForgetPasswordForm />
    </div>
    </AuthBasicLayout>
  );
};

export default ForgetPassword;
