import React from 'react';
import { Link } from 'react-router-dom';
import { Card, } from 'reactstrap';
import classNames from 'classnames'
import Flex from '../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CharityMixTable from '../dashboard/charities/CharityMixTable';

const CharitiesSettings  = ({className}) => {
  return (
      
    <div  className={classNames(' ',className )}>
    <Card className='p-4 shadow-none'>      
    
    <Flex align='center' className='mb-3 flex-wrap'>
          <h5 className='mb-0'>My Causes</h5>
          <Link className="font-weight-semi-bold ml-auto  fs--1 " to={'/my-causes'}>
            <p className='text-wrap m-0'>Edit    
            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
            </p>
          </Link>
        </Flex>
        <p>Split your tzedakah between the causes you care about. You decide how much of your donations go to which nonproft.</p>
        <CharityMixTable viewOnly/>

    </Card></div>
  );
};

export default CharitiesSettings;
