import React from 'react';



const TenantDetails = ({ tenant}) => { 
  return (
    <div className='mt-3'>
      <p>{'subdomain: ' +tenant.subdomain}</p>
      <p>{'charityName: ' +tenant.charityName}</p>
      <p>{'charityEIN: ' +tenant.charityEIN}</p>
      <p>{'contactName: ' +tenant.contactName}</p>
      <p>{'contactEmail: ' +tenant.contactEmail}</p>
      <p>{'contactPhone: ' +tenant.contactPhone}</p>
    </div>
  );
};

export default TenantDetails;
