import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Spinner } from 'reactstrap';
import Flex from './Flex';

const PlaidLoader = ({loading}) => (    
<Flex column align='center' justify="center" style={{height:'100vh'}}>
      <ClipLoader 
 color={'#BBBBBB'} loading={loading} size={40} />
    </Flex>
);

PlaidLoader.propTypes = { ...Spinner.propTypes };

PlaidLoader.defaultProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary'
};

export default PlaidLoader;
