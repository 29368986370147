import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {  Form, FormGroup,  Spinner } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import WizardInput from '../../common/WizardInput';
import { gql, useMutation } from '@apollo/client';
import { useForm } from "react-hook-form";
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';

const ChangePasswordForm = ({  setRedirectUrl, layout }) => {
  // State
  const { register, handleSubmit, errors, watch } = useForm();
  const [response, setResponse] = useState('');
  
  const CHANGE_PASSWORD = gql`
  mutation ChangePassword($password:String!,$newPassword: String!){
    changePassword(newPassword:$newPassword,password:$password)
  }
  `;
  const [submit,{data,loading,error}] = useMutation(CHANGE_PASSWORD)

  const onSubmit = (data) => {
    submit({variables:{...data}}).catch(e => {})
  }


  useEffect(() => {
    setRedirectUrl(`/dashboard`);
  }, [setRedirectUrl, layout]);


  useEffect(() => {
    if(data){
      setResponse(data.changePassword)
    }
  }, [data]);

  return (
    <Fragment>
      {!data && 
      
    <Form onSubmit={handleSubmit(onSubmit)}>
    <FormGroup>
      <WizardInput
          type="password"
          label="Current Password*"
          placeholder="Current Password"
          id="password"
          name="password"
          innerRef={register({
            required: 'You must specify a password',
            minLength: {
              value: 2,
              message: 'Password must have at least 2 characters'
            }
          })}
          errors={errors}
        />
    </FormGroup>
    
    <FormGroup>
      <WizardInput
          type="password"
          label="New Password*"
          placeholder="New Password"
          id="newPassword"
          name="newPassword"
          innerRef={register({
            required: 'You must specify a password',
            minLength: {
              value: 2,
              message: 'Password must have at least 2 characters'
            }
          })}
          errors={errors}
        />
    </FormGroup>
    <FormGroup>
      
    <WizardInput
          type="password"
          label="Confirm New Password*"
          placeholder="Confirm New Password"
          id="confirmPassword"
          name="confirmPassword"
          innerRef={register({
            validate: value => value === watch('newPassword')|| `The password do not match`
          })}
          errors={errors}
        />
    </FormGroup>
    {error &&<ErrorMessage error={error}/>}
          <ButtonIcon
                disabled={loading}
                color="primary"
                className="ml-auto px-5"
                type="submit"
                icon={false ? 'chevron-left' : 'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
                hideIcon={loading}
              >
                {(!loading ) && "Submit"}
                {loading && <Spinner  size={"sm"}/>}
              </ButtonIcon>
  </Form>
      
      }
      {data &&
      <Fragment>
        
      <p>{data.changePassword}</p>
      
      <Link className="btn btn-primary" to="/dashboard">Go To Dashboard</Link>
      
    </Fragment>
      }
    </Fragment>
    
  );
};

ChangePasswordForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ChangePasswordForm.defaultProps = { layout: 'basic' };

export default withRedirect(ChangePasswordForm);
