const initialState = {
    invited: false,
    EIN: null,
    _id: null,
    charityName:null,
    donorGroup:null,
    donorGroupName:null,
    approved:null
};

const inviteReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "LOG_OUT":
            return {
                ...initialState
            }
        case "UPDATE_INVITE_DATA": 
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default inviteReducer