import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Card } from 'reactstrap';
import {useDispatch} from 'react-redux'
import { gql, useQuery, useLazyQuery } from '@apollo/client';
// import Activate from './Activate';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Url from './Url';
import PayoutAccount from './PayoutAccount';
import PublishTheme from './PublishTheme';
const GET_CHARITY_USER_PROFILE = gql`
query{ 
  meCharityUser{ 
    firstName
    lastName
    email 
    charityName
    EIN
    approved
    isVerified
    charity{
      publishedTheme
      donorGroupsEnabled
      name
      EIN
      stripeRequirements{
        past_due
      }
    }
  }
}
`;
const RequiredAction  = ({className, location}) => {
//   const {active,requirements} = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const {past_due} = useSelector((state) => state.charityUser.charity.stripeRequirements)
  const {publishedTheme} = useSelector((state) => state.charityUser.charity)
  const [requirements, setRequirements] = useState([])

    // useEffect(() => {
    //     if(stripeRequirements.past_due){
    //         setRequirements(requirements => [...requirements, ...stripeRequirements.past_due])
    //     }
    // }, [stripeRequirements])


    const [getProfile, {data,loading,error}] = useLazyQuery (GET_CHARITY_USER_PROFILE,{
        fetchPolicy: "no-cache"
    })
    const handleSuccess = () => {
        getProfile()
    }
  useEffect(() => {
    if(data){
      dispatch({
        type:"UPDATE_CHARITY_USER",
        payload:{
          ...data.meCharityUser,
        }
      })
    }
    console.log(data)
  }, [data])
return(

<div className={className}>
<Card className={'shadow-none p-3'}>
  <h4 className='text-red mb-3'> <FontAwesomeIcon icon={faExclamationTriangle} size={'1x'} color='red'className='mr-2'/>Required Actions</h4>
  <p className>Please complete the following required actions </p>
  {past_due.includes('business_profile.url') && <Url  className='mb-3' handleSuccess={handleSuccess}/>}
  {past_due.includes('external_account') && <PayoutAccount  className='mb-3' handleSuccess={handleSuccess}/>}
  {!publishedTheme && <PublishTheme  className='mb-3' handleSuccess={handleSuccess}/>}
  {/* {charity} */}
  {/* {requirements.includes('verify_email') && <VerifyEmail className='mb-3' handleSuccess={handleSuccess}/>}
  {requirements.includes('bank_account') && <VerifyBankAccount className='mb-3'  handleSuccess={handleSuccess}/>} */}
  {/* {!active  &&<Activate/>} */}
</Card></div>

)
}
export default RequiredAction
