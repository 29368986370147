import React, { useState, useEffect, Fragment } from 'react';

import { gql, useMutation,  } from '@apollo/client';
import Flex from '../common/Flex';
import { Button, Form, FormGroup, Input,  Label,  Spinner , Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import ErrorMessage from '../common/ErrorMessage';
import {Image} from 'cloudinary-react'
import ButtonIcon from '../common/ButtonIcon';
import CloudinaryModal from '../cloudinary/CloudinaryModal';
import Logo from '../common/Logo'

const AboutText = ({  theme, refetch }) => {
  const [about, setAbout] = useState('')
  const [bioImage1, setBioImage1] = useState('')
  const [quote, setQuote] = useState('')
  const [bioTitle, setBioTitle] = useState('')
  const [iconLogo, setIconLogo] = useState('')
  const [charityName, setCharityName] = useState('')
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null)


  const UPDATE = gql`
  mutation UpdateTheme($input:ThemeInput!){
    updateTheme(input:$input)
  }
`;
const insertPublicId = (publicId) => {
  setSelectedId(publicId)
  setModal(false)
}
  const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{about:about,bioImage1:selectedId?selectedId:bioImage1,quote:quote,bioTitle:bioTitle}}})
  useEffect(() => {
    if (theme){
      setAbout(theme.about)
      setBioImage1(theme.bioImage1)
      setQuote(theme.quote)
      setBioTitle(theme.bioTitle)
      setIconLogo(theme.iconLogo)
      setCharityName(theme.charityName)
    }
  }, [theme])
  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (
    <Fragment>  
      <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertPublicId}/>
      <p>The about section of your campaign is an opportunity to tell your story. Personal stories resonate the most with donors so include a quote from a team member or from someone active in your donor community. </p>

 <Flex wrap align='center' className='p-3'>
    <Form onSubmit={handleSubmit} className='w-100'>
    <Row>
        <Col lg={6}>
          
      <FormGroup>
        {true && <Label>About</Label>}
        <Input
          placeholder={!true ? 'About' : ''}
          value={about}
          onChange={({ target }) => setAbout(target.value)}
          type="textarea"
          rows="6"
        />
      </FormGroup>
      <FormGroup>
        {true && <Label>Quote</Label>}
        <Input
          placeholder={!true ? 'Quote' : ''}
          value={quote}
          onChange={({ target }) => setQuote(target.value)}
          type="textarea"
          rows="3"
        />
      </FormGroup>
      <FormGroup>
        {true && <Label>Title</Label>}
        <Input
          placeholder={!true ? 'Title' : ''}
          value={bioTitle}
          onChange={({ target }) => setBioTitle(target.value)}
          type="textarea"
          rows="1"
        />
      </FormGroup>
      
      <FormGroup>

      <Label>Headshot</Label>
      <Flex align='center'>
      <Image className='about-image-quote mr-3 ' publicId={selectedId?selectedId:bioImage1} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/>
      <div>

      <Button className='mr-2'color='primary' onClick={() => {setModal(true)}}>Change photo</Button> 
      </div>      
      

      </Flex>
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={loading}
              hideIcon={loading}
            >
              {(!loading ) && "Save changes"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
        </Col>
        <Col lg={6}>
        <Card className='p-2 border border-primary'>
            <CardTitle>Preview:</CardTitle>
            <CardBody>
                  <Flex  className='mb-4' align='center'>
          <Logo publicId={iconLogo} />
            <h2 className='mb-0'>About <span>{charityName}</span></h2>
            </Flex>
            <p className='border-bottom pb-4'>{about}</p>
              <Flex align='center' className='flex-wrap-reverse pt-4 flex-lg-nowrap'>
                <div></div>
            <Image  className={'about-image-quote mr-3 '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={selectedId?selectedId:bioImage1}/>
              
                <div className=''>
                  <p>{quote}</p>
                      <p>{bioTitle}</p>
                </div>
              </Flex>
            </CardBody>
          </Card>
        </Col>
    </Row>
    </Form>
    </Flex>
        {error &&<ErrorMessage error={error}/>}

    
    
    
    </Fragment>


  );
};


export default AboutText;
