import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';
import classNames from 'classnames';
import Flex from '../../common/Flex';
import TenantSearch from './TenantSearch';
import TenantDetails from './TenantDetails';
const TenantSelectorModal = ({insert,modal, setModal}) => {

  const toggle = () => {
    
    setSelectedTenant(null)
    setActiveTab('1')
    setModal(!modal)};
  const [activeTab, setActiveTab] = useState('1');
  const [selectedTenant, setSelectedTenant] = useState(null);

  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const handleOnSelect = (tenant) => {
    setSelectedTenant(tenant)
    setActiveTab("2")
  }
  useEffect(() => {
    if(activeTab === '1'){
      setSelectedTenant(null)
    }
  }, [activeTab])
  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
    
    
    modalClassName="overflow-hidden modal-fixed-right modal-theme"
    className="modal-dialog-vertical"
    contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>New Nonprofit</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
      <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '1' })}
            onClick={() => { toggleTab('1'); }}
          >
            Search
          </NavLink>
        </NavItem>
        {selectedTenant && <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '2' })}
            onClick={() => { toggleTab('2'); }}
          >
            Details
          </NavLink>
        </NavItem>}
      </Nav>
      <TabContent  className='overflow-hidden'activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <TenantSearch onSelect={handleOnSelect}/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
         
        <Row>
            <Col sm="12">
              {selectedTenant && <TenantDetails  tenant={selectedTenant}/>}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
        <Button disabled={!selectedTenant} color="primary" onClick={()=>{
          insert(selectedTenant)
          toggle()}}>Insert</Button>{' '}
        <Button color="secondary" onClick={() => {
          toggle()}}>Cancel</Button>
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default TenantSelectorModal;