import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavLink, } from 'reactstrap';
import {useSelector} from 'react-redux'



const PushkaLandingRightSideNavItem = () => {
  const {role, loggedIn} = useSelector((state) => state.auth)
  const {management} = useSelector((state) => state.config)


  return (
    <Fragment>
      {!loggedIn &&
        <Fragment>
        <div>
          { !management && <NavLink  className="" tag={Link} to="/authentication/login">Login</NavLink>}
          { management && <NavLink tag={Link} to="/authentication/nonprofit/login">Login</NavLink>}
          </div>
          <div>
            { !management &&<Link className="btn btn-secondary btn-navbar" to="/get-started">Get Started</Link>}
            { management &&<Link className="btn btn-secondary btn-navbar" to="/authentication/nonprofit/signup">Get Started</Link>}
          </div>

        </Fragment>
      }
      {loggedIn &&
          <div>
            { role === 'DONOR' && <Link className="btn btn-secondary btn-navbar" to="/dashboard">Dashboard</Link>}
            { role === 'CHARITY' && <Link className="btn btn-secondary btn-navbar" to="/nonprofit/dashboard">Charity Dashboard</Link>}
            { role === 'ADMIN' && <Link className="btn btn-secondary btn-navbar" to="/admin/dashboard">Admin Dashboard</Link>}
            </div>

      }
      </Fragment>

  );
};

export default PushkaLandingRightSideNavItem;
