import React, { Fragment,  useState, useEffect } from 'react';
import AccountVerify from './AccountVerify';


const AccountVerifyList=({accounts,refetch,setIsLoading,SCF_type,refetchList,closeModal})=> {

  
  const [accountsLoading, setAccountsLoading] = useState(0)
  const [isVerifying, setIsVerifying] = useState(false)




  useEffect(() => {
    if(accountsLoading === 0 ){ 
      setIsLoading(false)
    }else{
      setIsLoading(true)
    }
  }, [accountsLoading]);


  
    return (
    <Fragment>
      <Fragment>
        {accounts.map((account)=>
          <AccountVerify  
          closeModal={closeModal}
          refetchList={refetchList}
          SCF_type={SCF_type}
          refetch={refetch}
          isVerifying={isVerifying}
          setIsVerifying={setIsVerifying}
          key={account._id} 
          account={account}
          />
        )}
      </Fragment>
    </Fragment>

  );
    }


export default AccountVerifyList