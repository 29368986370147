import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Spinner } from 'reactstrap';
import Flex from './Flex';

const TableNoData = ({message, height}) => (    
<Flex column align='center' justify="center" style={{height:`${height}px`}}>
  <p className='text-muted mb-0'>{message}</p>
    </Flex>
);


TableNoData.propTypes = { ...Spinner.propTypes };

TableNoData.defaultProps = {
  height: 100,
  message:'No Data'
  // loading: true,
  // type: 'grow',
  // size: 'lg',
  // color: 'primary'
};

export default TableNoData;
