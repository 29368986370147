import React, { Fragment, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, Row, Col, Label, Input, Button } from 'reactstrap';
import Loader from '../common/Loader';
import SelectCharityUserTable from '../tables/SelectCharityUserTable';
import TenantSelector from './Tenant/TenentSelector';
import ApproveNonprofitForm from './ApproveNonprofitForm';
// import ReactGA from 'react-ga';
import DashboardLayout from '../../layouts/DashboardLayout';
import AdminNonprofitTable from '../tables/AdminNonprofitTable'
import EditNonprofitModal from './Charity/EditNonprofitModal';

const CHARITY_USERS = gql`
query CharityUsers($isApproved: Boolean,$isVerified: Boolean,$subCharity:Boolean){
  charityUsers(isApproved:$isApproved,isVerified:$isVerified,subCharity:$subCharity){
    email
    EIN
    charityName
    firstName
    lastName
    isVerified
    approved
    created
    _id
  }
}
`;

const ApproveNonprofit  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const [charityUsers,setCharityUsers] = useState([]);
  const [selectedCharityUser,setSelectedCharityUser] = useState(null);
  const [approved,setApproved] = useState(false);
  const [modal,setModal] = useState(false);
  const [selectedNonprofit,setSelectedNonprofit] = useState({});
  //   const {data,loading,refetch} = useQuery(CHARITY_USERS, {
  //     variables:{subCharity:isSub?true:false},
  //   context: { headers: { tenantId, }}
  // })

  // useEffect(() => {
  //   if(data){
  //     setCharityUsers(data.charityUsers)
  //   }
  // }, [data])
  
  // useEffect(() => {
  //   if(tenantId){

  //     refetch()
  //   }
  // }, [tenantId])
  
//   const handleChange = (event) => {
//     setIsSub(event.target.checked)
// }  
// const handleSelectedCharityUser = (charityUser) => {
//   setSelectedCharityUser(charityUser)
// }
// const changeApproved = e => {
//   setApproved(e.target.value)
// }
const handleRowClick = (data) => {
  setModal(true)
  setSelectedNonprofit(data)
  console.log(data)
}

  return (
    <DashboardLayout>
      <EditNonprofitModal modal={modal} setModal={setModal} nonprofit={selectedNonprofit}/>
        <h4>Nonprofits</h4>

      <AdminNonprofitTable approved={approved} handleRowClick={handleRowClick}/>
      
      {/* <div onChange={changeApproved}  className='mb-2'>
        <label>Approved: </label>
          <input type="radio" value={true} name="taxStatus" /> True 
          <input type="radio" value={false} name="taxStatus" /> False 
      </div> */}
        {/* <Card className='p-3 mb-3'>
          <h4></h4>
          <FormGroup check>
        <Label check>
          <Input type="checkbox" onChange={handleChange} />{' '}
          Sub-tenant nonprofit
        </Label>
      </FormGroup>
      {isSub && 
        <TenantSelector className='mb-3' select={(tenant)=>{setTenantId(tenant._id)}}/> 
      }
        </Card>
          <Card className='mb-3'>
            <CardBody>
              {loading && <Loader/>}
              <SelectCharityUserTable selectedCharityUser={handleSelectedCharityUser} data={charityUsers}/>
            </CardBody>
          </Card>
          
          {selectedCharityUser && 
          <Card className='p-4'>
            <ApproveNonprofitForm charityUser={selectedCharityUser}/>
            </Card>} */}
      </DashboardLayout>
  );
};

export default ApproveNonprofit;
