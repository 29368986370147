import React, { useState, useEffect } from 'react';
import AlertButton from '../../common/AlertButton';
import { gql, useQuery } from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux'
import DonorVerifyEmailForm from '../../auth/forms/DonorVerifyEmailForm';
import { Badge, Card, CardBody } from 'reactstrap';
import BankAccount from '../../stripe/BankAccount';
import Flex from '../../common/Flex';
import StripeVerificationForm from '../../bankAccount/forms/StripeVerificationForm';

const VerifyBankAccount  = ({className, handleSuccess}) => {

const DEFUALT_ACCOUNT = gql`
query MyDefaultStripeBank{
  myDefaultStripeBank{
      _id
      account_holder_name
      bank_name
      last4
      routing_number
      currency
      created
      default
      status
  }
}
`;
const [account, setAccount] = useState([]);
const {data, refetch} = useQuery(DEFUALT_ACCOUNT)
useEffect(() => {
  if(data){
      setAccount(data.myDefaultStripeBank)
  }
}, [data])
  return (
    <Card className={'shadow-none border border-red ' + className}>
    <CardBody className="fs--1 font-weight-normal ">

<Flex className='mb-3' align='center'>

<h5 className='mb-0 mr-2'>Verify your bank account</h5>
<Badge color='soft-red'>Required</Badge>
</Flex>
<BankAccount account={account} isDefault={true} onVerificationSuccess={handleSuccess} hideVerificationButton/>
<StripeVerificationForm accountId={account._id} onSuccess={handleSuccess} />
{/* <p>This will show the defulat bank account and it will have the form below for the donor to verify.</p>
        <p>If they want to choose a different bank account instead of verifying this on, the will be a link to do</p> */}
</CardBody>

</Card>
      
    
  );
};

export default VerifyBankAccount;
