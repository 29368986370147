import React from 'react';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem,  } from 'reactstrap';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import {useSelector} from 'react-redux'

const Profile  = ({className}) => {
    const {email, firstName, lastName} = useSelector((state) => state.charityUser)
  return (
      
    <div  className={classNames(' ',className )}>
    <Card className='p-md-4 p-2 shadow-none'>
          <h5 className='mb-0'>Profile</h5>
        <ListGroup flush>
            <ListGroupItem className='px-md-3 py-3 px-0'>
                <p className=' fs--1 mb-0  font-weight-bold' >Email: <spam className='font-weight-normal'>{email}</spam></p>
            </ListGroupItem>
            <ListGroupItem className='px-md-3  py-3 px-0'>
                <p className='fs--1 mb-0  font-weight-bold'  >Name: <spam className='font-weight-normal'>{firstName + " " + lastName}</spam></p>
            </ListGroupItem>
            
            <ListGroupItem className='px-md-3 px-0'>
                
                
        <Flex wrap align='center'>
          <p className='m-0 fs--1 font-weight-bold'>Password:</p> 
          <p className='m-0 pl-2 '>••••••••</p>
          <Link className="btn btn-outline-primary btn-sm ml-auto" to="/authentication/nonprofit/change-password">Change</Link>
        </Flex>
            </ListGroupItem>
        </ListGroup>
    </Card></div>
  );
};

export default Profile;
