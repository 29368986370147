import { Fragment } from "react";
import SetupPassword from "./SetupPassword";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
const Header = ({step, setupPassword}) => {
  return (
    <Fragment>
      {/* <HeaderNav step={step} handleBackStep={handleBackStep}/> */}
      {(step === 0 && setupPassword !== false )&& <Step1/>}
      {(step === 0 && setupPassword === false )&& <SetupPassword/>}
      {step === 1 && <Step2/>}
      {step === 2 && <Step3/>}
      {step === 3 && <Step4/>}
    </Fragment>
    
  );
};

export default Header;
