import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, PopoverHeader, PopoverBody  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';

const TooltipIcon = ({className  }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverId, setPopoverId] = useState('popover'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className={className}>
      <Button id={popoverId} type="button" color={"muted"} className='tooltip-icon'>
        <Flex align='center' justify='center'>

            <FontAwesomeIcon icon="question" color={'white'}  size='xs'/>
        </Flex>
      </Button>
      <Popover placement="bottom" isOpen={popoverOpen} target={popoverId} toggle={toggle}>
        <PopoverHeader>Popover Title</PopoverHeader>
        <PopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</PopoverBody>
      </Popover>
    </div>
  );
};

TooltipIcon.propTypes = {
  error: PropTypes.object.isRequired,
};

TooltipIcon.defaultProps = {
};

export default TooltipIcon;
