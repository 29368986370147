import {loadStripe} from '@stripe/stripe-js';


const GetBankAccountToken = async ({
  account_holder_name,
  account_holder_type,
  account_number,
  routing_number,
  country,
  currency
}) => {
  try {    
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PKEY);
    return stripe
    .createToken('bank_account', {
      country: country,
      currency: currency,
      routing_number: routing_number,
      account_number: account_number,
      account_holder_name: account_holder_name,
      account_holder_type: account_holder_type,
    }).then(res => {
      if(res.error){
        throw res.error
      }else{
        return res
      }
    })
  } catch (error) {
    throw error
  }
}

export default GetBankAccountToken
