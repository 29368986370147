import React, {   useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Card, Form, FormGroup, Input,  Label,  Spinner  } from 'reactstrap';
import classNames from 'classnames'
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import CharityBalanceSummary from './CharityBalanceSummary';

  const CreatePayout  = ({ className, tenantId, charity}) => {
    const CREATE_PAYOUT = gql`
    mutation manualPayout($amount:Float!){
      manualPayout(amount:$amount){ 
        amount
      }
    }
    `;

    const hasLabel = true
    
  const [amount, setAmount] = useState(0);

  const [createPayout, {error,loading}] = useMutation(CREATE_PAYOUT,{
    variables:{amount:amount},
    context: { headers: { tenantId, charityId:charity._id} }
  })

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    createPayout().then().catch(e=>{})

  };
  return (    <Card  className={classNames('p-4 shadow-none',className )}>
    <CharityBalanceSummary tenantId={tenantId} charity={charity}/>


    <h5>Payout</h5>
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Amount</Label>}
        <Input
          placeholder={!hasLabel ? 'Amount' : ''}
          value={amount}
          onChange={({ target }) => setAmount(Number(target.value))}
          type="number"
        />
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={loading}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Log in"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
    </Form>

    </Card>
  );
};

export default CreatePayout;
