import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 21.6"   width='200px' height='100px' {...props}>
      <linearGradient
        id="cloud3"
        gradientUnits="userSpaceOnUse"
        x1={17.671}
        y1={21}
        x2={17.671}
        y2={0.801}
      >
      <stop offset={0} stopColor="white" stopOpacity={0} />
      <stop offset={1} stopColor="white" stopOpacity={.6} />
      </linearGradient>
      <path
        d="M34.63 17.91a3.017 3.017 0 00-2.62-4.52c-.3 0-.59.04-.86.13.04-.27.06-.54.06-.82a6.03 6.03 0 00-3.31-5.38 4.59 4.59 0 10-8.34-3.84 3.507 3.507 0 00-2.48-1.03c-1.6 0-2.95 1.08-3.37 2.55a6.97 6.97 0 00-8.15 8.33 2.88 2.88 0 00-.59-.06c-1.7 0-3.08 1.38-3.08 3.08 0 .4.08.78.21 1.13-.07-.01-.14-.01-.21-.01-.98 0-1.77.79-1.77 1.77.01.97.8 1.76 1.78 1.76h31.54c.98 0 1.77-.79 1.77-1.78.01-.52-.22-.99-.58-1.31z"
        opacity={0.58}
        fill="url(#cloud3)"
      />
    </svg>
  )
}

export default SvgComponent
