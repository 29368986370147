
import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CreateDonorGroup from './CreateDonorGroup';


const AddDonorGroupModal = ({modal, setModal,charity, buttonText,onClick}) => {
  const [view, setView] = useState("NEW")
  // const [selectedCampaign, setSelectedCampaign] = useState({})
  const toggle = () => {
    setModal(!modal)
  };
  useEffect(() => {
    setView("NEW")
  }, [modal])
  const onSuccess=() =>{
    setView('SUCCESS')
  }

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} toggle={toggle} className='modal-dialog modal-dialog-centered ' >
        <ModalHeader toggle={toggle}><h4 >New Donor Group</h4></ModalHeader>
        <ModalBody>
          {view==='NEW' && 
          <CreateDonorGroup onSuccess={onSuccess}/>
          }
          {view==='SUCCESS' && 
          <p>Donor Group Added!</p>
          }
        </ModalBody>

      </Modal>
    </div>
  );
}

export default AddDonorGroupModal;