import chroma from "chroma-js";


const ColorScale = ({  color, slices }) => {

  return chroma
  .scale(['white', color, '#212529'])
  .mode("lrgb")
  .colors(slices);

};


ColorScale.defaultProps = {
  slices:11,
};
export default ColorScale;
