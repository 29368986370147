import React, { useState, useEffect, Fragment } from 'react';
import Flex from '../common/Flex'
import { gql, useMutation,   } from '@apollo/client';
import ErrorMessage from '../common/ErrorMessage';
import CloudinaryModal from '../cloudinary/CloudinaryModal';
import ButtonIcon from '../common/ButtonIcon';
import Logo from '../common/Logo';
import Example from '../../assets/images/illustration/icon-examples.png'
import { Form,  FormGroup, Input,  Spinner ,Row,Col,Card,CardBody,CardTitle,Button } from 'reactstrap';

const General = ({  theme,refetch }) => {
 // const [charityName, setCharityName] = useState('charity Name')
  const [appName, setAppName] = useState('App Name')
  const [iconLogo, setIconLogo] = useState('')
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const UPDATE = gql`
  mutation UpdateTheme($input:ThemeInput!){
    updateTheme(input:$input)
  }
`;
const UPDATE_APP_NAME = gql`
mutation UpdateAppName($appName:String!){
  updateAppName(appName:$appName)
}
`;
const insertPublicId = (publicId) => {
  setSelectedId(publicId)
  setModal(false)
}
useEffect(() => {
  if(theme?.charityName){
    setAppName(theme?.charityName)
    setIconLogo(theme?.iconLogo)
  }
}, [theme])
// useEffect(() => {
//   if(config.appName){
//     setAppName(config.appName)
//   }
// }, [config])

const handleLogoUpate = e => {
  e.preventDefault();
  updateLogo().then( async () => {
    await refetch()
    await setSelectedId(null)
  }).catch(e => {})
};
const handleAppNameUpdate = e => {
  e.preventDefault();
  updateAppName().then( () => {
    refetch()
    //refetchConfig()
  }).catch(e => {})
};
  const [updateLogo, updateLogoResponse] = useMutation(UPDATE,  {variables:{input:{iconLogo:selectedId}}})
  const [updateAppName, {error,loading}] = useMutation(UPDATE_APP_NAME,  {variables:{appName:appName}})

  return (
    <Fragment>  
      <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertPublicId} />

      <p>Your organization's name and icon are used throughout your campaign and email notifications. They will always be displayed next to each other, creating a fool-proof and legible brand identity for your campaign.</p>

      <Row>
        <Col lg={6}>
        <h5 className='text-primary mb-2'>Organization name:</h5>
  
        <Flex wrap align='center' className=''>

        <FormGroup className='mr-2'>
        <Input
          value={appName}
          onChange={({ target }) => setAppName(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        <ButtonIcon
        onClick={handleAppNameUpdate}
              disabled={loading}
              hideIcon={loading}
            >
              {(!loading ) && "Update name"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
      </Flex>
      
      
      <h5 className='text-primary mb-2'>Organization icon:</h5>
      <Flex align='center'>
        <div className='mr-2'>
          <Logo at='preview' publicId={selectedId?selectedId:iconLogo} icon />
        </div>
     
          
            <ButtonIcon
            onClick={handleLogoUpate}
                  disabled={updateLogoResponse.loading || selectedId === null}
                  hideIcon={updateLogoResponse.loading}
                >
                  {(!updateLogoResponse.loading ) && "Update"}
                  {updateLogoResponse.loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
                <Button className='p-2'color='link' onClick={() => {setModal(true)}}>Change Icon</Button>
</Flex>
              {updateLogoResponse.error &&<ErrorMessage error={updateLogoResponse.error}/>}
<p className=' fs--1 text-muted border-bottom pb-3 mt-2'>Please upload a JPG or PNG. For best results, upload an icon without any text, and square in size.</p>
<p className=' fs--1 text-muted'>Icon examples:</p>

<img style={{maxWidth:'300px'}} className='mb-3'src={Example} alt=""/>
        </Col>
        <Col lg={6}>          
        <Card className='p-2 border border-primary'>
            <CardTitle>Preview:</CardTitle>
            <CardBody >
           
          <Logo at='preview' publicId={selectedId?selectedId:iconLogo} text={appName} />
            </CardBody>
          </Card>
          
        </Col>
      </Row>
    <Form >
    
    </Form>
</Fragment>


  );
};


export default General;
