import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error }) => {
  return (
    <p className="error-message">{error.message}</p>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object.isRequired,
};

ErrorMessage.defaultProps = {
};

export default ErrorMessage;
