import React, { useState,  useEffect } from 'react';
import { Button, Spinner } from 'reactstrap';
import { gql, useMutation  } from '@apollo/client';
import Flex from '../common/Flex';
import ButtonIcon from '../common/ButtonIcon';
const UploadFile = ({insert}) => {
  const UPLOAD = gql`
  mutation uploadImageCharityUser($image:String!){
    uploadImageCharityUser(image:$image)
  }
  `;
  const fileSizeLimit = 10000000
  const [fileSizeError, setFileSizeError] = useState(false)
  const [previewSource, setPreviewSource] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [upload, {loading, data, error}] = useMutation(UPLOAD,{variables:{image:previewSource}})

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);

  }
  const previewFile = (file) => {
    if(file.size > fileSizeLimit){
      setFileSizeError(true)
    }
    else{
      setFileSizeError(false)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewSource(reader.result)
      }
    }
  }
  const handleSubmitFile =(e) => {
    e.preventDefault()
    if(!previewSource) return;
    upload().then( () => {
      setPreviewSource('')
     // onUploaded()
    }).catch(e => console.error(e))
  }
  const chooseDifferentFile =(e) => {
    e.preventDefault()
    setPreviewSource('')
  }
  useEffect(() => {
    if(data){
      console.log(data)
     // setSelectedId(data.uploadImageCharityUser)
      insert(data.uploadImageCharityUser)
    }
  }, [data])


  return (
    <div>
      <form onSubmit={handleSubmitFile}>
        {!previewSource && 
          <div className='mt-3 p-2'>
            <p>Click “Choose File” to upload an image.</p>
            <p>Maximum upload file size: 10 MB</p>    
            <input type='file' name='image' onChange={handleFileInputChange} value={selectedFile} className='form-input'></input> 
            {fileSizeError && <p className='text-red'>File too large, maximum limit (10 MB) exceeded</p>  }  
          </div>    
        }
          {previewSource && 
          <div className='mt-3 p-2'>
          <img className='mt-2 img-preview' src={previewSource} alt='chosen' style={{maxHeight:"200px",maxWidth:"200px"}}/>
          
          <p className='mt-3'>Upload and insert this image or select a different file</p>
          <Flex wrap className='mt-3'><ButtonIcon
            type="submit"
            disabled={loading}
            className="px-5 mr-2 mb-2"
            iconAlign="right"
            transform="down-1 shrink-4"
            hideIcon={loading}
          >
            {(!loading ) && "Upload and Insert"}
            {loading && <Spinner  size={"sm"}/>}
          </ButtonIcon>

          <Button onClick={chooseDifferentFile} className=' px-5 mb-2'>Choose a different file</Button>
          </Flex>
        </div>
        }
      </form>
    </div>
  );
}

export default UploadFile;