const initialState = {
    active: null,
    email: null,
    firstName:null,
    lastName:null,
    inactiveReason:null,
    requirements:[],
    setupStep:null,
    dailyMax:null,
    dailyMaxAmount:null,
    multiplier:null,
    transferDay:null,
    wholeDollarRoundup:null,
    setupPassword:null
};

const userReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "UPDATE_USER": 
            return {
                ...state,
                ...payload
            }
        case "LOG_OUT":
            return {
                ...initialState
            }
        default:
            return state
    }
}

export default userReducer