import React from 'react';
// import ForgetPasswordForm from './forms/ForgetPasswordForm';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout'
// import ReactGA from 'react-ga';
import CharityChangePasswordForm from '../forms/CharityChangePasswordForm';

const CharityChangePassword = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  return (
    <AuthBasicLayout>
    <div className="text-center">
      <h5 className="mb-3"> Change your password</h5>
      <CharityChangePasswordForm />
    </div>
    </AuthBasicLayout>
  );
};

export default CharityChangePassword;
