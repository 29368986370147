import React from 'react';
import {  Card,  Badge } from 'reactstrap';
import Flex from '../common/Flex';
import BadgePending from '../tables/cell-renderings/BadgePending';
import BadgeSucceeded from '../tables/cell-renderings/BadgeSucceeded';
import moment from 'moment'
import {useSelector} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Summary  = ({donation}) => {
  const {lighter1} = useSelector((state) => state.theme.colors)
  return (

      <Card className='shadow-none p-2 mb-3'>
        <Flex align='center'>
            <h3 className='mb-0 mr-2'>${donation.amount?donation.amount.toFixed(2):""}</h3>
          <Badge className='mr-2 p-2' color='yellow'>USD</Badge>
          {!donation.processed
          ?<BadgePending/>
          :<BadgeSucceeded/>}
        </Flex>
        <hr/>
        <Flex wrap>
          <div className='border-right pr-3 mr-3'>
            <p className='mb-0'>Date</p>
            <p className='text-muted'>{ moment(donation.date).local().format("MMM D, YYYY")}</p>
          </div>
          <div className='border-right pr-3 mr-3 '>
            <p className='mb-0'>Merchant</p>
            <p className='mb-0 text-muted'>{donation.transaction?donation.transaction.name:donation.pendingTransaction?donation.pendingTransaction.name:""}</p>
          </div>
          <div>
            {donation.plaidAccount && <p className='mb-0'>{donation.plaidAccount.plaidItem.name}</p>}
          <Flex align='center'>
            <FontAwesomeIcon icon="credit-card" className='mr-3' color={lighter1} size='1x'/>
            {donation.plaidAccount && <p className='mb-0 text-muted'>•••• {donation.plaidAccount.mask}</p> }
          </Flex>

          </div>
        </Flex>
      </Card>
  );
};

export default Summary;
