import React, { useState, useEffect, Fragment } from 'react';
import Slider from 'rc-slider';
import {useSelector} from 'react-redux'
import 'rc-slider/assets/index.css';
import Flex from '../common/Flex';
import { gql, useMutation } from '@apollo/client';
import { Button, Spinner } from 'reactstrap';
import {useDispatch} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MFA_CHECK = gql`
mutation UpdateDailyMax($dailyMax:Boolean!,$dailyMaxAmount:Float){
  updateDailyMax(dailyMax:$dailyMax,dailyMaxAmount:$dailyMaxAmount){
    dailyMax
    dailyMaxAmount
    transferDay
    wholeDollarRoundup
  }
}
`;
const DailyMax  = () => {
    const {dailyMax, dailyMaxAmount} = useSelector((state) => state.user)
    const {primary, lighter2, darker2} = useSelector((state) => state.theme.colors)
    const [value, setValue] = useState(dailyMaxAmount)
    const [none, setNone] = useState(true)
  const dispatch = useDispatch()
  const [change, setChange] = useState(false)  
    const [update,{data,loading}] = useMutation(MFA_CHECK, {
      variables:{dailyMax:none,dailyMaxAmount:value}
    })
    const submit = () => {
      update().catch(e => {})
    }
    const style = { maxWidth:150, minWidth:120, marginRight:20};
    function log(value) {
      console.log(value)
        setValue(value);
        if(value===5){
          setNone(false);
           }else{
            setNone(true);
           }
    }
    useEffect(() => {

      if(value !== dailyMaxAmount && none !== dailyMax ){
        setChange(true)
      }else if(none === dailyMax && value === dailyMaxAmount ){
        setChange(false)
      }else{
        setChange(true)
      }

    }, [value, none])

    useEffect(() => {
      if(data){
        setChange(false)
        dispatch({
          type:"UPDATE_USER",
          payload:{
            ...data.updateDailyMax
          }
        })
      }
    }, [data])

    // useEffect(() => {
    //   if(value===5){
    //  //   setNone(true)
    //   }else{
    //     console.log(value)
    //     setNone(false)
    //   }
    // }, [value])
    useEffect(() => {
      if(!dailyMax){
        setValue(5);
        setNone(dailyMax)
      }
    }, [dailyMax])
    
  return (
    <Fragment>
      <Flex wrap align='center'>
      <p className='m-0 mr-3 font-weight-semi-bold'  >Daily Max:</p>
      <Flex className='my-2' align='center'>

        <Slider 
            style={style}
            onChange={log} 
            step={0.01}
            min={0.5}
            max={5.00}
            defaultValue={value}
            handleStyle={{borderColor: darker2,backgroundColor:darker2,} }
            railStyle={{backgroundColor:lighter2,} }
            trackStyle={{backgroundColor:primary,} }
        />
        {none 
        ?<p className='mb-0 mr-4' >${value?value.toFixed(2):''}</p>
        :<p className='mb-0 mr-4' >No_Max</p>
        }

      </Flex>
      <div 
          className=''>      
          {/* <Button
              color={!none?"outline-muted" :"outline-primary"}
              className="px-3 mr-3"
              disabled={!none}
              size='sm'
              onClick={()=>setNone(false)}>
              No Max
            </Button>
               */}

    

      </div>
      <Flex align='center' className='ml-auto'>
      <Button
              disabled={!change}
              // disabled={loading || !isNum}
              color={!change?"outline-muted" :"outline-green"}
              className="px-3"
              size='sm'
              onClick={submit}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Save changes"}
              {loading && <Spinner  size={"sm"}/>}
            </Button> 
            
            {data && !change && <FontAwesomeIcon icon='check' color='green' className='ml-2'></FontAwesomeIcon>}</Flex>
      </Flex>

            <p className='fs--1'>The daily max determines how much can be donated on a single day. You can choose to have no maximum by sliding the slider all the way to the right.</p>

    </Fragment>
       );
};

export default DailyMax;
