const initialState = {
    loading: null,
    message:null,
};

const processReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "UPDATE_PROCESS":
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default processReducer