import React from 'react';
import Flex from '../../common/Flex';
import {useSelector} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const SummaryOfCard  = ({card}) => {

  const {primary} = useSelector((state) => state.theme.colors)

  return (

 <div className='p-2'>
   <Flex align='center'>     
      <FontAwesomeIcon icon="credit-card" className='mr-3' color={primary} size='3x'/>
      <Flex column>
        <p className="m-0 fs--1 font-weight-semi-bold text-primary">{card.plaidItem.name}</p>
        <p className="m-0 fs--1" >{card.official_name}</p>
        <p className="m-0 fs--1">•••• {card.mask}</p>
      </Flex>
   </Flex>
 </div>
  );
};

export default SummaryOfCard;
