import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterStandard from '../footer/FooterStandard'
import Banner from '../common/Banner';
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';
import TermsConentent from '../Terms/TermsContent';
// import ReactGA from 'react-ga';
import Section from '../common/Section';


const Terms = ({ location ,match: { url } }) => {
  // ReactGA.pageview(url);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      <TransparencyLandingNav/>
      <Banner title={"Terms & Conditions"} bg="gradient" textColor="white" className='py-7'/>
      <Section>

      <TermsConentent/>
      </Section>
      <FooterStandard/>
    </Fragment>
  );
};

Terms.propTypes = { location: PropTypes.object.isRequired };

export default Terms;
