import React, { useState, useEffect, Fragment } from 'react';
import { Button,  CardBody, CardFooter,  Form, FormGroup, Input,  } from 'reactstrap';
import ButtonIcon from '../../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import { gql, useMutation } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import { useForm } from "react-hook-form";
import {useDispatch} from 'react-redux'
const OTP_CHECK = gql`
mutation verifyOTP($OTP:Int!){
  verifyOTP(OTP:$OTP)
}
`;
const RESEND_OTP = gql`
mutation sendOTP{
  sendOTP
}
`;
const VerifyEmail = ({email}) => {
  const {  handleSubmit,  } = useForm();
  
  const [code, setCode] = useState('');
  const [isNum, setIsNum] = useState(true);
  const dispatch = useDispatch()
  const [otpCheck,{data,loading,error}] = useMutation(OTP_CHECK, {
    variables:{OTP:Number(code)}
  })
  const [resendMfa] = useMutation(RESEND_OTP)
  const submit = () => {
    otpCheck().catch(e => {})
  }
  useEffect(() => {
    if(data){
      
      dispatch({
        type:"UPDATE_GET_STARTED",
        payload:{
          step:1,
          mfa:false
        }
      })
    }
  }, [data])

  useEffect(() => {
    isNaN(Number(code))?setIsNum(false):setIsNum(true)
    if(code.length===6 && isNum){
      submit()
    }
  }, [code])

  return (
    <Fragment>
    <Form onSubmit={handleSubmit(submit)}>
      <CardBody className="fs--1 font-weight-normal px-md-6 p-5 text-center">
        <h5>Verify your email address</h5>
        <p>We've send a 6-digit code to {email}. Please enter the code below.</p>
      <FormGroup>
        <Input
          placeholder={'code'}
          value={code}
          onChange={({ target }) => setCode(target.value)}
          type="number"
        />
      </FormGroup>
        <p>This code will expire in 30 minutes. Resend 6-digit code</p>
        <Button onClick={resendMfa} >Resend Code</Button>
      </CardBody>
      <CardFooter className={' bg-light d-flex'}>
        
  {error &&<ErrorMessage error={error}/>}
  {!isNum && <p className="error-message">You must enter a number</p>}
            <ButtonIcon
                  disabled={loading || !isNum}
                  color="primary"
                  className="ml-auto px-5"
                  icon={false ? 'chevron-left' : 'chevron-right'}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  hideIcon={loading}
                >
                  {(!loading ) && "Next"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
          </CardFooter>
          </Form>
      </Fragment>
  );
};

export default VerifyEmail;
