import React, { Fragment, useEffect } from 'react';
import { CardTitle, Card, CardBody, Row, Col, Spinner, CardText, CardSubtitle, CardHeader, Badge } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
const DonationStatusBadge  = ({pending, className,transferring, processed,status}) => {

  const showText = false
  return (
    <Fragment>

    {status==='pending' && 
    
    <div className={'px-0 ' +className } color=''>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='mx-1' icon={faProductHunt} size='sm' color='text-muted'></FontAwesomeIcon>
      </Flex>
    </div>
    }

    {status==='transferQueue' && 
    
    <Badge className={'p-1 ' +className } color='soft-yellow'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'clock'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }

    {status==='transferring' && 
    
    <Badge className={'p-1 ' +className } color='soft-blue'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'exchange-alt'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }

{status==='processed' && 
    
    <Badge className={'p-1 ' +className } color='soft-green'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className=''icon={'check'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {status==='restored' && 
    <div className={'p-1 ' +className }>
    <FontAwesomeIcon color='black'  size='sm' icon={faExclamationTriangle}></FontAwesomeIcon>

    </div>
    // <Badge className={'p-1 ' +className } color='soft-orange'>
    //   <Flex>
    //     {showText && <p className='m-0'>pending</p>}
    //     <FontAwesomeIcon className='' icon={faExclamationTriangle}></FontAwesomeIcon>
    //   </Flex>
    // </Badge>
    }
    {/* {pending && 
    
    <Badge className={'px-2 ' +className } color='soft-yellow'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'clock'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    
    {(!pending && !transferring && !processed )&& 
    
    <Badge className={'px-2 ' +className } color='soft-yellow'>
      <Flex>
        {showText && <p className='m-0'>pending</p>}
        <FontAwesomeIcon className='' icon={'clock'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {transferring && 
    <Badge className={'px-2 ' +className } color='soft-blue'>
      <Flex>
        {showText && <p className='m-0'>transfer</p>}
          <FontAwesomeIcon className='' icon={'exchange-alt'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    }
    {processed && 
    <Badge className={'px-2 ' +className } color='soft-green'>
      <Flex>
        {showText && <p className='m-0'>donated</p>}
          <FontAwesomeIcon className='' icon={'check'}></FontAwesomeIcon>
      </Flex>
    </Badge>
    } */}

    </Fragment>
  );
};

export default DonationStatusBadge;
