import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Collapse, ListGroupItem } from 'reactstrap';
const FAQItem = ({faq}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
      <ListGroupItem  className='faq-group'>
        <div onClick={toggle} active={false} className={isOpen? 'faq-question active':'faq-question'}>
          <h5>

          <span><FontAwesomeIcon icon={isOpen?'caret-up':'caret-down'} className='mr-4' size='xs'/></span>
          {faq.question}
          </h5>
        </div>
        <Collapse isOpen={isOpen}>
            <div  className='faq-answer'>
              <p className='mb-0'>{faq.answer}</p>
            </div>
          </Collapse>
        {/* <Card rounded>
          <CardHeader onClick={toggle} active={false} className={isOpen? 'faq-question active':'faq-question'} >{faq.question}</CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody  className='faq-answer'>{faq.answer}</CardBody>
          </Collapse>
        </Card> */}
      </ListGroupItem>

  );
};

export default FAQItem;
