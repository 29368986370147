import React from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Section from '../common/Section';
import HeroImage from '../../assets/images/svgr/HeroImage';
import DonorQuickSignupForm from '../auth/forms/DonorQuickSignupForm';
import { logout } from '../../functions/auth';
const HeroAlt = () => {
  const {charityName, heroTitle,heroText,heroSubtitle, iconLogo, primary, secondary, heroImage} = useSelector((state) => state.theme)
  const {loggedIn,role} = useSelector((state) => state.auth)
;
  const {email} = useSelector((state) => state.user)

  return (
<Section bg='gray' className=' mt-xl-4  mt-5 mb-3 pb-3 '>
<Row className="flex-center text-md-left text-center" style={{ marginBottom: '-50px' }}>
            <Col  md lg={5}>
            <h1 className='fs-4  mb-3 '> Round-Up and donate to <span  className='text-primary'>{charityName}</span></h1>
            {/* {!heroTitle &&             <h1 className='fs-4  mb-3 '> Roundup for <span  className='text-primary'>{charityName}</span> with every purchase</h1>} */}
            {/* {heroTitle && <h1 className='fs-4  mb-3 '> {heroTitle}</h1>} */}
            {/* {heroSubtitle && <p className='fs-1  font-weight-medium'>{heroSubtitle}</p>} */}
            {/* <p className='fs-1'>{heroText}</p> */}
              <p className='fs-0'>Round-Up your purchases to the next dollar by linking your credit/debit card.</p>
      {!loggedIn && 
          <Card className='p-3  border bg-light'>
                <p className='text-gray'>Enter your information:</p>
                    <DonorQuickSignupForm/>
                    <p className='mb-0 mt-2 fs--1'>Already have an account? <span><Link  to="/authentication/login">Login</Link></span></p>
              </Card>  
      }
      {loggedIn && role === 'DONOR' &&
          <Card className='p-3  border bg-light'>
            <p>You're logged in as {email}</p>
            <Link className={'round py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/dashboard">Go To Dashboard</Link>
            {/* <Button onClick={goToDashboard}>Go to Dashboard</Button> */}
            <Button color='link' size='sm' onClick={logout}>Logout</Button>
            </Card>
      
      }
           {/* <Link className={'round py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/get-started">Get Started</Link> */}
            </Col>
            <Col md lg={7} xl={6}>
              <div className='p-md-2 p-4 mb-md-0 '>
              <HeroImage iconLogo={iconLogo}  publicid={heroImage} secondary={secondary} primary={primary}/>

              </div>
            </Col>
          </Row >
</Section>
  );
}; 

HeroAlt.propTypes = {
};


export default HeroAlt;
