import React from 'react';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout';
// import ReactGA from 'react-ga';
import AdminLoginForm from '../forms/AdminLoginForm';

const AdminLogin= ({ match: { url } }) => {
  // ReactGA.pageview(url); 
  return (
    <AuthBasicLayout>
      <h4 className='mb-3'>Admin Login</h4>
      <AdminLoginForm layout="card" hasLabel />
    </AuthBasicLayout>
  );
};

export default AdminLogin;
