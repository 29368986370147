import React, { useState, useEffect, Fragment } from 'react';
import {useSelector} from 'react-redux'
import 'rc-slider/assets/index.css';
import { ButtonToolbar, ButtonGroup, Button, Spinner, Badge } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import {useDispatch} from 'react-redux'
import Flex from '../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const UPDATE = gql`
mutation updateWholeDollarRoundup($active:Boolean){
  updateWholeDollarRoundup(active:$active){
    wholeDollarRoundup
  }
}
`;
const WholeDollarRoundups  = () => {
    const {wholeDollarRoundup} = useSelector((state) => state.user)
  // const [change, setChange] = useState(false)
  const dispatch = useDispatch()
  // const [value, setValue] = useState(multiplier)
  //   useEffect(() => {
  //     if(value === multiplier){
  //       setChange(false)
  //     }else{
  //       setChange(true)
  //     }
  //   }, [value])

    const [update,{data,loading,error}] = useMutation(UPDATE, {
      variables:{active:!wholeDollarRoundup}
    })
  //   const submit = () => {
  //     update().catch(e => {})
  //   }


    useEffect(() => {
      if(data){
        dispatch({
          type:"UPDATE_USER",
          payload:{
            ...data.updateWholeDollarRoundup
          }
        })
      }
    }, [data])
  return (
      <Fragment>
        <Flex align='center' wrap className='mb-2'>
        <p className='m-0 mr-2 font-weight-semi-bold'>Whole Dollar Roundups:</p>
        <Badge   className='px-2' color={wholeDollarRoundup?'soft-green':'soft-red'}>{wholeDollarRoundup?'Active':'Inactive'}</Badge>

      {!loading && <Button className='ml-auto' color={wholeDollarRoundup?'outline-red':'outline-green'} size='sm' onClick={update} disabled={loading}>
        {wholeDollarRoundup?'Deactivate':'Activate'}
      </Button>}
     {loading && <Spinner className='ml-auto' size='sm' color={wholeDollarRoundup?'red':'green'}></Spinner>}


            {/* {data && !change && <FontAwesomeIcon icon='check' color='green' className='ml-2'></FontAwesomeIcon>} */}
  </Flex>
  <p className='fs--1'>If active, a whole dollar purchase (for example $12.00) will create a $1.00 donation.</p>

      </Fragment>

  );
};

export default WholeDollarRoundups;
