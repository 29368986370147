import React, { Fragment, useState,useEffect } from 'react';
import {  Button, CardBody,   CardTitle } from 'reactstrap';
import {useSelector} from 'react-redux'
import { gql, useQuery,useMutation } from '@apollo/client';
import Loader from '../../common/Loader'
import Flex from '../../common/Flex';
import { useHistory } from "react-router-dom";
import {useDispatch} from 'react-redux'
import ActivateCreditCards from './ActivateCreditCards';
import ErrorMessage from '../../common/ErrorMessage';

const AVAILABLE_ACCOUNTS = gql`
query AvailableAccounts($SCF_type: String!){
  availableAccounts(SCF_type:$SCF_type){
    _id
    mask
    name
    official_name
    item_id
    SCF_type
    active
    subtype
    plaidItem{
      _id
      name
      color
      logo
      loginRequired
    }
  }
}
`;

const WIZARD_STEP_2 = gql`
mutation WizardStep2{
  wizardStep2
}
`;


const Step2 = () => {
  const SCF_type = 'credit'
  const [accounts,setAccounts] = useState([]);
  const [submit,setSumbit] = useState(false);
  const [isRefetching,setIsRefetching] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch()
  const {appName} = useSelector((state) => state.theme)
  const {data,loading,refetch} = useQuery(AVAILABLE_ACCOUNTS, {
    variables:{SCF_type},    
    fetchPolicy: "no-cache"
  })
  
  const [submitWizardStep2,submitResponse] = useMutation(WIZARD_STEP_2)

  
  useEffect(() => {

    if (data){
      setAccounts(data.availableAccounts)
      setIsRefetching(false)
      // if(submit){
      //   submitWizardStep2().catch(e => {})
      // }
    }
  }, [data])
    
  const openPlaid = () => {
     dispatch({
      type:"ADD_NEW_CARD",
      payload: {
        redirectURL:window.location.href,
        product:["transactions"],  
      }
    })
    
    history.push('/plaid');
  }

  // const refetchAndSubmit = () => {
  //   //setSumbit(true)
  //   refetch()
  // }
    useEffect(() => {
    if(submitResponse.data){
      dispatch({
        type:"UPDATE_GET_STARTED",
        payload: {
          step: 2,
        }
      })
    }
  }, [submitResponse.data])

  return (
    <Fragment>
        {(loading || isRefetching )&& 
          <CardBody className="mt-3">
            <Loader/>
          </CardBody>} 
        {data && !isRefetching && accounts[0] &&
        
        <CardBody className="px-md-4  px-2">
          <ActivateCreditCards SCF_type={SCF_type} accounts={accounts} refetch={refetch} error={submitResponse.error}/>
            </CardBody>
        }
        {data && !accounts[0] && !isRefetching &&
          <CardBody className="px-md-4  px-2">
            {/* <CardTitle>Use your online account login to register a credit card</CardTitle> */}
            {/* <p>{appName} will monitor your credit card transactions and round them up to be donated. </p> */}
            <p>By connecting a debit or credit card, {appName} will round up your purchases to the next dollar. Login to your account through <span className='font-weight-semi-bold'>plaid</span> to connect a card.</p>
            <Flex justify='end' className='my-3'>
              <Button
                className="ml-auto px-5"
                color='primary'
                onClick={openPlaid}
              >
              Login to connect a card
              </Button>
            </Flex>
            </CardBody>
        }


        </Fragment>
  );
};

export default Step2;
