import React from 'react';
import styled from 'styled-components'
import moment from 'moment'
import TablePaginationAndExpanding from './react-table/TablePaginationAndExpanding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFolder,  faFolderOpen,} from "@fortawesome/free-solid-svg-icons";
import CharityUserSelector from '../admin/CharityUser/CharityUserSelector';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`

function SelectCharityUserTable({data,  selectedCharityUser}) {
  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded
                ?<FontAwesomeIcon icon={faFolderOpen} className="" size="md"/> 
                : <FontAwesomeIcon icon={faFolder} className="" size="md"/> }
          </span>
        ),
      },
          {
            Header: 'Charity Name',
            accessor: 'charityName',
          },
          {
            Header: 'EIN',
            accessor: 'EIN',
          },
          {
            id:'isVerified',    
            Header: 'Verified',
            accessor: d => d.isVerified.toString(),
          },
          {
            id:'approved',    
            Header: 'Approved',
            accessor: d => d.approved.toString(),
          },
          {
            id:'created',    
            Header: 'Created',
            accessor: d => {
              return moment(d.created)
                .local()
                .format("MM-DD-YYYY")
            }
          },
    ],
    []
  )

  //const data = React.useMemo(() => makeData(10), [])
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <CharityUserSelector selectedCharityUser={selectedCharityUser} charityUser={row.original} />
       // <EditAndApproveCharity sub={sub} charityUser={row.original}/>
    //   <pre
    //     style={{
    //       fontSize: '10px',
    //     }}
    //   >
    //     <code>{console.log(row.original)}</code>
    //   </pre>
    ),
    []
  )


  return (
    <Styles>
      <TablePaginationAndExpanding
        columns={columns}
        data={data}
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
        renderRowSubComponent={renderRowSubComponent}
      />
    </Styles>
  )
}

export default SelectCharityUserTable
