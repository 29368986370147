import React from 'react';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout'
import CharityResetPasswordForm from '../forms/CharityResetPasswordForm';

const CharityResetPassword = () => {
  return (
    <AuthBasicLayout>
    <div className="text-center">
      <h5 className="mb-3"> Reset your password</h5>
      <CharityResetPasswordForm />
    </div>
    </AuthBasicLayout>
  );
};

export default CharityResetPassword;
