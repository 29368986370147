import React, { Fragment } from 'react';
import { Card, CardBody, CardText,CardHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCheckSquare,} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from 'react-redux'
import Flex from '../common/Flex';

const InstantVerificationCard = ({setView}) => {
    const {appName} = useSelector((state) => state.config)
  return (
    <Fragment>
        <Card onClick={()=>setView("INSTANT")} className='card-select mb-3 shadow-none border'>
        <CardBody>
            <div className='p-0 pb-2'>
                <h5>
                    <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
                    Instant Verification
                </h5>
            </div>
            <CardText>Instantly verify your bank account with your online banking login.</CardText>
            
            {/* <Flex justify="end">
              <Button color="primary" onClick={()=>setView("INSTANT")}>Select</Button>
            </Flex> */}
        </CardBody>
        </Card>
        </Fragment>
  );
};

export default InstantVerificationCard;
