import React, { useState, useEffect,Fragment } from 'react';
import { Card, Badge, Button,ButtonGroup ,ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';
import Flex from '../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { gql, useMutation } from '@apollo/client';


const PayoutAccountOptions = ({account, refetch}) => {

  const SET_DEFAULT = gql`
  mutation setDefaultPayoutAccount($_id:String!){
    setDefaultPayoutAccount(_id:$_id){
        _id
        default
    }
  }
  `;
  const [setDefault,{data,loading, error}] = useMutation(SET_DEFAULT,{
    variables:{_id:account._id}
  })
  const [dropdown, setDropdown] = useState(false)
  const toggle =() => {
    setDropdown(!dropdown)
  }
  useEffect(() => {
    if(data){
      refetch()
    }
  }, [data])
  return (
    <Flex>
        {!loading && <ButtonGroup>
            <ButtonDropdown   direction="left"  isOpen={dropdown} toggle={toggle}>
                <DropdownToggle className='rounded-right border btn-white px-2 py1 shadow-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} color='gray' size='1x'/>
                </DropdownToggle>
                <DropdownMenu>
                    <p className='mb-0 pl-2 border-bottom pb-1 font-weight-semi-bold'>ACTIONS</p>
                    {/* {account.status!=='verified' && <DropdownItem onClick={verifyAccount} >Verify Account</DropdownItem>} */}
                    <DropdownItem disabled={account.default} onClick={setDefault}>Set as default</DropdownItem>
                    <DropdownItem disabled >Remove</DropdownItem>

                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>}
    </Flex>
  )
}



export default PayoutAccountOptions