import React, { Fragment, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import {useDispatch} from 'react-redux'
import { Button } from 'reactstrap';
import { Spinner } from 'reactstrap/lib';

const Deactivate  = () => {

  
const ACTIVATE = gql`
mutation SetUserProfileInactive{
  setUserProfileInactive{
    firstName
    lastName
    email 
    active
    inactiveReason
    requirements
    setupStep
    dailyMax
    dailyMaxAmount
    transferDay
    wholeDollarRoundup
  }
}
`;
const [mutate,{data, loading, error}] = useMutation(ACTIVATE)
const dispatch = useDispatch()

useEffect(() => {
  if(data){
    dispatch({
      type:"UPDATE_USER",
      payload: {
        ...data.setUserProfileInactive,
      }
    })
  }
}, [data])
  return (
<Fragment>
  
  {!loading && <Button className='ml-auto' size='sm' color='outline-red'
    onClick={mutate}
    error={error}
  >Deactivate</Button>}
  {loading &&
    <Spinner color='red' className='ml-auto' size={"sm"}/>
  }
</Fragment>
  );
};

export default Deactivate;
