import React, { Fragment, useEffect, useState } from 'react';
import { gql, useMutation,useQuery } from '@apollo/client';
import {  Row, Col,Card, Container } from 'reactstrap';
import Flex from '../common/Flex';
import {useSelector} from 'react-redux'
import Section from '../common/Section';
import NewPlaidItem from './linked-cards/NewPlaidItem';
import CloudScene from '../scene/CloudScene';
import ActiveCards from '../bankAccount/ActiveCards';
import {useDispatch} from 'react-redux'
import InactiveCards from '../bankAccount/InactiveCards';
import CardIllustration from '../../assets/images/svgr/CardIllustration';
import ActivateCreditCards from '../getStarted/ActivateCreditCards';
import ManageCards from './linked-cards/ManageCards';
import NoCardModal from './linked-cards/NoCardModal';
// import ReactGA from 'react-ga';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AVAILABLE_ACCOUNTS = gql`
query AvailableAccounts{
  availableAccounts(SCF_type:"credit"){
    _id
    mask
    name
    official_name
    SCF_type
    active
    subtype
    item_id
    plaidItem{
      _id
      name
      color
      logo
      loginRequired
    }
  }
  me{ 
    active
    requirements
  }
  isLoginRequired{
    required
    itemCount
    accountCount
  }
}
`;
// const CREATE_PLAID_ITEM = gql`
// mutation CreatePlaidItem($publicToken: String!){
//   createPlaidItem(publicToken:$publicToken){
//     item_id
//     name
//     color
//     logo
//   }
// }
// `;

const ACTIVATE_CREDIT_ACCOUNT = gql`
mutation ActivatePlaidCreditAccount($accountId: String!){
  activatePlaidCreditAccount(accountId:$accountId)
}
`;

const LinkedCards   = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const dispatch = useDispatch()
  const SCF_type = 'credit'
  // const [loading, setLoading] = useState(false);
  const [publicToken, setPublicToken] = useState(null);
  const [isRefetching,setIsRefetching] = useState(false);
  const [accounts,setAccounts] = useState([]);
  const {appName, primary} = useSelector((state) => state.theme)
  const [modal, setModal] = useState(false);
  const {active,requirements} = useSelector((state) => state.user)


  
  const {data,loading,refetch} = useQuery(AVAILABLE_ACCOUNTS, {
    variables:{SCF_type},    
    fetchPolicy: "no-cache"
  })
  // const [createPlaidItem] = useMutation(CREATE_PLAID_ITEM, {
  //   variables:{publicToken}
  // })
  // const linkSuccess = async ({token}) =>{
  //   setLoading(true)
  //   setPublicToken(token)
  //   createPlaidItem().then(()=>{
  //     availableAccountsData.refetch().catch(e=>{})
  //   }).catch(e=>{})
  // }

  
  // useEffect(() => {
  //   if(availableAccountsData.loading ){
  //     setLoading(true)
  //   }
  //   if (availableAccountsData.data){
  //     setAccounts(availableAccountsData.data.availableAccounts)
  //     setLoading(false)
  //   }
  // }, [availableAccountsData])
  useEffect(() => {
    if( requirements.includes('credit_card') ){
      setModal(true)
    }else{
      setModal(false)
    }
  }, [requirements])
    
  useEffect(() => {

    if (data){
      setAccounts(data.availableAccounts)
      dispatch({
        type:"UPDATE_USER",
        payload:{
          ...data.me
        }
      })
      setIsRefetching(false)
    }
  }, [data])




  return (
    <DonorDashboardLayout>
      <NoCardModal modal={modal} setModal={setModal}/>
      
    <CloudScene>
      <Section className=' h-100 pb-0'>
          <Flex wrap align='center' justify='around'className='h-100 ' >

          <div className='mt-6' style={{maxWidth:'400px'}}>

          <h1 className='text-black mb-4'>{"Linked Cards" }</h1>
          <h5 className='text-primary mb-4'>{'You can add more cards below:'}</h5>      
            </div>
          <CardIllustration  primary={primary} width='100%' className='scene-foreground  mt-8 '/>
          </Flex>  
      
      </Section>
    </CloudScene>
    {data?.isLoginRequired?.required &&   
    <div className='bg-yellow'>
        <Container>
          <Flex justify='center' align='center' className={'p-1'}>
            <FontAwesomeIcon className='mr-2' icon={faExclamationTriangle}/>
            <p className='mb-0'><b>{data.isLoginRequired.accountCount} card{data.isLoginRequired.accountCount===1?"":'s'} require reauthentication.</b> <span><Link className='text-black' to='/cards/restore'><u>Click Here</u></Link></span> to learn more.</p>
          </Flex>
        </Container>
      </div>}
    
    <Section className=' '>

     <Row noGutters className='justify-content-center'> 
     <Col  lg='8' md='10' sm='12'>
     
     <Card className='p-md-4 p-3 shadow-none w-100'>
       <ManageCards loading={loading} SCF_type={SCF_type}  accounts={accounts} refetch={refetch}/>        
     </Card>
     </Col>

      
     {/* <ActivateCreditCards SCF_type={SCF_type} linkSuccess={linkSuccess} accounts={accounts} refetch={availableAccountsData.refetch}/> */}

            {/* <Col md={8} >
 
          <NewPlaidItem/>
         <ActiveCards accounts={accounts} className='py-2'/>
         <InactiveCards accounts={accounts} className='py-2'/>
       </Col> */}
       </Row>
       </Section>
    </DonorDashboardLayout>
    
  // 
      
  );
};

export default LinkedCards;
