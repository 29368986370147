import React, { useEffect, Fragment, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch,  useLocation} from 'react-router-dom';
// import { gql, useQuery,useLazyQuery } from '@apollo/client';
// import loadable from '@loadable/component';
import ErrorLayout from './ErrorLayout';
// import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
// import TenantLoader from '../components/common/TenantLoader';
import OAuthPage from '../components/plaid/OAuthPage';
import PlaidPage from '../components/plaid/PlaidPage';
import PlaidUpdatePage from '../components/plaid/PlaidUpdatePage';
import LoadingOverlay from '../components/common/LoadingOverlay';
import HomeRedirect from '../components/page/HomeRedirect';

import PushkaInvite from '../components/page/PushkaInvite';
// import AuthRoutesAlt from '../components/auth/AuthRoutesAlt';
import Landing from '../components/page/Landing';
import PushkaLanding from '../components/page/PushkaLanding';
import Support from '../components/page/Support';
import Terms from '../components/page/Terms';
import Privacy from '../components/page/Privacy';
// import CharityRoutes from '../components/charity/CharityRoutes';
// import AdminRoutes from '../components/admin/AdminRoutes';
// import DashboardRoutesAlt from '../components/dashboard/DashboardRoutesAlt';
import GetStarted from '../components/getStarted/alt/GetStarted';
import PageLoader from '../components/common/PageLoader';
import Login from '../components/auth/Login';
import ForgetPassword from '../components/auth/ForgetPassword';
import ChangePassword from '../components/auth/ChangePassword';
import ResetPassword from '../components/auth/ResetPassword';
import CharityLogin from '../components/auth/charity/CharityLogin';
import CharitySignup from '../components/auth/charity/CharitySignup';
import PendingApproval from '../components/auth/charity/PendingApproval';
import CharityVerifyEmail from '../components/auth/charity/CharityVerifyEmail';
import CharityChangePassword from '../components/auth/charity/CharityChangePassword';
import CharityForgetPassword from '../components/auth/charity/CharityForgetPassword';
import CharityResetPassword from '../components/auth/charity/CharityResetPassword';
import AdminLogin from '../components/auth/admin/AdminLogin';
import Dashboard from '../components/charity/Dashboard';
import DashboardAlt from '../components/dashboard/DashboardAlt';
import Profile from '../components/dashboard/Profile';
import Charities from '../components/dashboard/Charities';
import LinkedCards from '../components/dashboard/LinkedCards';
import Payments from '../components/dashboard/Payments';
import SandboxData from '../components/dashboard/SandboxData';
import CharitySettings from '../components/charity/CharitySettings';
import PushkaCustomization from '../components/charity/PushkaCustomization';
import Customization from '../components/charity/Customization';
import CharityPayouts from '../components/charity/CharityPayouts';
import CharityDonors from '../components/charity/CharityDonors';
import CharityReports from '../components/charity/CharityReports';
import AdminDashboard from '../components/admin/AdminDashboard';
import ApproveNonprofit from '../components/admin/ApproveNonprofit';
import PayoutCharity from '../components/admin/PayoutCharity';
import CompleteSetup from '../components/auth/CompleteSetup';
import CharityDonorGroups from '../components/charity/CharityDonorGroups';
import DonorGroupsReport from '../components/charity/Reports/DonorGroupsReport';
import CharityReportsRoutes from '../components/charity/Reports/CharityReportsRoutes';
import NonprofitDetails from '../components/onboarding/NonprofitDetails'
import NonprofitOnboardingRoutes from '../components/onboarding/NonprofitOnboardingRoutes'

import Chatbot from '../components/zendesk/Chatbot'
import usePageViews from '../hooks/usePageViews';
import Misc from '../components/admin/Misc';
import SetupPassword from '../components/auth/SetupPassword';
import RestoreCards from '../components/dashboard/RestoreCards';
import RestoreDonations from '../components/dashboard/RestoreDonations';

const LayoutAlt = () => {
  const {primary,secondary} = useSelector((state) => state.theme)
  const {oneToMany,management,homeRedirect, pushka} = useSelector((state) => state.config)
  const charityUser = useSelector((state) => state.charityUser)
  const {authAvilable, role,loggedIn} = useSelector((state) => state.auth)
  const plaidState = useSelector((state) => state.plaid)
  const process = useSelector((state) => state.process)
  const {firstName, setupStep} = useSelector((state) => state.user)

  usePageViews();
  const LandingPage = (props) => {
    return(
      <Fragment>
        {pushka?<PushkaLanding {...props}/>:<Landing {...props}/>}
    </Fragment>
    )
  }

  const authenticationRoutes = [
    {
      path: "/authentication/login",
      component: Login,
      redirect:management===true ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/forgot-password",
      component: ForgetPassword,
      redirect:management===true ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/change-password",
      component: ChangePassword,
      redirect:management===true ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/reset-password",
      component: ResetPassword,
      redirect:management===true ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/setup-password",
      component: SetupPassword,
      redirect:management===true ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/complete-setup",
      component: CompleteSetup,
      // redirect:management===true ,
      // redirectTo:'/errors/404'
    },
    {
      path: "/authentication/nonprofit/login",
      component: CharityLogin,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/nonprofit/signup",
      component: CharitySignup,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/nonprofit/pending-approval",
      component: PendingApproval,
      loading:!authAvilable,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) || !loggedIn,
      redirectTo:!loggedIn ?'/authentication/nonprofit/login' :'/errors/404'
    },
    {
      path: "/authentication/nonprofit/verify-email",
      component: CharityVerifyEmail,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/nonprofit/change-password",
      component: CharityChangePassword,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/nonprofit/forgot-password",
      component: CharityForgetPassword,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/nonprofit/reset-password",
      component: CharityResetPassword,
      redirect: (oneToMany!==null && oneToMany===false) && (management!==null && management===false) ,
      redirectTo:'/errors/404'
    },
    {
      path: "/authentication/admin/login",
      component: AdminLogin,
      redirect:management!==null && management===false,
      redirectTo:'/errors/404'
    },
  ]

  const onboardingRoutes = [
    {
      path: "/onboarding",
      component: NonprofitOnboardingRoutes,
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :!charityUser.isVerified?"/authentication/nonprofit/verify-email" : "/authentication/nonprofit/login"
   
    },

  ]
  
  const nonprofitRoutes = [
 
    {
      path: "/nonprofit/dashboard",
      component: Dashboard,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false || charityUser.onboardingComplete === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :charityUser.isVerified === false?"/authentication/nonprofit/verify-email":"/onboarding"
    },
    {
      path: "/nonprofit/settings",
      component: CharitySettings,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :"/authentication/nonprofit/verify-email"
       },
    {
      path: "/nonprofit/theme",
      component: pushka? PushkaCustomization:Customization,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :"/authentication/nonprofit/verify-email"
    },
    {
      path: "/nonprofit/payouts",
      component: CharityPayouts,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :"/authentication/nonprofit/verify-email"
    },
    {
      path: "/nonprofit/donors/groups",
      component: CharityDonorGroups,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :"/authentication/nonprofit/verify-email"
    },
    {
      path: "/nonprofit/donors",
      component: CharityDonors,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :"/authentication/nonprofit/verify-email"
    },
    {
      path: "/nonprofit/reports",
      component: CharityReportsRoutes,
      loading:!authAvilable || (role==='CHARITY' && !charityUser.email),
      redirect: authAvilable &&  (role !== 'CHARITY' || charityUser.isVerified === false),
      redirectTo: role!=='CHARITY'?'/authentication/nonprofit/login' :"/authentication/nonprofit/verify-email"
    },
  ]

  const donorDashboardRoutes = [
    {
      path: "/dashboard",
      component: DashboardAlt,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :'/authentication/login'
    },
    {
      path: "/settings",
      component: Profile,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :'/authentication/login'
    },
    {
      path: "/my-causes",
      component: Charities,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || (oneToMany!==null && !oneToMany) || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :oneToMany?"/authentication/login":'/errors/404'
    },
    {
      path: "/cards",
      exact:true,
      component: LinkedCards,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :'/authentication/login'
    },
    {
      path: "/cards/restore",
      component: RestoreCards,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :'/authentication/login'
    },
    {
      path: "/donations",
      exact:true,
      component: Payments,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :'/authentication/login'
    },
    {
      path: "/donations/restore",
      component: RestoreDonations,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect: (authAvilable &&  role !== 'DONOR') || setupStep < 3,
      redirectTo:setupStep !== null && setupStep  < 3?'/authentication/complete-setup' :'/authentication/login'
    },
    {
      path: "/sandbox-data",
      component: SandboxData,
      loading:!authAvilable || (role==='DONOR' && !firstName),
      redirect:false,
      redirectTo:'/errors/404'
    },
  ]

  const adminRoutes = [
    {
      path: "/admin/dashboard",
      component: AdminDashboard,
      loading:!authAvilable || management===null,
      redirect: (authAvilable &&  role !== 'ADMIN') || !management,
      redirectTo:'/authentication/admin/login'
    },
    {
      path: "/admin/approve-nonprofit",
      component: ApproveNonprofit,
      loading:!authAvilable || management===null,
      redirect: (authAvilable &&  role !== 'ADMIN') || !management,
      redirectTo:'/authentication/admin/login'
    },
    {
      path: "/admin/payout-nonprofit",
      component: PayoutCharity,
      loading:!authAvilable || management===null,
      redirect: (authAvilable &&  role !== 'ADMIN') || !management,
      redirectTo:'/authentication/admin/login'
    },
    {
      path: "/admin/misc",
      component: Misc,
      loading:!authAvilable || management===null,
      redirect: (authAvilable &&  role !== 'ADMIN') || !management,
      redirectTo:'/authentication/admin/login'
    },
  ]

  const routes = [
    {
      path: "/",
      loading:oneToMany===null,
      component: homeRedirect===null?LandingPage:HomeRedirect,
      exact:true,
    },
    ...authenticationRoutes,
    ...onboardingRoutes,
    {
      path: "/get-started",
      component: GetStarted,
      exact:true,
    },
    {
      path: "/invite",
      component: PushkaInvite,
      loading:oneToMany===null,
      redirect:oneToMany!==null && oneToMany===false ,
      redirectTo:'/errors/404'
    },
    {
      path: "/support",
      component: Support,
    },
    {
      path: "/terms-and-conditions",
      component: Terms,
    },
    {
      path: "/privacy-policy",
      component: Privacy,
    },
    ...nonprofitRoutes,
    ...adminRoutes,
    {
      path: "/plaid-oauth",
      // loading:!plaidState.initialized,
      component: OAuthPage,
    },
    {
      path: "/plaid",
      // loading:!plaidState.initialized,
      component: PlaidPage,
    },
    {
      path: "/plaid-update",
      // loading:!plaidState.initialized,
      component: PlaidUpdatePage,
    },
    ...donorDashboardRoutes,
    {
      path: "/errors",
      component: ErrorLayout,
    },
  ];
  
  return (
    <Fragment>
      {process.loading && <LoadingOverlay message={process.message}/>}
        {/* <Router fallback={<span />}>
          <Switch> */}
            {routes.map((route,i)=>(
                <Route key={route.path} exact={route.exact} path={route.path} component={route.loading?PageLoader:route.component} >
                    {route.redirect && !route.loading && <Redirect to={route.redirectTo} />}
                </Route>
            ))}
            {/* <Redirect to="/errors/404" /> */}
          {/* </Switch>
        </Router> */}
      
    </Fragment>
  );
};

export default LayoutAlt;
