import React, {  useEffect, useState } from 'react';
import {  Form, FormGroup, Input,  Label,  Spinner  } from 'reactstrap';
import { gql, useMutation,   } from '@apollo/client';
import PropTypes from 'prop-types';
import ErrorMessage from '../common/ErrorMessage';
import ButtonIcon from '../common/ButtonIcon';


const ApproveNonprofitForm  = ({charityUser, hasLabel,  }) => {  
    const [charityName, setCharityName] = useState(charityUser.charityName)
    const [email, setEmail] = useState(charityUser.email)
    const [_id, set_id] = useState(charityUser._id)
    const [EIN, setEIN] = useState(charityUser.EIN)
    const [tenantId, setTenantId] = useState('')
    const [demo, setDemo] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true);
    



    const APPROVE_NONPROFIT = gql`
    mutation approveNonprofit($_id:String!,$subdomain:String!,$EIN:String,$charityName:String,$demo:Boolean){
    approveNonprofit(_id:$_id,subdomain:$subdomain,EIN:$EIN,charityName:$charityName,demo:$demo){ 
        firstName
        lastName
        email 
        EIN
        isVerified
        approved
        created
      }
    }
    `;    
  useEffect(() => {
    setIsDisabled(!tenantId);
  }, [tenantId]);
  
  const [approve, {loading,error}] = useMutation(APPROVE_NONPROFIT,{
    variables:{_id:_id,email:email,EIN:EIN,charityName:charityName,subdomain:tenantId,demo:demo}
  })

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    
    approve().then( () => {
    }).catch(e => {})

  };
    const handleDemoChange = (event) => {
      setDemo(event.target.checked)
  }


  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Charity Name</Label>}
        <Input
          placeholder={!hasLabel ? 'Charity Name' : ''}
          value={charityName}
          onChange={({ target }) => setCharityName(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>EIN</Label>}
        <Input
          placeholder={!hasLabel ? 'EIN' : ''}
          value={EIN}
          onChange={({ target }) => setEIN(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Tenant subdomain</Label>}
        <Input
          placeholder={!hasLabel ? 'tenantId' : ''}
          value={tenantId}
          onChange={({ target }) => setTenantId(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="checkbox" onChange={handleDemoChange}/>{' '}
          Demo?
        </Label>
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={isDisabled || loading}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Approve"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
    </Form>
  );
};
ApproveNonprofitForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ApproveNonprofitForm.defaultProps = {
  layout: 'basic',
  hasLabel: true
};

export default ApproveNonprofitForm;
