import React, { useState, useEffect, Fragment } from 'react';
import {  Card, } from 'reactstrap';
import { gql, useQuery,  } from '@apollo/client';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'
import AddNew from '../linked-cards/AddNew';
import SummaryOfCard from '../linked-cards/SummaryOfCard';
const CreditCardSummary  = ({className}) => {
  const AVAILABLE_ACCOUNTS = gql`
  query AvailableAccounts($SCF_type: String!){
    availableAccounts(SCF_type:$SCF_type){
      _id
      mask
      name
      official_name
      SCF_type
      active
      subtype
      plaidItem{
        name
        color
        logo
        loginRequired
      }
    }
  }
  `;
  const SCF_type = 'credit'
  const [cards, setCards] = useState([]);
  const availableAccountsData = useQuery(AVAILABLE_ACCOUNTS, {
    variables:{SCF_type}
  })
  
  useEffect(() => {
    if(availableAccountsData.data){
      setCards(_.filter(availableAccountsData.data.availableAccounts,'active'))
    }
  }, [availableAccountsData.data])
  return (
    <div className={classNames(' ',className )}>
      <Card className={'p-md-4 p-3 shadow-none'}>
      <Flex align='center' className='mb-3 flex-wrap'>
          <h5 className='mb-0'>Cards</h5>
          <Link className="font-weight-semi-bold ml-auto  fs--1 " to={'/cards'}>
            <p className='text-wrap m-0'>Edit          
            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
            </p>
          </Link>
        </Flex>
        <p>The spare change from these cards it set aside to be donated</p>
        

        <Fragment>
        {cards.map((card)=>
          <SummaryOfCard  
            card={card}
          />
        )}
      </Fragment>
          <AddNew/>
    <div>


</div>
    </Card>
      </div>
  );
};

export default CreditCardSummary;
