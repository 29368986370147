import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Spinner, Container } from 'reactstrap';
import Flex from '../common/Flex';


const PlaidPageLoader = ({loading, message,spinner}) => (    
  // <div className='loading-overlay'>

  <Flex align='center' column  justify='between' className='p-4'  style={{backgroundColor: '#E9ECEF',height:"100vh"}} >
    <div></div>
  <ClipLoader 
color={'gray'} loading={spinner} size={40} />

 <p className='' >{message}</p>
    </Flex>

  // </div>
);


PlaidPageLoader.propTypes = { ...Spinner.propTypes };

PlaidPageLoader.defaultProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary',
};

export default PlaidPageLoader;
