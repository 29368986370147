import React, { useState, useEffect, Fragment } from 'react';

import { gql, useMutation,  } from '@apollo/client';
import Flex from '../common/Flex';
import { Button, Spinner , Card,CardBody,CardTitle,Col,Row } from 'reactstrap';
import ErrorMessage from '../common/ErrorMessage';
import Image from '../../assets/images/svgr/HeroImage';
import ButtonIcon from '../common/ButtonIcon';
import CloudinaryModal from '../cloudinary/CloudinaryModal';

const HeroImage = ({  theme, refetch }) => {
  const [modal, setModal] = useState(false);
  const [heroImage, setHeroImage] = useState('')
  const [selectedId, setSelectedId] = useState(null)


 
  const UPDATE = gql`
  mutation UpdateTheme($input:ThemeInput!){
    updateTheme(input:$input)
  }
`;
const insertPublicId = (publicId) => {
  setSelectedId(publicId)
  setModal(false)
}
  const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{heroImage:selectedId}}})
  useEffect(() => {
    if (theme){
      setHeroImage(theme.heroImage)
    }
  }, [theme])
  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (
    <Fragment>  
      <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertPublicId} />
      <p>The banner image is designed to illustrate the sparechange fundraising concept while highlighting your brand with a customized polaroid photo and your branding.</p>
      <p>Change the polaroid photo image below.</p>
      <Row noGutters>
      <Col xl={5}lg={8} md={10} sm={12}>
          <Card className='p-2 border border-primary mb-3'>
            <CardTitle>Preview:</CardTitle>
            <CardBody>
      <div style={{maxWidth:'400px'}}  >

      <Image className='img-fluid' iconLogo={theme.iconLogo}  publicid={selectedId?selectedId:heroImage} secondary={theme.secondary} primary={theme.primary}/>
      </div>
            </CardBody>
          </Card>

          </Col></Row>
<Flex align='center'>
       <Button className='mr-2'color='primary' onClick={() => {setModal(true)}}>Change photo image</Button>
        <ButtonIcon
              disabled={loading || !selectedId}
              className=""
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
              onClick={handleSubmit}
            >
              {(!loading ) && "Save changes"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>

</Flex>
          {error &&<ErrorMessage error={error}/>}

    
    
    
    </Fragment>


  );
};


export default HeroImage;
