import React, { Fragment, useEffect , useState} from 'react';
import {  CardBody, CardText, ListGroup, ListGroupItem,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useSelector} from 'react-redux'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Flex from '../../common/Flex';

const DEFAULT_CHARITY_USER = gql`
query defaultCharityUser{
    defaultCharityUser{
        email
        firstName
        lastName
        isVerified
        approved
        onboardingComplete
        created
        _id
        charity{
            approved
            name
            subCharity
            taxStatus
            EIN
            url
            tenant{
                subdomain
            }
        }
    }
}
`;
const APPROVE_NONPROFIT = gql`
mutation approveNonprofit($_id:String!){
  approveNonprofit(_id:$_id){
            approved
            name
            subCharity
            taxStatus
            EIN
            url
            tenant{
                subdomain
            }
    }
}
`;

const EditNonprofitModal = ({modal, setModal, nonprofit}) => {
  const [charity, setCharity] = useState(null)
  const [charityUser, setCharityUser] = useState(null)

  const {appName} = useSelector((state) => state.theme)  
    const [getDefaultUser,{data,loading,refetch}] = useLazyQuery(DEFAULT_CHARITY_USER, {
    fetchPolicy:"no-cache",
      context: { headers: { charityId:nonprofit?._id },
    }
    })
    
  const [approve,approveResponse] = useMutation(APPROVE_NONPROFIT,{variables:{_id:nonprofit?._id}})


  useEffect(() => {
    setCharity(data?.defaultCharityUser.charity)
    setCharityUser(data?.defaultCharityUser)
  }, [data])

  useEffect(() => {
    setCharity(approveResponse.data?.approveNonprofit)
  }, [approveResponse.data])
  
    useEffect(() => {
        if(nonprofit?._id){
            getDefaultUser()
        }
    }, [nonprofit])
  const toggle = () => setModal(!modal);
  return (
      <Modal isOpen={modal} toggle={toggle} className={''}>
        <ModalHeader toggle={toggle}>Nonprofit Details</ModalHeader>
        <ModalBody>
            {(loading || approveResponse.loading) && <p>loading...</p>}
            {(charity && !approveResponse.loading) && 
            <Fragment>
                <h5>Nonprofit</h5>
                <ListGroup className='mb-3'>
                    <ListGroupItem>Approved: <span className={charity?.approved?"font-weight-bold text-green":"font-weight-bold text-red"}>{charity?.approved?"Yes":"No"}</span></ListGroupItem>
                    <ListGroupItem>Name: {charity?.name}</ListGroupItem>
                    <ListGroupItem>EIN: {charity?.EIN}</ListGroupItem>
                    <ListGroupItem>URL: {charity?.url}</ListGroupItem>
                    <ListGroupItem>Tenant: {charity?.tenant?.subdomain}</ListGroupItem>
                    <ListGroupItem>Tenant Admin: <span className={charity?.subCharity?"font-weight-bold text-red":"font-weight-bold text-green"}>{charity?.subCharity?"No":"Yes"}</span></ListGroupItem>
                    <ListGroupItem>Tax Status: {charity?.taxStatus}</ListGroupItem>
                </ListGroup>
                <h5>Default User</h5>
                <ListGroup className='mb-3'>
                    <ListGroupItem>Name: {charityUser?.firstName + " " + charityUser?.lastName}</ListGroupItem>
                    <ListGroupItem>Email: {charityUser?.email}</ListGroupItem>
                    <ListGroupItem>Verified: <span className={charityUser?.isVerified?"font-weight-bold text-green":"font-weight-bold text-red"}>{charityUser?.isVerified?"Yes":"No"}</span></ListGroupItem>
                    <ListGroupItem>Onboarding Complete: <span className={charityUser?.onboardingComplete?"font-weight-bold text-green":"font-weight-bold text-red"}>{charityUser?.onboardingComplete?"Yes":"No"}</span></ListGroupItem>

                </ListGroup>
                <Button color="primary" className='' onClick={approve}>Approve</Button>


                </Fragment>
            
            
            
            
            
            
            }
            {/* <div className='mb-2'>
            
        <Button color="primary" className='mr-2' onClick={toggle}>Edit</Button>
        <Button color="primary" className='' disabled={nonprofit?.approved} onClick={toggle}>Approve</Button>
        </div>
      <ListGroup>
        <ListGroupItem>Approved: <span className={nonprofit?.approved?"font-weight-bold text-green":"font-weight-bold text-red"}>{nonprofit?.approved?"Yes":"No"}</span></ListGroupItem>
        <ListGroupItem>Name: {nonprofit?.name}</ListGroupItem>
        <ListGroupItem>Tenant: {nonprofit?.tenant}</ListGroupItem>
        <ListGroupItem>Tenant Admin: <span className={nonprofit?.subCharity?"font-weight-bold text-red":"font-weight-bold text-green"}>{nonprofit?.subCharity?"No":"Yes"}</span></ListGroupItem>
        <ListGroupItem>Tax Status: {nonprofit?.taxStatus}</ListGroupItem>
      </ListGroup>
      
        <Flex>
          </Flex> */}
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>OK</Button>
        </ModalFooter> */}
      </Modal>
    
  );
};

export default EditNonprofitModal;
