import React from 'react';
import AuthBasicLayout from '../../layouts/AuthBasicLayout'
import ResetPasswordForm from './forms/ResetPasswordForm';
// import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const CompleteSetup = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  return (
    <AuthBasicLayout>
    <div className="text-center">
      <h5 className="mb-3">Please complete setting up your account</h5>        
      <Link className={' px-4 mt-3 btn btn-secondary'} to="/get-started">Complete Setup</Link>

      {/* <ResetPasswordForm /> */}
    </div>
    </AuthBasicLayout>
  );
};

export default CompleteSetup;
