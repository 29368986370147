import React, { useState, useEffect, Fragment } from 'react';

import { gql, useMutation,  } from '@apollo/client';
import Flex from '../../common/Flex';
import {  Form, Row, Col, FormGroup, Input,  Label,  Spinner, Card, CardBody,CardTitle, Alert  } from 'reactstrap';
import ErrorMessage from '../../common/ErrorMessage';
import { useForm } from "react-hook-form";
import ButtonIcon from '../../common/ButtonIcon';
import WizardInput from '../../common/WizardInput';

const CreateDonorGroup = ({   onSuccess,refetch }) => {
  const { register, handleSubmit, errors, watch } = useForm();


  const UPDATE = gql`
  mutation createDonorGroup($name:String!,$description:String!){
    createDonorGroup(name:$name,description:$description){
      name
      description
      _id
    }
  }
`;
  const [submit, {error,loading}] = useMutation(UPDATE)  
  // useEffect(() => {
  //   if (theme){  
  //     setHeroSubtitle(theme.heroSubtitle)
  //     setHeroText(theme.heroText)
  //     setHeroTitle(theme.heroTitle)
  //   }
  // }, [theme])
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   update().then( () => {
  //     refetch()
  //   }).catch(e => {})

  // };
  const onSubmit = (data) => {
    submit({variables:{...data}})
    // .then( () => {
    //   getProfile().catch(e => {})
    // }
    .then(res =>{
      onSuccess()
    })
    .catch(e => {})
  }
  return (
    <Fragment>  
      {/* <p className='text-muted'>Note: While not required, you can further customize your campaign by including an impact statement</p> */}
      <Flex wrap align='center' className='p-0'>
    <Form onSubmit={handleSubmit(onSubmit)} className='w-100'>
        <FormGroup>
          <Label className=''>Name:</Label>
        <Input
                    label={false ?"Name*":""}
                    placeholder="Name"
                    id="name"
                    name="name"
                    innerRef={register({
                      required: 'Name is required',
                      minLength: {
                        value: 2,
                        message: 'Min length 2'
                      }
                    })}
                    errors={errors}
                  />
         
      </FormGroup>
      <FormGroup>
          <Label className=''>Description:</Label>
        <Input
                    label={false?"Description":""}
                    placeholder="Description"
                    id="description"
                    name="description"
                    type="textarea"
                    rows="2"
                    innerRef={register({
                      required: 'Description is required',
                      minLength: {
                        value: 2,
                        message: 'Min length 2'
                      }
                    })}
                    errors={errors}
                  />
      </FormGroup>
      {/* <FormGroup>
        <Label>Image</Label>
        <p>User can upload an optional image here</p>
      </FormGroup> */}

    
      <FormGroup>

        <ButtonIcon
              disabled={loading}
              className="ml-auto "
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Create Donor Group"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
      </FormGroup>
      
    </Form>
    </Flex>
        {error &&<ErrorMessage error={error}/>}

    
    
    
    </Fragment>


  );
};


export default CreateDonorGroup;
