import React from 'react';
import Flex from '../../common/Flex';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const AddNew  = () => {
  return (
    <div className='p-2'>
    <Link className="" to="/cards">
   <Flex align='center'>     
        <Flex className='  add-card-button mr-3' align='center' justify='center'>

            <FontAwesomeIcon icon="plus" size='2x'/>
        </Flex>
     <p className='m-0 text-muted'>Add a card</p>
   </Flex>
      </Link>
 </div>
  );
};

export default AddNew;
