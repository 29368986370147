import classNames from 'classnames';
import React, {  useState } from 'react';
import {  Container,  Navbar, } from 'reactstrap';
import {useSelector} from 'react-redux'
import PushkaLandingRightSideNavItem from './PushkaLandingRightSideNavItem';
import PushkaLogo from './PushkaLogo';
import Flex from '../common/Flex';
import Logo from './Logo';
import LandingRightSideNavItem from './LandingRightSideNavItem';

const TransparencyLandingNav = ({light}) => {
  const {pushka} = useSelector((state) => state.config)
  const [active,setActive] = useState(false)
  const {iconLogo,charityName} = useSelector((state) => state.theme)


  const handleNavbarTransparency = () => {
    if(window.scrollY >= 80 ){
      setActive(true)
    }else{
      setActive(false)
    }
  }
  window.addEventListener('scroll', handleNavbarTransparency);

  return (
    <Navbar
      light
      fixed="top"
      expand='lg'
      className={classNames('pushka-nav-landing py-3',active?"active":null)}

    >
      <Container >
        <div className='w-100'>
        <Flex  align='center' justify='end' wrap>
          <div  className='flex-grow-1'>    
          {pushka
          ?<PushkaLogo at='navbar-top' white={!active}/>
          :<Logo white={!active} light={light} publicId={iconLogo} text={charityName} at='navbar-top' alt={!active} ></Logo>
          }
 
          </div>
          {pushka
          ?<PushkaLandingRightSideNavItem className='ml-auto'/>
          :<LandingRightSideNavItem  className='ml-auto'light={light} transparent={!active}/>
          }
        </Flex>
          
          </div>
  
      </Container>
    </Navbar>
  );
};

export default TransparencyLandingNav;
