import React from 'react';
import Confetti from '../../../assets/images/illustration/confetti.png'
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Flex from '../../common/Flex'

const WhatToExpect = () => {
  const {appName} = useSelector((state) => state.theme)
  const {transferDay} = useSelector((state) => state.user)
  const days = ['sunday'  ,    'monday',    'tuesday',    'wednesday',    'thursday',    'friday',    'saturday',]

  return (
    <div className='p-4'>
      <Flex justify='center'>
          <img className='dashboard-img p-2 mb-2'src={Confetti} alt=""/>

      </Flex>
      <h5 className='mb-3'>Congrats on transforming every purchase into purpose with {appName}!</h5>
      <h5 className='mb-3'>What happens now?</h5>
      <p>Its simple. Continue to use your credit card for daily purchases and you will start donating automatically.</p>
      <h5 className='mb-3'>See your impact</h5>
      <p>Every week on {days[transferDay]} your donations will be group together and transferred from your default bank account. You can expect an email summary every {days[transferDay]} letting you know how much you have donated that week. </p>
      <h5 className='mb-3'>You’re in control</h5>
      <p>Manage your cards, transfer accounts, and donation preferences in your donor dashboard</p>
      <Link className="btn btn-primary" to="/dashboard">Go To Dashboard</Link>
        </div>
  );
};

export default WhatToExpect;
