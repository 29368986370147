import React, {  useEffect, useState,forwardRef,Fragment } from 'react';
import EditAndApproveCharity from '../../admin/EditAndApproveCharity';
import { gql, useQuery, } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkAlt,} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { NavItem, NavLink,  Button, Card, Badge} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import Flex from '../../common/Flex';
import Loader from '../../common/Loader'
import TablePaginatonGroupBy from '../../tables/react-table/TablePaginatonGroupBy';
import _ from 'lodash'
import DonationStatusBadge from './DonationStatusBadge';




function DonationsTableAlt({ title, tooltip, handleRowClick,className}) {
 

  const DONATIONS_SCROLL = gql`
  query donationsScroll($offset:Int,$limit:Int){
    donationsCount
    donationsScroll(offset:$offset,limit:$limit){
      _id
      amount
      date
      pending
      multiplier
      transferring
      adjustmentReason
      processed
      status
      transaction{
        name
        amount
      }
      pendingTransaction{
        name
        amount
      }
      plaidAccount{
        _id
        mask
        name
        official_name
        SCF_type
        active
        subtype
        plaidItem{
          name
          color
          logo
        }
      }
    }
  }
  `;  

  // const {data, loading,refetch, fetchMore } = useQuery (DONATIONS,{variables:{month:month}})  
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [end, setEnd] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const limit = 20
  const {data, loading,refetch, fetchMore } = useQuery (DONATIONS_SCROLL,
    {variables:{offset:offset,limit:limit},
    // fetchPolicy: "no-cache"
  })  
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    if(data){
      setTotal(data.donationsCount)
      const dateCheck = []
      const grouped = data.donationsScroll.map(row => {
        const newRow = {
          ...row,
          firstOnDate:dateCheck.includes(row.date)?false:true,
          dailyMax:row.adjustmentReason==='Daily Max Reached'?true:false
        }        
        dateCheck.push(row.date)

        return newRow
      })
      setTableData(grouped)
      if(grouped.length === (limit +offset)){
        setIsLoadingMore(false)
      }
      if(grouped.length === total){
        setIsLoadingMore(false)
        setEnd(true)
      }
    }
  }, [data])


  const columns = React.useMemo(
    () => [
      {
        id: 'data',
        accessor: d =>
        <div className='py-0'onClick={() => handleRowClick(d._id)} >
          {d.firstOnDate && <Flex justify='start' align='center'>
            <h6 className='text-black py-1 rounded font-weight-semi-bold mb-0 mr-2'>
              { moment(d.date).format("MMM D")}
            </h6>
            
            {d.dailyMax &&
            
    <Badge className={'px-2 ' } color='soft-red'>
              <Flex>
              {true && <p className='m-0'>daily max reached</p>}
              {/* <FontAwesomeIcon className='' icon={'clock'}></FontAwesomeIcon> */}
            </Flex>
            </Badge>
            }
            </Flex>}
          <Flex >
            <Flex column>
              <Flex>

              <p className='mr-2 mb-0 fs--1 font-weight-medium'>{d.transaction?"$"+d.transaction.amount.toFixed(2):"$"+d.pendingTransaction.amount.toFixed(2)}</p>
              <p className='mb-0 fs--1'>{d.transaction?d.transaction.name:d.pendingTransaction.name}</p>
              </Flex>
              <Flex align='center'>
                <p className='mr-2 mb-0 fs--1'>{d.plaidAccount.name}</p>
                <p className='mb-0 fs--1'>{d.plaidAccount.mask}</p>
                </Flex>
              
            </Flex>
            <Flex align='center'className='ml-auto'>
              <p className='mb-0 ml-auto text-primary font-weight-semi-bold '>{"+$"+ d.amount.toFixed(2)}</p>
              <DonationStatusBadge {...d} className='ml-2' />

            
            </Flex>
          </Flex>
        </div> 
      },
    ],
    []
  )

  //const data = React.useMemo(() => makeData(10), [])
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
        <EditAndApproveCharity charityUser={row.original}/>

    ),
    []
  )

  const loadMore = () => {
    setIsLoadingMore(true)
    fetchMore({
      variables: {
        offset: offset+limit,
        limit: limit
      },
    });
    setOffset(offset+limit)

  }

  return (
    <div className={className}>
<Card className=' p-md-4 p-2  shadow-none '>
  <div className='' style={{minHeight:'500px'}}>
      <h5 className='mb-3 p-1'>Roundup Breakdown</h5>
    
    {/* <p></p> */}
    
    {/* <Flex>
        <p className='mb-0 pl-1'>Purchase</p>
        <p className='mb-0 pr-1 ml-auto'>Roundup</p>
      </Flex> */}
      {loading && <div className='mt-6'>
        <Loader/>
        </div>}
            {data && <TablePaginatonGroupBy
        tooltip={tooltip}
        title={title}
        columns={columns}
        data={tableData}
        renderRowSubComponent={renderRowSubComponent}
      /> }
      
      {isLoadingMore && <Loader/>}
      {!loading && !isLoadingMore && !end && <Flex justify='center'>
        <Button color='primary' size='sm' onClick={loadMore}>Load more</Button>
        </Flex>}
        {end && <Flex justify='center'>
          <p className='text-muted'>No more donations</p>
        </Flex>}
    </div>
    </Card>
    </div>
    
  
  )
}

export default DonationsTableAlt
