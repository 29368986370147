import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Flex from '../components/common/Flex';
import SideBar from '../components/navbar/adminDashboard/SideBar';
import classNames from "classnames";
import Topbar from '../components/navbar/adminDashboard/TopBar';

const AdminDashboardLayout = ({  children }) => {
  
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => {   
    setCollapsed(!collapsed)
  };
  return (
    <Fragment>  
      
    <div className='dashboard'>
      <Topbar/>  
      <Flex>
      <SideBar toggleNavbar={toggleNavbar} collapsed={collapsed}/>
      <div className={classNames("dashboard-content", { "collapsed": collapsed })}>
        {children} 
      </div>
      </Flex>
      
    </div>  
    </Fragment>


  );
};
AdminDashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default AdminDashboardLayout;
