import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import Flex from '../../common/Flex';
import CharitySearch from './CharitySearch';
import CharityDetailsAlt from './CharityDetailsAlt';
import ButtonIcon from '../../common/ButtonIcon';
const CharitySearchModalAlt = ({insertNewCharity,modal, setModal, tenantId}) => {


  const toggle = () => {
    setSelectedCharity(null)
    setView('SEARCH')
    setModal(!modal)};
  // const [activeTab, setActiveTab] = useState('1');
  const [view, setView] = useState('SEARCH');
  const [selectedCharity, setSelectedCharity] = useState(null);

  // const toggleTab = tab => {
  //   if(activeTab !== tab) setActiveTab(tab);
  // }
  const handleOnSelect = (charity) => {
    
    setSelectedCharity(charity)
    setView("DETAILS")
  }
  const onBack = () => {
    setView("SEARCH")
    setSelectedCharity(null)
  }
  const handleOnInsert = () => {
    insertNewCharity(selectedCharity)
    toggle()
  }
  // useEffect(() => {
  //   if(activeTab === '1'){
  //     setSelectedCharity(null)
  //   }
  // }, [activeTab])
  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle} modalClassName="overflow-hidden modal-fixed-right modal-theme" className="modal-dialog-vertical" contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>Select Non-profit</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
      <div>
        {view==='SEARCH' &&
        <div>
          <CharitySearch tenantId={tenantId} onSelect={handleOnSelect}/>
        </div>
        }
        {view==='DETAILS' && selectedCharity &&
        <div>
          <Flex align='start'className="mb-2">
            <ButtonIcon color="link" icon={'chevron-left'} iconAlign="left" transform="down-1 shrink-4" className='p-0 font-weight-semi-bold flex-shrink-r' onClick={onBack}>
              Back
            </ButtonIcon>
            <Flex className='flex-grow-1 text-center m-0' column>

            <h5 className="" >{selectedCharity.name}</h5>
            <p className='mb-0'>{selectedCharity.EIN}</p>
            </Flex>
          <div style={{ width: 56 }} ></div>
        </Flex>
          <CharityDetailsAlt  charity={selectedCharity} handleSelect={handleOnInsert}/>
        </div>
        }

      {/* <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '1' })}
            onClick={() => { toggleTab('1'); }}
          >
            Search
          </NavLink>
        </NavItem>
        {selectedCharity && <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '2' })}
            onClick={() => { toggleTab('2'); }}
          >
            Details
          </NavLink>
        </NavItem>}
      </Nav> */}
      {/* <TabContent  className='overflow-hidden'activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <CharitySearch tenantId={tenantId} onSelect={handleOnSelect}/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
         
        <Row>
            <Col sm="12">
              {selectedCharity && <CharityDetails  charity={selectedCharity}/>}
            </Col>
          </Row>
        </TabPane>
      </TabContent> */}
    </div>
      </ModalBody>
      <ModalFooter classNames='ml-auto' style={{height:'60px'}}>
        {/* <Button disabled={!selectedCharity} color="primary" onClick={()=>{
          insertNewCharity(selectedCharity)
          toggle()}}>Add Nonprofit</Button>{' '}
        <Button color="secondary" onClick={() => {
          toggle()}}>Cancel</Button> */}
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default CharitySearchModalAlt;