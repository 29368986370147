
import { gql } from '@apollo/client';
export const meCharityUser = gql`
    query MeCharityUser{ 
        meCharityUser{ 
            _id
            firstName
            lastName
            email 
            charityName
            EIN
            approved
            isVerified
            onboardingComplete
            charity{
                publishedTheme
                name
                EIN
                donorGroupsEnabled
                stripeRequirements{
                    past_due
                }
            }
        }
    }
`;