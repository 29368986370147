import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import IconLogo from '../common/Logo'
import {useSelector} from 'react-redux'
import Sparechange from '../../assets/images/illustration/sparechange-horizontal-color.png'
import SparechangeLogo from '../common/SparechangeLogo';




  const Display = ({ dark, at, width, icon,white,  light,className, ...rest }) => {
  const {management} = useSelector((state) => state.config)
  const {iconLogo, charityName} = useSelector((state) => state.theme)

  return(
    <div
      className={classNames(
        'd-flex',
        {
          'align-items-center py-3': at === 'navbar-vertical',
          'align-items-center': at === 'navbar-top',
          'flex-center  mb-4': at === 'auth',
          '': at === 'footer',
        },
        className
      )}
    >
      
      {icon 
      ? management ? <SparechangeLogo dark={dark} white={white}/>: <IconLogo at={at} dark={dark} publicId={iconLogo} /> 
      :   management ? <SparechangeLogo dark={dark} white={white}/> : <IconLogo at={at} white={!light&&white?true:false}  dark={dark} publicId={iconLogo}  text={charityName}/> 
      
      }
    </div>
  )
}

const Logo = ({ dark, at, width, white, light, icon, className, alt, ...rest }) => {
  const {management,homeRedirect} = useSelector((state) => state.config)
  return (
    <Fragment>
      {homeRedirect
      ?

      <a
      href={homeRedirect}
      className={classNames(
        'text-decoration-none text-black',
        { 'text-black': !dark},
        { 'text-white': dark},
        { 'text-left': at === 'navbar-vertical' },
        { 'text-left': at === 'navbar-top' },
      )}
      {...rest}
    >      <Display className={className} dark={dark} at={at} icon={icon} white={white}/>

    </a>
      :
      <Link
      to={"/"}
      className={classNames(
        'text-decoration-none text-black',
        { 'text-black': !dark},
        { 'text-white': dark},
        { 'text-left': at === 'navbar-vertical' },
        { 'text-left': at === 'navbar-top' },
      )}
      {...rest}
    >
      
      <Display className={className} dark={dark} at={at} icon={icon} white={white} light={light}/>
    </Link>
      }
    </Fragment>
   
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.bool
};

Logo.defaultProps = { at: 'auth', width: 60 };

export default Logo;
