import React from 'react';


const DemoMessage = ({ message }) => {
  return (
    <div className='demo-message'>{message}</div>
  );
};


export default DemoMessage;
