import React, { useState, useEffect, Fragment } from 'react';
import { gql, useMutation,  } from '@apollo/client';
import {   FormGroup, Input,  Spinner ,Row,Col,Card,CardBody,CardTitle,Button } from 'reactstrap';
import CloudinaryModal from '../../cloudinary/CloudinaryModal';
import Flex from '../../common/Flex';
import Example from '../../../assets/images/illustration/icon-examples.png'
import Logo from '../../common/Logo';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';

const General = ({  theme,refetch }) => {
  const [charityName, setCharityName] = useState('')
  const [iconLogo, setIconLogo] = useState('')
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null)

  const UPDATE = gql`
  mutation upadatePushkaCharityTheme($input:pushkaThemeInput!){
    upadatePushkaCharityTheme(input:$input)
  }
`;

const insertPublicId = (publicId) => {
  setSelectedId(publicId)
  setModal(false)
}
const [update, {error,loading}] = useMutation(UPDATE,  {
  variables:{
    input:{charityName:charityName}
  }})
  const [updateLogo, updateLogoResponse] = useMutation(UPDATE,  {variables:{input:{iconLogo:selectedId}}})
  //const theme = useQuery(MY_THEME)
  useEffect(() => {
    if (theme){  
      setCharityName(theme.charityName)
      setIconLogo(theme.iconLogo)
    }
  }, [theme])

  const handleLogoUpate = e => {
    e.preventDefault();
    updateLogo().then( async () => {
      await refetch()
      await setSelectedId(null)
    }).catch(e => {})
  };
  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (
    <Fragment>  
      <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertPublicId} />

    <p>Your organization's name and icon are used on your invite page and to identify your cause to your donors. They may be displayed next to each other, creating a fool-proof and legible brand identity for your campaign.</p>
    <Row>
        <Col lg={6}>
        <h5 className='text-primary mb-2'>Organization name:</h5>
        <Flex wrap align='center' className=''>
      <FormGroup className='mr-2'>
        <Input
          placeholder={!true ? 'Email address' : ''}
          value={charityName}
          onChange={({ target }) => setCharityName(target.value)}
          type="charityName"
        />
      </FormGroup>
      <FormGroup>

        <ButtonIcon
              disabled={loading}
              hideIcon={loading}
              onClick={handleSubmit}
            >
              {(!loading ) && "Update"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
      </Flex>
      <h5 className='text-primary mb-2'>Organization icon:</h5>
      <Flex align='center'>
        <div className='mr-2'>
          <Logo at='preview' publicId={selectedId?selectedId:iconLogo} icon />
        </div>
          
            <ButtonIcon
            onClick={handleLogoUpate}
                  disabled={updateLogoResponse.loading || selectedId === null}
                  hideIcon={updateLogoResponse.loading}
                >
                  {(!updateLogoResponse.loading ) && "Update"}
                  {updateLogoResponse.loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
          <Button className='p-2'color='link' onClick={() => {setModal(true)}}>Change Icon</Button>

</Flex>
              {updateLogoResponse.error &&<ErrorMessage error={updateLogoResponse.error}/>}
<p className=' fs--1 text-muted border-bottom pb-3 mt-2'>Please upload a JPG or PNG. For best results, upload an icon without any text, and square in size.</p>
<p className=' fs--1 text-muted'>Icon examples:</p>

<img style={{maxWidth:'300px'}} className='mb-3'src={Example} alt=""/>
        </Col>
        <Col lg={6}>
          
        <Card className='p-2 border border-primary'>
            <CardTitle>Preview:</CardTitle>
            <CardBody >
           
          <Logo at='preview' publicId={selectedId?selectedId:iconLogo} text={charityName} />
            </CardBody>
          </Card>
        </Col>
        </Row>
        {error &&<ErrorMessage error={error}/>}
    </Fragment>


  );
};


export default General;
