import React from 'react';
import classNames from 'classnames';
import { Row, Col,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from '../../common/Section';
import chart_desktop from '../../../assets/images/illustration/pushka/chart_desktop.png'
import chart_mobile from '../../../assets/images/illustration/pushka/chart_mobile.png'
import charity1 from '../../../assets/images/pictures/pushka/charity1.jpg'
import charity2 from '../../../assets/images/pictures/pushka/charity2.jpg'
import charity3 from '../../../assets/images/pictures/pushka/charity3.jpg'
import charity4 from '../../../assets/images/pictures/pushka/charity4.jpg'
import charity5 from '../../../assets/images/pictures/pushka/charity5.jpg'
import charity6 from '../../../assets/images/pictures/pushka/charity6.jpg'
import Flex from '../../common/Flex';
const Charities = () => {
  return (
    <div>
<Section bg='black' className='text-white '>
  <Row className="flex-center  py-4 px-md-4 px-2 text-md-left text-center" >
    <Col md lg={5} className={classNames('pl-lg-6', { 'order-md-2': false })}>
      <h2 className='fs-3 mb-4 text-white'>Support the Cause You Care About</h2>
      <p>Split your spare change between the causes
you care about. You decide how much of
your donations go to which charities.
        </p>
        <Flex className='' wrap>
           <Link className={'round   py-2 px-md-5 px-4 mt-3 mr-3 btn btn-secondary btn-md'} to="/get-started">Get Started</Link>
        <Button outline color="white" className="round  py-2 px-md-5 px-4 mt-3 " size='md'>View Charities</Button>

        </Flex>
    </Col>
    <Col md lg={7} className="p-xl-6 p-0">
      <div>
        <img src={chart_desktop} alt="Logo"className="img-fluid p-2 d-lg-block d-none"/>
        <img src={chart_mobile} alt="Logo"className="img-fluid p-4 d-lg-none d-block"/>
      </div>
    </Col>
  </Row>

</Section>
<Row noGutters>
    <Col md={2}  xs={4} className='p-0'>
        <img src={charity1} alt="Logo"className="img-charity"/>
    </Col>
    <Col md={2}xs={4}  className='p-0 '>
        <img src={charity2} alt="Logo"className="img-charity"/>
    </Col>
    <Col md={2} xs={4} className='p-0'>
        <img src={charity3} alt="Logo"className="img-charity"/>
    </Col>
    <Col md={2} xs={4} className='p-0 '>
        <img src={charity4} alt="Logo"className="img-charity"/>
    </Col>
    <Col md={2} xs={4} className='p-0 '>
        <img src={charity5} alt="Logo"className="img-charity"/>
    </Col>
    <Col md={2} xs={4} className='p-0 '>
        <img src={charity6} alt="Logo"className="img-charity"/>
    </Col>

  </Row>
    </div>

  );
};



export default Charities;
