import React, {  useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {  Form, Row, Col, FormGroup,  CustomInput,  Spinner, Label, Input, Alert } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import ButtonIcon from '../../common/ButtonIcon';
import { useForm } from "react-hook-form";
import WizardInput from '../../common/WizardInput'
import { gql, useMutation,useLazyQuery } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import { loginCharity, updateCharity } from '../../../functions/auth';
import Flex from '../../common/Flex';

const CHARITY_SIGNUP = gql`
mutation nonprofitOnboardingDetails($charityName:String!,$taxStatus:String!,$url: String!,$yearEnd: Int!,$EIN: String!){
  nonprofitOnboardingDetails(charityName:$charityName,taxStatus:$taxStatus,url:$url,yearEnd:$yearEnd,EIN:$EIN){
    _id
  }
}
`;
const GET_PROFILE = gql`
query{ 
  meCharityUser{ 
    firstName
    lastName
    email 
    EIN
    isVerified
    approved
    created
    charity{
      publishedTheme
      donorGroupsEnabled
      name
      EIN
      stripeRequirements{
        past_due
      }
    }
  }
}
`;

const NonprofitDetailsForm = ({ setRedirect,setRedirectUrl, hasLabel }) => {
  const {appName} = useSelector((state) => state.config)

  const { register, handleSubmit, errors, watch } = useForm();
  const [taxStatus, setTaxStatus] = useState(null)
  const [submit,{data,loading,error}] = useMutation(CHARITY_SIGNUP)
  // const [getProfile, profile] = useLazyQuery (GET_PROFILE)
  const dispatch = useDispatch()

  const changeTaxStatus = e => {
    setTaxStatus(e.target.value)
  }

  const onSubmit = (data) => {
    dispatch({
      type:"UPDATE_PROCESS",
      payload:{
        loading:true,
        message:'We are processing your information. Please do not refresh this page. This may take up to 2 minutes.',
      }
    })
    const yearEnd = Number(data.yearEnd.substring(0,2))
    dispatch({type:"UPDATE_CHARITY_USER",payload:{onboardingCreatingCharity:true}})
    submit({variables:{charityName:data.charityName,yearEnd:yearEnd,url:data.url,taxStatus:data.taxStatus, EIN:data.EIN,taxStatus:taxStatus}})
    .then(async (res)=>{
      await updateCharity()
      .then(
        dispatch({type:"UPDATE_PROCESS",payload:{loading:false,message:null,}}),
        setRedirectUrl({
          pathname: `/nonprofit/dashboard`
        }),
        setRedirect(true)
      ).catch(e=>{dispatch({type:"UPDATE_PROCESS",payload:{loading:false,message:null,}})})
    })
    .catch(e => {dispatch({type:"UPDATE_PROCESS",payload:{loading:false,message:null,}})})
  }


  return (
    <Fragment>
      <h5>Nonprofit Details</h5>
      <p>Please provide some basic information about your nonprofit. Our team will review and approve this information before your campaign is live.</p>
    <Form onSubmit={handleSubmit(onSubmit)}>

              <FormGroup>
                  <WizardInput
                    label={"Orginazation Name"}
                    placeholder="Orginazation Name"
                    id="charityName"
                    name="charityName"
                    innerRef={register({
                      required: 'Orginazation Name is required',
                      minLength: {
                        value: 2,
                        message: 'Min length 2'
                      }
                    })}
                    errors={errors}
                  />
              </FormGroup>

              
      <div  onChange={changeTaxStatus} className='mb-2'>
        <label>Tax Exempt Status</label>
        <div>
          <input type="radio" value="501C3" name="taxStatus" /> We have 501(c)(3) status
        </div>
        <div>
          <input type="radio" value="FP" name="taxStatus" /> We have a Fiscal Partner with 501(c)(3) status
        </div>
        <div>
          <input type="radio" value="none" name="taxStatus" /> We are not tax exempt
        </div>
      </div>
      {taxStatus === 'none' && 
      <Alert color='yellow'>
          Sorry, you mus t have 501(c)(3) status or work with a fiscal sponsor to fundraise on {appName} at this time. Please contact us with any questions.
        </Alert>
      }

      {(taxStatus === "501C3" || taxStatus === "FP" )  && 
      <Fragment>
      <FormGroup>
      <WizardInput
        label={taxStatus === "FP"?"EIN of Fiscal Sponsor":"EIN" }
        placeholder="EIN"
        id="EIN"
        name="EIN"
        innerRef={register({
          required: 'EIN is required',
          minLength: {
            value: 2,
            message: 'Min length 2'
          }
        })}
        errors={errors}
      />
      </FormGroup>
              <FormGroup>
              <WizardInput
                label={taxStatus === "FP"?"Website of Fiscal Sponsor":"Website" }
                placeholder="Website"
                id="url"
                name="url"
                innerRef={register({
                  required: 'Website is required',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                errors={errors}
              />
          </FormGroup>
              <FormGroup>
              <WizardInput
                type="select"
                label={"When does your financial year end?"}
                placeholder="Choose a month"
                id="yearEnd"
                name="yearEnd"
                options={['01 - January', '02 - February', '03 - March','04 - April','05 -May', '06 - June','07 - July','08 - August','09 - September','10 - October', '11 - November', '12 - December']}
                innerRef={register({
                  required: 'Year End is required',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                errors={errors}
              />
          </FormGroup>
          <Flex>

          <ButtonIcon
                  disabled={loading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  hideIcon={loading}
                >
                  {(!loading ) && "Next"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
            </Flex>
      </Fragment>
      
      
      
      
      }
{/* 
              <FormGroup tag="fieldset">
          <legend>Tax Exempt Status</legend>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" onChange={changeTaxStatus} />{' '}
              We have 501(c)(3) status
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" />{' '}
              We have a Fiscal Partner with 501(c)(3) status
            </Label>
          </FormGroup>
        </FormGroup> */}
        {/* <WizardInput
          label={hasLabel?"Email*":""}
          placeholder="Email"
          id="email"
          name="email"
          innerRef={register({
            required: 'Email is required',
            pattern: {
              value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          })}
          errors={errors}
        />   
        <WizardInput
          label={hasLabel?"Phone Number*":""}
          placeholder="Phone Number"
          id="phone"
          name="phone"
          innerRef={register({
            required: 'Phone number is required',
            pattern: {
              value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
              message: 'Phone number must be valid'
            }
          })}
          errors={errors}
        />         
        <WizardInput
        label={hasLabel?"Non-Profit*":""}
        placeholder="Non-profit"
        id="charityName"
        name="charityName"
        innerRef={register({
          required: 'Non-profit is required',
          minLength: {
            value: 2,
            message: 'Min length 2'
          }
        })}
        errors={errors}
      /> 
      <WizardInput
      label={hasLabel?"EIN*":""}
      placeholder="EIN"
      id="EIN"
      name="EIN"
      innerRef={register({
        required: 'EIN is required',
        minLength: {
          value: 2,
          message: 'Min length 2'
        }
      })}
      errors={errors}
    /> 
      <Row form>
        <Col  sm={6}>
          <WizardInput
            type="password"
            label="Password*"
            placeholder="Password"
            id="password"
            name="password"
            innerRef={register({
              required: 'You must specify a password',
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })}
            errors={errors}
          />
        </Col>       
         <Col  sm={6}>
          <WizardInput
            type="password"
            label="Confirm Password*"
            placeholder="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            innerRef={register({
              validate: value => value === watch('password')|| `The password do not match`
            })}
            errors={errors}
          />
        </Col>
      </Row> */}
      {/* <p>By clicking Create Campaign, you accept the <a href="/terms-and-conditions"> terms</a> and <a href="/privacy-policy"> privacy policy</a></p> */}

  <div className={'d-flex'}>
    
  {error &&<ErrorMessage error={error}/>}
            {/* <ButtonIcon
                  disabled={loading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  hideIcon={loading}
                >
                  {(!loading ) && "Create campaign"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon> */}
          </div>
    </Form></Fragment>
  );
};

NonprofitDetailsForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

NonprofitDetailsForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(NonprofitDetailsForm);
