import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';

import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Section from '../common/Section';
import CoffeeDonate from '../../assets/images/svgr/CoffeeDonate';
const HowItWorks = () => {
  const {charityName, primary, iconLogo} = useSelector((state) => state.theme)
  return (
<Section bg='gray' className=''>
  <Row className="flex-center bg-white py-5 px-md-4 px-2 text-md-left text-center" >
    <Col md lg={6} xl={5} className={classNames('pl-lg-6', { 'order-md-2': true })}>
      <h3 className='fs-1 text-primary'>How It Works</h3>
      <h2 className='fs-3 mb-4 '>Transform Every Purchase into Purpose</h2>
      <p>With your linked credit card, <span>{charityName}</span> rounds up your purchases
        to the next dollar and sets the change aside to be donated.
        <ul className='text-left mt-3'>
          <li>You purchase a $2.65 cup of iced coffee</li>
          <li>{charityName} rounds that coffee up to $3.00</li>
          <li>The $0.35 in change gets set aside to be donated</li>
        </ul>
        </p>
        <p>At the end of each week, your donations are grouped together and donated to {charityName}. </p>
        <Link className={'round py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/get-started">Get Started</Link>
    </Col>
    <Col md lg={4} xl={5} className="mt-4 mt-md-0 p-0">
      <CoffeeDonate primary={primary} iconLogo={iconLogo} className='img-fluid  p-5 '/>
    </Col>
  </Row>
</Section>
  );
};



export default HowItWorks;
