import React, { Fragment, useState, useEffect } from 'react';
import { Button, Card, } from 'reactstrap';
import classNames from 'classnames'
import { gql, useQuery,   } from '@apollo/client';
import { useLocation, useHistory ,useParams  } from 'react-router-dom';
import Flex from '../../common/Flex';
import ChangeTransferAccountModal from './ChangeTransferAccountModal';
import BankAccount from '../../stripe/BankAccount';

const DEFUALT_ACCOUNT = gql`
query MyDefaultStripeBank{
  myDefaultStripeBank{
      _id
      account_holder_name
      bank_name
      last4
      routing_number
      currency
      created
      default
      status
  }
  myStripeBankAccounts{
    _id
    account_holder_name
    bank_name
    last4
    routing_number
    currency
    created
    default
    status
  }
  availableAccounts(SCF_type:"checking"){
    _id
    mask
    name
    official_name
    SCF_type
    active
    subtype
    plaidItem{
      stripeAuthSupported
      name
      color
      logo
      loginRequired
    }
  }
}
`;

const ManageBankAccount  = ({className}) => {
  const [modal, setModal] = useState(false);
  
  
  // let { changeBank } = useParams();
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  const changeBank = params.get('change_bank'); 
 // const view = params.get('view'); 

 const {origin, pathname} = (window.location)

 const [plaidRedirectUrl, setPlaidRedirectUrl] = useState(origin + pathname + '?change_bank=OPTIONS')
  const [account, setAccount] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [plaidAccounts,setPlaidAccounts] = useState([]);
  const {data, refetch} = useQuery(DEFUALT_ACCOUNT,{
    fetchPolicy: "no-cache"
  })
  useEffect(() => {
    if(data){
        setAccount(data.myDefaultStripeBank)
        setAccounts(data.myStripeBankAccounts)
        setPlaidAccounts(data.availableAccounts)
    }
  }, [data])

  const changeAccount = () => {
    history.push('?change_bank=OPTIONS')
    // history.replace({
    //   search: 'change_bank=true',
    //   search: 'view=OPTIONS',
    // })
  }
  
  const removeSearch = () => {
    history.replace({
      search: '',
    })
  }
  
  useEffect(() => {
    if(changeBank){
      setModal(true)
    }
  }, [changeBank])


  return (
    <Fragment>
      
      <ChangeTransferAccountModal  accounts={accounts} plaidAccounts={plaidAccounts} refetch={refetch} view={changeBank} modal={modal} setModal={setModal} removeSearch={removeSearch} redirectURL={plaidRedirectUrl} /> 
    <div className={classNames(' ',className )}>
      <Card className={' p-md-4 p-3 shadow-none '}>
          <h5 className='mb-2'>Bank Account</h5>
          <p className=''>Your weekly donations are withdrawn from this account.</p>
        <BankAccount account={account} isDefault={true} onVerificationSuccess={refetch}/>
        <Flex className='mt-3'>
          
        <Button onClick={changeAccount} className='ml-auto'>Change Account</Button>
        </Flex>
    </Card>
      </div>
    </Fragment>
  );
};

export default ManageBankAccount;
