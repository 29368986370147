import React, { Fragment, } from 'react';
import {  Badge, Card, } from 'reactstrap';
import Flex from '../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../common/ButtonIcon';
import PayoutAccountOptions from './options/PayoutAccountOptions';

const PayoutAccount = ({account, makeDefault, loading, refetch}) => {


  return (
    <Fragment>
            <Card  className="p-0 my-2 shadow-none"  key={account.routing_number}>
            <Flex wrap align="center"> 
             <FontAwesomeIcon icon={ ['fas', 'university']} className="fs-4 mr-3"/>
             <div>
            <Flex wrap align="center">
              {account.bank_name}
              {account.default && 
                  <Badge className="ml-2" color={'soft-green'}pill>
                    default
                  </Badge>
                  }
            </Flex> 
            
              <p className="m-0">{account.routing_number} | •••• {account.last4}</p>
              </div>
              
              {account.type !== 'external' &&
              
              <div className='ml-auto'>
                
        
        
          
              <Flex   justify='end'>       
              <PayoutAccountOptions account={account} refetch={refetch}/>
              {/* { !account.default && 
                <ButtonIcon size='sm' disabled={loading} className='ml-1 mt-1 fs--2' onClick={() => makeDefault(account._id)}>make default</ButtonIcon>
              } */}

                </Flex>         
              
      

              
              </div>}
            </Flex>
              </Card>
    </Fragment>
  );
};

export default PayoutAccount;
