import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {  Form, Row, Col, FormGroup, Input, CustomInput, Label,  Spinner  } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { gql, useMutation, useLazyQuery  } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import {useDispatch} from 'react-redux'
import { loginDonor } from '../../../functions/auth';

const LoginForm = ({ setRedirect,setRedirectUrl, hasLabel }) => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch()
  const LOGIN = gql`
  mutation Login($email: String!,$password: String!){
    login(email:$email,password:$password){
        token
        userRole
        exp
    }
  }
  `;
  // const GET_PROFILE = gql`
  // query{
  //   me{
  //     firstName
  //     lastName
  //     email
  //     active
  //     inactiveReason
  //     requirements
  //     setupStep
  //     dailyMax
  //     dailyMaxAmount
  //     multiplier
  //     transferDay
  //     wholeDollarRoundup
  //   }
  // }
  // `;
  const [login, auth] = useMutation(LOGIN,{variables:{email:email,password:password}})
  // const [getProfile, profile] = useLazyQuery (GET_PROFILE)

  // useEffect(() => {
  //   if(auth.data){
  //     localStorage.setItem("token",auth.data.login.token,)
  //     dispatch({
  //       type:"LOG_IN",
  //       payload:{
  //         loggedIn:true,
  //         token:auth.data.login.token,
  //         role:auth.data.login.userRole,
  //         expiry:auth.data.login.exp
  //       }
  //     })
  //   }
  // }, [auth.data])

  // useEffect(() => {
  //   if(profile.data){
  //     dispatch({
  //       type:"UPDATE_USER",
  //       payload:{
  //         ...profile.data.me
  //       }
  //     })
  //     dispatch({
  //       type:"UPDATE_GET_STARTED",
  //       payload:{
  //         step:profile.data.me.setupStep,
  //         mfa:profile.data.me.isVerified?false:true,
  //       }
  //     })
  //     console.log('here')
  //     setRedirectUrl({
  //       pathname: `/dashboard`
  //     })
  //     setRedirect(true)

  //   }

  // }, [profile.data])


  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    login()
    .then((res) => {
      loginDonor(res.data.login)
      .then(
        setRedirectUrl({
          pathname: `/dashboard`
        }),
        setRedirect(true)
      ).catch(e => {})
      // getProfile().catch(e => {})
    }).catch(e => {})

  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        {/* <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col> */}
        <Col xs="auto">
          <Link className="fs--1" to={`/authentication/forgot-password`}>
            Forget Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>

        <ButtonIcon
              disabled={isDisabled}
              className="ml-auto px-5 mt-2"
            >
              {(!auth.loading ) && "Log in"}
              {auth.loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
              {auth.error &&<ErrorMessage error={auth.error}/>}
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
