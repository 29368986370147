import React, { useState, useEffect, Fragment } from 'react';
import {useSelector} from 'react-redux'
import 'rc-slider/assets/index.css';
import { ButtonToolbar, ButtonGroup, Button, Spinner } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import {useDispatch} from 'react-redux'
import Flex from '../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const UPDATE = gql`
mutation UpdateMultiplier($multiplier:Float){
  updateMultiplier(multiplier:$multiplier){
    multiplier
    transferDay
    wholeDollarRoundup
  }
}
`;
const Multiplier  = () => {
    const {multiplier} = useSelector((state) => state.user)
  const [change, setChange] = useState(false)  
  const dispatch = useDispatch()
  const [value, setValue] = useState(multiplier?multiplier:1)
    useEffect(() => {
      if(value === multiplier){
        setChange(false)
      }else{
        setChange(true)
      }
    }, [value])

    const [update,{data,loading,error}] = useMutation(UPDATE, {
      variables:{multiplier:value}
    })
    const submit = () => {
      update().catch(e => {})
    }
    

    useEffect(() => {
      if(data){
        setChange(false)
        dispatch({
          type:"UPDATE_USER",
          payload:{
            ...data.updateMultiplier
          }
        })
      }
    }, [data])
  return (
      <Fragment>
        <Flex align='center'wrap>
        <p className='m-0 mr-3 font-weight-semi-bold'>Multiplier:</p>
      <ButtonToolbar className='multiplier mr-3 my-2'>
        <ButtonGroup>
          <Button className={value===1?'selected':null} onClick={()=>setValue(1)}>1x</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button className={value===1.5?'selected':null} onClick={()=>setValue(1.5)}>1.5x</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button className={value===2?'selected':null} onClick={()=>setValue(2)}>2x</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button className={value===3?'selected':null} onClick={()=>setValue(3)}>3x</Button>
        </ButtonGroup>
      </ButtonToolbar>
      <div 
          className='ml-auto'>      
              
              <Button
              disabled={!change}
              // disabled={loading || !isNum}
              color={!change?"outline-muted" :"outline-green"}
              className="px-3"
              size='sm'
              onClick={submit}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Save changes"}
              {loading && <Spinner  size={"sm"}/>}
            </Button> 
    

      </div>
            {data && !change && <FontAwesomeIcon icon='check' color='green' className='ml-2'></FontAwesomeIcon>}
  </Flex>
  <p className='fs--1'>The multiplier will multiply your donations. For example, if you have a 2x multiplier, a $0.15 in spare change will result in a $0.30 donation.</p>

      </Fragment>
    
  );
};

export default Multiplier;
