import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './forms/LoginForm';

import AuthCardLayout from '../../layouts/AuthCardLayout';
import {useSelector} from 'react-redux'
import DemoMessage from '../demo/DemoMessage';

const Login = () => {
  const {demo,oneToMany} = useSelector((state) => state.config)
  return (
    <AuthCardLayout
      leftSideContent={
        <Fragment>
          
          <p>
            Don't have a donor account?
          </p>
            <Link className="btn btn-outline-white" to="/get-started">
              Get started
            </Link>
            {oneToMany && 
            <Fragment>
            <p className='my-1'>or</p>
            <Link className={'btn btn-white'} to="/authentication/nonprofit/login">Log in as non-profit</Link>
              </Fragment>

            }

        </Fragment>
      }
    >
      <h3>Donor Login</h3>
      <LoginForm layout="card" hasLabel />
      {demo && <DemoMessage message={"Email: test@test.com   password: pswrd"}/>}
    </AuthCardLayout>
  );
};

export default Login;
