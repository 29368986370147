import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterStandard from '../footer/FooterStandard'
import Banner from '../common/Banner';
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';
import PrivacyContent from '../Terms/PrivacyContent';
// import ReactGA from 'react-ga';
import Section from '../common/Section';


const Privacy = ({ location,match: { url }  }) => {
  // ReactGA.pageview(url);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      <TransparencyLandingNav/>
      <Banner title={"Privacy"} bg="gradient" textColor="white"/>
      <Section>

      <PrivacyContent/>
      </Section>
      <FooterStandard/>
    </Fragment>
  );
};

Privacy.propTypes = { location: PropTypes.object.isRequired };

export default Privacy;
