import React from 'react';
import { Spinner } from 'reactstrap';
import Flex from './Flex';

const TenantLoader = props => (
    <Flex column align='center' justify="center" style={{height:'100vh'}}>
      <Spinner {...props} />
      <p className='text-muted'>{props.loadingMessage}</p>
    </Flex>
);

TenantLoader.propTypes = { ...Spinner.propTypes };

TenantLoader.defaultProps = {
  size: 'lg',
  color: 'muted'
};

export default TenantLoader;
