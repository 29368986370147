import React from 'react';
import Section from '../common/Section';
import DummyData from './sandbox-data/DummyData';
import ProcessDonations from './sandbox-data/ProcessDonations';
import AchOnSuccess from './sandbox-data/AchOnSuccess';
import AchTransfer from './sandbox-data/AchTransfer';

const SandboxData  = ({}) => {
  return (
      <Section className='pt-1'>
       <div className='p-3'>
        <h4 >Sandbox Data</h4>
        <p>This is only valid on a local environment or on a demo app</p>
        <h5>Create fake transactions and pending donations</h5>
        <DummyData/>
        <h5 className='mt-3'>
          Process Donations</h5>
        <ProcessDonations/>
        <h5 className='mt-3'>ACH Transfer</h5>
        <AchTransfer/>
        <h5 className='mt-3'>ACH success</h5>
        <AchOnSuccess/>
      </div> 
      </Section>
  );
};

export default SandboxData;
