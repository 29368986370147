import React, {Fragment, useState} from 'react';
import { Button, Col, Row, Tooltip } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';


const CopyToClipboard = props => {
  const {secondary} = useSelector((state) => state.theme)
  const [copied, setCopied] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const copy = (link) => {
    navigator.clipboard.writeText(link)
    setCopied(true)
  }
  return (
    <Fragment>
    <FontAwesomeIcon  onClick={()=>{copy(props.link)}} icon="copy" color={secondary} {...props}  href="#" id="TooltipExample"/>
    <Tooltip placement={props.placement} isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
        {copied?"Copied!":"Copy to clipboard"}
      </Tooltip>
    </Fragment>


)};

// CopyToClipboard.propTypes = { ...Spinner.propTypes };

CopyToClipboard.defaultProps = {
  placement: 'top',
};

export default CopyToClipboard;
