import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Nav, Navbar,NavLink,  NavItem, Container, Button } from 'reactstrap';

import {useSelector} from 'react-redux'
import Logo from './Logo';
import Flex from '../common/Flex';
import {useDispatch} from 'react-redux'
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  shutdown as shutdownIntercom
} from "../intercom/intercom"

const NavbarDonorDashboard = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const [active,setActive] = useState(false)
  const dispatch = useDispatch()
  const {pushka} = useSelector((state) => state.config)
  const {secondary,primary} = useSelector((state) => state.theme)
  const logout = () => {
    
    shutdownIntercom()
    bootIntercom({
      action_color:secondary,
      background_color:primary})
    // updateIntercom({
    //   email: profile.data.me.email,
    //   name: profile.data.me.firstName + " " + profile.data.me.lastName,
    // })
    dispatch({
      type:"LOG_OUT",
      payload:{
      }
    })
  }

  const handleNavbarTransparency = () => {
    if(window.scrollY >= 80 ){
      setActive(true)
    }else{
      setActive(false)
    }
  }
  window.addEventListener('scroll', handleNavbarTransparency);

  return (
    <Navbar
    light
    fixed="top"
    expand={'md'}
    className={classNames(' navbar-standard navbar-transparent ',active?"active ":null,!navbarCollapsed?"collapsed":null)}
  >
    <Container>
      <Logo icon at='navbar-top' className=""/>
      
    <div className="toggle-icon-wrapper d-md-none d-block">
    <Button
        color="link"
        className="navbar-toggler-humburger-icon navbar-vertical-toggle"
        id="toggleNavigationTooltip"
        onClick={() => setNavbarCollapsed(!navbarCollapsed)}
      >        <span className="navbar-toggle-icon">
      <span className="toggle-line" />
    </span>
  </Button>
    </div>
      <Collapse isOpen={!navbarCollapsed} navbar className="">
        <Nav  navbar className='ml-auto'>
        <NavItem onClick={setNavbarCollapsed}>
          <NavLink  className="mx-1" tag={Link} to="/dashboard">Home</NavLink>
        </NavItem>        
        <NavItem onClick={setNavbarCollapsed}>
          <NavLink  className="mx-1" tag={Link} to="/settings">Account</NavLink>
        </NavItem>
        {pushka && <NavItem onClick={setNavbarCollapsed}>
          <NavLink  className="mx-1" tag={Link} to="/my-causes">Causes</NavLink>
        </NavItem>}
        <NavItem onClick={setNavbarCollapsed}>
          <NavLink  className="mx-1" tag={Link} to="/cards">Cards</NavLink>
        </NavItem>
        {/* <NavItem onClick={setNavbarCollapsed}>
          <NavLink  className="mx-1" tag={Link} to="/purchases">Purchases</NavLink>
        </NavItem> */}
        <NavItem onClick={setNavbarCollapsed}>
          <NavLink  className="mx-1" tag={Link} to="/donations">Donations</NavLink>
        </NavItem>
        <Flex  align='center'>
          <Button  onClick={logout} color='primary' className="btn-navbar ml-3" >Logout</Button>
        </Flex>
        </Nav>
      </Collapse>
    </Container>
  </Navbar>


  );
};

export default NavbarDonorDashboard;
