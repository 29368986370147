import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterStandard from '../footer/FooterStandard'
import Banner from '../common/Banner';
// import ReactGA from 'react-ga';
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';


const Support = ({ location ,match: { url } }) => {
  // ReactGA.pageview(url);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      <TransparencyLandingNav/>
      <Banner title={"Support"} bg="gradient" textColor="white"/>
      <FooterStandard/>
    </Fragment>
  );
};

Support.propTypes = { location: PropTypes.object.isRequired };

export default Support;
