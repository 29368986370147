import React, { useState, useEffect,} from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup,  Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { gql, useMutation, } from  '@apollo/client';
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import Spinner from 'reactstrap/lib/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetBankAccountToken from './GetBankAccountToken'
import WizardInput from '../common/WizardInput';
const ADD_EXTERNAL_ACCOUNT = gql`
mutation addCharityPayoutAccount(  $token: String!){
  addCharityPayoutAccount(token:$token){ 
    _id
    }
  }
`;


function AddExternalAccountForm ({ hasLabel,handleSuccess   }){
  // State
  const {name} = useSelector((state) => state.charityUser.charity)

  const [token, setToken] = useState('');
  const dispatch = useDispatch()
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState('');
  const [account_number, setAccount_number] = useState('');
  const [routing_number, setRouting_number] = useState('');
  const [account_holder_name, setAccount_holder_name] = useState('');
  const [account_holder_type, setAccount_holder_type] = useState('');
  const [success, setSuccess] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [mutate,{loading,data,error}] = useMutation(ADD_EXTERNAL_ACCOUNT, {
     variables:{token:token}
  })

  useEffect(() => {
    if(loading)
      setIsLoading(true)
    if (data){
      setIsLoading(false)
      setSuccess(true)
      dispatch({
        type:"UPDATE_PROCESS",
        payload:{
          loading:false,
          message:null,
        }
      })
      handleSuccess()
    }
    if (error){
      dispatch({
        type:"UPDATE_PROCESS",
        payload:{
          loading:false,
          message:null,
        }
      })
      setIsLoading(false)
    }
  }, [loading, data,error])
  // // Handler
  const onSubmitData = async data => {
    setIsLoading(true)
    dispatch({
      type:"UPDATE_PROCESS",
      payload:{
        loading:true,
        message:'We are verifying your account. Please do not refresh this page. This process can take up to 2 minutes.',
      }
    })
    await GetBankAccountToken({
      ...data,
      account_holder_name: name,
      account_holder_type: 'company',
      country: 'US',
      currency:'usd'
    })
    .then(res => {
      setToken(res.token.id)
      mutate()
      .catch(e => {
        dispatch({
          type:"UPDATE_PROCESS",
          payload:{
            loading:false,
            message:null,
          }
        })
        const messages = e.graphQLErrors.map(({ message, locations, path }) =>   {
          return(message)
        })
        setErrorMessages(messages)
        setIsLoading(false)
      })
    })
    .catch(e => {
      dispatch({
        type:"UPDATE_PROCESS",
        payload:{
          loading:false,
          message:null,
        }
      })
      setErrorMessages(e.message)
      setIsLoading(false)
    })

  };


 

  return (
    <Form  onSubmit={handleSubmit(onSubmitData)} > 
       
      {/* <FormGroup>
        {hasLabel && <Label>Account Holder Name</Label>}
        <WizardInput
          placeholder={!hasLabel ? 'Account Holder Name' : ''}
          value={account_holder_name}
          onChange={e => setAccount_holder_name(e.target.value)}
          type="text"
          placeholder="Account Holder Name"
          id="account_holder_name"
          name="account_holder_name"
          innerRef={register({
            required: 'Account Holder Name is required',
            minLength: {
              value: 2,
              message: 'Min length 2'
            }
          })}
          errors={errors}
          errors={errors}
        />
      </FormGroup>   */}
      <FormGroup>
        {hasLabel && <Label>Account Number</Label>}
        <WizardInput
          placeholder={!hasLabel ? 'Account Number' : ''}
          value={account_number}
          onChange={e => setAccount_number(e.target.value)}
          type="text"
          placeholder="Account Number"
          id="account_number"
          name="account_number"
          innerRef={register({
            required: 'Account Number is required',
            pattern: {
              value: /^[-+]?[0-9]+$/,
              message: 'Account Number Required (numbers only)'
            }
          })}
          errors={errors}
          errors={errors}
        />
      </FormGroup>  
      <FormGroup>
        {hasLabel && <Label>Routing Number</Label>}
        <WizardInput
          placeholder={!hasLabel ? 'Routing Number' : ''}
          value={routing_number}
          onChange={e => setRouting_number(e.target.value)}
          type="text"
          placeholder="Routing Number"
          id="routing_number"
          name="routing_number"
          innerRef={register({
            required: 'Routing Number is required',
            pattern: {
              value: /^[-+]?[0-9]+$/,
              message: 'Routing Number Required (numbers only)'
            },
            minLength: {
              value: 9,
              message: 'Routing Number must be 9 digits'
            },
            maxLength: {
              value: 9,
              message: 'Routing Number must be 9 digits'
            }
          })}
          errors={errors}
          errors={errors}
        />
      </FormGroup>  
      
      
      
      <FormGroup>
        <Button color={success?"success":"primary"} disabled={success} block className="mt-3" >
          {(!isLoading && !success) && "Add Account"}
          {isLoading && <Spinner  size={"sm"}/>}
          {success && <FontAwesomeIcon icon={ ['fas', 'check']} className="fs-2"/>}
        </Button>       
        {errorMessages && 
           <p className='text-danger d-inline-block'>{errorMessages}</p>
         }
      </FormGroup>
    </Form>
  );
};

AddExternalAccountForm.propTypes = {
  hasLabel: PropTypes.bool
};

AddExternalAccountForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default AddExternalAccountForm;
