const initialState = {
    oneToMany: null,
    demo:null,
    management:null,
    pushka:null,
    homeRedirect:null,
    tenant:null,
    approved:null
};

const configReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "STORE_CONFIG":
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default configReducer