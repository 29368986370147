import store from '../store'
import moment from 'moment';
import client from '../../apollo/client'

const initialState = {
    role: null,
    loggedIn: false,
    token:null,
    expiry:null,
    timeout: false,
    authAvilable: false,
};
const authReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "LOG_IN":
            const now = moment()
            const exp = moment(payload.expiry)
            const diff = exp.diff(now)
            localStorage.setItem("token",payload.token)  
            setTimeout(() => {  
                store.dispatch({type:'LOG_OUT'})
            },diff)
            return {
                loggedIn:true,
                role:payload.role,
                token:payload.token,
                expiry:payload.expiry,
                timeout:false,
                authAvilable:true
            }
        case "LOG_OUT":
            localStorage.clear()
            client.clearStore()
            return {
                loggedIn:false,
                role:null,
                token:null,
                expiry:null,
                timout: false,
                authAvilable:true
            }
        case "JWT_EXPIRE":
            localStorage.clear()
            return {
                loggedIn:false,
                role:null,
                token:null,
                expiry:null,
                timout: true,
                authAvilable:true
            }
            
        case "CLEAR_TIMEOUT":
            return {
                loggedIn:false,
                role:null,
                token:null,
                expiry:null,
                timout: false,
                authAvilable:true
            }
        case 'AUTH_AVAIL':
            return {
                authAvilable:true
            }
        default:
            return state
    }
}

export default authReducer 