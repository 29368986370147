import React, { Fragment } from 'react';
import {  CardBody, CardText, CardSubtitle, CardTitle } from 'reactstrap';
import {  faLock,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useSelector} from 'react-redux'
import Flex from '../../../common/Flex';

const Step2 = () => {
  const {appName} = useSelector((state) => state.theme)
  return (
    <Fragment>

<CardBody className='theme-wizard-header rounded  px-md-4  px-sm-3 px-3  py-sm-6 py-4'>
          <CardTitle>Connect a debit or credit card</CardTitle>
          {/* <CardSubtitle >Register your credit card to allow {appName} to monitor your transactions. </CardSubtitle> */}
          

  <CardText className=''><span>
  <FontAwesomeIcon icon={faLock} className="mr-2" size="sm"/></span>The registration process uses <strong>Plaid</strong>, which is safe and secure. Read more about <strong>Plaid</strong> <a href="https://plaid.com/how-it-works-for-consumers/" target="_blank">here</a>.</CardText>

        </CardBody>
        </Fragment>
  );
};

export default Step2;
