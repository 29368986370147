import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faTachometerAlt,
  faCog,
  faPalette,
  faBars,
  faUsers,
  faFileInvoiceDollar,
  faHandsHelping
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Flex from "../../common/Flex";

const SideBar = ({ collapsed, toggleNavbar,mobile }) => {

  const routes = [
    {title:"Dashboard",to:"/nonprofit/dashboard",icon:faTachometerAlt},
    {title:"Account Settings",to:"/nonprofit/settings",icon:faCog},
    {title:"Customization",to:"/nonprofit/theme",icon:faPalette},
    {title:"Payouts",to:"/nonprofit/payouts",icon:faCoins},
    {title:"Donors",to:"/nonprofit/donors",icon:faUsers},
    // {title:"Campaigns",to:"/nonprofit/campaigns",icon:faHandsHelping},
    {title:"Reports",to:"/nonprofit/reports",icon:faFileInvoiceDollar},
  ]
  return(
    <div className={classNames("sidebar", { "collapsed": collapsed }, { "mobile": mobile })}>
    <div className="sidebar-header">
    </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          {routes.map((route,i) => {
            return(
              <NavItem key={i}>
              <NavLink tag={Link} to={route.to}>
                <Flex align='center'>
                  <FontAwesomeIcon icon={route.icon} className="" size="lg"/>
                  <div className='ml-2 sidebar-text'>{route.title}</div>
                </Flex>
              </NavLink>
            </NavItem>
            )
          })}
        </Nav>
      </div>
      
      <Flex column align='center' className="mb-3">
            <Flex  onClick={toggleNavbar} align='center' className="" >
              <FontAwesomeIcon icon={faBars} />
              <div className='sidebar-text ml-2'>Collapse Menu</div>
            </Flex>
      </Flex>
    </div>
  )
}


export default SideBar;
