import React, { useState, useEffect, Fragment } from 'react';

import { gql, useQuery, useMutation } from '@apollo/client';
import Flex from '../../common/Flex';
import {  Form, Row, Col, FormGroup, Input,  Label,  Spinner, Card, CardBody,CardTitle, Alert, Button  } from 'reactstrap';
import ErrorMessage from '../../common/ErrorMessage';
import { useForm } from "react-hook-form";
import ButtonIcon from '../../common/ButtonIcon';
import WizardInput from '../../common/WizardInput';
import CopyToClipboard from '../../common/CopyToClipboard';
import MyDonorGroupsTable from '../../tables/MyDonorGroupsTable';

const DonorGroupDetails = ({   _id }) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const Details = gql`
  query donorGroupDetails($_id:String!){
    donorGroupDetails(_id:$_id){
      name
      description
      created
      _id
    }
    donorGroupInviteLink(_id:$_id)
  }
  `;
//   const EDIT = gql`
//   mutation editCampaign($_id:String!,$name:String!,$description:String!){
//     editCampaign(_id:$_id,name:$name,description:$description){
//       name
//       description
//       _id
//       created
//     }
//   }
// `;

  const {data, loading,error,refetch,  } = useQuery (Details,{variables:{_id:_id}})  
  // const [edit, editResponse] = useMutation(EDIT)  
  useEffect(() => {
    if(data){
      setName(data.donorGroupDetails.name)
      setDescription(data.donorGroupDetails.description)
    }
  }, [data])
  // const onSubmit = (data) => {
  //   edit({variables:{...data,_id:_id}})
  //   // .then( () => {
  //   //   getProfile().catch(e => {})
  //   // })
  //   .catch(e => {})
  // }
  return (
    <Fragment>  
      {loading && <Spinner/>}
      {data && <Fragment>
        <p className='font-weight-semi-bold mb-0'>Name: <span className='font-weight-normal'>{data.donorGroupDetails.name}</span></p>
        <p className='font-weight-semi-bold mb-0'>Description: <span className='font-weight-normal'>{data.donorGroupDetails.description}</span></p>
        <p className='font-weight-semi-bold mb-0'>Invite Link: <span className='font-weight-normal'>{data.donorGroupInviteLink}</span></p>
        
        
        </Fragment>}

    
    
    </Fragment>


  );
};


export default DonorGroupDetails;
