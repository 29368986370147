import React, {Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import {useSelector} from 'react-redux'

const Error404 = () => {
  
  const {demo,oneToMany} = useSelector((state) => state.config)
  return (
    <Card className="text-center">
      <CardBody className="p-5">
        <div className="display-1 text-200 fs-error">401</div>
        <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
          Unauthorized
        </p>
        <hr />
        <p>
          You do not have permission to visit this page
          {/* <a href="mailto:info@exmaple.com" className="ml-1">
            contact us
          </a> */}
          .
        </p>
        
        <Link className="btn btn-primary btn-sm " to="/authentication/login">
          <FontAwesomeIcon icon="user" className="mr-2" />
          Login
        </Link>
      </CardBody>
    </Card>
  );
}

export default Error404;
