import React, { Fragment, useEffect, useState } from 'react';
import AdminDashboardLayout from '../../layouts/AdminDashboardLayout';
// import ReactGA from 'react-ga';


const AdminDashboard  = ({ match: { url } }) => {
  // ReactGA.pageview(url);


  return (
    <AdminDashboardLayout>
      <h4>Admin Dashboard</h4>
      
      </AdminDashboardLayout>
  );
};

export default AdminDashboard;
