import React from 'react';
import NotAllowed from '../../../assets/images/illustration/not-allowed.png'
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Flex from '../../common/Flex'

const NotApproved = () => {
  const {appName} = useSelector((state) => state.theme)
  const {transferDay} = useSelector((state) => state.user)
  const days = ['sunday'  ,    'monday',    'tuesday',    'wednesday',    'thursday',    'friday',    'saturday',]

  return (
    <div className='p-4'>
      <Flex justify='center'>
          <img className='dashboard-img p-2 mb-2'src={NotAllowed} alt=""/>

      </Flex>
      <h5 className='mb-3'>The {appName} campaign is not active</h5>
      <p>{appName} has recently signed up with sparechange and their application is being reviewed.</p>
          <p>Are you part of {appName} and want an update on your application? Please contact the sparechange support team.</p>
          
      <Link className="btn btn-primary" to="/">Ok</Link>
        </div>
  );
};

export default NotApproved;
