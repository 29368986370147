import React from 'react';
import { Button, } from 'reactstrap';
import AuthBasicLayout from '../../../layouts/AuthBasicLayout';
// import ReactGA from 'react-ga';
import {useDispatch} from 'react-redux'
const PendingApproval= ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const dispatch = useDispatch()
  const logout = () => {
    dispatch({
      type:"LOG_OUT",
      payload:{
      }
    })
  }
  return (
    <AuthBasicLayout>
    <div className = ''>
        <h4>Your application is under review</h4>
        <p>Our team is reviewing your application. You will be notified via email when your application is approved</p>
        <p>If we need additional information from you, we will contact you via email.</p>
        <Button onClick={logout}>Logout</Button>
      </div>
      </AuthBasicLayout>
  );
};

export default PendingApproval;
