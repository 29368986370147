import React, { Fragment, useState,useEffect } from 'react';
import {useSelector} from 'react-redux'
import Flex from '../../common/Flex';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountSelectList from '../../bankAccount/AccountSelectList';
import Loader from '../../common/Loader';
import TextLink from '../../plaid/TextLink';
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import { Spinner } from 'reactstrap/lib';
import OauthLink from '../../plaid/OauthLink';
import { useHistory } from "react-router-dom";

import { Button } from 'reactstrap';

const ManageCards  = ({SCF_type,linkSuccess,accounts,loading,refetch}) => {
  const {appName, primary} = useSelector((state) => state.theme)
  const [isAccountUpdating, setIsAccountUpdating] = useState(false);
  const dispatch = useDispatch()
  const [creditCards, setCreditCards] = useState([]);
  const [debitCards, setDebitCards] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if(accounts){
      const creditAccounts = _.filter(accounts,{SCF_type:'credit'})
      const debitAccounts = _.filter(accounts,{subtype:'checking'})
      setCreditCards(creditAccounts)
      setDebitCards(debitAccounts)
    }
  }, [accounts])
  const openPlaid = () => {
     dispatch({
      type:"ADD_NEW_CARD",
      payload: {
        redirectURL:window.location.href,
        product:["transactions"]
      }
    })
    
    history.push('/plaid');
  }
  return (
    <Fragment>
                  {loading && 
              <div className='py-5 w-100'>
                <Loader/>
              </div>}
              {!loading && 
              <Fragment>
  <h5 className='mb-3'>Select the cards you want use for {appName} round-up donations</h5>
              <p>Use the toggle buttons below to select which credit cards to use, or register another card.</p>        
  
              <Flex align="end" className='mb-3'>
                <Button onClick={openPlaid} color="link" className="ml-auto">
                  Register another credit card
                  </Button>
              </Flex>
              {creditCards[0] && 
            <div>
              <h6>Credit Cards ({creditCards.length}):</h6>
              <AccountSelectList
                readOnly={false}
                refetch={refetch}
                SCF_type={SCF_type}
                loading={loading}
                setIsLoading={setIsAccountUpdating}
                accounts={creditCards}
              ></AccountSelectList>
            </div>
            }
            {debitCards[0] && 
            <div>
              <h6>Debit Cards ({debitCards.length}):</h6>
              <AccountSelectList
                readOnly={false}
                refetch={refetch}
                SCF_type={SCF_type}
                loading={loading}
                setIsLoading={setIsAccountUpdating}
                accounts={debitCards}
               ></AccountSelectList>
             </div>
            }
              </Fragment>
              }
 
 </Fragment>
  );
};

export default ManageCards;
