import React, { useState, useEffect } from 'react';
import { gql,useQuery } from '@apollo/client';
import { Form, Input, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CharitySelect from './CharitySelect'
import Flex from '../../common/Flex';

const CHARITY_SEARCH_QUERY = gql`
  query CharitySearchQuery($filter: String!) {
    charity(filter: $filter) {
      id
      count
      charities{
        _id
        name
        EIN
      }
    }
  }
`;
const CharitySearch = ({onSelect,EINSelect, EINCompare,tenantId}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [charities, setCharities] = useState([]);
  const { data, loading } = useQuery(CHARITY_SEARCH_QUERY, {
    variables:{filter:searchFilter},
    context: { headers: { tenantId: tenantId } }
  })

  useEffect(() => {
    if(loading ){
      setIsLoading(false)
    }
    if (data && data.charity){
      setIsLoading(false)
      setCharities(data.charity.charities)
    }
  }, [loading, data])
  return(
    <div className=''>
      <Form inline className="search-box mb-3 ">
        <Input onChange={(e) => setSearchFilter(e.target.value)} type="search" placeholder="Search by EIN or Name" aria-label="Search" className="rounded-pill search-input w-100  " />
        <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
      </Form>
      {/* <p className='text-muted my-2 pl-2'>Search by either Non-profit name or EIN number</p> */}

      {isLoading && <Spinner/>}
      {charities && 
      <CharitySelect charities={charities} onSelect={onSelect} EINSelect={EINSelect} EINCompare={EINCompare}/>
      }
      <div className='mt-6'>
        <Flex column align='center'>
          <p className='text-muted font-weight-semi-bold text-center'>Is your cause not on Pushka yet?</p>
          <p className=' text-muted fs--1 text-center'>Tell us which causes you want on Pushka by emailing us at <span><a class="mailto" href="mailto:hello@pushka.fund">hello@pushka.fund</a></span></p>
          <p className='text-muted fs--1 text-center'>Non-profits can join Pushka for free by registering <span><a href="https://pushka.sparechange.fund/authentication/nonprofit/signup" target="_blank">here</a></span>.</p>
        </Flex>

      </div>
    </div>

  );
};

export default CharitySearch