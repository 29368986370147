import React from 'react';
import moment from 'moment'
import EditAndApproveCharity from '../admin/EditAndApproveCharity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkAlt,} from "@fortawesome/free-solid-svg-icons";
import TablePaginaton from './react-table/TablePaginaton';
import { NavItem, NavLink} from "reactstrap";
import BadgePending from './cell-renderings/BadgePending';
import BadgeSucceeded from './cell-renderings/BadgeSucceeded';
import { Link } from 'react-router-dom';
import Source from './cell-renderings/Source';
import Flex from '../common/Flex';
import TransferStatusBadge from '../dashboard/donations/TransferStatusBadge';


function Table({data, title, tooltip,handleRowClick}) {
  const columns = React.useMemo(
    () => [
      {
        id: 'data',
        accessor: d => 
        <div className='py-0'onClick={() => handleRowClick(d._id)} >
          <Flex justify='between' wrap>
            <p className='mb-0 mr-2'>{ moment(d.created)
              .local()
              .format("MMM DD, YYYY")}
              </p>
              <div className='mr-2'>
              <Source last4={d.source.last4}/>

              </div>
            <Flex className='' align='center'>
              <p className='mb-0'>{"$"+ Number(d.amount).toFixed(2)}</p>
              <TransferStatusBadge {...d} className='ml-2' />
            </Flex>
          </Flex>
        </div>
      }
      
      // {
      //   id:'created',    
      //   Header: 'Date',
      //   accessor: d => {
      //     return moment(d.created)
      //       .local()
      //       .format("MMM D, YYYY")
      //   }
      // },
      // {
      //   Header: 'Amount',
      //   id: 'amount',
      //   accessor: d => "$"+ Number(d.amount).toFixed(2),
      // },
      // {
      //   id:'status',    
      //   Header: '',
      //   //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
      //   accessor: d => 
      //     d.status==='pending'
      //     ?<BadgePending/>
      //     :d.status==='succeeded'
      //       ?<BadgeSucceeded/>
      //       :""
      // },
      // {
      //   id:'source',    
      //   Header: 'Source',
      //   //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
      //   accessor: d => <Source last4={d.source.last4}/>
      // },
      // {
      //   id:'link',    
      //   Header: '',
      //   //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
      //   accessor: d =>     
      //   <NavItem  className='list-unstyled'>
      //   <NavLink className='p-0' tag={Link} to={'?_id='+d._id}>
      //       <FontAwesomeIcon icon={faExternalLinkAlt} className="" size="md"/>
      //   </NavLink>
      // </NavItem>
      // },

    ],
    []
  )

  //const data = React.useMemo(() => makeData(10), [])
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
        <EditAndApproveCharity charityUser={row.original}/>

    ),
    []
  )


  return (
    <div>
      
      <TablePaginaton
      tooltip={tooltip}
        title={title}
        columns={columns}
        data={data}
        initialPageSize={3}
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  )
}

export default Table
