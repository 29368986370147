import React, { useState, useEffect, Fragment } from 'react';

import { gql, useMutation,  } from '@apollo/client';
import Flex from '../common/Flex';
import {  Form, FormGroup, Input,  Label,  Spinner , Row, Col , Card, CardBody, CardTitle} from 'reactstrap';
import ErrorMessage from '../common/ErrorMessage';
import Logo from '../common/Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames'
import ButtonIcon from '../common/ButtonIcon';

const Footer = ({  theme, refetch }) => {
  const [mission, setMission] = useState('')
  const [url, setUrl] = useState('')
  const [urlMask, setUrlMask] = useState('')
  const [facebook, setFacebook] = useState('')
  const [instagram, setInstagram] = useState('')
  const [iconLogo, setIconLogo] = useState('')
  const [charityName, setCharityName] = useState('')
  const [secondary, setSecondary] = useState('')
  const UPDATE = gql`
  mutation UpdateTheme($input:ThemeInput!){
    updateTheme(input:$input)
  }
`;
  const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{mission:mission, url:url,urlMask:urlMask,facebook:facebook,instagram:instagram}}})
  useEffect(() => {
    if (theme){
      setMission(theme.mission)
      setUrl(theme.url)
      setUrlMask(theme.urlMask)
      setFacebook(theme.facebook)
      setInstagram(theme.instagram)
      setIconLogo(theme.iconLogo)
      setCharityName(theme.charityName)
      setSecondary(theme.secondary)
    }
  }, [theme])
  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (
    <Fragment>  
      <p>The footer section is yet another opportunity to brand your campaign. Feel free to include links to your main website and other social accounts.</p>
      <Flex wrap align='center' className='p-3'>
    <Form onSubmit={handleSubmit} className='w-100'>
      
    <Row>
        <Col lg={6}>
        <FormGroup>
        {true && <Label>Mission</Label>}
        <Input
          placeholder={!true ? 'Mission' : ''}
          value={mission}
          onChange={({ target }) => setMission(target.value)}
          type="textarea"
          rows="4"
        />
      </FormGroup>
      <FormGroup>
        {true && <Label>Website URL</Label>}
        <Input
          placeholder={!true ? 'Website Url' : ''}
          value={url}
          onChange={({ target }) => setUrl(target.value)}
          type="text"
        />
        <p className=' fs--1 text-muted'>Include "https://"</p>
      </FormGroup>
      <FormGroup>
        {true && <Label>Website URL Mask</Label>}
        <Input
          placeholder={!true ? 'Website Url Mask' : ''}
          value={urlMask}
          onChange={({ target }) => setUrlMask(target.value)}
          type="text"
        />
        <p className=' fs--1 text-muted'>Enter the mask of your website (i.e. website.com)</p>
      </FormGroup>
      <FormGroup>
        {true && <Label>Facebook Link</Label>}
        <Input
          placeholder={!true ? 'Facebook Link' : ''}
          value={facebook}
          onChange={({ target }) => setFacebook(target.value)}
          type="text"
        />
        <p className=' fs--1 text-muted'>Include "https://"</p>
      </FormGroup>
      <FormGroup>
        {true && <Label>Instagram Link</Label>}
        <Input
          placeholder={!true ? 'Instagram Link' : ''}
          value={instagram}
          onChange={({ target }) => setInstagram(target.value)}
          type="text"
        />
        <p className=' fs--1 text-muted'>Include "https://"</p>
      </FormGroup>
      <FormGroup>
        <ButtonIcon disabled={loading}>
              {(!loading ) && "Save Changes"}
              {loading && <Spinner  size={"sm"}/>}
        </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
        </Col>
        <Col lg={6}>
          <Card className='p-2 border border-primary'>
            <CardTitle>Preview:</CardTitle>
            <CardBody >
            <Flex column  align='start'>

            <Logo at={'footer'} text={charityName} publicId={iconLogo} className='mb-3' />
            <p className="">{mission}
            <Flex className='footer-social' align='center' className='pt-2'>
              {url && urlMask && <a href={url} className={classNames('mr-3 text-black')}target="_blank"><strong>{urlMask}</strong></a>}
              {facebook && <a href={facebook} target="_blank">
                <FontAwesomeIcon   color={secondary} size='2x' className='mx-1' icon={['fab', 'facebook']} ></FontAwesomeIcon>
              </a>}
              {instagram && <a href={instagram} target="_blank">
                <FontAwesomeIcon color={secondary} size='2x' className='mx-1'  icon={['fab', 'instagram']} ></FontAwesomeIcon>
              </a>}
            </Flex>
              </p>
            </Flex>

            </CardBody>
          </Card>
          
        </Col>
      </Row>
     
    </Form>
    </Flex>
        {error &&<ErrorMessage error={error}/>}

    
    
    
    </Fragment>


  );
};


export default Footer;
