import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from '../../common/Flex';

const Source = ({last4}) => {
  return (
        <Flex align='center'>
          <div className='px-1 content-center bg-light-gray'>
          <FontAwesomeIcon className='' icon={'university'} size='md'></FontAwesomeIcon>
          </div>          
          <p className='m-0 ml-2'>••••{last4}</p>
        </Flex>
  );
};

export default Source;
