import React, { Fragment,  useState } from 'react';
import TenantSelector from './Tenant/TenentSelector';
import CharitySelector from './Charity/CharitySelector';
import CreatePayout from './Payout/CreatePayout';
// import ReactGA from 'react-ga';
import AdminDashboardLayout from '../../layouts/AdminDashboardLayout';


const PayoutCharity  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const [tenantId,setTenantId] = useState('');
  const [charity,setCharity] = useState({});


  return (
    <AdminDashboardLayout>
      <TenantSelector className='mb-3' select={(tenant)=>{setTenantId(tenant._id)}}/>
      <CharitySelector tenantId={tenantId} className='mb-3' select={setCharity}/>
      <CreatePayout tenantId={tenantId} charity={charity}/>
      <p>{tenantId}</p>
    </AdminDashboardLayout>
  );
};

export default PayoutCharity;
