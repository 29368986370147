const initialState = {
    email: null,
    firstName:null,
    lastName:null,
    charityName:null,
    approved:null,
    EIN:null,
    isVerified:null,
    onboardingComplete:null,
    onboardingCreatingCharity:false,
    charity:{
        name:null,
        EIN:null,
        stripeRequirements:null,
        publishedTheme:null,
        donorGroupsEnabled:null
    },
};

const charityUserReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "LOG_OUT":
            return {
                ...initialState
            }
        case "UPDATE_CHARITY_USER": 
        console.log(payload)
            return {
                ...state,
                ...payload
            }
        case "UPDATE_CHARITY": 
            return {
                ...state,
                charity:{
                    ...payload
                }
            }    
        default:
            return state
    }
}

export default charityUserReducer