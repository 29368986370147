import React, { useState, useEffect } from 'react';
import {  Card,} from 'reactstrap';
import { gql, useQuery  } from '@apollo/client';
import classNames from 'classnames'
import Flex from '../../common/Flex';
import {useSelector} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confetti from '../../../assets/images/illustration/hourglass.png'
import DonationStatusBadge from '../donations/DonationStatusBadge';
const RoundupKey  = ({className}) => {
 // const [total, setTotal] = useState(0)
  // const [pending, setPending] = useState(0)
  // const {primary, } = useSelector((state) => state.theme.colors)
  // const [transferring, setTransferring] = useState(0)

  // const PENDING = gql`
  // query pendingDonationSummary{
  //   pendingDonationSummary{
  //     pending
  //     transferring
  //     transferQueue
  //   }
  // }
  // `;
  // const getPending = useQuery (PENDING)
  // useEffect(() => {
  //   if(getPending.data){
  //   //  setTotal(getPending.data.pendingDonationSummary.total)
  //     setPending(getPending.data.pendingDonationSummary.pending + getPending.data.pendingDonationSummary.transferQueue )
  //     setTransferring(getPending.data.pendingDonationSummary.transferring)
  //   }
  // }, [getPending.data])

  return (
    <div  className={classNames(className )}>

<Card className={' p-md-4 p-3 shadow-none h-100 '}>
  <Flex className='h-100 text-center' column align='center' justify='center'>
    {/* <h5 className='mb-3'>Roundup Key</h5> */}
    <Flex  className='w-100 mb-1' align='center' >
      <DonationStatusBadge status={'pending'} className='mr-2'/>
      <p className='mb-0 fs--1'>Pending purchase</p>
    </Flex>
    
    <Flex  className='w-100 mb-1' align='center' >
      <DonationStatusBadge status={'transferQueue'} className='mr-2'/>
      <p className='mb-0 fs--1'>Roundup will be included in next donation</p>
    </Flex>
    
    <Flex  className='w-100 mb-1' align='center' >
      <DonationStatusBadge status={'transferring'} className='mr-2'/>
      <p className='mb-0 fs--1'>Donation is transferring</p>
    </Flex>
    
    <Flex  className='w-100 mb-1' align='center' >
      <DonationStatusBadge status={'processed'} className='mr-2'/>
      <p className='mb-0 fs--1'>Donation Successful</p>
    </Flex>
    {/* <img className='dashboard-img p-2'src={Confetti} alt=""/> */}
    {/* <h4>${(pending).toFixed(2)}</h4> */}
  </Flex>
  {/* <Flex align='center' wrap  className='mt-2 fs--1'>
      <p className='mb-0 '> 
      <FontAwesomeIcon icon='exchange-alt' color={primary} className='mr-2'/>
      <span className=''>You have ${transferring.toFixed(2)} in donations transferring from your bank account. </span>
      </p>
    </Flex> */}
</Card>
    </div>
  );
};

export default RoundupKey;
