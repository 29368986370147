import React, {useState} from 'react';
import moment from 'moment'
import EditAndApproveCharity from '../admin/EditAndApproveCharity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkAlt, faMailBulk,} from "@fortawesome/free-solid-svg-icons";
import { Badge, Table, Button, NavLink, Spinner, Card, Tooltip} from "reactstrap";
import BadgePending from './cell-renderings/BadgePending';
import { useTable, usePagination,  } from 'react-table'
import BadgeSucceeded from './cell-renderings/BadgeSucceeded';
import { Link } from 'react-router-dom';
import Source from './cell-renderings/Source';
import Flex from '../common/Flex';
import TransferStatusBadge from '../dashboard/donations/TransferStatusBadge';
import { gql, useQuery } from '@apollo/client';
import TableLoader from '../common/TableLoader';
import TableNoData from '../common/TableNoData';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import {  isMobile} from "react-device-detect";
const MY_CAMPAIGNS = gql`
  query myDonorGroups{
    myDonorGroups{
      name
      description
      created
      _id
    }
  }
  `;
  

  const NoAvgTooltip = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
  
    const toggle = () => setTooltipOpen(!tooltipOpen);
  
    return (
      <div>
        <Badge color='muted' href="#" id="TooltipExample" >
<Flex>
      <p className='mb-0 mr-1 '>Not avail.</p>
        <FontAwesomeIcon color='muted' icon={'clock'} />

</Flex>
        </Badge>
        {/* <p style={{textDecoration: "underline", color:"blue"}} href="#" id="TooltipExample">tooltip</p> */}
        {/* <p>Somewhere in here is a <span style={{textDecoration: "underline", color:"blue"}} href="#" id="TooltipExample">tooltip</span>.</p> */}
        <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
          Average will be calculated after 4 weeks of donations
        </Tooltip>
      </div>
    );
  }
  
function MyDonorGroupsTable({ title, tooltip,selectCampaign, className,active}) {

  const {data,loading,error, refetch} = useQuery(MY_CAMPAIGNS,{
    fetchPolicy: "no-cache"
  })

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: d => d.name,
        Cell: props => <div>{props.value}</div>
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: d => d.description,
        Cell: props => <div>{props.value}</div>
      },
      {
        id:'created',    
        Header: 'Created',
        accessor: d => d.created,        
        Cell: props => <div>{ moment(props.value).local().format("MMM Do, YYYY")}</div>
      },
      {
        id: '_id',
        accessor: d => d._id,
      },
    ],
    []
  )

  function TablePaginaton({ columns, data,initialPageSize, }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      pageCount,
      nextPage,
      previousPage,
      state: { pageIndex },
      
    } = useTable(
      {
        columns,
        data,
        initialState: { 
          pageIndex: 0, 
          pageSize: 5,
          hiddenColumns: ['_id']
        },
      },
      usePagination
    )
  
    // Render the UI for your table
    return (
      <div className={`bg-white ${className}` } >
        <div style={{minHeight:'180px'}} >
    <Table responsive size="sm" className={`fs--1 table-bordered  table-hover`}  {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} onClick={()=>selectCampaign(row.values._id)}>
                  {row.cells.map(cell => {
                    return <td className=''  data-title={cell.render('Header')} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                  })}
                </tr>
              )
            })}
          </tbody>
          
        </Table>
          {loading && <TableLoader height={130}/>}
          {!loading && !data[0] &&  <TableNoData message={"No Donor Groups yet"} height={150}/>}
          </div>
                   <Flex wrap align='center' className='pl-1'>
          <p className='m-0 fs--1 text-muted'>{"results: " + data.length}</p>
          <Button color='falcon-primary' size='sm' className='ml-2' onClick={() => refetch()}><FontAwesomeIcon icon={faSync}/></Button>
          
        <Flex justify='end' align='center' className='ml-auto' >
          <p className='m-0 mr-2 fs--1'>{'page ' }{ pageIndex + 1} {' of ' + pageCount}</p>
          <ul className='pagination mb-0'>
            <li  className='page-item'>
              <Button disabled={pageIndex===0} color='falcon-primary' size='sm' onClick={() => previousPage()}>Previous</Button>
            </li>
            <li  className='page-item'>
              <Button disabled={pageIndex===pageCount-1}  color='falcon-primary' size='sm' onClick={() => nextPage()}>Next</Button>
            </li>
            <li  className='page-item'></li>
          </ul>
        </Flex>
        </Flex>
        
      </div>
    )
  }

  return (
      <TablePaginaton
        tooltip={tooltip}
        title={title}
        columns={columns}
        data={data?data.myDonorGroups:[]}
        loading={loading}
        initialPageSize={3}
        //renderRowSubComponent={renderRowSubComponent}
      />
      
  )
}

export default MyDonorGroupsTable
