
import React, {Fragment, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import {useDispatch} from 'react-redux'
import TenantLoader from '../components/common/TenantLoader';

const CONfIG = gql`
query{
  getConfig{
    appName
    oneToMany
    demo
    management
    pushka
  }
}
`;

const Config = (data) => {
  // const { data, loading, error  }  = useQuery(CONfIG)
  const dispatch = useDispatch()
  const addConfig = (data) => {
    dispatch({
      type:"STORE_CONFIG",
      payload: {
        ...data
      }
    })
  }
  useEffect(() => {
    if (data){
      console.log(data)
      addConfig(data)
    }
    }, [data,]
  )

    return (
      <Fragment>
{/*         
        {error && <p>Error: this domain is not a valid tenant</p>}
        {loading && <TenantLoader loadingMessage='loading...'/>}
        {data &&
          <Fragment>
            {props.children}
          </Fragment>
        } */}
    </Fragment>
      
    );
  };
  
  export default Config;




