import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, ListGroup, ListGroupItem,  Spinner } from 'reactstrap';
import classNames from 'classnames'
import moment from 'moment'
const CHARITY_USERS = gql`
query myCharity{
  myCharity{
    name
    EIN
    created
    approved
  }
}
`;


const Charity  = ({className}) => {
  const {data,loading} = useQuery(CHARITY_USERS, {})
  const [charity,setCharity] = useState([]);

  useEffect(() => {
    if(data){
      setCharity(data.myCharity)
    }
  }, [data])

  return (
      
    <div  className={classNames(' ',className )}>
    <Card className='p-md-4 p-2  shadow-none'>
          <h5 className='mb-0'>Nonprofit</h5> 
          {loading && <Spinner/>}
        <ListGroup flush>
            <ListGroupItem className='px-md-3 py-3 px-0'>
                <p className=' fs--1 mb-0  font-weight-bold' >Application Status:  <span className={charity.approved?'text-green':'text-yellow'}>{charity.approved?'Approved':'Under Review'}</span></p>
            </ListGroupItem>
            <ListGroupItem className='px-md-3 py-3 px-0'>
                <p className=' fs--1 mb-0  font-weight-bold' >Name:  <span className='font-weight-normal'>{charity.name}</span></p>
            </ListGroupItem>
            <ListGroupItem className='px-md-3  py-3 px-0'>
                <p className='fs--1 mb-0  font-weight-bold'  >EIN: <span className='font-weight-normal'>{charity.EIN}</span></p>
            </ListGroupItem>
            
            <ListGroupItem className='px-md-3 px-0'>
                
            <p className='fs--1 mb-0  font-weight-bold'  >Created: <span className='font-weight-normal'>{moment(charity.created).local().format('MMMM Do YYYY')}</span></p>
                
            </ListGroupItem>
        </ListGroup>
    </Card></div>
  );
};

export default Charity;
