export default {
  primary: "#0091AD",
  secondary: "#B7094C",
  primary_gradient: "#455E89",
  primary_light: "#72adc0",
  white: '#FFFFFF',
  gray_100:"#F9FAFC",
  gray_200:"#EDEEF4",
  gray_300:"#D3D5E4",
  gray_400:"#D3D5E4",
  gray_500:"#C0C2D8",
  gray_600:"#A6AAC9",
  gray_700:"#7D84AB",
  gray_800:"#545D8C",
  gray_900:"#545D8C",
  gray_1000:"#3E4365",
  gray_1100:"#2E324D",
  black:"#222539",
}