import React, { Fragment,useState,useEffect } from 'react';
import {  CardBody, Button,  CardFooter,CardTitle, Alert, ListGroup } from 'reactstrap';
import ButtonIcon from '../../common/ButtonIcon';
import Spinner from 'reactstrap/lib/Spinner';
import { gql, useMutation,useQuery } from '@apollo/client';
import { useLocation, useHistory   } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import Loader from '../../common/Loader';
import BankAccountList from '../../stripe/BankAccountList';
import {useSelector} from 'react-redux'
import Flex from '../../common/Flex';
import ErrorMessage from '../../common/ErrorMessage';
import ConnectBankAccountModal from '../../bankAccount/connectBankAccountModal/ConnectBankAccountModal';
import BankExplainModal from './BankExplainModal';
import _ from 'lodash'
import PlaidAccountVerifyList from '../../bankAccount/PlaidAccountVerifyList';
import AlertModal from '../../common/AlertModal';
import { NetworkStatus } from '@apollo/client';

const STRIPE_ACCOUNTS = gql`
query MyStripeBankAccounts{
  myDefaultStripeBank{
    _id
    account_holder_name
    bank_name
    last4
    routing_number
    currency
    created
    default
    status
}
  myStripeBankAccounts{
    _id
    account_holder_name
    bank_name
    last4
    routing_number
    currency
    created
    default
    status
  }
  availableAccounts(SCF_type:"checking"){
    _id
    mask
    name
    official_name
    SCF_type
    active
    subtype
    plaidItem{
      stripeAuthSupported
      name
      color
      logo
      loginRequired
    }
  }
}
`;
const WIZARD_STEP_3 = gql`
mutation WizardStep3{
  wizardStep3
}
`;

const Step3 = () => {
  const [view, setView] = useState("OPTIONS")
  const {appName} = useSelector((state) => state.theme)
  const [accounts,setAccounts] = useState([]);
  const [plaidAccounts,setPlaidAccounts] = useState([]);
  const [defaultAccount,setDefaultAccount] = useState({});
  const [show,setShow] = useState(false);
  const [caseNumber,setCaseNumber] = useState(0);
  const {data,loading,error,refetch,networkStatus } = useQuery(STRIPE_ACCOUNTS,{
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  })
  const [mutate,submitResponse] = useMutation(WIZARD_STEP_3)
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(true);
  const [explainModal, setExplainModal] = useState(false);
  const [plaidRedirectUrl, setPlaidRedirectUrl] = useState(window.location.href)
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  const changeBank = params.get('change_bank'); 

  const changeAccount = () => {
    history.push('?change_bank=OPTIONS')
  }
  const removeSearch = () => {
    history.replace({
      search: '',
    })
  }  
  useEffect(() => {
    if(changeBank){
      setModal(true)
    }
  }, [changeBank])
  useEffect(() => {
    if (data){
      
      setDefaultAccount(data.myDefaultStripeBank)
      setAccounts(data.myStripeBankAccounts)
      setPlaidAccounts(data.availableAccounts)
      // setAccounts(stripeAccountsData.data.myStripeBankAccounts)
      // const defaultAccount = _.find(stripeAccountsData.data.myStripeBankAccounts, {default:true})
      // setDefaultAccount(defaultAccount)
    }
  }, [data])
  useEffect(() => {
    if(submitResponse.data){
      dispatch({
        type:"UPDATE_GET_STARTED",
        payload: {
          step: 3,
        }
      })
    }
    if(submitResponse.error){
      setErrorModal(true)
    }
  }, [submitResponse.error, submitResponse.data])


  //case:
  //  1) no account data at all, need to link the first bank account
  //  2) there is plaid account data but nothing is default
  //  3) a new account was verified and set to default
  //  4) user wants to show all possble bank accounts



  useEffect(() => {
    if(!loading && !accounts[0] && !plaidAccounts[0]){
      //case 1
      setCaseNumber(1)
    } else if(!loading && !accounts[0] && plaidAccounts[0]){
      //case 2
      setCaseNumber(2)
    } else if(!loading && defaultAccount && !show){
      //case 3
      setCaseNumber(3)
    }else if(!loading && defaultAccount && show){
      //case 4
      setCaseNumber(4)
    }
  }, [defaultAccount,plaidAccounts,accounts,show,loading])

  const onClick = (e) => {
    e.preventDefault()
    //setIsSubmitLoading(true)
    mutate().catch(e => {})
  };
  const showAccounts = () => {
    setShow(true)
  }



  return (
    <Fragment>
      {/* {networkStatus === NetworkStatus.refetch && <p>refetching...</p>} */}
      {submitResponse.error && <AlertModal modal={errorModal} setModal={setErrorModal} title={'Error'} buttonText='Ok' onClick={() => {setErrorModal(false)}}>
        <p>{submitResponse.error.message}</p>
      </AlertModal>}
      <ConnectBankAccountModal refetch={refetch}  accounts={accounts} modal={modal} view={changeBank} setModal={setModal} removeSearch={removeSearch} redirectURL={plaidRedirectUrl}/>

      <CardBody className="px-md-4  px-2">
          {loading && <Loader/>} 
          { !loading && 
            <Fragment>
                
              <p>Your roundups are group together and withdrawn once a week from your bank account to minimize fees (similar to venmo).</p>
            {((plaidAccounts[0] || accounts[0] )&& !defaultAccount)&&  <p className='font-weight-semi-bold'>Verify an account, then click Next.</p>
}
                
              <ListGroup flush>
                <BankAccountList refetch={refetch} accounts={accounts}  hideVerificationButton/>
               {(caseNumber === 2 || caseNumber === 4) && 
                <PlaidAccountVerifyList accounts={plaidAccounts} refetch={() => refetch()}/>
                 }
              </ListGroup>
              {/* {(plaidAccounts[0] || accounts[0] || defaultAccount)&&  <p className=''>Want to use a different bank? <span><a style={{textDecoration: "underline"}} href="#"  onClick={changeAccount}>Connect another account</a></span>.</p>
} */}
       {caseNumber === 3 && <Flex>
         <Button  className='ml-auto p-0 fs--1 mb-2' style={{textDecoration: "underline"}}  onClick={showAccounts} color='link'>Show other accounts</Button>
        </Flex>}
        {(caseNumber === 2 || caseNumber === 4) && <Flex>
            <Button className='ml-auto  mb-2 border fs--1'  onClick={changeAccount} color='white'>
              Connect another bank
            </Button>
          </Flex>}
              <Flex>
              {caseNumber === 1 &&              <Button
                className="ml-auto px-5"
                color='primary'
                onClick={changeAccount}
              >
              Connect a bank account
              </Button>}

                {(defaultAccount || plaidAccounts[0] || accounts[0]) && <Button className='ml-auto px-5' color='primary' onClick={onClick} disabled={submitResponse.loading}>
                  {!submitResponse.loading?"Next":<Spinner  size={"sm"}/>}
                </Button>}
            </Flex>
            </Fragment> 
            
          }
        
        </CardBody>
  


        </Fragment>
  );
};

export default Step3;
