import React, { Fragment, useEffect } from 'react';
import AuthBasicLayout from '../../layouts/AuthBasicLayout';
import NonprofitDetailsForm from './forms/NonprofitDetailsForm';
import {useSelector} from 'react-redux'

import { useHistory } from "react-router-dom";
import PageLoader from '../common/PageLoader';

const Onboarding = () => {
  
  let history = useHistory();

  const {charity, isVerified, onboardingCreatingCharity, onboardingComplete} = useSelector((state) => state.charityUser)
  const {pushka} = useSelector((state) => state.config)
  useEffect(() => {
    if(isVerified !== null){
      if(onboardingCreatingCharity===false && charity===null ){
        history.push('onboarding/nonprofit-details');
      }else if(!pushka){
        history.push('onboarding/subdomain');
      }else if(onboardingComplete){
        history.push('nonprofit/dashboard');
      }
    }
  }, [charity])
  return (
    <Fragment>
      <PageLoader/>
    </Fragment>
  );
};


export default Onboarding;
