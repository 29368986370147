import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useHistory   } from 'react-router-dom';
import classNames from 'classnames'
import {  Row, Col, Alert, Container,  } from 'reactstrap';
import Flex from '../common/Flex';
import Section from '../common/Section';
import CloudScene from '../scene/CloudScene';
import TransfersTable from './transfers/TransfersTable';
import TransferDetailsModal from './transfers/TransferDetailsModal';
import { Link } from 'react-router-dom';
import { gql, useQuery  } from '@apollo/client';
import {useSelector} from 'react-redux'
import ManageBankAccount from './transfers/ManageBankAccount';
import NextTransfer from './transfers/NextTransfer';
import DonationsTableAlt from './donations/DonationsTableAlt';
import Transfers from '../../assets/images/svgr/Transfers';
import RoundupKey from './dashboard/RoundupKey';
import DonationDetailsModal from './donations/DonationDetailsModal';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'
// import ReactGA from 'react-ga';

const Payments  = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const {primary} = useSelector((state) => state.theme)
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  const [modal, setModal] = useState(false);
  const _id = params.get('_id'); // bar
  const roundup = params.get('roundup'); // bar
  const donation = params.get('donation'); // bar
  
  const YTD = gql`
  query DonationsPage{
    ytdDonations
    restoredDonations{
      count
      sum
    }
    isLoginRequired{
      required
      itemCount
      accountCount
    }
  }
  `;
  const {data,loading} = useQuery (YTD,{
    fetchPolicy: "no-cache"})
  
  const [ytd, setYtd] = useState("0.00")
  const [unprocessedSum, setUnprocessedSum] = useState(null)
  
  useEffect(() => {
      setYtd(data?.ytdDonations)
      setUnprocessedSum(data?.restoredDonations?.[0]?_.sumBy(data?.restoredDonations,'sum'):null)
  }, [data])
  const removeSearch = () => {
    history.replace({
      search: '',
    })
  }
  useEffect(() => {
    if(roundup ||donation ){
      setModal(true)
    }
  }, [donation,roundup])
  const addSearch = (_id) => {
    history.replace({
      search: '_id='+_id,
    })
  }
  const addDonationSearch = (donation) => {
    history.replace({
      search: 'donation='+donation,
    })
  }
  const addRoundupSearch = (roundup) => {
    history.replace({
      search: 'roundup='+roundup,
    })
  }
  return (
<DonorDashboardLayout>
      {donation && <TransferDetailsModal  _id={donation} modal={modal} setModal={setModal} removeSearch={removeSearch}/> }
      {roundup && <DonationDetailsModal  _id={roundup} modal={modal} setModal={setModal} removeSearch={removeSearch}/> }
    <CloudScene>
      <Section className=' h-100 pb-0'>
          <Flex wrap align='center' justify='around'className='h-100 ' >

          <div className='mt-6' style={{maxWidth:'500px'}}>

          <h1 className='text-black mb-4'>{"Donations" }</h1>
          <h5 className='text-primary mb-4'>{'Your roundups are grouped together and withdrawn from your bank account, automatically.'}</h5>    
          
          {/* <Link className=" btn btn-black btn-md" to={'/purchases'}>See purchases</Link>   */}
            </div>
          <Transfers  primary={primary} amount={ytd?"$"+ytd:''} width='100%' className='scene-foreground  mt-6 px-4'/>
          </Flex>  
    </Section>
    </CloudScene>
    {unprocessedSum && !data?.isLoginRequired?.required &&
      <div className='bg-yellow'>
        <Container>
          <Flex justify='center' align='center' className={'p-1'}>
            <FontAwesomeIcon className='mr-2' icon={faExclamationTriangle}/>
            <p className='mb-0'><b>${unprocessedSum.toFixed(2)} of unprocessed donations.</b> <span><Link className='text-black' to='/donations/restore'><u>Click Here</u></Link></span> to learn more.</p>
          </Flex>
        </Container>
      </div>
    }
        {data?.isLoginRequired?.required &&   
    <div className='bg-yellow'>
        <Container>
          <Flex justify='center' align='center' className={'p-1'}>
            <FontAwesomeIcon className='mr-2' icon={faExclamationTriangle}/>
            <p className='mb-0'><b>{data.isLoginRequired.accountCount} card{data.isLoginRequired.accountCount===1?"":'s'} require reauthentication.</b> <span><Link className='text-black' to='/cards/restore'><u>Click Here</u></Link></span> to learn more.</p>
          </Flex>
        </Container>
      </div>}
    <Section className='mt-3 pt-1 px-md-3 px-0 '>
      
    <Row noGutters className='justify-content-center'>
      <Col lg={11}>
      </Col>
      </Row>
      <Row noGutters className='justify-content-center'>
        
      <Col lg="6"  className={classNames('pl-0 pl-lg-3 pt-3 pt-lg-0 order-lg-1 ', )}>
        
      <Row  noGutters className=''>
          {/* <Col lg='12' md='6' className='pl-lg-2 pl-md-0 px-2  mb-md-3 mb-2'>
            <NextTransfer className='h-100'/>  
          </Col> */}
          <Col lg='12' md='6' className='pr-lg-2 pr-md-0 px-2 '>
      <TransfersTable className='py-2' handleRowClick={addDonationSearch} />
      <RoundupKey className='py-2'/>
            {/* <ManageBankAccount className='h-100 '/>   */}
          </Col>
        </Row>
      {/* <Flex column className='h-100'>
        <ManageBankAccount/>
        <NextTransfer className='px-2 mb-2'/>  
      </Flex> */}
    </Col>
      <Col lg="5"  className='px-md-0 px-2 '>
      <DonationsTableAlt handleRowClick={addRoundupSearch} className='py-2'/>

    </Col>
  </Row>
    </Section></DonorDashboardLayout>
  );
};

export default Payments;
