
import React, { useState,  useRef, useLayoutEffect } from 'react';

import Cloud1 from '../../assets/images/svgr/Cloud1';
import Cloud2 from '../../assets/images/svgr/Cloud2';
import Cloud3 from '../../assets/images/svgr/Cloud3';
import Cloud4 from '../../assets/images/svgr/Cloud4';
const CloudScene = (props) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
      const sceneRef = useRef();
      useLayoutEffect(() => {
          setWidth(sceneRef.current.getBoundingClientRect().width)
          setHeight(sceneRef.current.getBoundingClientRect().height)

      }, [])
 
    return (
      <div ref={sceneRef}className='dashboard-scene '  >
          <div style={{position:'relative'}}>
          <Cloud1 width={200} style={{position:'absolute', top:`${.5*height}px`, left:`90%`}}/>
          <Cloud2 width={200} style={{position:'absolute', top:`${.4*height}px`, left:`${-.03*width}px`}}/>
          <Cloud2 width={200} style={{position:'absolute', top:`${.2*height}px`, left:`${.6*width}px`}}/>
          <Cloud3 width={200} style={{position:'absolute', top:`${.3*height}px`, left:`${.8*width}px`}}/>
          <Cloud3 width={200} style={{position:'absolute', top:`${.2*height}px`, left:`${.13*width}px`}}/>
          <Cloud4 width={200} style={{position:'absolute', top:`${.43*height}px`, left:`${.2*width}px`}}/>
          <Cloud1 width={200} style={{position:'absolute', top:`${.37*height}px`, left:`${.4*width}px`}}/>
          </div>
      

        
        {props.children}

      </div> 
    
      
    );
  };
  
  export default CloudScene;




