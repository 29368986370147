
import { createHttpLink, InMemoryCache, ApolloClient,from} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {onError} from 'apollo-link-error'
import store from '../redux/store'

const httpLink = createHttpLink({uri: process.env.REACT_APP_API_URI,});
const authLink = setContext((_, { headers }) => {
  const token = (localStorage.getItem('token'))
  return {
    headers: {
      ...headers,
      'bearer': token ? `${token}` : "",
    }
  }
});
const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        donationsScroll: {
          keyArgs: [],
          merge(existing, incoming, { args: { offset = 0 }}) {
            // Slicing is necessary because the existing data is
            // immutable, and frozen in development.
            const merged = existing ? existing.slice(0) : [];
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          }
        }
      }
    }
  }
})

// const stateLink = withClientState({
//   cache: cache,
//   resolvers: {},
//   // set default state, else you'll get errors when trying to access undefined state
//   defaults: {
//     authStatus: {
//       __typename: 'authStatus',
//       status: 'loggedOut'
//     },
//   }
// });

const errorlink = onError(({graphQLErrors}) => {
  // console.log(graphQLErrors)
  // console.log(networkError)
  // console.log(TokenExpiredError)
  // if(TokenExpiredError){
  //     alert('test')
      
  //     localStorage.clear()
  // }
  // if(TokenExpiredError)
  //   console.log(TokenExpiredError)
  console.log(graphQLErrors)
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>{
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // )
      if(message.includes('jwt expired')){
        alert('Your session has expired')
        store.dispatch({
          type:"LOG_OUT",
          payload:{}
        })
      }
    });
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    //link:errorlink.concat(authLink.concat(httpLink)),
    link: from([
      authLink,
     // stateLink,
      errorlink,
      httpLink
    ]),
    //link: authLink.concat(httpLink),  
    cache: cache,
  });


export default client