import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useRef} from 'react'
import styled from 'styled-components'

const _ = require('lodash')

const BAR_HEIGHT = 3
const THUMB_WIDTH = 0.9
const THUMB_BORDER = 0.2

const SlideBar = styled.div`
  display: flex;
  width: 100%;
  border-radius: 3px;
  height: ${BAR_HEIGHT}rem;
  overflow: hidden;
`
const Rect = styled.div`
  height: ${BAR_HEIGHT}rem;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .text {
    font-weight: 600;
    font-size: 0.9rem;
  }
  .percentage {
    font-size: 0.83rem;
  }
`
const ThumbStyle = {
  borderLeft: `${THUMB_BORDER}rem`,
  borderRight: `${THUMB_BORDER}rem`,
  borderTop: "0px",
  borderBottom: '0px',
  borderStyle: 'solid',
  borderColor:'#495057',
  borderWidth:'1px',
  padding: '0px',
  width: `${THUMB_WIDTH}rem`,
  height: `${BAR_HEIGHT}rem`,
  top: '0px',
  background: '#e9ecef',
  color:'#495057',
  cursor: 'ew-resize',
  userSelect: 'none',
  display:'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
const SlideThumb = styled.div`
  border-left: ${THUMB_BORDER}rem;
  border-right: ${THUMB_BORDER}rem;
  border-top: 0;
  border-bottom: 0;
  border-style: solid;
  border-color:#495057;
  border-width:1px;
  padding: 0;
  width: ${THUMB_WIDTH}rem;
  height: ${BAR_HEIGHT}rem;
  top: 0px;
  background: #e9ecef;
  color:#495057;
  cursor: ew-resize;
  user-select: none;
  display:flex;
  align-items: center;
  justify-content: center;

`

const remToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}


const Thumb = ({barRef, divisions, leftIndex, leftColor, rightColor, setDivisions, thumbsSpace}) => {
  const rightIndex = leftIndex + 1
  let oldX, oldPercentages = {}

  const handleMouseMove = event => {
    const eventObject = getEventObeject(event)
    const newX = eventObject.clientX
    const barWidth = barRef.current.offsetWidth

    let newPercentages = _.clone(oldPercentages)

    const changePercentage = (oldX - newX) / (barWidth - remToPixels(thumbsSpace)) * 100

    if(oldPercentages.left - changePercentage < 0) {
      newPercentages.right = oldPercentages.right + Math.abs(oldPercentages.left)
      newPercentages.left = 0
    }
    else if (oldPercentages.right + changePercentage < 0) {
      newPercentages.left = oldPercentages.left + Math.abs(oldPercentages.right)
      newPercentages.right = 0
    }
    else {
      newPercentages.left = oldPercentages.left - changePercentage
      newPercentages.right = oldPercentages.right + changePercentage
    }

    let tempDivisions = divisions
    tempDivisions[leftIndex].percentage = newPercentages.left
    tempDivisions[rightIndex].percentage = newPercentages.right

    setDivisions(_.clone(tempDivisions))
  }
  const getEventObeject = (event) => {
    if(event.touches){
      return event.touches[0]
    }else{
      return event
    }
  }
  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
    document.removeEventListener('touchmove', handleMouseMove)
    document.removeEventListener('touchend', handleMouseUp)
  }

  const handleMouseDown = event => {
    const eventObject = getEventObeject(event)
    oldX = eventObject.clientX
    oldPercentages.left = divisions[leftIndex].percentage
    oldPercentages.right = divisions[rightIndex].percentage
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('touchmove', handleMouseMove)
    document.addEventListener('touchend', handleMouseUp)
  }

  return (
    <div onMouseDown={handleMouseDown} onTouchStart={handleMouseDown} style={{
      ...ThumbStyle,
      borderLeftColor: leftColor, 
      borderRightColor: rightColor,}} >
      <FontAwesomeIcon icon='grip-lines-vertical'/>
    </div>
  )
}


const PercentagesSlider = ({divisions, setDivisions}) => {
  const thumbsSpace = (divisions.length - 1) * THUMB_WIDTH
  const barRef = useRef()
  return (
    <SlideBar ref={barRef}>
      {divisions.map((division, index) =>
        <React.Fragment key={index}>
          <Rect style={{width: `calc((100% - ${thumbsSpace}rem) * ${division.percentage} / 100 )`, backgroundColor: division.color}}>
            <span className='text text-white'>{division.text}</span>
            <span className='percentage text-white'>{(division.percentage).toFixed(0)}%</span>
          </Rect>
          {index < divisions.length - 1 ?
            <Thumb barRef={barRef} divisions={divisions}
                   leftIndex={index} leftColor={division.color}
                   rightIndex={divisions[index + 1].percentage} rightColor={divisions[index + 1].color}
                   setDivisions={setDivisions} thumbsSpace={thumbsSpace} />
            :
            <React.Fragment></React.Fragment>
          }
        </React.Fragment>
      )}

    </SlideBar>
  )
}

export default PercentagesSlider