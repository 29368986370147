import React, { Fragment, useState, } from 'react';
import { Modal, Badge, Card, CardHeader, CardBody } from 'reactstrap';
import Flex from '../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../common/ButtonIcon';
import StripeVerificationForm from '../bankAccount/forms/StripeVerificationForm';
import BankAccountOptions from '../bankAccount/options/BankAccountOptions';

const BankAccount = ({account,  type,status, isDefault, makeDefault, loading, created, onVerificationSuccess, hideVerificationButton}) => {
  const [verifyModal, setVerifyModal] = useState(false)
  const verifyAccountToggle = () => {
    setVerifyModal(!verifyModal)
  }

  const setAsDefault = () => {
    makeDefault(account._id)
  }


  return (
    <Fragment>
        <Modal isOpen={verifyModal} toggle={verifyAccountToggle} className={"className"}>
          <Card >
        <CardHeader>

        <Flex align='center' className="px-3">
            <ButtonIcon
              color="link"
              icon={'times'}
              iconAlign="left"
              className='px-0 font-weight-semi-bold flex-shrink-r'
              onClick={() => {
                verifyAccountToggle()
              }}
              >
            </ButtonIcon>
            <h5 className="flex-grow-1 text-center m-0" >Manual Verification</h5>
            <div style={{ width: 13 }} ></div>
          </Flex>
        </CardHeader>
        <CardBody>

        <StripeVerificationForm  bank_name={account.bank_name} accountId={account._id} last4={account.last4} created={account.created} onSuccess={onVerificationSuccess}/>
        </CardBody>
        </Card>
      </Modal>
            <Card  className={`py-2 px-1 my-0 shadow-none  ${account.status!=='verified'?'border border-yellow':''}` } key={account.routing_number}>
            <Flex  align="center"> 
            
            <div className='d-sm-block d-none'> 
              <FontAwesomeIcon icon="university" className='mr-3' color={'muted'} size='2x'/>
            
            </div>
             <div>
            <Flex wrap align="center">
              {account.bank_name}
              
              {isDefault && 
                  <Badge className="ml-1" color={'soft-blue'}pill>
                    Default
                  </Badge>
                  }
              {account.status!=='verified' && <Badge className="ml-1" color={'soft-yellow' }pill>
                {'Pending verification' }
              </Badge>}     
              {/* <Badge className="ml-2" color={'soft-yellow'}pill>
                {currency}
              </Badge> */}
            </Flex> 
            
              <p className="m-0">{account.routing_number} | •••• {account.last4}</p>
              </div>
              
              {type !== 'external' &&
              
              <div className='ml-auto'>
                
              <Flex align='center' justify='end'>
        
{/* 
              {isDefault && 
                  <Badge className="ml-2" color={'soft-blue'}pill>
                    Default
                  </Badge>
                  } */}
              </Flex>  
              <Flex   justify='end'>   
              <BankAccountOptions account={account} verifyAccount={verifyAccountToggle} setAsDefault={setAsDefault}/>    
              {/* {status === 'verified' && !isDefault && 
                <ButtonIcon size='sm' disabled={loading} className='ml-1 mt-1 fs--2' onClick={() => makeDefault(_id)}>make default</ButtonIcon>
              }
              {(status !== 'verified' && !hideVerificationButton ) &&
                  <ButtonIcon size='sm'  disabled={loading}  className='ml-1 mt-1 fs--2'onClick={verifyAccountToggle}>verify account</ButtonIcon>
              } */}

                </Flex>         
              
      

              
              </div>}
            </Flex>
              </Card>
    </Fragment>
  );
};

export default BankAccount;
