import React, { Fragment,  useState } from 'react';
import Flex from '../../common/Flex';
import { Button, Card, Table } from 'reactstrap';
import classNames from 'classnames'
import CharitySearchModal from './CharitySearchModal';

  const CharitySelector  = ({select,className, tenantId}) => {
    
  const [modal, setModal] = useState(false);
  const [charity, setCharity] = useState(null);

  const onInsert = (charity) => {
    setCharity(charity)
    select(charity)
  }


  return (
    <Card  className={classNames('p-4 shadow-none',className )}>
      <CharitySearchModal tenantId={tenantId} modal={modal} setModal={setModal} insertNewCharity={onInsert}/>
      <Flex wrap align='center' >
        <h5 className='font-weight-semi-bold mr-2 mb-0'>Non Profit</h5>
        <Button  onClick={() => {setModal(true)}} color='primary' size='sm'>Select Non Profit</Button>
      </Flex>
      {!charity && <p className='text-muted'>select a charity</p>}
      {charity && 
      <Fragment>
        <Table className='table-hover mt-3'>
      <tbody>
        <tr>
            <td>Name</td>
            <td>{charity.name}</td>
        </tr>
        <tr>
            <td>EIN</td>
            <td>{charity.EIN}</td>
        </tr>
        <tr>
            <td>approved</td>
            <td>{charity.approved?"true":"false"}</td>
        </tr>
        <tr>
            <td>created</td>
            <td>{charity.created}</td>
        </tr>
        <tr>
            <td>payouts_enabled</td>
            <td>{charity.payouts_enabled?"true":"false"}</td>
        </tr>
      </tbody>
    </Table>
        </Fragment>}

    </Card>
  );
};

export default CharitySelector;
