import React, { Fragment, useState, useEffect } from 'react';
import { Button, Card,  ListGroup,  Spinner, Alert } from 'reactstrap';
import classNames from 'classnames'
import { gql, useQuery } from '@apollo/client';
import Flex from '../../common/Flex';
import ChangeExternalAccountModal from './ChangeExternalAccountModal';
import PayoutAccount from '../../bankAccount/PayoutAccount';
import PayoutAccountList from '../../bankAccount/PayoutAccountList';
import AddPayoutAccountModal from './AddPayoutAccountModal';
const ManagePayoutAccount  = ({className}) => {
  const [modal, setModal] = useState(false);
  const PAYOUT_ACCOUNTS = gql`
  query myPayoutAccounts{
    myPayoutAccounts{
        _id
        account_holder_name
        bank_name
        last4
        routing_number
        currency
        created
        default
        status
    }
  }
  `;
  const [accounts, setAccounts] = useState(null);
  const {data,loading, refetch} = useQuery(PAYOUT_ACCOUNTS,{
    fetchPolicy: "no-cache"
  })
  useEffect(() => {
    if(data){
        setAccounts(data.myPayoutAccounts)
        setModal(false)
    }
  }, [data])

  const addNew = () => {
    setModal(true)
  }
  return (
    <Fragment>
      <AddPayoutAccountModal modal={modal} setModal={setModal} refetch={refetch}/>
      {/* <ChangeExternalAccountModal  refetch={refetch} modal={modal} setModal={setModal} />  */}
    <div className={classNames('  ',className )}>
      <Card className={' p-4 shadow-none '}>
          <h5 className='mb-2'>Payout Accounts</h5>
          {accounts && accounts[0] && 
            <p className='fs--1'>Choose your default payout account, or <span><a style={{textDecoration: "underline"}} href="#"  onClick={addNew}>add another bank account</a></span>.</p>
          }
          {accounts &&  !accounts[0] && 
            <Alert color='yellow'>Please add a payout account
            <Button color='yellow' className='mt-1' onClick={addNew}>
              Add Account
            </Button>
            </Alert>
          }

        {accounts && 
        <ListGroup flush>
            <PayoutAccountList accounts={accounts} refetch={refetch}/>
        </ListGroup>}
        {loading && <Spinner/>}
        {!accounts && data && <p className='text-muted'>You have not setup a payout account yet.</p>}
        {/* <Flex className='mt-3'>
          
        <Button onClick={() => {setModal(true)}}className='ml-auto'>Change Account</Button>
        </Flex> */}
    </Card>
      </div>
    </Fragment>
  );
};

export default ManagePayoutAccount;
