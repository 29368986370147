import React, { Fragment } from 'react';
import Profile from './Settings/Profile';
import Charity from './Settings/Charity';
import Invite from './Settings/Invite';
import { Col, Row } from 'reactstrap';
import DashboardLayout from '../../layouts/DashboardLayout';
// import ReactGA from 'react-ga';


const CharitySettings = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  return (
      <DashboardLayout>
      <Row>
        <Col lg="12" className='px-1'>
          <Charity className='mb-3'/>
        </Col>
        <Col lg="12" className='px-1'>
          <Profile className='mb-3'/>
        </Col>
        <Col lg="12" className='px-1'>
          <Invite className='mb-3'/>
        </Col>

      </Row>

      </DashboardLayout>

  );
};

export default CharitySettings;
