import React from 'react';
import {  Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from "react-router-dom";

const PlaidErrorModal = ({modal, setModal,error}) => {
  let history = useHistory();
  
  const toggle = () => setModal(!modal);
  return (
      <Modal isOpen={modal} toggle={toggle} className={''}>
        <ModalHeader toggle={toggle}>Plaid Error</ModalHeader>
        <ModalBody>
          <p>{error}</p>
          <p>Please choose another account and try again.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { history.push('/plaid')}}>Try again</Button>
        </ModalFooter>
      </Modal>
    
  );
};

export default PlaidErrorModal;
