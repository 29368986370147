import React, { useState, Fragment,useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,CardTitle, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import Flex from '../../common/Flex';
import InstantVerificationCard from '../InstantVerificationCard';
import { gql, useMutation,useQuery } from '@apollo/client';
import ManualVerificationCard from '../ManualVerificationCard';
import ManualVerification from '../ManualVerification';
import { useLocation, useHistory   } from 'react-router-dom';
import InstantVerification from '../InstantVerification';
import InstantVerificationAlt from '../InstantVerificationAlt';


const ConnectBankAccountModal = ({accounts, view,modal, setModal, removeSearch,refetch,redirectURL}) => {
    
  const location = useLocation()
  const history = useHistory()
  const search = location.search; 
  const params = new URLSearchParams(search);
  
  const toggle = () => {
    setView('OPTIONS')
    setModal(false)
    removeSearch()
  };
  const setView = (view) => {
    history.push(`?change_bank=${view}`)
  }
  


  return (
    <div>
    <Modal  width="xl" isOpen={modal} toggle={toggle}
      modalClassName="overflow-hidden modal-fixed-right theme-modal"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0">
        <Flex column className='h-100'>
        <ModalHeader toggle={toggle}>Connect a bank account</ModalHeader>
      <ModalBody className=' flex-grow-1 overflow-auto'>
        {view==='OPTIONS' && 
            <Fragment>
                <p className='mb-3'>Select either instant or manual verification to connect a bank account</p>
                <InstantVerificationCard setView={setView}/>
                <ManualVerificationCard setView={setView}/>
            </Fragment>
        }
        {view === 'INSTANT' && 
            <Fragment>
                <InstantVerificationAlt redirectURL={redirectURL} refetch={refetch} closeModal={toggle} onBack={() => setView("OPTIONS")}/>
            </Fragment>    
        }
        {view === 'MANUAL' && 
            <Fragment>
                <ManualVerification refetch={refetch} closeModal={toggle} onBack={() => setView("OPTIONS")}/>
            </Fragment>    
        }
      </ModalBody>
      <ModalFooter classNames='ml-auto'>
        <Button color="secondary"  onClick={toggle}>Cancel</Button>
        {/* {activeTab === '2' && <Button disabled={!selectedId} color="primary" onClick={insert}>Insert Media</Button>} */}
      </ModalFooter>
        </Flex>
      
    </Modal>
    </div>
  );
}

export default ConnectBankAccountModal;