import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import IconLogo from '../theme/IconLogo'
import {useSelector} from 'react-redux'
import whiteLogo from '../../assets/images/illustration/pushka/logo/flame_white.svg'
import colorLogo from '../../assets/images/illustration/pushka/logo/flame_color.svg'
import Flex from '../common/Flex';

const Display = ({ dark, at, width, icon,white, className, ...rest }) => {
  
  const {iconLogo} = useSelector((state) => state.theme)
  return(
<div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center  mb-4': at === 'auth',
            '': at === 'footer',
          },
          className
        )}
      >
        
        {icon 
        ? <IconLogo base64={iconLogo} /> 
        :
        <Flex align='center' className={className}>
          <img 
          style={{height:'35px',marginRight:'10px'}} src={white?whiteLogo:colorLogo} ></img>
            <div  className={classNames('logo-text fs-1 font-weight-semi-bold',white?"text-white":"text-black")}   >Pushka</div>
        </Flex>
        }
      </div>
  )
}

const PushkaLogo = ({ dark, at, width, icon,white, className, ...rest }) => {
  const {management,homeRedirect} = useSelector((state) => state.config)
  return (
    <Fragment>
      {homeRedirect
      ? 
      <a
        href={homeRedirect}
        className={classNames(
          'text-decoration-none text-black',
          { 'text-black': !dark},
          { 'text-white': dark},
          { 'text-left': at === 'navbar-vertical' },
          { 'text-left': at === 'navbar-top' },
        )}
        {...rest}
      >      <Display className={className} dark={dark} at={at} icon={icon} white={white}/>

      </a>
      :
      <Link
      to={"/"}
      className={classNames(
        'text-decoration-none text-black',
        { 'text-black': !dark},
        { 'text-white': dark},
        { 'text-left': at === 'navbar-vertical' },
        { 'text-left': at === 'navbar-top' },
      )}
      {...rest}
    >
      <Display className={className} dark={dark} at={at} icon={icon} white={white}/>
      
    </Link>
      }
    </Fragment>
    
  );
};

PushkaLogo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.bool,
  white: PropTypes.bool,
};

PushkaLogo.defaultProps = { at: 'auth', width: 60,white:false };

export default PushkaLogo;
