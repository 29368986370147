import { CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const HeaderNav = ({step, mfa, handleBackStep}) => {
  return (
    <CardHeader className="theme-wizard-card-header p-sm-3 py-2 px-0">
        <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 0,
                  active: step === 0 
                })}
                // onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="lock" />
                  </span>
                </span>
                <span className="mt-1 fs--1">Account</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 1,
                  active: step === 1 || step === 2
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="credit-card" />
                  </span>
                </span>
                <span className=" mt-1 fs--1">Card / Bank</span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="university" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Bank Account</span>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="donate" />
                  </span>
                </span>
                <span className=" mt-1 fs--1">Donations</span>
              </NavLink>
            </NavItem>
        </Nav>
    </CardHeader>
  );
};

export default HeaderNav;
