import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Card } from 'reactstrap';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import Activate from './Activate';
import {useDispatch} from 'react-redux'
import VerifyBankAccount from './VerifyBankAccount';
import VerifyEmail from './VerifyEmail';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ActivateCreditCard from './ActivateCreditCard';
const GET_PROFILE = gql`
query{ 
  me{ 
    firstName
    lastName
    email 
    active
    inactiveReason
    requirements
    isVerified
    setupStep
    dailyMax
    dailyMaxAmount
    multiplier
    transferDay
    wholeDollarRoundup
    setupPassword
  }
}
`; 
const Status  = ({className, location}) => {
  const {active,requirements} = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [getProfile, {data,loading,error}] = useLazyQuery (GET_PROFILE,{
    fetchPolicy: "no-cache"})
  const handleSuccess = () => {
    getProfile()
  }
  useEffect(() => {
    if(data){
      dispatch({
        type:"UPDATE_USER",
        payload:{
          ...data.me
        }
      })
    }
    console.log(data)
  }, [data])
  switch(location){
    case('dashboard'):
      return (
        <div className={className}>
        <Card className={'shadow-none p-3'}>
          <h4 className='text-red mb-3'> <FontAwesomeIcon icon={faExclamationTriangle} size={'1x'} color='red'className='mr-2'/>Required Actions</h4>
          <p className>Please complete the following required actions </p>
          {/* {requirements.includes('verify_email') && <VerifyEmail className='mb-3' handleSuccess={handleSuccess}/>} */}
          {requirements.includes('bank_account') && <VerifyBankAccount className='mb-3'  handleSuccess={handleSuccess}/>}
          {requirements.includes('credit_card') && <ActivateCreditCard className='mb-3'  handleSuccess={handleSuccess}/>}
          {!active  &&<Activate/>}
        </Card></div>
          );
        break
    default:
      return(
        <Fragment></Fragment>
      )    
      };
  }

export default Status;
