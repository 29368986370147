import React, { Fragment,  useState, useEffect } from 'react';
import AccountSelect from './AccountSelect'
import _ from 'lodash'
import { ListGroup, ListGroupItem } from 'reactstrap';


const AccountSelectList=({accounts,loading,setVerSuccess, setModal, setGraphQLErrors,setIsLoading,refetch, SCF_type, isSubmitLoading,readOnly})=> {

  
  const [accountsLoading, setAccountsLoading] = useState(0)
  const [filteredAccounts, setFilteredAccounts] = useState([])

  const accountUpdating = (x) => {
    setAccountsLoading(currentAccountsLoading => currentAccountsLoading + x)
  }



  useEffect(() => {
    if(accountsLoading === 0 ){ 
      setIsLoading(false)
    }else{
      setIsLoading(true)
    }
  }, [accountsLoading]);

  // useEffect(() => {
  //    if(filter){
  //     setFilteredAccounts(_.filter(accounts,{active:filter==="active"?true:false}))
  //    }else{
  //     setFilteredAccounts(accounts)
  //    }
  //  }, [accounts]);


  
    return (
    <ListGroup flush>
        {accounts.map((account)=>
        
      <ListGroupItem className='p-1'  key={account._id} >

        <AccountSelect  
                  loading={loading}
                  refetch={refetch}
                  readOnly={readOnly}
                  setVerSuccess={setVerSuccess}
                  isSubmitLoading={isSubmitLoading}
                  SCF_type={SCF_type}
                  accountUpdating={accountUpdating}
                 
                  account={account}
                  setModal={setModal}
                  setGraphQLErrors={setGraphQLErrors}
                  />

      </ListGroupItem>
        )}
        </ListGroup>

  );
    }


export default AccountSelectList