import React, { Fragment,  useState } from 'react';
import TenantSelector from './Tenant/TenentSelector';
import CharitySelector from './Charity/CharitySelector';
import CreatePayout from './Payout/CreatePayout';
// import ReactGA from 'react-ga';
import AdminDashboardLayout from '../../layouts/AdminDashboardLayout';
import UpdateDonorIdentities from './Misc/UpdateDonorIdentities';
import UpdateCharityUserIdentities from './Misc/UpdateCharityUserIdentities';


const Misc  = () => {


  return (
    <AdminDashboardLayout>
      <h3>Misc.</h3>
      <h5>Update Donor Identities</h5>
      <UpdateDonorIdentities/>
      <h5>Update Charity Identities</h5>
      <UpdateCharityUserIdentities/>
      {/* <TenantSelector className='mb-3' select={(tenant)=>{setTenantId(tenant._id)}}/>
      <CharitySelector tenantId={tenantId} className='mb-3' select={setCharity}/>
      <CreatePayout tenantId={tenantId} charity={charity}/>
      <p>{tenantId}</p> */}
    </AdminDashboardLayout>
  );
};

export default Misc;
