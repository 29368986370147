import React, { Fragment, useState } from 'react';
import {  CardBody, CardText, CardSubtitle, CardTitle,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useSelector} from 'react-redux'

const BankExplainModal = ({modal, setModal}) => {
  const {appName} = useSelector((state) => state.theme)
  
  const toggle = () => setModal(!modal);
  return (
      <Modal isOpen={modal} toggle={toggle} className={''}>
        <ModalHeader toggle={toggle}>Why register your bank account?</ModalHeader>
        <ModalBody>
          <p className='font-weight-semi-bold'>{appName} monitors your credit card(s) to calculate your donation amount. Your donations are group together and withdrawn from your bank account.</p>
          <p className='fs--1'>{appName} monitors your credit cards' transactions and calculates the donation amount accordingly. {appName} does not pull the donation from your credit card because the credit card fees will significantly diminish your charitable impact. </p>
          <p className='fs--1'>Instead, your donations are grouped together once a week and transferred from your bank account. You may connect any US bank account as your transfer account. This once-a-week transfer, called an ACH or Automated Clearing House transfer, ensures your charitable donations are not wasted on high processing fees. </p>
          {/* <p>At the end of each week, {appName} transfers your weekly donations from your bank account as an ACH (Automated Clearing House) transfer.</p>
          <p>{appName} uses ACH transfers so your donations are not wasted on high credit card fees. </p> */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    
  );
};

export default BankExplainModal;
