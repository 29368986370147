import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image, Transformation } from 'cloudinary-react';
import Flex from './Flex';
import Color from '../../assets/images/illustration/sparechange-horizontal-color.png'
import White from '../../assets/images/illustration/sparechange-horizontal-white.png'

const SparechangeLogo = ({ dark, white, text, width, icon, className, }) => {
  return (
    <Fragment>
      {dark || white
      ?
      <img className='sparechange-logo'src={White} alt=""/>
      :
      <img className='sparechange-logo'src={Color} alt=""/>}
    </Fragment>
  );
};

SparechangeLogo.propTypes = {
  width: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.bool
};

SparechangeLogo.defaultProps = {  width: 50 };

export default SparechangeLogo;
