import React, { Fragment, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Table } from 'reactstrap';


const CharityBalanceSummary  = ({tenantId, charity}) => {
  const [availableNow, setAvailableNow] = useState("")
  const [availableSoon, setAvailableSoon] = useState("")
  const [total, setTotal] = useState("")
  const SUMMARY = gql`
  query myCharitysBalanceSummary{
    myCharitysBalanceSummary{
      availableNow
      availableSoon
    }
  }
  `;
  const [getSummary, {data}] = useLazyQuery(SUMMARY,{
    
    context: { headers: { tenantId,charityId:charity._id } }
  })

  useEffect(() => {
    if(tenantId && charity._id){
      getSummary()
    }

  }, [tenantId,charity])

  useEffect(() => {
    if(data){
      setAvailableNow(data.myCharitysBalanceSummary.availableNow)
      setAvailableSoon(data.myCharitysBalanceSummary.availableSoon)
      setTotal(
        (Number(data.myCharitysBalanceSummary.availableNow)
        + Number(data.myCharitysBalanceSummary.availableSoon)).toFixed(2)        )
    }
  }, [data])


  return (
    <Fragment> 
      <h5>Balance Summary</h5>

    <Table className=' table-sm table-borderless w-auto '>
      <tbody>
        <tr>
          <td className='pr-4'>Available Now:</td>
          <td >${availableNow}</td>
        </tr>
        <tr>
          <td className='pr-4'>Available Soon:</td>
          <td >${availableSoon}</td>
        </tr>
        <tr>
          <td className='pr-4 font-weight-semi-bold'>Total:</td>
          <td className='font-weight-semi-bold'>${total}</td>
        </tr>
      </tbody>
    </Table>
    </Fragment>
  );
};

export default CharityBalanceSummary;
