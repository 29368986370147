
const initialState = {
    colors:{
        primary:null,
        darker1:null,
        darker2:null,
        lighter1:null,
        lighter2:null,
    },
    iconLogo: null,
    primary:null,
    secondary:null,
    appName: null,
    charityName: null,
    aboutImage1: null,
    aboutImage2: null,
    aboutImage3: null,
    bioImage1: null,
    about: null,
    quote: null,
    bioTitle: null,
    heroImage:null,
    heroSubtitle: null,
    heroText: null,
    mission: null,
    url: null,
    urlMask: null,
    facebook: null,
    instagram: null,
};

const themeReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "STORE_THEME":
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default themeReducer