import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Container } from 'reactstrap';
import Settings from './dashboard/Settings';
import {useSelector} from 'react-redux'
import Flex from '../common/Flex';
import Section from '../common/Section';
import CreditCardSummary from './dashboard/CreditCardSummary';
import Status from './alert-status/Status';
import TransferAccount from './dashboard/TransferAccount';
import {useDispatch} from 'react-redux'
import CloudScene from '../scene/CloudScene';
import { gql, useQuery  } from '@apollo/client';
import moment from 'moment';
import Trophy from '../../assets/images/svgr/Trophy';
import classNames from 'classnames'
import ContentLoader from "react-content-loader"
import ColorScale from '../color-picker/ColorScale';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
// import ReactGA from 'react-ga';


const YtdLoader = () => {
  const {primary} = useSelector((state) => state.theme)
  const scale = ColorScale({color:primary,slices:21})

  return (
    <ContentLoader speed={2} width={90} height={40} viewBox="0 0 90 40" backgroundColor={scale[3]} foregroundColor={scale[6]}  >
      <rect x="0" y="0" rx="3" ry="3" width="90" height="40" /> 
    </ContentLoader>
  )
}

const DashboardAlt   = ({ match: { url } }) => {
  // ReactGA.pageview(url);
  const toDo = true
  const YTD = gql`
  query Dashboard{
    ytdDonations 
    restoredDonations{
      count
      sum
    }
    isLoginRequired{
      required
      itemCount
      accountCount
    }
  }
  `;
  const {data,loading} = useQuery (YTD)
  const {primary} = useSelector((state) => state.theme)
  const dispatch = useDispatch()
  const [ytd, setYtd] = useState("0.00")
  const [unprocessedSum, setUnprocessedSum] = useState(null)
  const year = moment().format('YYYY')
  const {active,requirements} = useSelector((state) => state.user)
  
  useEffect(() => {
      setYtd(data?.ytdDonations)
      setUnprocessedSum(data?.restoredDonations?.[0]?_.sumBy(data?.restoredDonations,'sum'):null)

  }, [data])
  const logout = () => {
    dispatch({
      type:"LOG_OUT",
      payload:{
      }
    })
  }
  return (
    <DonorDashboardLayout>
    <CloudScene>
      
      <Section className=' h-100 pb-0'>
          <Flex wrap align='center' justify='around'className='h-100 '>
            <div className='mt-6' style={{maxWidth:'500px'}}>
                <h1 className='text-black mb-4'>{"So far in " + year  + ", you've donated "}<span className='text-primary'>{loading? <YtdLoader/>:"$" + ytd?ytd:'0.00'}</span></h1>
                  {ytd !== '0.00' && <h3 className='text-primary mb-4'>{'Great Job!'}</h3>        }      
                  <Link className=" btn btn-black btn-md" to={'/donations'}>See donation history</Link>
            </div>
            

      <Trophy  primary={primary} amount={"$"+ytd}width='100%' className='scene-foreground mt-5'/>
          </Flex>
      </Section> 
    </CloudScene>
    
    {unprocessedSum && !data?.isLoginRequired?.required &&
      <div className='bg-yellow'>
        <Container>
          <Flex justify='center' align='center' className={'p-1'}>
            <FontAwesomeIcon className='mr-2' icon={faExclamationTriangle}/>
            <p className='mb-0'><b>${unprocessedSum.toFixed(2)} of unprocessed donations.</b> <span><Link className='text-black' to='/donations/restore'><u>Click Here</u></Link></span> to learn more.</p>
          </Flex>
        </Container>
      </div>
    }
{data?.isLoginRequired?.required &&   
    <div className='bg-yellow'>
        <Container>
          <Flex justify='center' align='center' className={'p-1'}>
            <FontAwesomeIcon className='mr-2' icon={faExclamationTriangle}/>
            <p className='mb-0'><b>{data.isLoginRequired.accountCount} card{data.isLoginRequired.accountCount===1?"":'s'} require reauthentication.</b> <span><Link className='text-black' to='/cards/restore'><u>Click Here</u></Link></span> to learn more.</p>
          </Flex>
        </Container>
      </div>}
    
      <Section>
          <Row noGutters className='justify-content-center'> 
          
          {(requirements.includes('bank_account') || requirements.includes('credit_card')  || !active )  && <Col lg="5"  className=' px-2 order-lg-1 '>            
              <Status className='py-2' location='dashboard'/>
    </Col>}
          <Col lg="6"  className={' px-2'}>
            <Settings className='py-2'/>
            <CreditCardSummary className='py-2'/>
            <TransferAccount className='py-2'/>
            <Flex column align='center' className='my-5'>
              <div>
              <Button color='primary' onClick={logout}>Logout</Button>
              </div>
              <Flex className='mt-4' align='center'>

              {/* <Link className="font-weight-semi-bold text-muted fs--1 text-nowrap" to={'/settings'}>
              Edit Email Preferences
              </Link>
              <p className='mb-0 px-2 text-muted'> | </p> */}
            <Link className="font-weight-semi-bold text-muted fs--1 text-nowrap" to={'/settings'}>
              Deactivate Account
              </Link>
              </Flex>

            </Flex>
            </Col>
          </Row>
        </Section>
      </DonorDashboardLayout>
  );
};

export default DashboardAlt;
