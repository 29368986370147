import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterStandard from '../footer/FooterStandard'
import DemoMessage from '../demo/DemoMessage';
import {useSelector} from 'react-redux'
import HeroAlt from '../landing/HeroAlt';
import HowItWorks from '../landing/HowItWorks';
import Features from '../landing/Features';
import About from '../landing/About';
import { Redirect, Switch } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';
import FAQ from '../landing/FAQ';
// import ReactGA from 'react-ga';

const Landing = ({ location, match,match: { url } }) => {
  // ReactGA.pageview(url);
  const {management, demo,tenant} = useSelector((state) => state.config)
  const {charityName,iconLogo} = useSelector((state) => state.theme)
  const {loggedIn} = useSelector((state) => state.auth)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      {/* {management && 
        <Switch>
        {loggedIn && <Redirect to="/charity/dashboard" />}
          {!loggedIn && <Redirect to="/authentication/charity/signup" />}
        </Switch>} */}
      {!management && 
      
      <Fragment>    
        <Helmet>
          <title>{charityName + " | Roundup donations"}</title>
          <link rel="icon" href={iconLogo}/>
        </Helmet>  
        <TransparencyLandingNav light location={location} match={match} />
        <div className='overflow-hidden'>

        <HeroAlt/>
        <HowItWorks/>
        <Features/>
        <About/>
        <FAQ/>

        <FooterStandard/>
        </div>
      </Fragment>

      }
      {tenant?.approved===false && <DemoMessage message={"This campaign is not active"}/>}
      {demo && <DemoMessage message={"This is a demo"}/>}
    </Fragment>
  );
};

Landing.propTypes = { location: PropTypes.object.isRequired };

export default Landing;
