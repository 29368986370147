import React, {   Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {  Form, FormGroup,  Spinner } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import WizardInput from '../../common/WizardInput';
import { gql, useMutation } from '@apollo/client';
import { useForm } from "react-hook-form";
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';

const CharityResetPasswordForm = () => {
  // State
  const { register, handleSubmit, errors, watch } = useForm();
  
  const CHANGE_PASSWORD = gql`
  mutation charityResetPassword($email: String!, $OTP: String!,$newPassword:String!){
    charityResetPassword(email:$email,OTP:$OTP,newPassword:$newPassword)
  }
  `;
  const [submit,{data,loading,error}] = useMutation(CHANGE_PASSWORD)

  const onSubmit = (data) => {
    submit({variables:{...data}}).catch(e => {})
  }



  return (
    <Fragment>
      {data &&
        <Fragment>
          <p>You have successful changed your password. Click the button below to login.</p>
          <Link className="btn btn-primary" to="/authentication/nonprofit/login">Login</Link>
        </Fragment>
        
      }
      {!data && 
      
    <Form onSubmit={handleSubmit(onSubmit)}>
      <p>You should have recieved an email with a One Time Passcode (OTP). Enter your email address, OTP, and new password below:</p>
    <FormGroup>
        <WizardInput
          //label={hasLabel?"Email*":""}
          placeholder="Email Address"
          id="email"
          name="email"
          innerRef={register({
            required: 'Email is required',
            pattern: {
              value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          })}
          errors={errors}
        /> 
    </FormGroup>
    <FormGroup>
      <WizardInput
        //label={hasLabel?"First Name*":""}
        placeholder="One Time Passcode (OTP)"
        id="OTP"
        name="OTP"
        innerRef={register({
          required: 'One Time Passcode is required',
          minLength: {
            value: 6,
            message: 'OTP must be 6 digits'
          },
          maxLength: {
            value: 6,
            message: 'OTP must be 6 digits'
          }
        })}
        errors={errors}
      />
    </FormGroup>
    
    <FormGroup>
      <WizardInput
          type="password"
          //label="New Password*"
          placeholder="New Password"
          id="newPassword"
          name="newPassword"
          innerRef={register({
            required: 'You must specify a password',
            minLength: {
              value: 2,
              message: 'Password must have at least 2 characters'
            }
          })}
          errors={errors}
        />
    </FormGroup>
    
    <FormGroup>
      
    <WizardInput
          type="password"
          //label="Confirm New Password*"
          placeholder="Confirm New Password"
          id="confirmPassword"
          name="confirmPassword"
          innerRef={register({
            validate: value => value === watch('newPassword')|| `The password do not match`
          })}
          errors={errors}
        />
    </FormGroup>
    {error &&<ErrorMessage error={error}/>}
    <div>
          <ButtonIcon
                disabled={loading}
                color="primary"
                className="ml-auto px-5"
                type="submit"
                icon={false ? 'chevron-left' : 'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
                hideIcon={loading}
              >
                {(!loading ) && "Submit"}
                {loading && <Spinner  size={"sm"}/>}
              </ButtonIcon>

    </div>
              <div>
                
      <Link className="fs--1" to={`/authentication/nonprofit/forgot-password`}>
            Didn't recieve a code?
          </Link>
              </div>
              
  </Form>
      
      }

    </Fragment>
    
  );
};

CharityResetPasswordForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

CharityResetPasswordForm.defaultProps = { layout: 'basic' };

export default withRedirect(CharityResetPasswordForm);
