import React, { Fragment,useEffect,useState } from 'react';
import { ListGroup,ListGroupItem,CardBody, CardHeader, CardFooter, Spinner, CardTitle } from 'reactstrap';
import { gql, useMutation,useQuery } from '@apollo/client';
import ButtonIcon from '../../common/ButtonIcon';
import Loader from '../../common/Loader';
import ErrorMessage from '../../common/ErrorMessage';
import 'rc-slider/assets/index.css';
import DailyMax from '../../Settings/DailyMax';
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import Multiplier from '../../Settings/Multiplier';
const GET_PROFILE = gql`
query{ 
  me{ 
    firstName
    lastName
    email 
    active
    inactiveReason
    requirements
    setupStep
    dailyMax
    dailyMaxAmount
    multiplier
    transferDay
    wholeDollarRoundup
  }
}
`;
const WIZARD_STEP_4 = gql`
mutation WizardStep4{
  wizardStep4
}
`;
const Step4 = () => {
  const {appName} = useSelector((state) => state.theme)
  const profile = useQuery (GET_PROFILE)
  // const [dailyMax, setDailyMax] = useState(false)
  // const [dailyMaxAmount, setDailyMaxAmount] = useState("")
  const [mutate,{data, loading, error}] = useMutation(WIZARD_STEP_4)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if(profile.data)
  //   setDailyMax(profile.data.me.dailyMax)
  //   setDailyMaxAmount(profile.data.me.dailyMaxAmount)

  // }, [profile.data])
  useEffect(() => {
    if(data){
      profile.refetch()
      .then( res => {
        dispatch({
          type:"UPDATE_USER",
          payload:{
            ...res.data.me
          }
        })
      })
      dispatch({
        type:"UPDATE_GET_STARTED",
        payload: {
          step: 4,
        }
      })
    }
  }, [data])
  
  return (
    <Fragment>
      {profile.loading && <Loader/>}

      {!profile.loading && <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
        
      <CardTitle>Set donation preferences</CardTitle>
            <ListGroup flush>
              {/* <ListGroupItem>
                    <DailyMax/>
              </ListGroupItem> */}
              <ListGroupItem>
                <Multiplier/>
              </ListGroupItem>
            </ListGroup>
            
          </CardBody>
          }
          
        <CardFooter className={' bg-light d-flex'}>
            {error && <ErrorMessage error={error}/>}
        <ButtonIcon
              onClick={mutate}
              disabled={false}
              color="primary"
              className="ml-auto px-5"
              type="submit"
              hideIcon={loading}
            >
              {(!loading ) && "Complete setup"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
      </CardFooter>
    </Fragment>
  );
};

export default Step4;
