import React, {  useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {  Form, Row, Col, FormGroup,  CustomInput,  Spinner } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import ButtonIcon from '../../common/ButtonIcon';
import { useForm } from "react-hook-form";
import WizardInput from '../../common/WizardInput'
import { gql, useMutation,useLazyQuery } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import {useDispatch} from 'react-redux'
import { loginCharity } from '../../../functions/auth';

const CHARITY_SIGNUP = gql`
mutation charityUserSignup($email:String!,$phone:String!,$password: String!,$firstName: String!,$lastName: String!,){
  charityUserSignup(email:$email,phone:$phone,password:$password,firstName:$firstName,lastName:$lastName){
    userRole
    token
    exp
  }
}
`;
// const GET_PROFILE = gql`
// query{ 
//   meCharityUser{ 
//     firstName
//     lastName
//     email 
//     EIN
//     isVerified
//     approved
//     created
//     charity{
//       publishedTheme
//       donorGroupsEnabled
//       name
//       EIN
//       stripeRequirements{
//         past_due
//       }
//     }
//   }
// }
// `;

const CharitySignupForm = ({ setRedirect,setRedirectUrl, hasLabel }) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [submit,{data,loading,error}] = useMutation(CHARITY_SIGNUP)
  // const [getProfile, profile] = useLazyQuery (GET_PROFILE)
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    submit({variables:{...data}})
    .then((res)=>{
      loginCharity(res.data.charityUserSignup)
      .then(
        setRedirectUrl({
          pathname: `/nonprofit/dashboard`
        }),
        setRedirect(true)
      ).catch(e=>{})
    })
    .catch(e => {})
  }


  // const onSubmit = (data) => {
  //   submit({variables:{...data}})
  //   .then( () => {
  //     getProfile().catch(e => {})
  //   })
  //   .catch(e => {})
  // }
  // useEffect(() => {
  //   if(profile.data){
  //     dispatch({
  //       type:"UPDATE_CHARITY_USER",
  //       payload:{
  //         ...profile.data.meCharityUser
  //       }
  //     })
  //     setRedirectUrl({
  //       pathname: `/nonprofit/dashboard`
  //     })
  //     setRedirect(true)
  //   }
  // }, [profile.data])
  // useEffect(() => {
  //   if(data){
  //     localStorage.setItem("token",data.charityUserSignup.token,)  
  //     dispatch({
  //       type:"LOG_IN",
  //       payload:{
  //         loggedIn:true,
  //         token:data.charityUserSignup.token,
  //         role:data.charityUserSignup.userRole,
  //         expiry:data.charityUserSignup.exp,
  //       }
  //     }) 
  //     // setRedirectUrl({
  //     //   pathname: `/nonprofit/dashboard`
  //     // })
  //     // setRedirect(true)
  //   }
  // }, [data])
  return (
    <Fragment>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row form>
          <Col md={6}>
              <FormGroup>
                  <WizardInput
                    label={hasLabel?"First Name*":""}
                    placeholder="First Name"
                    id="firstName"
                    name="firstName"
                    innerRef={register({
                      required: 'First Name is required',
                      minLength: {
                        value: 2,
                        message: 'Min length 2'
                      }
                    })}
                    errors={errors}
                  />
              </FormGroup>
          </Col>
          <Col md={6}>
          <FormGroup>
            <WizardInput
              label={hasLabel?"Last Name*":""}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              innerRef={register({
                required: 'Last Name is required',
                minLength: {
                  value: 2,
                  message: 'Min length 2'
                }
              })}
              errors={errors}
          />
            </FormGroup>
        </Col>
        </Row>
        <WizardInput
          label={hasLabel?"Email*":""}
          placeholder="Email"
          id="email"
          name="email"
          innerRef={register({
            required: 'Email is required',
            pattern: {
              value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          })}
          errors={errors}
        />   
        <WizardInput
          label={hasLabel?"Phone Number*":""}
          placeholder="Phone Number"
          id="phone"
          name="phone"
          innerRef={register({
            required: 'Phone number is required',
            pattern: {
              value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
              message: 'Phone number must be valid'
            }
          })}
          errors={errors}
        />         
        {/* <WizardInput
        label={hasLabel?"Non-Profit*":""}
        placeholder="Non-profit"
        id="charityName"
        name="charityName"
        innerRef={register({
          required: 'Non-profit is required',
          minLength: {
            value: 2,
            message: 'Min length 2'
          }
        })}
        errors={errors}
      /> 
      <WizardInput
      label={hasLabel?"EIN*":""}
      placeholder="EIN"
      id="EIN"
      name="EIN"
      innerRef={register({
        required: 'EIN is required',
        minLength: {
          value: 2,
          message: 'Min length 2'
        }
      })}
      errors={errors}
    />  */}
    
    <WizardInput
            type="password"
            label="Password*"
            placeholder="Password"
            id="password"
            name="password"
            innerRef={register({
              required: 'You must specify a password',
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })}
            errors={errors}
          />
     
      <p>By clicking Create Campaign, you accept the <a href="/terms-and-conditions"> terms</a> and <a href="/privacy-policy"> privacy policy</a></p>
      {/* <WizardInput
          type="checkbox"
          id="agreeToTerms"
          tag={CustomInput}
          label={
            <Fragment>
              I accept the <a href="/terms"> terms</a> and <a href="/privacy"> privacy policy</a>
            </Fragment>
          }
          name="agreeToTerms"
          innerRef={register({
            required: 'You have to agree with us'
          })}
          errors={errors}
        /> */}
  <div className={'d-flex'}>
    
  {error &&<ErrorMessage error={error}/>}
            <ButtonIcon
                  disabled={loading}
                  color="primary"
                  className="ml-auto px-5"
                  type="submit"
                  hideIcon={loading}
                >
                  {(!loading ) && "Create account"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
          </div>
    </Form></Fragment>
  );
};

CharitySignupForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

CharitySignupForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(CharitySignupForm);
