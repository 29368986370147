import React, { Fragment, useState, useEffect } from 'react';
import Flex from '../../common/Flex';
import { gql,  useLazyQuery  } from '@apollo/client';
import AboutImageGrid from '../../landing/About/AboutImageGrid';
import Loader from '../../common/Loader';
import Section from '../../common/Section';
import { Image } from 'cloudinary-react';
import { Button } from 'reactstrap';


const CharityDetailsAlt = ({ charity, handleSelect}) => { 
  const [theme, setThene] = useState({})
  const [EIN, setEIN] = useState({})
 
  const DETAILS = gql`
  query getPushkaCharityDetails($EIN:String!){
    getPushkaCharityDetails(EIN:$EIN){
      theme{
        iconLogo
        heroText
        heroImage
        heroTitle
        heroSubtitle
        aboutTitle
        aboutText
        aboutSubtitle
        aboutImage1
        aboutImage2
        aboutImage3
        bioImage1
        quote
        bioTitle
      }
    }
  }
  `;
  const [getDetails,{data,loading}] = useLazyQuery(DETAILS,{variables:{EIN:EIN}})
  useEffect(() => {
    if(data){
      setThene(data.getPushkaCharityDetails.theme)
    }
  }, [data])
  useEffect(() => {
    if(charity){
      setEIN(charity.EIN)
      getDetails()
    }
  }, [charity])
  return (
    <div className='overflow-hidden'>
  <Flex  column align='center'>
      <Button onClick={handleSelect}>Select Non-Profit</Button>
      {loading && <p className='text-muted mt-3'>loaiding Non-Profit details...</p>}
      {data && 
      <Flex className='mt-3' align='center' column>
        
        {theme.heroSubtitle && <p className='font-weight-semi-bold'>{theme.heroSubtitle}</p>}
        <p className='mb-0'>{theme.aboutText}</p>
        <div style={{marginRight:'20px'}} className='mt-0 mt-md-3 '>

        <AboutImageGrid iconLogo={theme.iconLogo} aboutImage1={theme.aboutImage1} aboutImage2={theme.aboutImage2} aboutImage3={theme.aboutImage3} />
        </div>
        <p className='mt-3'>{theme.quote}</p>
        <Flex align='center'> 
          <Image  className={'about-image-quote mr-3 '} cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={theme.bioImage1}/>
          <p className=''>{theme.bioTitle}</p>
        </Flex>

      </Flex>
      
      }
    </Flex>
    </div>
  
  );
};

export default CharityDetailsAlt;
