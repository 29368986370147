import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import TransparencyLandingNav from '../navbar/TransparencyLandingNav';
import PushkaFooterLanding from '../footer/PushkaFooterLanding'
import DemoMessage from '../demo/DemoMessage';
import {useSelector} from 'react-redux'
import { Redirect, Switch } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Hero from '../pushka/pushka-landing/Hero';
import HowItWorks from '../pushka/pushka-landing/HowItWorks';
import Charities from '../pushka/pushka-landing/Charities';
import Features from '../pushka/pushka-landing/Features';
import CharityCTA from '../pushka/pushka-landing/CharityCTA';
import FAQ from '../pushka/pushka-landing/FAQ';
// import ReactGA from 'react-ga';

const PushkaLanding = ({ location, match: { url }  }) => {
  // ReactGA.pageview(url);
  const {management, demo} = useSelector((state) => state.config)
  const {charityName,iconLogo} = useSelector((state) => state.theme)
  const {loggedIn} = useSelector((state) => state.auth)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      {/* {management && 
        <Switch>
        {loggedIn && <Redirect to="/charity/dashboard" />}
          {!loggedIn && <Redirect to="/authentication/charity/signup" />}
        </Switch>} */}
      {!management && 
      
      <Fragment>    
        <Helmet>
          <title>{charityName + " | Roundup donations"}</title>
          <link rel="icon" href={iconLogo}/>
        </Helmet>  
        <TransparencyLandingNav  />
        <Hero/>
        <HowItWorks/>
        <Charities/>
        <Features/>
        <CharityCTA/>
        <PushkaFooterLanding/>
      </Fragment>

      }
      {demo && <DemoMessage message={"This is a demo"}/>}
    </Fragment>
  );
};

PushkaLanding.propTypes = { location: PropTypes.object.isRequired };

export default PushkaLanding;
