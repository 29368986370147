import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';

import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Section from '../common/Section';
import CoffeeDonate from '../../assets/images/svgr/CoffeeDonate';
import Flex from '../common/Flex';
import FAQItem from '../FAQ/FAQItem';
const FAQ = () => {
  const {appName, charityName, iconLogo} = useSelector((state) => state.theme)
  return (
<Section bg='gray' className=''>
  <Row className='justify-content-center mb-4'> 
    <Col lg='6' md='8' sm='12' className='text-center'>
      <h3 className='fs-1 text-primary'>Get Answers</h3>
      <h2 className='fs-3 mb-4 '>Frequently Asked Questions</h2>
      <p>Select from the list below to see answers to commonly asked questions or contact us if you have any questions.</p>
    </Col>
  </Row> 
  <Row className='justify-content-center'> 
    <Col g='8' md='10' sm='12' >
      <FAQItem faq={{question:'How do I know my information is secure?',answer:`${appName} uses Plaid and Stripe to monitor your credit card information and process payments. This keeps your information safe and secure.`}}/>
      <FAQItem faq={{question:'Can I select a maximum donation limit?',answer:`Yes! You can set a daily maximum amount which will limit how much is donated on a given day. If the rest of the credit card purchases that day exceed the daily maximum, they won’t be counted towards your donation.`}}/>
      <FAQItem faq={{question:'How often is money withdrawn from my account for donations?',answer:`You donate one time per week, your round ups are grouped together into a weekly donation. This is an automatic transfer from your bank account.`}}/>
      <FAQItem faq={{question:'Can I claim this on my taxes?',answer:`Yes. You’ll get annual tax receipt from ${charityName}`}}/>
    </Col>
  </Row>


</Section>
  );
};



export default FAQ;
