import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { gql, useQuery, } from '@apollo/client';
import Flex from '../../common/Flex';
import AddExternalAccountForm from '../../stripe/AddExternalAccountForm'
import PayoutAccountList from '../../stripe/PayoutAccountList';
const AddPayoutAccountModal = ({modal, setModal, refetch}) => {
  const [accounts,setAccounts] = useState([]);
  const [view, setView] = useState("OPTIONS")
  const toggle = () => {
   setView('OPTIONS')
    setModal(false)
  };
  const STRIPE_ACCOUNTS = gql`
  query myPayoutAccounts{
    myPayoutAccounts{
      _id
      account_holder_name
      bank_name
      last4
      routing_number
      currency
      created
      default
      status
    }
  }
  `;
  const stripeAccounts = useQuery(STRIPE_ACCOUNTS)
  useEffect(() => {
    if (stripeAccounts.data){
      setAccounts(stripeAccounts.data.myPayoutAccounts)
    }
  }, [stripeAccounts.data])
 
  return (
    <Modal isOpen={modal} toggle={toggle} className='modal-dialog modal-dialog-centered ' >
    <ModalHeader toggle={toggle}>Add a Payout Account</ModalHeader>
    <ModalBody className=''>
      <p>To add a new payout account, provide the account number and routing number below.</p>
      <AddExternalAccountForm handleSuccess={refetch} />
    </ModalBody>

  </Modal>
  );
}

export default AddPayoutAccountModal;