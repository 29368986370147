import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NonprofitDetails from './NonprofitDetails';
import NonprofitCustomization from './NonprofitCustomization';
import Onboarding from './Onboarding';

const NonprofitOnboardingRoutes = ({ match: { url } }) => {


return(

  <Fragment>
      <Switch>
        <Route exact path={`${url}`} component={Onboarding}/>
        <Route path={`${url}/nonprofit-details`} component={NonprofitDetails}/>
        <Route path={`${url}/subdomain`} component={NonprofitCustomization}/>
        <Route path={`${url}`} ><Redirect to="/errors/404" /></Route>
      </Switch>
  </Fragment>
);
}
export default NonprofitOnboardingRoutes;
