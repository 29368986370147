import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {  Form, FormGroup, Input, Spinner } from 'reactstrap';
import { gql, useMutation,   } from '@apollo/client';
import withRedirect from '../../../hoc/withRedirect';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';

const ForgetPasswordForm = ({ setRedirect, setRedirectUrl }) => {
  const [email, setEmail] = useState('');
  
  const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!){
    forgotPassword(email:$email)
  }
  `;
  
  const [submit, {error,loading}] = useMutation(FORGOT_PASSWORD,{variables:{email:email}})

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    submit().then(() => {

      setRedirectUrl(`/authentication/reset-password`);
      setRedirect(true);
    }).catch(e => {})
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        <ButtonIcon
              disabled={loading}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
            >
              {(!loading ) && "Send OTP"}
              {loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {error &&<ErrorMessage error={error}/>}
      </FormGroup>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'basic' };

export default withRedirect(ForgetPasswordForm);
