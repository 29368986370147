import React, { Fragment } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import Section from '../../common/Section';
import hero from '../../../assets/images/illustration/pushka/hero.png'
import DonorQuickSignupForm from '../../auth/forms/DonorQuickSignupForm';
import { logout } from '../../../functions/auth';
const Hero = () => {
  // const text = `Pushka is changing the way donations work. Now, you can have
  // a massive impact without the price tag of a large donation. In
  // just a few minutes, the change from your everyday purchases
  // could be going straight to the causes you care about.`
  const text = `Round-Up your purchases to the next dollar by linking your credit/debit card.`
  
  const {loggedIn,role} = useSelector((state) => state.auth)
  const {email} = useSelector((state) => state.user)

  
  return (
    <Fragment>      <div   className=' pt-xl-4  pt-5 bg-gradient'>
        <Section   bg='' className=' mb-3 pb-3 text-white '>
          <Row className="flex-center text-md-left text-center" style={{ marginBottom: '-50px' }}>
            <Col className='overlapped-hero-text mt-md-5 mt-0' md lg={5}>
            <h1 className='fs-4  mb-3  text-white'>Round-Up and give Tzedakah with Pushka</h1>
            {/* <p className='fs-2 text-white font-weight-medium'> Pushka is your digital tzedakah box</p> */}
              <p className='fs-0'>{text}</p>
              {!loggedIn && 
          <Card className='p-3  border bg-light text-black'>
                <p className='text-gray'>Enter your information:</p>
                    <DonorQuickSignupForm/>
                    <p className='mb-0 mt-2 fs--1'>Already have an account? <span><Link  to="/authentication/login">Login</Link></span></p>
              </Card>  
      }
      {loggedIn && role === 'DONOR' &&
          <Card className='p-3  border bg-light text-black'>
            <p>You're logged in as {email}</p>
            <Link className={'round py-2 px-md-5 px-4 mt-3 btn btn-secondary btn-md'} to="/dashboard">Go To Dashboard</Link>
            {/* <Button onClick={goToDashboard}>Go to Dashboard</Button> */}
            <Button color='link' size='sm' onClick={logout}>Logout</Button>
            </Card>
      
      }
           {/* <Link className={'round   py-2 px-md-5 px-4 mt-3  btn btn-outline-white btn-md'} to="/get-started">Get Started</Link> */}
            </Col>
            <Col md lg={7} xl={6}>
              <div className='overlapped-hero-img '>

              <img src={hero} alt="Logo"className="img-fluid" />
              </div>
            </Col>
          </Row >


</Section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width='100%' height='200px' style={{marginTop:'-100px'}} preserveAspectRatio="none">
    <polygon fill="white"stroke="white"  points="0,100 100,0 100,100"/>
  </svg>
</div></Fragment>


  );
}; 

Hero.propTypes = {
};


export default Hero;
