import React from 'react'
import { useTable, usePagination,  } from 'react-table'
import {Table, Button, } from 'reactstrap';

import Flex from '../../common/Flex';

    
    function TablePaginatonGroupBy({ columns, data,initialPageSize, }) {
        // Use the state and functions returned from useTable to build your UI
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          prepareRow,
          rows,
        } = useTable(
          {
            columns,
            data,
          },
        )
      
        // Render the UI for your table
        return (
          <div className=''>
            <Flex wrap align='center' className=''>
              {/* <p className='m-0'>{"results: " + data.length}</p> */}
              
            {/* <Flex justify='end' align='center' className='ml-auto' >
              <p className='m-0 mr-2 fs--1'>{'page ' }{ pageIndex + 1} {' of ' + pageCount}</p>
              <ul className='pagination mb-0'>
                <li  className='page-item'>
                  <Button disabled={pageIndex===0} color='falcon-primary' size='sm' onClick={() => previousPage()}>Previous</Button>
                </li>
                <li  className='page-item'>
                  <Button disabled={pageIndex===pageCount-1}  color='falcon-primary' size='sm' onClick={() => nextPage()}>Next</Button>
                </li>
                <li  className='page-item'></li>
              </ul>
            </Flex> */}
            </Flex>
        <Table   hover responsive size="sm" {...getTableProps()}>
              {/* <thead>
                {headerGroups.map(headerGroup => (
                  <tr  {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead> */}
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td className='donation-row' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
            
          </div>
        )
      }
    export default TablePaginatonGroupBy
    