import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../../common/Section';
import Flex from '../../common/Flex';
const CharityCTA = () => {
  return (
    <div style={{zIndex: '10'}} >

<Section bg="black" className='text-white pb-0 '>
  <Flex justify='center'>
  <div className='px-2 pt-4 ' style={{ maxWidth: 800, }}>
<h4 className='text-white'>Are you involved in a Jewish Non-profit <span className='font-weight-normal'>and to join Pushka?</span></h4>  
  <Link className={'btn btn-secondary round  py-2 px-md-5 px-4 mt-3  mb-n4'} to="/authentication/nonprofit/signup">Create an account!</Link>

</div>
  </Flex>



</Section>

    </div>
  );
};



export default CharityCTA;
