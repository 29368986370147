import React, { forwardRef, useImperativeHandle , useEffect, useState } from 'react';
import {  Spinner } from 'reactstrap';
import { gql,  useQuery  } from '@apollo/client';
import Flex from '../common/Flex';
import Loader from '../common/Loader';
import ImageSelector from './ImageSelector';
const MediaLibrary = ({  insert },ref) => {
  const [selectedId, setSelectedId] = useState('')
  const MY_MEDIA = gql`
  query MyCloudinaryAssets{
    myCloudinaryAssets
  }
  `; 
  const {data,loading,refetch } = useQuery(MY_MEDIA, {
    fetchPolicy:"no-cache"
  })
  const [publicIds, setPublicIds] = useState([])

  useEffect(() => {
    if(data){
      setPublicIds(data.myCloudinaryAssets)
    }
  }, [data])

  useImperativeHandle(ref, () => ({
    refresh () {
      refetch()
    }
  }), [])



  return (
    <div className='mt-3 p-2'>
    <p className='mb-0'>Click on an image below to insert.</p>
    <p className='text-muted'>Your library: {publicIds.length} image(s)</p>
<Flex wrap justify='between'>
{loading && <Flex align='center' justify='center' className='w-100 h-100'>
  <Loader/>
  </Flex>}
{publicIds && publicIds.map(publicId => 
<div key={publicId}>
  
<ImageSelector 
insert={insert}
 setSelectedId={setSelectedId}
  selectedId={selectedId}
  className='img-fluid img-thumbnail img-cloudinary-modal'
    key={publicId} 
    publicId={publicId} 
    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
    width='300'
    crop='scale'>

  </ImageSelector>

</div>
)}
</Flex>
    </div>
  );
}

export default forwardRef(MediaLibrary);