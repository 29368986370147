import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {  Form, Row, Col, FormGroup, Input, CustomInput, Label,  Spinner  } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import { gql, useMutation, useLazyQuery  } from '@apollo/client';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import {useDispatch} from 'react-redux'
import { loginCharity } from '../../../functions/auth';

const CharityLoginForm = ({ setRedirect,setRedirectUrl, hasLabel,  }) => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const dispatch = useDispatch()
  const LOGIN = gql`
  mutation CharityUserLogin($email: String!,$password: String!){
    charityUserLogin(email:$email,password:$password){ 
        token
        userRole
        exp
    }
  }
  `;
  const GET_CHARITY_USER_PROFILE = gql`
  query{ 
    meCharityUser{ 
      firstName
      lastName
      email 
      charityName
      EIN
      approved
      isVerified
      charity{
        publishedTheme
        donorGroupsEnabled
        name
        EIN
        stripeRequirements{
          past_due
        }
      }
    }
  }
  `;
  const [login, auth] = useMutation(LOGIN,{variables:{email:email,password:password}})
  // const [getProfile, profile] = useLazyQuery (GET_CHARITY_USER_PROFILE)

  // useEffect(() => {
  //   if(auth.data){
  //     // localStorage.setItem("token",auth.data.charityUserLogin.token,)  
  //     dispatch({
  //       type:"LOG_IN",
  //       payload:{
  //         loggedIn:true,
  //         token:auth.data.charityUserLogin.token,
  //         role:auth.data.charityUserLogin.userRole,
  //         expiry:auth.data.charityUserLogin.exp,
  //       }
  //     })
  //   }
  // }, [auth.data])

  // useEffect(() => {
  //   if(profile.data){
  //     dispatch({
  //       type:"UPDATE_CHARITY_USER",
  //       payload:{
  //         ...profile.data.meCharityUser
  //       }
  //     })
  //     setRedirectUrl({
  //       pathname: `/nonprofit/dashboard`
  //     })
  //     setRedirect(true)

  //   }
    
  // }, [profile.data])


  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    login()
    .then( (res) =>{
      loginCharity(res.data.charityUserLogin)
      .then(
        setRedirectUrl({
          pathname: `/nonprofit/dashboard`
        }),
        setRedirect(true)
      ).catch(e => {})
    }).catch(e => {})
  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        {/* <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col> */}
        <Col xs="auto">
          <Link className="fs--1" to={`/authentication/nonprofit/forgot-password`}>
            Forget Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>

        <ButtonIcon
              disabled={isDisabled}
              className="ml-auto px-5 mt-2"
            >
              {(!auth.loading ) && "Log in"}
              {auth.loading && <Spinner  size={"sm"}/>}
            </ButtonIcon>
          {auth.error &&<ErrorMessage error={auth.error}/>}
      </FormGroup>
    </Form>
  );
};

CharityLoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

CharityLoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(CharityLoginForm);
