import React, { Fragment, useState, useEffect } from 'react';
import {  ListGroupItem, Spinner } from 'reactstrap';
import { gql, useMutation  } from '@apollo/client';
import BankAccount from './BankAccount';
import _ from 'lodash'

const BankAccountList = ({ accounts, filter, refetch, onVerificationSuccess, hideVerificationButton}) => {
  
  const [filteredAccounts, setFilteredAccounts] = useState([])
  
  const SET_DEFAULT = gql`
  mutation setDefaultBankAccount($_id: String!){
    setDefaultBankAccount(_id:$_id){
      default
    }
  }
  `;
  const [setDefault, {data,loading}] = useMutation(SET_DEFAULT)

  useEffect(() => {
    if(filter==="verified"){
     setFilteredAccounts(_.filter(accounts,{status:"verified"}))
    }else if(filter==="unverified"){
      setFilteredAccounts(_.filter(accounts,{status:"new"}))
    } else{
      setFilteredAccounts(accounts)
    }
  }, [accounts]);

  


  
  const makeDefault = (_id) => {
    setDefault({variables:{_id:_id}})
  }
  useEffect(() => {
    if(data){
      refetch()
    }
  }, [data])
  return (
    <Fragment>
      {loading && <Spinner/>}
        {filteredAccounts.map((account)=>
        
      <ListGroupItem className='p-1'  key={account._id} >
          <BankAccount account={account} isDefault={account.default} loading={loading} makeDefault={makeDefault} onVerificationSuccess={onVerificationSuccess} hideVerificationButton={hideVerificationButton}/>
          </ListGroupItem>
          )}
    </Fragment>
  );
};

export default BankAccountList;
