import React, { Fragment } from 'react';
import ManualVerificationForm from './forms/ManualVerificationForm';
import ButtonIcon from '../common/ButtonIcon';
import Flex from '../common/Flex';


const ManualVerification = ({onBack, refetch,closeModal}) => {
  return (
    <Fragment>
      <Flex align='center' className="mb-4 px-3">
          <ButtonIcon
            color="link"
            icon={'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className='px-0 font-weight-semi-bold flex-shrink-r'
            onClick={() => {
              onBack()
            }}
            >
            Back
          </ButtonIcon>
          <h5 className="flex-grow-1 text-center m-0" >Manual Verification</h5>
          <div style={{ width: 55 }} ></div>
        </Flex>
        <p>In the next three days, you will see two micro deposits in your bank statement, each $1 or less.</p>
        <p>You will use these two micro deposit amounts to verify this account. Expect an email with verification instructions in 3 days.</p>
        {/* <p className=''>To verify your bank account, you’ll see two deposits and two withdrawals on your bank statement over the next three days, each $1 or less.</p> */}
        {/* <p className=''>To avoid any overdraft or other bank fees, make sure you have at least $2 in your bank account.</p> */}
      <ManualVerificationForm onBack={onBack} refetch={refetch} closeModal={closeModal}/>

    </Fragment>
  );
};

export default ManualVerification;
