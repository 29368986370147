import React, { useState, useEffect, Fragment } from 'react';
import {  Card, Spinner, Table, Button} from 'reactstrap';
import { gql, useQuery,  } from '@apollo/client';
import classNames from 'classnames'
import { usePagination, useTable } from 'react-table';
import moment from 'moment'
import Flex from '../common/Flex';
import BadgePending from './cell-renderings/BadgePending';
import BadgeSucceeded from './cell-renderings/BadgeSucceeded';
import Source from './cell-renderings/Source';
import TableLoader from '../common/TableLoader';
import TableNoData from '../common/TableNoData';
const PayoutsTable  = ({className}) => {
  
  const PAYMENTS = gql`
  query payoutHistory{
    payoutHistory{
      _id
      scheduled
      scheduled_date
      manual
      status
      arrival_date
      created
      amount
      payoutAccount{
        bank_name
        last4
      }
    }
  }
  `;
  const {data,error,loading} = useQuery (PAYMENTS) 
  const columns = React.useMemo(
    () => [
      {
        id:'created',    
        Header: 'Date',
        accessor: d => d.created,
        Cell: props => <div>{ moment(props.value).local().format("MM/D/YY")}</div>

      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: d => d.amount,
        Cell: props => <div>{"$"+ Number(props.value).toFixed(2)}</div>
      },
      {
        Header: 'Account',
        id: 'account',
        accessor: d => d.payoutAccount.last4,
        Cell: props => <Source last4={props.value} />
      },
      {
        id:'status',    
        Header: 'Status',
        //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
        accessor: d => d.status,
        Cell: props => <Fragment>
          {props.value==='pending' &&<BadgePending/> }
          {props.value==='succeeded' &&<BadgeSucceeded/> }
          </Fragment>
      },

      // {
      //   id:'link',    
      //   Header: '',
      //   //accessor: d => d.status==='pending'?<Badge color='primary-light'>pending</Badge>:"",
      //   accessor: d =>     
      //   <NavItem  className='list-unstyled'>
      //   <NavLink className='p-0' tag={Link} to={'?_id='+d._id}>
      //       <FontAwesomeIcon icon={faExternalLinkAlt} className="" size="md"/>
      //   </NavLink>
      // </NavItem>
      // },

    ],
    []
  )

  function TablePaginaton({ columns, data,initialPageSize, }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      pageCount,
      nextPage,
      previousPage,
      state: { pageIndex },
      
    } = useTable(
      {
        columns,
        data,
        initialState: { 
          pageIndex: 0, 
          pageSize: 5,
//          hiddenColumns: showStatus?[]:['status']
        },
      },
      usePagination
    )
  
    // Render the UI for your table
    return (
      <div className={`bg-white ${className}`}>

    <Table responsive size="sm" className='fs--1 table-bordered' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td className='' {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
          {loading && <TableLoader height={130}/>}
          {!loading && !data[0] &&  <TableNoData message={"No payouts yet"} height={150}/>}
                   <Flex wrap align='center' className='pl-1'>
          <p className='m-0 fs--1 text-muted'>{"results: " + data.length}</p>
          
        <Flex justify='end' align='center' className='ml-auto' >
          <p className='m-0 mr-2 fs--1'>{'page ' }{ pageIndex + 1} {' of ' + pageCount}</p>
          <ul className='pagination mb-0'>
            <li  className='page-item'>
              <Button disabled={pageIndex===0} color='falcon-primary' size='sm' onClick={() => previousPage()}>Previous</Button>
            </li>
            <li  className='page-item'>
              <Button disabled={pageIndex===pageCount-1}  color='falcon-primary' size='sm' onClick={() => nextPage()}>Next</Button>
            </li>
            <li  className='page-item'></li>
          </ul>
        </Flex>
        </Flex>
        
      </div>
    )
  }
 
  return (
    <div>
      <TablePaginaton
       // tooltip={tooltip}
        //title={title}
        columns={columns}
        data={data?data.payoutHistory:[]}
        initialPageSize={3}
        loading={loading}
        //renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
};

export default PayoutsTable;
