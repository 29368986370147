import React, { Fragment } from 'react';
import {  Col, Row } from 'reactstrap';
import Section from '../common/Section';
import DonationSettings from '../Settings/DonationSettings';
import {useSelector} from 'react-redux'
import DonorProfile from '../Settings/DonorProfile';
import CloudScene from '../scene/CloudScene';
import CharitiesSettings from '../Settings/CharitiesSettings';
import classNames from 'classnames'
import Account from '../../assets/images/svgr/Account';
import Flex from '../common/Flex';
import ManageBankAccount from './transfers/ManageBankAccount';
import DonorDashboardLayout from '../../layouts/DonorDashboardLayout';
// import ReactGA from 'react-ga';

const Profile   = ({ match: { url } }) => {
  // ReactGA.pageview(url);

  const {firstName} = useSelector((state) => state.user)
  const {primary} = useSelector((state) => state.theme)
  const {pushka} = useSelector((state) => state.config)
  return (
    <DonorDashboardLayout>
    <CloudScene>
      <Section className=' h-100 pb-0'>
        
      <Flex wrap align='center' justify='around'className='h-100 '>
        
      <div className='mt-6' style={{maxWidth:'500px'}}>

        <h1 className='text-black mb-4'>{"Hi " + firstName +"!" }</h1>
          <h5 className='text-primary mb-4'>{'You can modify your account settings below'}</h5>         
        </div>
      <Account  primary={primary} width='100%' className='scene-foreground  mt-5 px-4 '/>
      </Flex>
      </Section>
    </CloudScene>
    

    <Section className=' '>
    <Row noGutters className='justify-content-center'>
        
        <Col lg="5"  className={classNames('pl-0 pl-lg-3 pt-3 pt-lg-0 order-lg-1 ', )}>
          <Row  noGutters className=''>
            <Col md='12' className='pl-lg-2 pl-md-0 px-2  '>
           
          <DonorProfile className='py-2'/>
          
          <ManageBankAccount  className='py-2'/>

            </Col>
          </Row>
        </Col>
        <Col lg="6"  className='px-md-0 px-2 '>
          <DonationSettings className='py-2'/>
          {pushka && <CharitiesSettings className='py-2'/>}
        </Col>
      </Row>
     <Row noGutters className='justify-content-center'>
            <Col md={8} >
 
       </Col></Row>
       </Section>
    </DonorDashboardLayout>
  );
};

export default Profile;
