import * as React from "react"
import {Image,Transformation} from 'cloudinary-react'

function CoffeeDonate(props) {

  const primary = props.primary?props.primary:'#2c7be5'
  return (
    <div className='position-relative img-fluid   ' >
      
      <Image  className={'position-absolute'} style={{width:'10%', left:'67%',top:'54%', backgroundColor:'rgba(255, 255, 255, 0.4)', borderRadius:'8px'}}cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={props.iconLogo + '.png'}>
          <Transformation width="500" height='500' background="auto:predominant" crop="pad"   />
      </Image>

    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 250.54 280.83"
      {...props}
    >
      <defs>
        <style>
          {
            `.prefix__cls__coffeeDonate-1{fill:${primary}}.prefix__cls__coffeeDonate-2{fill:#a3a5a8}.prefix__cls__coffeeDonate-3{fill:#bdbebf}.prefix__cls__coffeeDonate-10,.prefix__cls__coffeeDonate-11,.prefix__cls__coffeeDonate-7,.prefix__cls__coffeeDonate-8,.prefix__cls__coffeeDonate-9{fill-rule:evenodd}.prefix__cls__coffeeDonate-7{fill:#fff}.prefix__cls__coffeeDonate-8{fill:#e6e7e6}.prefix__cls__coffeeDonate-9{fill:#ccc}.prefix__cls__coffeeDonate-10{fill:#cccccb}.prefix__cls__coffeeDonate-11{fill:#a2a2a1}.prefix__cls__coffeeDonate-17{fill:#f0d859}.prefix__cls__coffeeDonate-18{fill:#eecd53}.prefix__cls__coffeeDonate-19{fill:#e5bd4f}.prefix__cls__coffeeDonate-20{fill:#efdb78}.prefix__cls__coffeeDonate-21{fill:#c99a43;opacity:.74;isolation:isolate}`
          }
        </style>
      </defs>
      <path
        className="prefix__cls__coffeeDonate-1"
        d="M140.56 91.79h108.52v146.79l-41.56 15.96-66.96-7.29V91.79z"
      />
      <path
        className="prefix__cls__coffeeDonate-2"
        d="M162.34 100.06l.56-6.42 110.62 1.1v5.32s-3.65 14.19-57.08 15.3c0 0-43.9.56-54.1-15.3z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-3"
        d="M215.33 109.82s31.59.11 47.11-6S272.64 91 272.64 91s-5.43-8.54-38.13-11.75-56.43 4.21-56.43 4.21-15.29 4.43-15.18 10.2 6.54 8.16 6.54 8.16a104.41 104.41 0 0045.89 8z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-1"
        d="M62.48 102.73a17.09 17.09 0 002.37-3.67c.76-1.22 1.51-2.45 2.36-3.62a41.11 41.11 0 015.93-6.57 44.24 44.24 0 017-5.08c1.31-.76 2.64-1.46 4-2.19.59-.32 1.3-.59 2-.89a5.38 5.38 0 00-.94 2.39.94.94 0 001.51.83c1.1-.85 1.59-2.5 2.2-3.72.37-.86 1.5-2.21 1.09-3.21s-1.78-1-2.64-1.2a26.64 26.64 0 00-4.72-.42c-1.21 0-1.48 1.79-.22 1.92 1 .11 2 .26 3 .44L87 78a13.35 13.35 0 00-3.91 1 24.73 24.73 0 00-4.3 1.92 40.6 40.6 0 00-7.69 5.52 45.22 45.22 0 00-6.31 7 36.42 36.42 0 00-2.45 3.82c-.68 1.26-1.74 3-1.77 4.44.02.92 1.11 1.77 1.91 1.03z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-2"
        d="M273.63 245.9v5.25s0 3.66-5.65 7.09-17.73 7.1-17.73 7.1a236.16 236.16 0 01-27.16 3.77c-15.85 1.33-45-4.87-45-4.87l2.24-6.65 45.55 2.91 36-6.25z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-3"
        d="M272.3 241.73s3.11 4.76-.11 7.87-5.1 4.43-10.86 7-28.71 6.65-39.35 6.32-29.07-1.41-39.86-5.31.06-3 .06-3l6.12 1.39s24.48 3.8 42.1 1.56 27.94-2 41.9-12.54z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        d="M169.44 94.74s-8.63-8.13 25.05-12.63S250 83.55 250 83.55s22.89 4.14 16.9 11.19z"
        transform="translate(-23.11 -10.02)"
        fill="#abacae"
      />
      <path
        d="M169.44 94.74s2-8.75 50.82-10.42c0 0 41.29 1.78 46.61 10.42 0 0-7.57 9.09-46.18 9.09s-51.03-7.42-51.25-9.09z"
        transform="translate(-23.11 -10.02)"
        fill="#cfced0"
      />
      <path
        d="M120 290.84c37.64 0 68.35-11.78 68.35-26.29s-30.76-26.29-68.35-26.29-68.41 11.79-68.41 26.29 30.66 26.29 68.41 26.29z"
        transform="translate(-23.11 -10.02)"
        fillRule="evenodd"
        fill="#838584"
      />
      <path
        className="prefix__cls__coffeeDonate-7"
        d="M120.07 287.49c36.56 0 66.38-12.13 66.38-27s-29.82-27-66.38-27-66.41 12.13-66.41 27 29.85 27 66.41 27z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-8"
        d="M120.07 236.1c33.9 0 61.36 10.64 61.36 23.79s-27.43 23.82-61.36 23.82S58.72 273 58.72 259.89s27.45-23.79 61.35-23.79z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-8"
        d="M120.07 236.1c33.9 0 61.36 10.68 61.36 23.82s-27.43 23.83-61.36 23.83-61.35-10.68-61.35-23.83 27.45-23.82 61.35-23.82zm0 .82c32.74 0 59.26 10.16 59.26 22.63s-26.52 22.66-59.26 22.66-59.29-10.15-59.29-22.66 26.55-22.63 59.29-22.63z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-9"
        d="M120.07 273.93c19.74 0 35.85-6 35.85-13.41s-16.11-13.44-35.85-13.44-35.88 6-35.88 13.44 16.11 13.41 35.88 13.41z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-8"
        d="M186.41 261.08a7.71 7.71 0 010 .94c0 14.91-29.71 27-66.38 27s-66.41-12.1-66.41-27a8.13 8.13 0 01.08-.94c1.19 14.5 30.41 26.08 66.33 26.08s65.14-11.58 66.34-26.08z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-10"
        d="M60.44 189.39s0 1.65-2.17 1.91c-4.8.6-23-8.05-27 9-7 29.81 73.79 69.41 72.71 48.54l-.45-8.61c-.45-8.28-51 .56-60.87-27.95-7-20.41 18.73-4.94 23 .94 0 0 3-13.71 3-14.8s-8.24-9.06-8.24-9.06z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-11"
        d="M60.44 189.39s0 1.65-2.17 1.91a19.24 19.24 0 01-4.83-.56c2.69 4.72 5 10.67 3.7 15.66 3.82 2.32 7.2 5 8.51 6.85 0 0 3-13.71 3-14.8s-8.24-9.06-8.24-9.06zm6.07 54.84c18.84 10.56 38 14.87 37.46 4.64l-.45-8.61c-.27-4.87-17.87-3.82-34.35-7.94a14.49 14.49 0 01-.94 8.24 18.71 18.71 0 01-1.72 3.67z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-10"
        d="M182.18 186.28c-3 34.39-18.54 83.87-61 83.87-42.14 0-57.69-49.89-60.68-83.87 22.25.3 44.46.6 66.67.71z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-11"
        d="M175 221.64c-8.5 25.55-24.91 48.51-53.86 48.51-28.66 0-45-23.07-53.49-48.51 1.91 3.37 3.26 6.14 4.87 9.59 9.36 20.12 24.72 36.18 48.62 36.18 25.06 0 40.72-17.19 50-38.35a75.4 75.4 0 013.85-7.42z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-8"
        d="M178.06 198.87c-2.92 18.69-12.4 45.51-28.77 58.39 4.42-10.9 9.33-26.82 0-46 12-1.53 22.66-7.15 28.73-12.43z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-7"
        d="M121.31 159.42c81.06 0 81.1 52 0 52s-81.06-52 0-52z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-9"
        d="M121.31 209.13c31.24 0 56.75-10.82 56.75-24S152.55 161 121.31 161s-56.75 10.83-56.75 24.05 25.47 24 56.75 24z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        d="M121.31 209.17c29.22 0 53-9 53-20.08s-23.78-20.09-53-20.09-53 9-53 20.08 23.82 20.08 53 20.08z"
        transform="translate(-23.11 -10.02)"
        fill="#151618"
        fillRule="evenodd"
      />
      <path
        d="M119 171.41c-10.78 0-20.67 1.42-28.39 3.86-25.36 7.86-19.14 14.34-1.83 10.52 19.89-4.42 34.38-8.31 52.14 3.6 6.81 4.61 12.88 10.34 21.2 6.48 3.29-1.5 6.1-4.12 6.1-7.08 0-9.36-24.49-17.38-49.22-17.38z"
        transform="translate(-23.11 -10.02)"
        fill="#444945"
        fillRule="evenodd"
      />
      <path
        className="prefix__cls__coffeeDonate-7"
        d="M121.31 167.85c29.29 0 53 9 53 20.08v.6c-.78-10.83-24.23-19.52-53-19.52s-52.25 8.69-53 19.52v-.6c0-11.09 23.75-20.08 53-20.08z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-8"
        d="M42.5 192c2.66-.37 4.9-.48 5.84 1.2s-1.5 3.56-4.19 3.9-5 .34-5.47-1 1.12-3.71 3.82-4.05zM36.2 199.73a1.44 1.44 0 100-2.85 1.44 1.44 0 100 2.85z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        d="M236.13 93.36c0 1.43-2.25 2.6-5 2.6h-26.61c-2.75 0-5-1.17-5-2.6s2.25-2.59 5-2.59h26.61c2.75 0 5 1.16 5 2.59z"
        transform="translate(-23.11 -10.02)"
        fillRule="evenodd"
        fill="#a3a5a8"
      />
      <text
        transform="translate(6.2 116.6)"
        fontFamily="Poppins-SemiBold,Poppins"
        fontWeight={600}
        fontSize={14}
        fill="#231f20"
      >
        {"$2.65 "}
        <tspan x={-3.91} y={16.8}>
          {"Coffee"}
        </tspan>
      </text>
      <text
        transform="translate(88.71 46.41)"
        fontSize={13}
        fontFamily="Poppins-SemiBold,Poppins"
        fontWeight={600}
        fill={primary}
      >
        {"$0.35 "}
        <tspan x={-10.23} y={15.6}>
          {"Donated"}
        </tspan>
      </text>
      <circle className="prefix__cls__coffeeDonate-17" cx={169.57} cy={19.78} r={19.78} />
      <circle className="prefix__cls__coffeeDonate-18" cx={169.42} cy={19.8} r={17.34} />
      <path
        className="prefix__cls__coffeeDonate-19"
        d="M209.52 26.3a17.86 17.86 0 01-9.58 19.21c-9.05 4.22-19.08-2-18.93-2.72.1-.51 4.8 1.6 10.12-.35 7.22-2.62 12.58-11.53 11.66-19.17-.73-6.08-5.46-9.42-4.92-10 .71-.59 9.81 4.02 11.65 13.03z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-20"
        d="M180 28.15c-1.5 7.47 4.78 14 4.23 14.39S177 37.36 177 29.8c.06-7 5.81-13.91 13-15.15 7.69-1.35 14.06 4.46 13.93 4.76s-6-4.19-12.69-2.67A15.5 15.5 0 00180 28.15z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-21"
        d="M188.57 32.26l.27-.17a15.59 15.59 0 002.72.28c1.54 0 2-.43 2-1.22 0-.48-.25-.87-1.14-1l-1.52-.28a2.8 2.8 0 01-2.64-2.92c0-2.34 1.28-3.74 4.67-3.74a13.42 13.42 0 012.53.27c-.06 1.05-.2 2.18-.2 2.18l-.29.15a13.83 13.83 0 00-2-.21c-1.45 0-1.81.49-1.81 1.11s.28.81.93.93l1.4.28c2.16.42 3 1.47 3 3.18 0 2.38-1.23 3.73-4.56 3.73a13.74 13.74 0 01-3-.28 17.24 17.24 0 01-.36-2.29z"
        transform="translate(-23.11 -10.02)"
      />
      <circle className="prefix__cls__coffeeDonate-17" cx={197.57} cy={62.78} r={19.78} />
      <circle className="prefix__cls__coffeeDonate-18" cx={197.42} cy={62.82} r={17.34} />
      <path
        className="prefix__cls__coffeeDonate-19"
        d="M237 67.36a17.84 17.84 0 01-7.26 20.19c-8.5 5.25-19.18.26-19.12-.49 0-.51 4.95 1 10-1.52 6.86-3.45 11.14-12.93 9.34-20.41-1.44-5.95-6.53-8.71-6.05-9.31.63-.71 10.2 2.8 13.09 11.54z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-20"
        d="M207.85 72.65c-.61 7.6 6.39 13.36 5.88 13.79s-7.76-4.29-8.58-11.81c-.76-6.93 4.14-14.49 11.08-16.56 7.49-2.24 14.49 2.79 14.4 3.1s-6.47-3.46-12.92-1.17a15.51 15.51 0 00-9.86 12.65z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-21"
        d="M216.88 75.73l.25-.2a16.7 16.7 0 002.74 0c1.52-.18 1.92-.66 1.83-1.45-.06-.48-.35-.83-1.26-.88l-1.54-.1a2.81 2.81 0 01-3-2.59c-.27-2.33.84-3.86 4.21-4.26a12.68 12.68 0 012.54 0c.07 1 0 2.18 0 2.18l-.26.18a14.19 14.19 0 00-2.06 0c-1.43.17-1.74.7-1.67 1.31s.38.78 1 .82l1.43.12c2.19.16 3.17 1.1 3.37 2.8.28 2.36-.79 3.85-4.09 4.24a13.77 13.77 0 01-3 .07 16.77 16.77 0 01-.49-2.24z"
        transform="translate(-23.11 -10.02)"
      />
      <circle className="prefix__cls__coffeeDonate-17" cx={218.57} cy={38.78} r={19.78} />
      <circle className="prefix__cls__coffeeDonate-18" cx={218.43} cy={38.84} r={17.34} />
      <path
        className="prefix__cls__coffeeDonate-19"
        d="M257.11 41.22a17.83 17.83 0 01-4.48 21c-7.71 6.35-19 2.84-19 2.09 0-.51 5 .35 9.72-2.86 6.33-4.34 9.29-14.31 6.5-21.48-2.22-5.7-7.64-7.75-7.25-8.41s10.48 1.44 14.51 9.66z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-20"
        d="M229 50.38c.41 7.61 8.12 12.37 7.68 12.87S228.36 60 226.54 52.7c-1.69-6.76 2.15-14.92 8.75-17.9 7.12-3.22 14.74.81 14.68 1.14s-6.88-2.56-12.95.58A15.48 15.48 0 00229 50.38z"
        transform="translate(-23.11 -10.02)"
      />
      <path
        className="prefix__cls__coffeeDonate-21"
        d="M238.31 52.21l.22-.23a16.42 16.42 0 002.71-.41c1.49-.38 1.81-.91 1.62-1.68-.13-.47-.47-.77-1.37-.7l-1.49.11a2.8 2.8 0 01-3.29-2.17c-.58-2.27.31-3.94 3.59-4.78a12.67 12.67 0 012.52-.37c.21 1 .35 2.16.35 2.16l-.24.21a14 14 0 00-2 .3c-1.4.36-1.63.93-1.48 1.53s.48.72 1.14.67l1.43-.08c2.19-.13 3.29.67 3.72 2.32.59 2.31-.26 3.93-3.48 4.76a13.63 13.63 0 01-3 .48 17.91 17.91 0 01-.95-2.12z"
        transform="translate(-23.11 -10.02)"
      />
      {/* <g opacity={1}>
        <rect
          x={179.08}
          y={153.52}
          width={32.14}
          height={32.14}
          rx={4}
          fill="#fff"
        />
        <image
          width={300}
          height={300}
          transform="matrix(.107 0 0 .107 179.08 153.52)"
          xlinkHref={`http://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/b_auto,c_pad,h_60,w_60/r_8/v1/${props.iconLogo}`}
        />
      </g> */}
    </svg>
  
    </div>
)
}

export default CoffeeDonate
