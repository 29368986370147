import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Spinner } from 'reactstrap';
import Flex from './Flex';
import ButtonIcon from './ButtonIcon';
import ErrorMessage from './ErrorMessage';

const AlertButton = ({  children, color, onClick, buttonTitle, loading, error }) => (

<Alert color={color} fade={false}>
        <Flex align='center' wrap>

        <p className='m-0'>{children}</p>

        <ButtonIcon
              disabled={ loading}
              className="ml-auto px-5 mt-2"
              icon={false ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              hideIcon={loading}
              color={color} 
              onClick={onClick}

        >{(!loading ) && buttonTitle }
        {loading && <Spinner  size={"sm"}/>}

        </ButtonIcon>
      
        </Flex>
        
        {error &&<ErrorMessage error={error}/>}
      </Alert>
);

AlertButton.propTypes = {
  className: PropTypes.node,
  onClick: PropTypes.node,
  color: PropTypes.string.isRequired,
  children: PropTypes.node,
  buttonTitle:  PropTypes.string.isRequired,
};

export default AlertButton;
