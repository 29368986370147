import React, { useState, useEffect, Fragment } from 'react';

import { gql, useMutation,  } from '@apollo/client';
import Flex from '../../common/Flex';
import { Button, Spinner, Row, Col, Card,CardBody,CardTitle  } from 'reactstrap';
import ErrorMessage from '../../common/ErrorMessage';
import ButtonIcon from '../../common/ButtonIcon';
import { Image } from 'cloudinary-react';
import CloudinaryModal from '../../cloudinary/CloudinaryModal';
import Photograph from '../../../assets/images/svgr/HeroImagePushkaInvite';

const HeroSection = ({   theme,refetch  }) => {
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const [heroImage, setHeroImage] = useState('')


  const UPDATE = gql`
  mutation upadatePushkaCharityTheme($input:pushkaThemeInput!){
    upadatePushkaCharityTheme(input:$input)
  }
`;
const insertPublicId = (publicId) => {
  setSelectedId(publicId)
  setModal(false)
}
  const [update, {error,loading}] = useMutation(UPDATE,  {variables:{input:{heroImage:selectedId}}})
  useEffect(() => {
    if (theme){
      setHeroImage(theme.heroImage)
    }
  }, [theme])
  const handleSubmit = e => {
    e.preventDefault();
    update().then( () => {
      refetch()
    }).catch(e => {})

  };
  return (
    <Fragment>  
    <CloudinaryModal modal={modal} setModal={setModal} insertPublicId={insertPublicId}/>     
     <p>The banner image is designed to illustrate the Pushka fundraising concept while highlighting your brand with a customized polaroid photo.</p>
      <p>Change the polaroid photo image below.</p>
      <Row noGutters>
      <Col xl={5}lg={8} md={10} sm={12}>
          <Card className='p-2 border border-primary mb-3'>
            <CardTitle>Preview:</CardTitle>
            <CardBody>
      <div style={{maxWidth:'400px'}}  >

      <Photograph className='img-fluid' publicid={selectedId?selectedId:heroImage} iconLogo={theme.iconLogo}/>
      </div>
            </CardBody>
          </Card>

          </Col></Row>
    
    <Flex align='center'>
     <Button className='mr-2'color='primary' onClick={() => {setModal(true)}}>Change</Button> 
      {error &&<ErrorMessage error={error}/>}
            <ButtonIcon
                  disabled={loading || !selectedId}
                  color="primary"
                  className=""
                  type="button"
                  onClick={handleSubmit}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  hideIcon={true}
                >
                  {(!loading ) && "Save Changes"}
                  {loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
    </Flex>

        

  </Fragment>


  );
};


export default HeroSection;
