
export const colors = [
    "#892B64","#723C70","#5C4D7D","#455E89","#2E6F95"
  ]
export const getAcronym = (str) => {
    return str.match(/\b(\w)/g).join('')
  }

export const formatCharities = (charities) => {
    return charities.map((each,i) => {
        return{
          ...each.charity,
          name:each.charity.name,
          text: getAcronym(each.charity.name),
          color:colors[i],
          EIN: each.charity.EIN,
          percentage: each.percent * 100,
          campaigns:each.campaigns
        }
      })
}