import React from 'react';
import PropTypes from 'prop-types';
import NavbarDonorDashboard from '../components/navbar/NavbarDonorDashboard';
const DonorDashboardLayout = ({  children }) => {

  return (
    <div>
      <NavbarDonorDashboard/>
      <div className=''>

      {children}
      </div>
    </div>


  );
};
DonorDashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DonorDashboardLayout;
