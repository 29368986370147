
import React, {  useState, useEffect, Fragment } from 'react';

import { gql, useQuery, useMutation   } from '@apollo/client';
import { Card, Table,Alert, Button, Spinner } from 'reactstrap';
import ButtonIcon from '../../common/ButtonIcon';


const DashboardSummmary  = ({}) => {
  const [availableNow, setAvailableNow] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [requested, setRequested] = useState(false)
  const [notEnoughFunds, setNotEnoughFund] = useState(false)
  const threshold = 150
  const [availableSoon, setAvailableSoon] = useState("")
  const [total, setTotal] = useState("")
  const SUMMARY = gql`
  query myCharitysBalanceSummary{
    myCharitysBalanceSummary{
      availableNow
      availableSoon
    }
  }
  `;
  const REQUEST = gql`
  mutation requestPayout{
    requestPayout
  }
  `;
  const {data,} = useQuery(SUMMARY)
  const [request,requestResponse] = useMutation(REQUEST)
  useEffect(() => {
    if(requestResponse.data){
      setRequested(true)
    }
  }, [requestResponse.data])

  useEffect(() => {
    if(data){
      if(data.myCharitysBalanceSummary.availableNow > threshold){
        setDisabled(false)
      }
      setAvailableNow(data.myCharitysBalanceSummary.availableNow)
      setAvailableSoon(data.myCharitysBalanceSummary.availableSoon)
      setTotal(
        (Number(data.myCharitysBalanceSummary.availableNow)
        + Number(data.myCharitysBalanceSummary.availableSoon)).toFixed(2)        )
    }
  }, [data])

  const requestPayout = () => {
    if(availableNow > threshold){
      request()
    }else{
      setNotEnoughFund(true)
      setRequested(true)
    }

  }
  return (
    <Card className='shadow-none p-4 mb-3 bg-white'>
    <h5 className='mb-2'>Balance:</h5>
    <div>

    <Table className=' table-sm table-borderless w-auto '>
      <tbody>
        <tr>
          <td className='pr-4 pl-0'>Available Now:</td>
          <td >${availableNow}</td>
        </tr>
        <tr>
          <td className='pr-4 pl-0'>Available Soon:</td>
          <td >${availableSoon}</td>
        </tr>
        <tr className='border-top border-black'>
          <td className='pr-4 pl-0 font-weight-semi-bold'>Total:</td>
          <td className='font-weight-semi-bold'>${total}</td>
        </tr>
      </tbody>
    </Table>
    </div>


    {!requested && 
    <Fragment>

    <div>
      <p>To transfer your balance to your bank account, click "Request Payout".</p>

    <ButtonIcon
                  disabled={requestResponse.loading || disabled }
                  color="primary"
                  className="px-5 mb-2 "
                  onClick={requestPayout}
                >
                  {(!requestResponse.loading ) && "Request Payout"}
                  {requestResponse.loading && <Spinner  size={"sm"}/>}
                </ButtonIcon>
                <p className='fs--1 text-muted'>Your available balance must be at least ${threshold.toFixed(2)} to request a payout.</p>
    </div>
    </Fragment>

                }
                {requested && !notEnoughFunds &&<Alert color='green'>
        <p className='mb-0'>We recieved your payout request. It may take 24-48 hours to process this request.</p>
    </Alert>}
                {requested && notEnoughFunds &&<Alert color='red'>
        <p className='mb-0'>Your available now balance must be at least ${threshold.toFixed(2)} to request a payout.</p>
    </Alert>}
               


  </Card>
  );
};

export default DashboardSummmary;
