import React, { Fragment } from 'react';
import {  Table  } from 'reactstrap';
import Flex from '../../common/Flex';


const TenantSelect = ({ tenants, onSelect,}) => {
  return (
    <Fragment>

<Table className='table-hover'>
      <thead>
        <tr>
          <th>Subdomain</th>
          <th>Subdomain</th>
        </tr>
      </thead>
      <tbody>
        {tenants.map((tenant,i) => {
          return(
            <tr onClick={()=>{onSelect(tenant)}} key={i}>
            <td>{
              <Flex align='center'>
                <p className='m-0'>{tenant.subdomain}</p>
              </Flex>
              }</td>
            <td>{tenant.subdomain}</td>
          </tr>
          )
      })}
      </tbody>
    </Table>

    </Fragment>
  );
};

export default TenantSelect;
