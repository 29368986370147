import React, { Fragment } from 'react';
import {  CardBody, CardText, CardSubtitle, CardTitle } from 'reactstrap';

import {  faLock,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useSelector} from 'react-redux'
import Flex from '../../../common/Flex';

const Step1 = () => {
    const {appName} = useSelector((state) => state.theme)
  return (
    <Fragment>

        <CardBody className='theme-wizard-header rounded  px-md-4  px-sm-3 px-3  py-sm-6 py-4'>
          <CardTitle>Create Account</CardTitle>
          <CardSubtitle className='mb-2'>{appName} lets you donate the spare change from your every day credit card purchases </CardSubtitle>

            <CardText className='' ><span>
            <FontAwesomeIcon icon={faLock} className="mr-2" size="sm"/></span>Protected by bank-level security</CardText>
        </CardBody>
        </Fragment>
  );
};

export default Step1;
